<template>
  <div class="page-wrapper">
    <div class="d-flex align-center justify-space-between mb-4">
      <h3 class="mr-4">Дни мастерской с темами</h3>
      <router-link
        class="gradient-button"
        :to="{
          name: 'theme-create',
          params: { workshopId: $route.params.workshopId },
        }"
        >Добавить</router-link
      >
    </div>

    <ul v-if="list.length" class="list">
      <li v-for="day in list" :key="day.id" class="gradient-list-item">
        <router-link
          class="gradient-list-item__content"
          :to="{
            name: 'theme-edit',
            params: { themeId: day.id, workshopId: $route.params.workshopId },
          }"
        >
          <h4>{{ getDateFormatWithTime(day.eventDateTimeBegin) }}</h4>
          <h4 v-if="day.eventSubject">Тема: {{ day.eventSubject }}</h4>
        </router-link>
      </li>
    </ul>
    <p v-else>Пока нет тем дня</p>
  </div>
</template>

<script>
import { useDatesChanger } from "@/composables/datesChanger";

export default {
  props: { list: { type: Array, required: false } },

  setup() {
    const { getDateFormatWithTime } = useDatesChanger();

    return { getDateFormatWithTime };
  },
};
</script>

<style lang="scss"></style>
