<template>
  <v-select
    v-if="filteredMonth.length"
    :items="filteredMonth"
    variant="outlined"
    item-value="value"
    item-title="title"
    density="compact"
    multiple
    placeholder="Месяц"
    hint="Вы можете выбрать 1 или несколько месяцев"
    hide-details
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template v-slot:prepend-item>
      <v-list-item
        title="Выбрать все"
        @click="selectAllMonth = !selectAllMonth"
      >
        <template v-slot:prepend>
          <v-checkbox-btn :model-value="selectAllMonth"></v-checkbox-btn>
        </template>
      </v-list-item>

      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { useDatesData } from "@/composables/datesData";
import { onMounted, defineProps, defineEmits, ref, watch } from "vue";

const { filteredMonth } = useDatesData();

defineProps(["modelValue"]);
const emits = defineEmits(["update:modelValue"]);

const allMonth = filteredMonth.map((v) => v.value);
const selectAllMonth = ref(true);

onMounted(() => {
  emits("update:modelValue", allMonth);
});

watch(selectAllMonth, toggle);

function toggle() {
  if (selectAllMonth.value) {
    emits("update:modelValue", allMonth);
  } else {
    emits("update:modelValue", []);
  }
}
</script>

