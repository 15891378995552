<template>
  <div v-if="cognation && user">
    <PageTitle title="Достижение" />

    <div v-if="cognation.creator.id === userId">
      <ModerationInfo :cognation="cognation" />

      <div class="d-flex mb-2 justify-end">
        <router-link
          :to="{
            name: 'cognation-edit',
            params: { cognationId: cognation.id },
          }"
          class="accent-button"
          >Редактировать</router-link
        >
      </div>
    </div>

    <div class="page-wrapper my-3">
      <h3 v-if="cognation.cognationName" class="mb-3">
        {{ cognation.cognationName }}
      </h3>
      <p v-if="cognation.cognationDescription">
        {{ cognation.cognationDescription }}
      </p>
    </div>

    <div class="page-wrapper my-3">
      <h3>Файлы</h3>

      <FilesPreview
        @update="$emit('update')"
        :files="cognation.cognationFiles"
        :links="{
          downloadUrl: '/downloadFile/cognation',
          deleteUrl: '/cognation/my/file/delete/',
        }"
        class="mb-5"
      />
    </div>

    <div class="page-wrapper my-3">
      <h3>Видео</h3>

      <CVideo :cognation="cognation" />
    </div>

    <div class="page-wrapper my-3">
      <div class="mb-2">
        <p class="text-grey">Категория</p>
        <p>{{ cognation.cognationCategory.name }}</p>
      </div>

      <div class="mb-2">
        <p class="text-grey">Автор</p>
        <p>
          {{ cognation.creator.lastname }} {{ cognation.creator.firstname }}
          {{ cognation.creator.secname }}
        </p>
      </div>

      <div class="mb-2">
        <p class="text-grey">Дата создания</p>
        <p>{{ getDateFormatWithTime(cognation.cognationCreateDate) }}</p>
      </div>
    </div>
  </div>
  <LoaderRing v-else />
</template>

<script setup>
import { useCognationsStore } from "@/store/cognations";
import { computed, defineEmits } from "vue";
import { useRoute } from "vue-router";

import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
import { useDatesChanger } from "@/composables/datesChanger";

import LoaderRing from "@/elements/LoaderRing.vue";
import FilesPreview from "@/components/FilesPreview.vue";
import ModerationInfo from "./ModerationInfo.vue";

import CVideo from "./CVideo.vue";
import PageTitle from "@/elements/PageTitle.vue";

defineEmits(["update"]);

const { user, userId } = storeToRefs(useAuthStore());
const route = useRoute();
const { getCognationById } = useCognationsStore();
const { getDateFormatWithTime } = useDatesChanger();

const cognation = computed(() => getCognationById(route.params.cognationId));
</script>
