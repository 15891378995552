<template>
  <div>
    <IamStudioEventEditor v-if="event" :event="event" class="mb-5" />

    <IStudioChildren
      v-if="sortedChildren.length"
      :sortedChildren="sortedChildren"
      @update="updateEvent"
      :event="event"
    />
  </div>
</template>

<script>
import { onMounted, watch, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { usePopupStore } from "@/store/popup";

import { customAxios } from "@/service/customAxios";
import { sortByChildLastname } from "@/util/common";

import IamStudioEventEditor from "./IamStudioEventEditor.vue";
import IStudioChildren from "./IStudioChildren.vue";

export default {
  setup() {
    const { showError } = usePopupStore();
    const route = useRoute();
    const event = ref(null);

    const studioChildren = ref([]);
    const sortedChildren = computed(() => {
      if (!studioChildren.value.length) {
        return [];
      }
      return sortByChildLastname(studioChildren.value);
    });

    watch(route, () => {
      if (route.params.eventId) {
        updateEvent();
      }
    });

    onMounted(() => {
      if (route.params.eventId) {
        updateEvent();
      }
    });

    async function updateEvent() {
      await getDataOfEvent();
      setEventData();
    }

    function setEventData() {
      studioChildren.value = event.value.eventChildList;
    }

    async function getDataOfEvent() {
      try {
        const r = await getEventById();
        event.value = r.data;
      } catch (e) {
        showError(e);
      }
    }

    async function getEventById() {
      return customAxios.get(`/i_studio/event/${route.params.eventId}`);
    }

    return { event, sortedChildren, updateEvent };
  },
  components: { IamStudioEventEditor, IStudioChildren },
};
</script>
