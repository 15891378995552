export function useSwotData() {
  function filterListById(list, id) {
    if (!list.length) {
      return [];
    }
    return list.filter((v) => v.tagTypeId === id);
  }
  return {
    filterListById,
  };
}
