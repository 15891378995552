<template>
  <v-card>
    <v-card-title class="d-flex justify-center">
      <EditorTitle name="SWOT анализа" :mode="mode" />
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <h3 v-if="currentChild">
            Ученик: {{ currentChild.firstname }} {{ currentChild.lastname }}
          </h3>
        </v-col></v-row
      >
      <v-row>
        <v-col cols="12">
          <v-switch
            v-model="isActive"
            :label="isActive ? 'Доступен' : 'Недоступен'"
            color="success"
            hide-details
          >
          </v-switch>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="d-flex justify-end">
      <AppButton class="bordered-item mr-3" @click="saveSwot">
        Сохранить
      </AppButton>
      <ClosingButton @close="$emit('close')" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, onMounted } from "vue";
import { useTutorStore } from "@/store/tutor";
import { storeToRefs } from "pinia";

import AppButton from "@/elements/AppButton.vue";
import ClosingButton from "@/elements/ClosingButton.vue";
import EditorTitle from "@/elements/EditorTitle.vue";

export default {
  components: {
    AppButton,
    ClosingButton,
    EditorTitle,
  },
  emits: ["saveSwot", "close"],
  setup(_, ctx) {
    const tutorStore = useTutorStore();
    const { currentChild, currentSwot } = storeToRefs(tutorStore);
    const isActive = ref(false);

    function saveSwot() {
      ctx.emit("saveSwot", {
        isActive: isActive.value,
      });
    }

    onMounted(() => {
      if (currentSwot.value) {
        isActive.value = currentSwot.value.isActive;
      }
    });

    return {
      currentChild,
      isActive,
      currentSwot,
      saveSwot,
    };
  },
};
</script>
