import { customAxios } from "@/service/customAxios";

export function useDiaryData() {
  function getSortedDiaryDays(diary) {
    if (!diary.dayList.length) {
      return [];
    }

    let currentDate = new Date(diary.beginPeriodDateTime);

    const filledResult = [];

    for (let i = 0; i < 6; i++) {
      filledResult[i] = {
        dayDate: new Date(currentDate),
        lessons: diary.dayList.filter(
          (v) => new Date(v.dayDate).getDate() === currentDate.getDate()
        ),
      };

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return filledResult;
  }

  function updateDiaryHandler({
    beginPeriodDateTime,
    endPeriodDateTime,
    childId,
  }) {
    return customAxios.post(`/diary/by_child/${childId}`, {
      beginPeriodDateTime: beginPeriodDateTime,
      endPeriodDateTime: endPeriodDateTime,
    });
  }

  return {
    updateDiaryHandler,
    getSortedDiaryDays,
  };
}
