<template>
  <div>
    <PageTitle title="Заметки о ребёнке" />
    <div class="page-wrapper">
      <NotesFilters />
    </div>

    <ComponentWithPagination
      :list="filteredSortedItems"
      @changeList="paginatedList = $event"
    >
      <ul>
        <li v-for="n in paginatedList" :key="n.id">
          <router-link
            v-if="n.isChildNote"
            :to="{
              name: 'child-note-page',
              query: {
                noteId: n.childNote.id,
                filterByChildId: $route.query.filterByChildId,
              },
            }"
            class="link pa-2 d-block"
          >
            <NotePreview :note="n.childNote" />
          </router-link>

          <router-link
            v-else
            :to="{
              name: 'users-group-note-page',
              query: {
                noteId: n.classNote.id,
                filterByChildId: $route.query.filterByChildId,
              },
            }"
            class="link pa-2 d-block"
          >
            <NotePreview :note="n.classNote" />
          </router-link>
        </li>
      </ul>
    </ComponentWithPagination>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useNotesStore } from "@/store/notes";
import { storeToRefs } from "pinia";

import { useNotes } from "@/composables/notes";
import { useRoute } from "vue-router";

import PageTitle from "@/elements/PageTitle.vue";
import NotesFilters from "@/components/notes/components/NotesFilters.vue";

import ComponentWithPagination from "@/components/ComponentWithPagination.vue";
import NotePreview from "./components/NotePreview.vue";

const { notes } = storeToRefs(useNotesStore());
const { getFilteredSortedItems, paginatedList } = useNotes();

const route = useRoute();

const filteredSortedItems = computed(() => {
  if (!notes.value.length) {
    return [];
  }

  const filteredByRecepient = notes.value
    .filter((n) => {
      if (route.query.filterByRecepient === "common" && n.isChildNote) {
        return false;
      }

      if (route.query.filterByRecepient === "individual" && !n.isChildNote) {
        return false;
      }

      return true;
    })
    .map((v) => ({ ...v, createDateTime: v.noteDateTime }));

  return getFilteredSortedItems(filteredByRecepient);
});
</script>
