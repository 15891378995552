<template>
  <div class="diary-day bg-white">
    <div class="diary-day__top">
      <p>{{ getDayTitle(day.dayDate) }}</p>
      <p>{{ getDateFormatWithoutTime(day.dayDate) }}</p>
    </div>

    <ul v-if="day.lessons.length > 0">
      <li
        v-for="l in day.lessons"
        :key="l.id"
        :class="{ comment: l.teacherComment }"
      >
        <div class="diary-day__subject">
          <span>{{ getTimeFromDate(l.dayDate) }}</span>
          <span>{{ l.subjectName }}</span>
        </div>

        <div v-if="l.teacherComment" class="diary-day__comment">
          <p>
            <CIcon icon="bubble-icon" />
            <span >Комментарий учителя:</span>
          </p>

          <span> {{ l.teacherComment }}</span>
        </div>
      </li>
    </ul>
    <p v-else class="pa-2">{{ TITLE_NO_LESSONS }}</p>
  </div>
</template>

<script setup>
import { useDatesChanger } from "@/composables/datesChanger";
import { dayOfWeekDict, TITLE_NO_LESSONS } from "@/util/common";
import { defineProps } from "vue";

import CIcon from "@/elements/CIcon.vue";

defineProps({
  day: {
    type: Object,
    required: true,
  },
});

const { getDateFormatWithoutTime, getTimeFromDate } = useDatesChanger();

function getDayTitle(date) {
  const d = new Date(date);
  return dayOfWeekDict[d.getDay()].title;
}
</script>

<style lang="scss">
.diary-day {
  border-radius: 16px;
  border: 1px solid rgba($main-1, 0.3);
  overflow: hidden;
  box-shadow: $shadow;

  & + & {
    margin-top: 16px;
  }

  &__top {
    color: #fff;
    padding: 8px;
    display: flex;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    justify-content: space-between;
    position: relative;

    p {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &::before {
      background-image: url("../../assets/school-pattern.png");
      background-size: cover;
      filter: opacity(0.8);
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba($main-1, 0.3);
    padding: 4px 8px;

    &.comment {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8px;

      .diary-day__comment {
        svg {
          stroke: rgba($main-1, 0.8);
        }

        p {
          display: flex;
          align-items: center;
          color: $main-1;
          text-transform: uppercase;
          font-size: 14px;
          font-style: normal;
          margin-bottom: 8px;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__subject {
    span:first-child {
      color: rgba($main-1, 0.9);
      margin-right: 8px;
    }
  }

  svg {
    margin-right: 8px;
    stroke: rgba(#000, 0.3);
  }

  &__comment {
    padding-left: 8px;
    border-left: 1px solid rgba($main-1, 0.1);
    font-style: italic;
    text-transform: capitalize;

    span {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 480px) {
    &__top {
      display: block;
      text-align: center;

      p + p {
        margin-top: 8px;
      }
    }

    li {
      padding: 8px;
      grid-template-columns: 1fr;
    }

    li,
    li.comment {
      display: grid;
      grid-template-columns: 1fr;
    }

    &__subject {
      padding-top: 8px;
      padding-bottom: 16px;
    }

    &__comment {
      border-left: none;
      border-top: 1px solid rgba($main-1, 0.1);
      padding-top: 8px;
    }
  }
}
</style>
