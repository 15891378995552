<template>
  <div class="my-5 text-black">
    <div v-html="showCurrenText(props.noteBody)"></div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const props = defineProps({ noteBody: String });

function showCurrenText(t) {
  if (route.query.filterBySearch) {
    const wordsExist = t
      .toLowerCase()
      .includes(route.query.filterBySearch.toLowerCase());

    if (route.query.filterBySearch && wordsExist) {
      const regExp = new RegExp(`(${route.query.filterBySearch})`, "ig");
      return t.replace(
        regExp,
        `<span class='text-deep-purple-lighten-2 font-weight-bold'>$1</span>`
      );
    }
  }

  return t.length < 100 ? t : t.slice(0, 100) + "...";
}
</script>
