<template>
  <div class="s-note-preview">
    <div v-if="item.isLastVersion" class="s-note-preview__top">
      Последняя версия
    </div>

    <div class="d-flex justify-space-between">
      <div class="s-note-preview__info">
        <v-label>ID</v-label>
        <p>{{ item.id }}</p>
      </div>

      <div class="s-note-preview__info">
        <v-label>Дата создания</v-label>
        <p>{{ getDateFormatWithTime(item.createDateTime) }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
const { getDateFormatWithTime } = useDatesChanger();

defineProps({ item: { type: Object, requred: false } });
</script>

<style lang="scss">
.s-note-preview {
  border: 1px solid $secondary;
  border-radius: 4px;
  transition: all 0.2s;
  background-color: #fff;
  box-shadow: $light-shadow;

  p {
    color: $secondary;
  }

  &.with-hover:hover {
    background-color: $secondary;
    p,
    label {
      color: #fff;
    }
  }

  &.with-hover:active {
    background-color: rgba($secondary, 0.7);
  }

  &__info {
    padding: 4px;
  }

  &__top {
    background-color: $secondary;
    color: #fff;
    padding: 4px;
  }
}
</style>
