<template>
  <v-select
    v-if="childrenGroupsSelector.length"
    :items="childrenGroupsSelector"
    variant="outlined"
    item-value="value"
    item-title="title"
    density="compact"
    multiple
    persistent-hint
    hint="Вы можете выбрать 1 или несколько классов"
    hide-details
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template v-slot:prepend-item>
      <v-list-item
        title="Выбрать все"
        @click="selectAllGroups = !selectAllGroups"
      >
        <template v-slot:prepend>
          <v-checkbox-btn :model-value="selectAllGroups"></v-checkbox-btn>
        </template>
      </v-list-item>

      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { useChildrenGroupsData } from "@/composables/childrenGroups";
import { onMounted, defineProps, defineEmits, ref, watch, computed } from "vue";

const { childrenGroupsSelector, getChildrenGroups } = useChildrenGroupsData();

const props = defineProps(["modelValue"]);
const emits = defineEmits(["update:modelValue"]);

const selectAllGroups = ref(true);

const allGroupsIds = computed(() => {
  if (!childrenGroupsSelector.value.length) {
    return [];
  }
  return childrenGroupsSelector.value.map((v) => Number(v.id));
});

onMounted(async () => {
  await getChildrenGroups();
  
  if (!props.modelValue.length) {
    emits("update:modelValue", allGroupsIds.value);
  }
});

watch(selectAllGroups, toggle);

function toggle() {
  if (selectAllGroups.value) {
    emits("update:modelValue", allGroupsIds.value);
  } else {
    emits("update:modelValue", []);
  }
}
</script>
