<template>
  <div class="child-competence-editor">
    <div class="d-flex justify-space-between flex-wrap">
      <h3 v-if="inboxCompetence" class="mr-2">{{ inboxCompetence.competenceLineName }}</h3>
      <AppButton
        @click="isCompetenceAddingShowed = true"
        class="gradient-button"
      >
        Добавить
      </AppButton>
    </div>

    <div v-if="props.modelValue.length" class="d-flex flex-wrap">
      <ClosableChip
        v-for="c in props.modelValue"
        :key="c.id"
        :data="c.competenceName"
        @closeChip="deleteCompetence(c.id)"
      />
    </div>
    <v-label
      v-else
      class="d-flex text-deep-purple-darken-1 font-weight-bold my-2"
      >Список пуст</v-label
    >

    <v-dialog v-model="isCompetenceAddingShowed">
      <CompetenceList
        :inboxCompetence="inboxCompetence"
        @editChildsCompetence="editChildsCompetence"
      />
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, defineProps,defineEmits } from "vue";

import CompetenceList from "./CompetenceList.vue";
import AppButton from "@/elements/AppButton.vue";
import ClosableChip from "./ClosableChip.vue";

const props = defineProps(["modelValue", "inboxCompetence", "addedCompetence"]);
const emits = defineEmits(["update:modelValue"]);

const isCompetenceAddingShowed = ref(false);

function editChildsCompetence(data) {
  emits("update:modelValue", data);
  isCompetenceAddingShowed.value = false;
}

function deleteCompetence(id) {
  emits('update:modelValue', props.modelValue.filter(v => v.id !== id))
}
</script>
