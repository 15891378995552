<template>
  <CustomForm
    title="журнала"
    @create="create"
    @remove="remove"
    :saveIsDisable="saveIsDisable"
  >
    <v-autocomplete
      v-model="selectedSchoolSubjectId"
      variant="outlined"
      :items="subjectsSelector"
      placeholder="Предмет"
      :rules="requiredField"
      hide-details="auto"
      density="compact"
      class="mb-4"
      :disabled="$route.name.includes('edit')"
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :title="item.raw.title"></v-list-item>
      </template>
    </v-autocomplete>

    <v-autocomplete
      v-model="selectedChildrenGroupId"
      label="Класс"
      variant="outlined"
      :items="childrenGroupsSelector"
      :rules="requiredField"
      class="mb-4"
      hide-details="auto"
      density="compact"
      :disabled="$route.name.includes('edit')"
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :title="item.raw.title"></v-list-item>
      </template>
    </v-autocomplete>

    <div
      :class="{
        error: v$.name.$errors.length,
        success: !v$.name.$errors.length && v$.name.$model && v$.name.$dirty,
      }"
      class="form__item"
    >
      <input
        v-model="v$.name.$model"
        type="text"
        @focus="v$.name.$touch"
        placeholder="Название журнала"
        maxlength="100"
        class="form__input"
      />

      <div
        :class="{ 'showing-tip': v$.name.$dirty && v$.name.$model.length }"
        class="form__tip-wrapper"
      >
        <div class="form__tip form__tip--counter showing">
          <span>Любые символы </span>
          <span> {{ v$.name.$model.length }} / 100 </span>
        </div>
      </div>

      <div
        class="input-errors"
        v-for="error of v$.name.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <div
      :class="{
        error: v$.description.$errors.length,
        success:
          !v$.description.$errors.length &&
          v$.description.$model &&
          v$.description.$dirty,
      }"
      class="form__item"
    >
      <textarea
        v-model="v$.description.$model"
        type="text"
        @focus="v$.description.$touch"
        placeholder="Описание журнала"
        maxlength="250"
        class="form__input"
      ></textarea>

      <div
        :class="{
          'showing-tip': v$.description.$dirty && v$.description.$model.length,
        }"
        class="form__tip-wrapper"
      >
        <div class="form__tip form__tip--counter showing">
          <span>Любые символы </span>
          <span v-if="v$.description.$model">
            {{ v$.description.$model.length }} / 250
          </span>
        </div>
      </div>

      <div
        class="input-errors"
        v-for="error of v$.description.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <div
      :class="{
        error: v$.period.$errors.length,
        success:
          !v$.period.$errors.length &&
          v$.period.$model.length &&
          v$.period.$dirty,
      }"
      class="form__item"
    >
      <DatePicker
        v-model="period"
        range
        autoApply
        locale="ru"
        placeholder="Период журнала"
        :enable-time-picker="false"
        @focus="v$.period.$touch"
        :clearable="false"
        :format="getRangeUIFormat"
      />

      <div
        class="input-errors"
        v-for="error of v$.period.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <v-switch
      v-model="isActive"
      color="success"
      :label="isActive ? 'активен' : 'не активен'"
    />
  </CustomForm>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useSchoolSubjectsData } from "@/composables/schoolSubjects";
import { requiredField } from "@/util/inputsRules";
import { useChildrenGroupsData } from "@/composables/childrenGroups";

import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";

import CustomForm from "@/components/CustomForm.vue";

const rules = {
  period: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  description: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 250 символов", maxLength(250)),
  },
  name: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
};
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { useSubjectsJournalStore } from "@/store/subjects-journal";

import { useRouter, useRoute } from "vue-router";
import { watch } from "vue";

import { useDatesChanger } from "@/composables/datesChanger";
import { storeToRefs } from "pinia";
import { debounce } from "@/util/common";

const route = useRoute();
const router = useRouter();
const { getRangeUIFormat } = useDatesChanger();

const { journal } = storeToRefs(useSubjectsJournalStore());
const { clearJournal } = useSubjectsJournalStore();

const { showToastPopup, showError, showConfirmPopup, closePopup } =
  usePopupStore();

const name = ref("");
const description = ref("");
const period = ref([]);
const isActive = ref(false);

const journalData = computed(() => ({
  teacherLogName: name.value,
  teacherLogDescription: description.value,
  isActive: isActive.value,
}));

const v$ = useVuelidate(rules, {
  name: name,
  description: description,
  period: period,
});

const { subjectsSelector, selectedSchoolSubjectId, getSchoolSubjects } =
  useSchoolSubjectsData();

const { selectedChildrenGroupId, getChildrenGroups, childrenGroupsSelector } =
  useChildrenGroupsData();

onMounted(async () => {
  await getSchoolSubjects();
  await getChildrenGroups();

  if (route.name.includes("edit") && journal.value) {
    setCurrentData();
  }
});

const saveIsDisable = computed(
  () =>
    !selectedSchoolSubjectId.value ||
    !selectedChildrenGroupId.value ||
    !name.value.length ||
    !description.value ||
    !period.value.length
);

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();

      await removeTeacherLog();
      showToastPopup("Журнал удален");
      router.push({ name: "subject-journal-list" });
    } else {
      closePopup();
    }
  } catch (e) {
    showError(e);
  }
}

async function customConfirm() {
  return showConfirmPopup("Вы точно хотите удалить журнал?");
}

async function removeTeacherLog() {
  return customAxios.delete(`/teacher/log/${journal.value.id}`);
}

async function create() {
  try {
    await createNewJournal();
    showToastPopup("Успешно сохранено");
    router.push({ name: "subject-journal-list" });
  } catch (e) {
    showError(e);
  }
}

function createNewJournal() {
  return customAxios.post("/teacher/log", {
    scheduleClassId: selectedSchoolSubjectId.value,
    personGroupId: selectedChildrenGroupId.value,
    beginDateTime: period.value[0],
    endDateTime: period.value[1],
    ...journalData.value,
  });
}

const editPage = route.name === "subject-journal-edit";

watch(journal, (j) => {
  if (editPage && j) {
    setCurrentData();
  }
});

function setCurrentData() {
  selectedChildrenGroupId.value = journal.value.personGroupId;
  selectedSchoolSubjectId.value = journal.value.scheduleClassId;
  name.value = journal.value.teacherLogName;
  period.value = [journal.value.beginDateTime, journal.value.endDateTime];
  description.value = journal.value.teacherLogDescription;
  isActive.value = journal.value.isActive;
}

const debouncedEditJournal = debounce(editJournal, 1000);

watch(name, (n) => {
  if (editPage && n !== journal.value.teacherLogName) {
    debouncedEditJournal();
  }
});

watch(description, (n) => {
  if (editPage && n !== journal.value.teacherLogDescription) {
    debouncedEditJournal();
  }
});

watch(isActive, (n) => {
  if (editPage && n !== journal.value.isActive) {
    editJournal();
  }
});

watch(period, (p) => {
  if (editPage && journal.value) {
    const periodChanged =
      p[0] !== journal.value.beginDateTime ||
      p[1] !== journal.value.endDateTime;

    if (periodChanged) {
      editJournal();
    }
  }
});

async function editJournal() {
  if (editPage) {
    try {
      await editJournalHandler();
      showToastPopup("Изменения сохранены");
    } catch (e) {
      showError(e);
    }
  }
}

async function editJournalHandler() {
  return customAxios.put("/teacher/log/" + journal.value.id, journalData.value);
}

onUnmounted(clearJournal);
</script>
