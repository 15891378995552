<template>
  <router-link
    :to="{ name: 'news-article', params: { articleId: item.id } }"
    class="news-card"
    :class="{ 'news-card--big': bigCard }"
  >
    <div class="news-card__img">
      <img :src="getImage(item.newsBody)" />
    </div>

    <div class="news-card__content">
      <div class="news-card__creator">
        <p>{{ getDateFormatWithoutTime(item.createDateTime) }}</p>

        <p>
          {{ item.creator.firstname }}
          {{ item.creator.lastname }}
        </p>
      </div>
      <div class="news-card__title">{{ item.newsHeader }}</div>
    </div>
  </router-link>
</template>

<script>
import { useDatesChanger } from "@/composables/datesChanger";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    bigCard: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const { getDateFormatWithoutTime } = useDatesChanger();

    function getImage(code) {
      const re = /(?<=src=")[^"]+/;
      const newStr = code.match(re);
      return newStr;
    }

    return { getImage, getDateFormatWithoutTime };
  },
};
</script>

<style lang="scss">
.news-card {
  border-radius: 16px;
  box-shadow: $shadow;
  height: 100%;
  display: block;
  text-decoration: none;
  transition: 0.2s;
  position: relative;

  &__title {
    font-weight: 500;
    color: $main-2;
    font-size: 16px;
    line-height: 20px;
  }

  &__content {
    padding: 8px 16px;
    padding-bottom: 32px;
  }

  &__img {
    height: 150px;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    background-image: url("../assets/school-pattern.png");

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__creator {
    color: $light-text;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;

    display: flex;
    flex-wrap: wrap;

    p:first-child {
      margin-right: 8px;
      position: relative;
      padding-right: 8px;

      &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 4px;
        background-color: $light-text;
        border-radius: 50%;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
      }
    }
  }

  &:hover {
    transform: scale(1.02);
  }

  &--big {
    border-radius: 16px;

    .news-card__img {
      border-radius: 16px;
      height: 300px;
    }

    .news-card__title {
      font-size: 22px;
      color: #fff;
    }

    .news-card__creator {
      margin-bottom: 16px;
      color: #fff;
      font-size: 16px;

      p:first-child::after {
        background-color: #fff;
      }
    }

    .news-card__content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: 0 0 16px 16px;
      padding: 30px;

      background-color: rgba($main-2, 0.7);
    }
  }
}
</style>
