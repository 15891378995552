<template>
  <div>
    <PageTitle
      :title="`Все SWOT анализы ${
        swot !== null
          ? `для ${swot.child.lastname} ${swot.child.firstname}`
          : ''
      }`"
    />
    <div v-if="swots.length > 0">
      <TogglerOfWrapper>
        <template v-slot:name>Подсказка</template>
        <template v-slot:content>
          <p class="my-2">Это список анализов ребёнка</p>
          <p>Для того, чтобы перейти к определенной копии:</p>
          <ol class="pl-4">
            <li>
              Нажмите на <strong>Показать копии анализа</strong> у нужного вам
              SWOT-а
            </li>
            <li>Нажмите на <strong>элемент из списка</strong></li>
          </ol>
        </template>
      </TogglerOfWrapper>

      <SPreview v-for="s in swots" :item="s" :key="s.id" class="my-3" />
    </div>
    <NothingFound v-else />
  </div>
</template>

<script setup>
import { useSwotStore } from "@/store/swot";
import { storeToRefs } from "pinia";

import SPreview from "./SPreview.vue";
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import NothingFound from "@/elements/NothingFound.vue";

import PageTitle from "@/elements/PageTitle.vue";

const { swots, swot } = storeToRefs(useSwotStore());
</script>
