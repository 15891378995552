<template>
  <StatusWrapper
    :loadingStatus="loadingStatus"
    :is-data-existed="cognations.length > 0"
  >
    <router-view />
  </StatusWrapper>
</template>

<script setup>
import { usePopupStore } from "@/store/popup";
import { useCognations } from "@/composables/cognations";
import { useCognationsStore } from "@/store/cognations";

import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";

import StatusWrapper from "@/components/StatusWrapper.vue";

const { showError } = usePopupStore();
const { getCognationsHandler } = useCognations();
const { setCognations } = useCognationsStore();

const { cognations } = storeToRefs(useCognationsStore());

onMounted(getCognations);

const loadingStatus = ref("nothing");

async function getCognations() {
  loadingStatus.value = "loading";

  try {
    const r = await getCognationsHandler();
    setCognations(r.data);
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}
</script>
