import { computed } from "vue";
import { useRoute } from "vue-router";

export function useRussianReportsData() {
  const route = useRoute();

  const subjectTitle = computed(() => {
    let title;

    switch (route.params.subjectId) {
      case "53":
        title = "Русскому языку";
        break;
      case "25":
        title = "Литературе";
        break;
      default:
        "предмету";
    }

    return title;
  });

  return {
    subjectTitle,
  };
}
