<template>
  <div class="journals-search page-wrapper">
    <v-label
      v-if="!subjectsSelector.length && !childrenGroupsSelector.length"
      class="text-deep-purple-darken-1 font-weight-bold mt-2"
    >
      Подтягиваем Классы и Предметы...
    </v-label>

    <div v-else>
      <div class="filters-inputs">
        <v-select
          v-model="selectedSchoolSubjectId"
          :items="subjectsSelector"
          variant="outlined"
          density="compact"
          hide-details
          placeholder="Предмет"
        ></v-select>

        <v-select
          v-model="selectedChildrenGroupId"
          :items="childrenGroupsSelector"
          variant="outlined"
          density="compact"
          hide-details
          placeholder="Класс"
        />

        <v-select
          v-if="periodsSelector.length"
          v-model="selectedJournalPeriod"
          :items="periodsSelector"
          variant="outlined"
          density="compact"
          hide-details
          placeholder="Периоды"
        />
        <v-label
          v-if="
            !periodsSelector.length &&
            selectedSchoolSubjectId &&
            selectedChildrenGroupId
          "
          class="text-deep-purple-darken-1 font-weight-bold mt-2"
        >
          Подтягиваем периоды...</v-label
        >
      </div>

      <v-label
        v-if="!selectedSchoolSubjectId || !selectedChildrenGroupId"
        class="text-deep-purple-darken-1 font-weight-bold mt-2"
      >
        Выберите сначала Класс и Предмет</v-label
      >
    </div>
  </div>
</template>

<script setup>
import { useSchoolSubjectsData } from "@/composables/schoolSubjects";
import { computed, ref, watch, onMounted } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

import { useChildrenGroupsData } from "@/composables/childrenGroups";
import { customAxios } from "@/service/customAxios";
import { pushFilterToURL, removeFilterFromURL } from "@/service/router";

const cachedChildren = localStorage.getItem("ss-search-by-journals-children");
const cachedSubject = localStorage.getItem("ss-search-by-journals-subject");

const { getDateFormatWithoutTime } = useDatesChanger();

const periods = ref([]);
const selectedJournalPeriod = ref(null);

const { selectedChildrenGroupId, getChildrenGroups, childrenGroupsSelector } =
  useChildrenGroupsData();

const { subjectsSelector, getSchoolSubjects, selectedSchoolSubjectId } =
  useSchoolSubjectsData();

const periodsSelector = computed(() => {
  if (!periods.value.length) {
    return [];
  }
  return periods.value.map((v) => {
    return {
      value: v.id,
      title: `${getDateFormatWithoutTime(
        v.beginDateTime
      )} - ${getDateFormatWithoutTime(v.endDateTime)}`,
    };
  });
});

onMounted(async () => {
  await getSchoolSubjects();
  await getChildrenGroups();

  if (cachedChildren && cachedSubject) {
    setCachedData();
  }
});

function setCachedData() {
  selectedChildrenGroupId.value = Number(cachedChildren);
  selectedSchoolSubjectId.value = Number(cachedSubject);
}

watch(selectedSchoolSubjectId, async (v) => {
  if (v && selectedChildrenGroupId.value && Number(cachedSubject) !== selectedSchoolSubjectId.value) {
    clearPeriodsData();
    await getJournalsPeriods();
  }
});

watch(selectedChildrenGroupId, async (v) => {
  if (v && selectedSchoolSubjectId.value && Number(cachedChildren) !== selectedChildrenGroupId.value) {
    clearPeriodsData();
    await getJournalsPeriods();
  }
});

watch(selectedJournalPeriod, (id) => {
  if (id) {
    pushFilterToURL({ name: "journalId", value: id });
    cachSearchingData();
  } else {
    removeFilterFromURL("journalId");
  }
});

async function getJournalsPeriods() {
  try {
    const r = await customAxios.post(`/teacher/log/for_req`, {
      personGroupId: selectedChildrenGroupId.value,
      scheduleClassId: selectedSchoolSubjectId.value,
    });

    periods.value = r.data;
  } catch (e) {
    console.log(e);
  }
}

function clearPeriodsData() {
  periods.value = [];
  selectedJournalPeriod.value = null;
}

function cachSearchingData() {
  localStorage.setItem(
    "ss-search-by-journals-children",
    selectedChildrenGroupId.value
  );
  localStorage.setItem(
    "ss-search-by-journals-subject",
    selectedSchoolSubjectId.value
  );
}
</script>
