<template>
  <v-autocomplete
    v-if="checklistsSelector.length > 0"
    v-model="checklist"
    :items="checklistsSelector"
    variant="outlined"
    placeholder="Оценочный лист"
    item-value="value"
    item-title="title"
    density="compact"
    hide-details
    bg-color="white"
  ></v-autocomplete>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { customAxios } from "@/service/customAxios";
import { computed, onMounted, ref, watch } from "vue";
import { usePopupStore } from "@/store/popup";
import { pushFilterToURL, removeFilterFromURL } from "@/service/router";

const { showError } = usePopupStore();
const route = useRoute();

const checklists = ref([]);
const checklist = ref(null);
const checklistsSelector = computed(() => {
  if (!checklists.value.length) {
    return [];
  }

  return checklists.value.map((v) => ({
    title: v.listDescription,
    value: v.id,
  }));
});

watch(checklist, (id) =>
  id
    ? pushFilterToURL({ name: "filterByChecklist", value: id })
    : removeFilterFromURL("filterByChecklist")
);

watch(() => route.query.filterByChecklist, setChecklist);

function setChecklist() {
  if (!checklists.value.length || !route.query.filterByChecklist) {
    return;
  }
  const isExisted = checklists.value.find(
    (v) => v.id === Number(route.query.filterByChecklist)
  );
  checklist.value = isExisted ? Number(route.query.filterByChecklist) : null;
}

watch(() => route.query.filterByGroupId, getChecklists);
watch(() => route.query.filterByYear, getChecklists);

onMounted(getChecklists);

async function getChecklists() {
  if (!route.query.filterByGroupId || !route.query.filterByYear) {
    return;
  }
  clear();
  try {
    const r = await customAxios.post(
      "/foreign_checklist/report/list_of_checklists_by_year_and_group",
      {
        personGroupId: Number(route.query.filterByGroupId),
        studyYearId: Number(route.query.filterByYear),
      }
    );

    checklists.value = r.data;
  } catch (error) {
    showError(error);
  }
}

function clear() {
  checklist.value = null;
  checklists.value = [];
  removeFilterFromURL("filterByChecklist");
}
</script>
