<template>
  <div>
    <PageTitle title="Заметки из журналов по детям" />
    <div v-if="isParent || isTutor || notes.length > 0" class="page-wrapper">
      <div class="g-col g-col--1fr-max">
        <FilterByChild v-if="isParent" />
        <FilterByGroupAndChild v-if="isTutor" />
        <FilterByRecipient v-if="notes.length > 0" />
      </div>
    </div>
    <ComponentWithPagination
      :list="filteredSortedItems"
      @changeList="paginatedList = $event"
    >
      <ul>
        <li v-for="n in paginatedList" :key="n.id" class="my-2">
          <router-link
            v-if="isTutor"
            :to="{
              name: 'subject-journal-main',
              query: { journalId: n.journalId },
            }"
            class="link"
          >
            <CommentPreview :comment="n" />
          </router-link>
          <CommentPreview v-else :comment="n" />
        </li>
      </ul>
    </ComponentWithPagination>
  </div>
</template>

<script setup>
import { useNotesStore } from "@/store/notes";
import { storeToRefs } from "pinia";
import { computed } from "vue";

import { useNotes } from "@/composables/notes";
import { useAuthStore } from "@/store/auth";

import PageTitle from "@/elements/PageTitle.vue";
import ComponentWithPagination from "../ComponentWithPagination.vue";
import FilterByGroupAndChild from "../filtersElements/FilterByGroupAndChild.vue";

import FilterByChild from "../filtersElements/FilterByChild.vue";
import CommentPreview from "./components/CommentPreview.vue";
import FilterByRecipient from "../filtersElements/FilterByRecipient.vue";

const { isParent, isTutor } = useAuthStore();
const { notes } = storeToRefs(useNotesStore());

const { getFilteredSortedItems, paginatedList } = useNotes();

const filteredSortedItems = computed(() => getFilteredSortedItems(notes.value));
</script>
