<template>
  <v-autocomplete
    v-if="masters.length"
    :items="mastersOptions"
    outlined
    dense
    variant="outlined"
    density="compact"
    placeholder="Сотрудники"
    multiple
    return-object
    hide-details
    chips
  >
    <template v-slot:chip="{ props, item }">
      <v-chip v-bind="props" :text="item.title"></v-chip>
    </template>

    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :title="item.title"></v-list-item>
    </template>
  </v-autocomplete>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { computed, onMounted, ref } from "vue";

const { showError } = usePopupStore();

onMounted(getMasters);

const masters = ref([]);

const mastersOptions = computed(() => {
  if (!masters.value.length) {
    return [];
  }
  return masters.value.map((v) => ({
    value: v.id,
    title: `${v.firstname} ${v.secname} ${v.lastname}`,
    id: v.id,
  }));
});

async function getMasters() {
  try {
    const r = await getStudioMasters();
    masters.value = r.data;
  } catch (e) {
    showError(e);
  }
}

async function getStudioMasters() {
  return customAxios.get("/user/studio_masters");
}
</script>

<style lang="scss"></style>
