<template>
  <div>
    <TableElement>
      <template v-slot:tabs>
        <v-tabs bg-color="primary-dark" v-model="tab">
          <v-tab value="1">Новости</v-tab>
        </v-tabs>
      </template>
      <template v-slot:window>
        <v-card-text>
          <v-window v-model="tab" class="align-self-stretch">
            <v-window-item value="1" class="align-self-stretch">
              <v-dialog v-model="showFileInput" persistent scrollable>
                <v-form ref="myFileInputForm">
                  <v-card>
                    <v-card-title class="card_ligth_header">
                      <h5>Загрузка файла новости</h5>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-file-input
                              show-size
                              v-model="currentFiles"
                              ref="files"
                              accept="image/png, image/bmp, image/jpeg,.pdf"
                              clearable
                              multiple
                              variant="outlined"
                              counter
                              rounded
                              label="Нажмите, чтобы рикрепить файлы"
                            ></v-file-input>
                          </v-col>

                          <v-col cols="12">
                            <v-progress-linear
                              v-if="uploadPercentage > 0"
                              color="amber"
                              v-model="uploadPercentage"
                              height="25"
                            >
                              <strong
                                >{{ Math.ceil(uploadPercentage) }}%</strong
                              >
                            </v-progress-linear>
                          </v-col>
                        </v-row>
                      </v-container>
                      <small>*обязательно для заполнения</small>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="card_ligth_actions">
                      <v-spacer></v-spacer>
                      <v-btn
                        rounded
                        variant="outlined"
                        class="primary-dark"
                        style="margin-left: 5px"
                        @click="closeFileInput"
                        color="primary-dark"
                      >
                        Отмена
                      </v-btn>
                      <v-btn
                        rounded
                        variant="outlined"
                        class="primary"
                        style="margin-left: 5px"
                        :disabled="uploadPercentage > 0"
                        @click="saveFiles"
                      >
                        Сохранить
                        <v-progress-circular
                          v-if="downloadAnimation"
                          indeterminate
                          color="amber"
                        ></v-progress-circular>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
              <v-card v-if="isNewsEditorShow">
                <v-card-title class="card_ligth_header">
                  <h5 v-if="editingNews">Редактирование новости</h5>
                  <h5 v-else>Новая новость</h5>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-form ref="teacherLogForm">
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            label="Заголовок*"
                            v-model="newsHeader"
                            :rules="newsHeaderRules"
                            variant="outlined"
                            :counter="300"
                            hint="Укажите заголовок новости"
                            required
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="py-2" style="height: 650px">
                          <quill-editor
                            style="height: 550px"
                            v-model:value="newsBody"
                            :options="{
                              modules: {
                                imageDrop: true,
                                imageResize: true,
                                htmlEditButton: {
                                  debug: true,
                                  syntax: false,
                                  buttonTitle: 'Показать HTML код',
                                  msg: 'Отредактируйте HTML  в этом окне,  для сохранения нажмите - сохранить',
                                  okText: 'Сохранить',
                                  cancelText: 'Отмена',
                                  buttonHTML: '&lt;&gt;',
                                  prependSelector: 'div#myelement',
                                  editorModules: {},
                                },
                                imageCompress: {
                                  quality: 0.7, // default
                                  maxWidth: 1000, // default
                                  imageType: 'image/jpeg', // default
                                  debug: true, // default
                                  suppressErrorLogging: false, // default
                                  insertIntoEditor: undefined, // default
                                },
                              },
                            }"
                          />
                        </v-col>

                        <v-col cols="12" class="py-2">
                          <v-autocomplete
                            v-model="personGroups"
                            label="Группа пользователей"
                            variant="outlined"
                            multiple
                            chips
                            :items="personGroupList"
                            :rules="personGroupsRules"
                          >
                            <template v-slot:item="{ props, item }">
                              <v-list-item
                                v-bind="props"
                                :title="item.raw.personGroupName"
                              ></v-list-item>
                            </template>
                            <template v-slot:chip="{ props, item }">
                              <v-chip
                                v-bind="props"
                                :prepend-avatar="item.raw.avatar"
                                :text="item.raw.personGroupName"
                              ></v-chip>
                            </template>
                          </v-autocomplete>
                        </v-col>

                        <v-col cols="12" class="py-2">
                          <v-row class="px-4" fluid>
                            <v-switch
                              v-model="published"
                              :label="`Опубликовано`"
                              color="success"
                            >
                            </v-switch>
                            <v-chip v-if="published">Да</v-chip>
                            <v-chip v-else> Нет</v-chip>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-col cols="12" class="w-100">
                  <v-label>Прикрепленные файлы:</v-label>
                  <v-chip-group class="mt-3" v-if="newsFiles.length">
                    <v-chip
                      v-for="(f, i) in newsFiles"
                      :key="i"
                      closable
                      close-icon="mdi-delete"
                      @click:close="removeFile(f)"
                    >
                      {{ f.originalFileName }}
                    </v-chip>
                  </v-chip-group>
                  <v-label v-else>Вы ничего не добавили</v-label>
                </v-col>
                <v-divider></v-divider>
                <v-card-actions
                  class="card_ligth_actions"
                  style="height: 100px"
                >
                  <v-col cols="12" class="py-2">
                    <v-row class="px-4" fluid>
                      <v-spacer></v-spacer>
                      <v-btn rounded variant="outlined" @click="closeNewsEditor"
                        >Отмена</v-btn
                      >
                      <v-btn @click="addFile" variant="outlined" rounded
                        >Добавить файлы</v-btn
                      >
                      <v-btn rounded variant="outlined" @click="saveNews"
                        >Сохранить</v-btn
                      >
                      <v-btn rounded variant="outlined" @click="deleteNews"
                        >Удалить</v-btn
                      >
                    </v-row></v-col
                  >
                </v-card-actions>
              </v-card>
              <v-card v-else>
                <v-card-title class="card_ligth_header">
                  <h3 class="ma-2">Новости</h3>
                </v-card-title>

                <v-card-title>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="newsSearchBody"
                          label="Найти"
                          variant="outlined"
                          clearable
                          hint="Введите ключевое слово для поиска"
                          rounded
                          @click:clear="newsSearchBody = ''"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          variant="outlined"
                          rounded
                          color="primary"
                          class="mb-4"
                          @click="isNewsEditorShow = true"
                          >Добавить</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="min-height: 80vh">
                  <div class="d-flex align-center flex-column ma-10">
                    <v-card
                      class="mx-auto w-100 ma-6"
                      min-width="400"
                      v-for="note in paginatedData"
                      :key="note.id"
                    >
                      <v-card-title
                        class="text-h5 pa-2 bg-deep-purple-lighten-4"
                      >
                        {{ note.newsHeader }}
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-actions class="bg-purple-lighten-5">
                        <v-list-item class="w-100">
                          <template v-slot:prepend>
                            <v-avatar color="grey-darken-3">
                              <v-img
                                v-if="note.creator.personAvatar"
                                :src="
                                  'https://school.eurekacenter.ru/avatar/' +
                                  note.creator.personAvatar
                                "
                                :alt="`${note.creator.firstname} ${note.creator.lastname}`"
                              ></v-img>
                              <v-icon
                                v-else
                                icon="mdi-image"
                                color="#fff"
                              ></v-icon>
                            </v-avatar>
                          </template>

                          <v-list-item-title
                            >{{ note.creator.firstname }}
                            {{ note.creator.lastname }}</v-list-item-title
                          >

                          <v-list-item-subtitle>{{
                            getDateFormatWithTime(note.createDateTime)
                          }}</v-list-item-subtitle>

                          <template v-slot:append>
                            <div class="justify-self-end">
                              <span>
                                <v-icon
                                  class="me-1"
                                  icon="mdi-newspaper"
                                  @click="openNews(note)"
                                ></v-icon>
                                <v-tooltip activator="parent" location="top"
                                  >Читать</v-tooltip
                                >
                              </span>

                              <span class="me-1">·</span>
                              <span>
                                <v-icon
                                  class="me-1"
                                  icon="mdi-pencil"
                                  @click="openNewsEditMode(note)"
                                ></v-icon>
                                <v-tooltip activator="parent" location="top"
                                  >Редактировать</v-tooltip
                                >
                              </span>
                              <span class="me-1">·</span>
                              <span>
                                <v-tooltip activator="parent" location="top">
                                  Группы:
                                  <v-chip-group v-if="note.personGroups">
                                    <v-chip
                                      v-for="v in note.personGroups"
                                      :key="v"
                                    >
                                      <v-chip class="ma-1" size="x-small">
                                        {{ v.personGroupName }}
                                      </v-chip>
                                    </v-chip>
                                  </v-chip-group>
                                </v-tooltip>
                                <v-icon
                                  class="me-1"
                                  icon="mdi-account-group"
                                ></v-icon>
                                <span
                                  v-if="note.personGroups"
                                  class="subheading me-2"
                                  >{{ note.personGroups.length }}</span
                                >
                                <span v-else class="subheading me-2">0</span>
                              </span>

                              <span class="me-1">·</span>
                              <span>
                                <v-tooltip activator="parent" location="top">
                                  Смотрели:
                                  <v-chip-group>
                                    <v-chip
                                      v-for="v in note.newsViews"
                                      :key="v"
                                    >
                                      <v-chip class="ma-1" size="x-small">
                                        {{ v.creator.firstname }}
                                        {{ v.creator.lastname }}
                                      </v-chip>
                                    </v-chip>
                                  </v-chip-group>
                                </v-tooltip>
                                <v-icon class="me-1" icon="mdi-eye"></v-icon>
                                <span
                                  v-if="note.newsViews"
                                  class="subheading me-2"
                                  >{{ note.newsViews.length }}</span
                                >
                                <span v-else class="subheading me-2">0</span>
                              </span>
                            </div>
                          </template>
                        </v-list-item>
                      </v-card-actions>
                    </v-card>
                  </div>
                </v-card-text>
                <v-card-actions
                  class="card_ligth_header"
                  style="min-height: 5vh"
                >
                  <v-container class="text-center">
                    <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :total-visible="3"
                      size="small"
                    ></v-pagination>
                  </v-container>
                </v-card-actions>
              </v-card>

              <v-row justify="center">
                <v-dialog v-model="showLoader" persistent>
                  <v-alert
                    ><span class="text-h5 text-primary"
                      >Загрузка данных
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular></span
                  ></v-alert>
                </v-dialog>
              </v-row>
            </v-window-item>
            <v-window-item value="2" class="align-self-stretch">
              <v-card v-if="!!selectedNews">
                <v-col cols="12">
                  <v-btn
                    variant="outlined"
                    rounded
                    color="primary"
                    class="mb-4"
                    @click="backToNews"
                    >Назад</v-btn
                  >
                </v-col>
                <v-card-title
                  ><h3 class="ma-4">
                    {{ selectedNews.newsHeader }}
                  </h3></v-card-title
                >
                <v-card-text>
                  <div class="ma-4" v-html="selectedNews.newsBody"></div>
                </v-card-text>
                <v-col cols="12" class="w-100" v-if="selectedNews.newsFiles">
                  <v-label>Прикрепленные файлы:</v-label>
                  <v-chip-group class="mt-3">
                    <v-chip
                      v-for="(f, i) in selectedNews.newsFiles"
                      :key="i"
                      @click="downloadFile(f)"
                    >
                      {{ f.originalFileName }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    variant="outlined"
                    rounded
                    color="primary"
                    class="mb-4"
                    @click="backToNews"
                    >Назад</v-btn
                  >
                </v-col>
              </v-card>
            </v-window-item>
          </v-window>
        </v-card-text>
      </template>
    </TableElement>
  </div>
</template>

<script>
import { customAxios } from "@/service/customAxios";

import TableElement from "../elements/TableElement.vue";

import { useUserData } from "@/composables/userData";
import { onMounted, ref } from "vue";
import { capitalizeFirstLetter } from "@/util/common";
import { watch } from "vue";
import Quill from "quill";
Quill.debug("error");
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "@taoqf/quill-image-resize-module";
import ImageCompress from "quill-image-compress";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/htmlEditButton", htmlEditButton);
Quill.register("modules/imageCompress", ImageCompress);
import htmlEditButton from "quill-html-edit-button";
import _ from "lodash";
import download from "js-file-download";

import { useDatesChanger } from "@/composables/datesChanger";
import { useNewsStore } from "@/store/news";
import { storeToRefs } from "pinia";
import { usePopupStore } from "@/store/popup";

const newsHeaders = [
  { text: "Заголовок", value: "newsHeader", sortable: true, with: "150px;" },
  { text: "Автор", value: "creator", with: "150px;" },
  { text: "Добавлено", value: "createDateTime", with: "150px;" },
  { text: "Файлы", value: "newsFiles", with: "150px;" },
  { text: "Видео", value: "newsVideos", with: "150px;" },
  { text: "Аудитория", value: "personGroups", with: "150px;" },
  { text: "Опубликовано", value: "published", sortable: true, with: "150px;" },
  { text: "Действие", value: "actions", with: "180" },
];

const newsHeaderRules = [
  (v) => !!v || "Поле обязательно для заполнения!",
  (v) => (v && v.length <= 300) || "Значение до должно превышать 300 символов!",
];

const personGroupsRules = [(v) => !!v || "Поле обязательно для заполнения!"];
const currentFiles = ref([]);
const newsFiles = ref([]);

export default {
  name: "AdminNews",
  components: {
    TableElement,
  },
  computed: {
    filteredData() {
      const ss = this.newsSearchBody.toLowerCase();
      let tempData = this.newsList;
      console.log("tempData:" + tempData.length);

      return tempData.filter((item) =>
        _.some(item, (v) => {
          if (!(v instanceof Object)) {
            return _.toLower(v).indexOf(ss) > -1;
          }
        })
      );
    },

    pageCount() {
      let l = this.filteredData.length,
        s = parseInt(this.noteCount);
      console.log("Page count: " + Math.ceil(l / s));
      return Math.ceil(l / s);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * parseInt(this.noteCount),
        end = start + parseInt(this.noteCount);
      return this.filteredData.slice(start, end);
    },
    currentPage() {
      if (this.pageCount < this.page) {
        return 1;
      } else {
        return this.page;
      }
    },
  },

  setup() {
    const { getDateFormatWithTime } = useDatesChanger();

    const selectedNewsObject = ref(1);
    const selectedNews = ref({});

    async function getNewsById() {
      if (
        selectedNewsObject.value !== null &&
        selectedNewsObject.value !== undefined
      ) {
        try {
          const r = await customAxios.get(
            `/news/${selectedNewsObject.value.id}`
          );
          console.log(r.data);
          selectedNews.value = r.data;
        } catch (e) {
          console.log(e);
        }
      }
    }
    const { news } = storeToRefs(useNewsStore());
    const { getAllNews, setNews } = useNewsStore();

    const { activeUserGroups, getActiveUserGroups } = useUserData();

    const newsBody = ref("");
    const newsHeader = ref("");
    const published = ref(false);
    const personGroups = ref([]);
    const personGroupList = ref([]);
    const newsList = ref([]);

    const tab = ref(1);

    const newsSearchBody = ref("");
    const id = ref();
    const showLoader = ref(false);
    const currentNewsId = ref(null);
    const currentNews = ref({});

    const isNewsEditorShow = ref(false);
    const editingNews = ref(false);
    const noteCount = ref(10);
    const page = ref(1);
    const allFiles = ref([]);
    const showFileInput = ref(false);
    const uploadPercentage = ref(0);
    const downloadAnimation = ref(false);
    const { showError } = usePopupStore();

    onMounted(getNews);

    async function getNews() {
      try {
        const r = await getAllNews();
        setNews(r.data);
      } catch (e) {
        showError(e);
      }
    }

    function setCurrentNewsData(news) {
      id.value = news.id;
      newsBody.value = news.newsBody;
      newsHeader.value = news.newsHeader;
      published.value = news.published;
      personGroups.value = news.personGroups;
      newsFiles.value = news.newsFiles;
    }
    async function removeFile(item) {
      try {
        customAxios.put(`/news/files/delete/${item.id}`).then((response) => {
          console.log(response);
          if (response.status === 202) {
            alert("Файл успешно удален!");
            setCurrentNewsData(response.data);
          } else if (response.status === 207) {
            alert(response.data.message);
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
    async function downloadFile(item) {
      try {
        const r = await customAxios.get(`/news/file/${item.id}`, {
          responseType: "blob",
        });
        download(r.data, item.originalFileName);
      } catch (e) {
        console.log(e);
      }
    }
    async function saveFiles() {
      let formData = new FormData();
      for (var i = 0; i < currentFiles.value.length; i++) {
        let file = currentFiles.value[i];
        formData.append("files", file);
      }
      customAxios
        .post(`/news/files/${id.value}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            uploadPercentage.value = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
        .then((response) => {
          if (response.status === 201) {
            alert("Файлы успешно сохранены!");
            uploadPercentage.value = 0;
            currentFiles.value = [];
            updateAllNewsList();
            showFileInput.value = false;
          } else if (response.status === 207) {
            alert(response.data.message);
            currentFiles.value = [];
            showFileInput.value = false;
            updateAllNewsList();
            console.log(response);
          }
        });
    }

    function cleanCurrentNews() {
      id.value = null;
      newsBody.value = "";
      newsHeader.value = "";
      published.value = null;
      personGroups.value = null;
      newsFiles.value = [];
    }

    function openNews(item) {
      selectedNewsObject.value = item;
      getNewsById();
      if (selectedNews.value) {
        console.log("selected news id: " + selectedNews.value.id);
        tab.value = 2;
      }
    }

    function addFile() {
      showFileInput.value = true;
    }
    function closeFileInput() {
      showFileInput.value = false;
    }
    async function saveNews() {
      showLoader.value = true;
      const options = {
        newsBody: capitalizeFirstLetter(newsBody.value),
        newsHeader: capitalizeFirstLetter(newsHeader.value),
        published: published.value,
        personGroups: personGroups.value,
      };

      try {
        if (id.value) {
          await customAxios.put(`/news/${id.value}`, options);
        } else {
          await customAxios.post(`/news`, options);
        }
        await updateAllNewsList();
        showLoader.value = false;
        cleanCurrentNews();
      } catch (e) {
        console.log(e);
      }
    }

    async function deleteNews() {
      if (confirm("Вы точно хотите удалить запись?")) {
        try {
          await customAxios.delete(`/news/${id.value}`);
          await updateAllNewsList();
          showLoader.value = false;
          cleanCurrentNews();
        } catch (e) {
          console.log(e);
        }
      }
    }

    function openNewsEditMode(log) {
      editingNews.value = true;
      isNewsEditorShow.value = true;
      setCurrentNewsData(log);
    }

    function closeNewsEditor() {
      isNewsEditorShow.value = false;
      cleanCurrentNews();
    }

    async function updateAllNewsList() {
      await getNews();
      isNewsEditorShow.value = false;
      newsList.value = news.value;
    }

    async function updateAllUserGroups() {
      await getActiveUserGroups();
      personGroupList.value = activeUserGroups.value;
    }

    async function backToNews() {
      showLoader.value = true;
      tab.value = 1;
      await updateAllNewsList();

      showLoader.value = false;
    }

    watch(selectedNewsObject, getNewsById);

    onMounted(async () => {
      showLoader.value = true;
      tab.value = "1";
      await updateAllUserGroups();
      await updateAllNewsList();

      showLoader.value = false;
    });

    return {
      selectedNewsObject,
      showLoader,

      tab,
      newsHeaders,

      newsSearchBody,
      isNewsEditorShow,
      newsList,
      openNews,
      openNewsEditMode,
      cleanCurrentNews,
      saveNews,
      closeNewsEditor,
      deleteNews,
      editingNews,
      newsBody,
      newsHeader,
      newsHeaderRules,
      personGroups,
      personGroupsRules,
      published,
      currentNewsId,
      personGroupList,
      currentNews,
      selectedNews,
      backToNews,
      getDateFormatWithTime,
      page,
      noteCount,
      currentFiles,
      allFiles,
      addFile,
      showFileInput,
      uploadPercentage,
      closeFileInput,
      downloadAnimation,
      saveFiles,
      newsFiles,
      downloadFile,
      removeFile,
    };
  },
};
</script>
<style lang="scss">
// .v-dialog .v-overlay__content {
//   max-width: 800px;
//   min-width: 800px;
// }
.img {
  max-width: none;
  width: 80%;
  padding: 20px;
}

.ul {
  padding: 20px;
}
</style>
