<template>
  <CustomForm
    title="мастерской"
    @create="create"
    @remove="remove"
    :saveIsDisable="saveIsDisable"
  >
    <div v-if="$route.name === 'workshop-edit'" class="mb-5">
      <div v-if="isAdmin" class="d-flex align-center mb-3">
        <v-label>ID мастерской:</v-label>
        <span class="ml-2">{{ currentWorkshop.id }}</span>
      </div>

      <div class="form__item">
        <div class="form__label">Время начала:</div>
        {{ commonStartTime }}
      </div>
      <div class="form__item">
        <div class="form__label">Период:</div>
        {{ getDateFormatWithoutTime(range[0]) }} -
        {{ getDateFormatWithoutTime(range[1]) }}
      </div>
    </div>

    <div class="form__item">
      <v-switch
        v-model="isPublished"
        color="success"
        :label="isPublished ? 'Опубликована' : 'Не опубликована'"
        hide-details
      />
    </div>

    <div v-if="isAdmin" class="form__item">
      <div class="form__label">Мастер</div>
      <v-autocomplete
        v-model="selectedMaster"
        :items="mastersOptions"
        required
        item-title="title"
        item-value="value"
        density="compact"
        variant="outlined"
        :disabled="!isAdmin"
      />
    </div>

    <div class="form__item">
      <div
        :class="{
          error: v$.year.$errors.length,
          success: !v$.year.$errors.length && v$.year.$model && v$.year.$dirty,
        }"
        class="form__item"
      >
        <div class="form__label">Учебный год*</div>
        <YearsSelector v-model="v$.year.$model" @change="v$.year.$touch" />

        <div
          class="input-errors"
          v-for="error of v$.year.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>
    </div>

    <div class="form__item">
      <div
        :class="{
          error: v$.title.$errors.length,
          success:
            !v$.title.$errors.length && v$.title.$model && v$.title.$dirty,
        }"
        class="form__item"
      >
        <div class="form__label">Название</div>
        <input
          v-model="v$.title.$model"
          type="text"
          @focus="v$.title.$touch"
          placeholder="Название"
          maxlength="100"
          class="form__input"
        />

        <div
          :class="{ 'showing-tip': v$.title.$dirty && v$.title.$model.length }"
          class="form__tip-wrapper"
        >
          <div class="form__tip form__tip--counter showing">
            <span>Любые символы </span>
            <span> {{ v$.title.$model.length }} / 100 </span>
          </div>
        </div>

        <div
          class="input-errors"
          v-for="error of v$.title.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>
    </div>

    <div class="form__item">
      <div
        :class="{
          error: v$.description.$errors.length,
          success:
            !v$.description.$errors.length &&
            v$.description.$model &&
            v$.description.$dirty,
        }"
        class="form__item"
      >
        <div class="form__label">Описание</div>
        <textarea
          v-model="v$.description.$model"
          type="text"
          @focus="v$.description.$touch"
          placeholder="Описание"
          maxlength="250"
          class="form__input"
        ></textarea>

        <div
          :class="{
            'showing-tip':
              v$.description.$dirty && v$.description.$model.length,
          }"
          class="form__tip-wrapper"
        >
          <div class="form__tip form__tip--counter showing">
            <span>Любые символы </span>
            <span> {{ v$.description.$model.length }} / 250 </span>
          </div>
        </div>

        <div
          class="input-errors"
          v-for="error of v$.description.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>
    </div>

    <div class="form__item">
      <div
        :class="{
          error: v$.quantity.$errors.length,
          success:
            !v$.quantity.$errors.length &&
            v$.quantity.$model &&
            v$.quantity.$dirty,
        }"
        class="form__item"
      >
        <div class="form__label">Количество мест</div>
        <input
          v-model="v$.quantity.$model"
          type="text"
          @focus="v$.quantity.$touch"
          placeholder="Описание"
          maxlength="250"
          class="form__input"
        />

        <div
          class="input-errors"
          v-for="error of v$.quantity.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="$route.name === 'workshop-create'" class="mt-4 mb-4">
      <div class="form__item">
        <div class="form__label">Время начала</div>
        <v-select
          v-model="commonStartTime"
          :items="['15:30', '17:00']"
          variant="outlined"
          density="compact"
          hide-details
        >
        </v-select>
      </div>

      <div class="form__item">
        <div
          :class="{
            error: v$.range.$errors.length,
            success:
              !v$.range.$errors.length && v$.range.$model && v$.range.$dirty,
          }"
          class="form__item"
        >
          <div class="form__label">Период мастерской</div>
          <DatePicker
            v-model="v$.range.$model"
            autoApply
            range
            locale="ru"
            :enable-time-picker="false"
            ignore-time-validation
            :disabled-week-days="[6, 0]"
            placeholder="Нажмите для выбора дат"
            :clearable="false"
            @focus="v$.range.$touch"
            :format="getRangeUIFormat"
          />

          <div
            class="input-errors"
            v-for="error of v$.range.$errors"
            :key="error.$uid"
          >
            <div class="error-msg form__tip form__tip--error showing">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="range.length" class="form__item">
        <div class="form__label">Дни занятий</div>

        <SelectorOfWorkshopDays
          :range="range"
          @changeWorkshopDates="workshopDates = $event"
        />
      </div>
    </div>
  </CustomForm>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import { customAxios } from "@/service/customAxios";

import { watch, defineEmits } from "vue";
import { useRoute, useRouter } from "vue-router";
import { debounce } from "@/util/common";

import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers, minValue } from "@vuelidate/validators";
import { useWorkshopStore } from "@/store/workshop";

import { usePopupStore } from "@/store/popup";
import { useDatesData } from "@/composables/datesData";
import { useEmployeeData } from "@/composables/employeeData";

import { useAuthStore } from "@/store/auth";

import CustomForm from "@/components/CustomForm.vue";
import SelectorOfWorkshopDays from "@/components/SelectorOfWorkshopDays.vue";
import YearsSelector from "@/components/filtersElements/YearsSelector.vue";

const emits = defineEmits(["updateList"]);

const WORKSHOP_DURATION = 60;

const rules = {
  title: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
  description: {
    max: helpers.withMessage("Не более 250 символов", maxLength(250)),
  },
  quantity: {
    min: helpers.withMessage("Не менее 1 места в группе", minValue(1)),
  },
  range: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  year: {
    required: helpers.withMessage("Обязательное поле", required),
  },
};

const { getTime } = useDatesData();
const { getMasters, mastersOptions } = useEmployeeData();
const { showToastPopup, showError } = usePopupStore();

const { getWorkshopById } = useWorkshopStore();
const { setDateTime, getDateFormatWithoutTime, addZero, getRangeUIFormat } =
  useDatesChanger();

const { isAdmin } = useAuthStore();

onMounted(getMasters);

const route = useRoute();
const router = useRouter();

const currentWorkshop = computed(() =>
  getWorkshopById(route.params.workshopId)
);

const title = ref("");
const description = ref("");
const quantity = ref(1);
const range = ref([]);

const isPublished = ref(false);
const commonStartTime = ref("15:30");
const workshopDates = ref([]);

const selectedMaster = ref(null);
const showDaysSelector = ref(false);
const year = ref(null);

const v$ = useVuelidate(rules, {
  title: title,
  quantity: quantity,
  description: description,
  range: range,
  year: year,
});

const saveIsDisable = computed(() => {
  v$.value.$touch();
  console.log(v$.value);

  return v$.value.$invalid;
});

onMounted(setCurrentData);

function setCurrentData() {
  if (!currentWorkshop.value) {
    return;
  }

  description.value = currentWorkshop.value.workshopDescription;
  title.value = currentWorkshop.value.workshopName;
  quantity.value = currentWorkshop.value.maxChildrenCount;
  range.value = [
    currentWorkshop.value.beginDateTime,
    currentWorkshop.value.endDateTime,
  ];
  isPublished.value = currentWorkshop.value.published;
  commonStartTime.value = getTime(
    currentWorkshop.value.workshopDays[0].eventDateTimeBegin
  );
  year.value = currentWorkshop.value.studyYearPeriodId;
  selectedMaster.value = currentWorkshop.value.master.id;
}

watch(range, () => {
  showDaysSelector.value = true;
});

watch(title, (t) => {
  if (currentWorkshop.value && t !== currentWorkshop.value.workshopName) {
    debouncedSave();
  }
});

watch(description, (t) => {
  if (
    currentWorkshop.value &&
    t !== currentWorkshop.value.workshopDescription
  ) {
    debouncedSave();
  }
});

watch(quantity, (v) => {
  if (currentWorkshop.value.maxChildrenCount !== v) {
    saveWorkshop();
  }
});

watch(isPublished, (v) => {
  if (currentWorkshop.value && currentWorkshop.value.published !== v) {
    saveWorkshop();
  }
});

watch(year, (v) => {
  if (currentWorkshop.value && currentWorkshop.value.studyYearPeriodId !== v) {
    saveWorkshop();
  }
});

watch(selectedMaster, (v) => {
  if (currentWorkshop.value && currentWorkshop.value.master.id !== v) {
    saveWorkshop();
  }
});

const debouncedSave = debounce(saveWorkshop, 1000);

async function create() {
  try {
    await createHandler();
    showToastPopup("Мастерская создана");
    escape();
  } catch (e) {
    showError(e);
  }
}

const options = computed(() => ({
  published: isPublished.value,
  workshopName: title.value,
  workshopDescription: description.value,
  maxChildrenCount: Number(quantity.value),
  studyYearPeriodId: Number(year.value),
  masterId: selectedMaster.value,
}));

async function createHandler() {
  return customAxios.post("/workshop/", {
    ...options.value,
    workshopType: commonStartTime.value === "15:30" ? 1 : 2,
    workshopDays: setWorkshopDays(),
    studyYearPeriodId: Number(year.value),
    beginDateTime: setDateTime(range.value[0]),
    endDateTime: setDateTime(range.value[1]),
  });
}

async function saveWorkshop() {
  if (!currentWorkshop.value) {
    return;
  }

  try {
    await saveWorkshopHandler();
    showToastPopup("Изменения сохранены");
  } catch (e) {
    showError(e);
  }
}

function saveWorkshopHandler() {
  return customAxios.put(`/workshop/${route.params.workshopId}`, {
    ...options.value,
    beginDateTime: range.value[0],
    endDateTime: range.value[1],
  });
}

async function remove() {
  if (!confirm("Вы точно хотите удалить запись?")) {
    return;
  }

  try {
    await removeHandler();
    showToastPopup("Мастерская удалена");
    router.push({ name: "master-workshops" });
  } catch (e) {
    showError(e);
  }
}

function removeHandler() {
  return customAxios.delete(`/workshop/${route.params.workshopId}`);
}

function escape() {
  emits("updateList");
  router.back();
}

function setDateTimeOfBegin(date) {
  return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(
    date.getDate()
  )}T${commonStartTime.value.split(":")[0]}:${
    commonStartTime.value.split(":")[1]
  }:00.000Z`;
}

function setWorkshopDays() {
  if (!workshopDates.value.length) {
    return [];
  }

  return workshopDates.value.map((v) => {
    return {
      eventDuration: WORKSHOP_DURATION,
      eventDateTimeBegin: setDateTimeOfBegin(v),
    };
  });
}
</script>
