import { defineStore } from "pinia";
export const useRatingSheetsStore = defineStore("rating-sheets", {
  state: () => ({
    list: null,
  }),
  actions: {
    setList(list) {
      this.list = list;
    },
    clearList() {
      this.list = null;
    },
  },
});
