import { defineStore } from "pinia";

export const useAttendanceStore = defineStore("attendance", {
  state: () => ({
    isPopupVisible: false,
    popup: null,
  }),
  actions: {
    setPopup(v) {
      this.isPopupVisible = true;
      this.popup = v;
    },
    clearPopup() {
      this.isPopupVisible = false;
      this.popup = null;
    },
  },
});
