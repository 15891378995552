<template>
  <ul class="side-menu-list">
    <li v-for="(item, i) in items" :key="i">
      <router-link
        v-if="item.type === 'link'"
        :to="{ name: item.name, params: { ...item.params } }"
        @click="$emit('close')"
      >
        <SideMenuLink
          :data="{
            icon: item.icon,
            title: item.title,
          }"
        />
      </router-link>

      <SideMenuSubMenu
        v-if="item.type === 'list'"
        :data="item"
        @close="$emit('close')"
      />

      <p v-if="item.type === 'title'">{{ item.title }}</p>
    </li>
  </ul>
</template>

<script>
import SideMenuLink from "./SideMenuLink.vue";
import SideMenuSubMenu from "./SideMenuSubMenu.vue";

export default {
  emits: ["close"],
  components: { SideMenuLink, SideMenuSubMenu },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.side-menu-list {
  margin: 0;
  padding: 0;
  margin-top: 16px;

  p {
    text-transform: uppercase;
    color: #93909e;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    padding: 30px 0 8px 0;
  }

  li {
    list-style: none;

    &:first-child p {
      padding-top: 8px;
    }
  }

  .router-link-active .side-menu-link {
    background-image: $light-gradient;
    color: #fff;
    border-radius: 4px;
    svg {
      stroke: #fff;
    }
  }
}
</style>
