<template>
  <div class="s-tag">
    <AppButton @click="$emit('showPoints', tag.id)" class="s-tag__top">
      <h4>{{ tag.name }}</h4>
      <span>+</span>
    </AppButton>

    <div class="s-tag__content">
      <ol v-if="addedPoints.length > 0" class="pl-5">
        <li v-for="p in addedPoints" :key="p.id">{{ p.name }}</li>
      </ol>

      <p v-else>Пока ничего нет...</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useSwotData } from "@/composables/swot";

import AppButton from "@/elements/AppButton.vue";

const props = defineProps({
  tag: { type: Object, required: true },
  note: { type: Object, required: true },
});

defineEmits(["showPoints"]);
const { filterListById } = useSwotData();

const addedPoints = computed(() => {
  if (!props.note || props.note.tagList.length === 0) {
    return [];
  }
  return filterListById(props.note.tagList, props.tag.id);
});
</script>

<style lang="scss">
.s-tag {
  border-radius: 8px;
  box-shadow: $light-shadow;
  background-color: #fff;

  h4 {
    color: #fff;
  }

  &__content {
    padding: 8px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 700;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #fff;
    background-color: $main-2;
    padding: 2px 8px;
    transition: all 0.2s;

    span {
      width: 20px;
      aspect-ratio: 1/1;
      font-size: 30px;
    }

    &:hover {
      background-color: rgba($main-2, 0.8);
    }

    &:active {
      background-color: rgba($main-2, 0.9);
    }
  }
}
</style>
