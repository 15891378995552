<template>
  <h2
    v-if="mode === 'creating' || mode === 'editing'"
    class="d-flex flex-wrap justify-center mb-10 text-pink-lighten-2 editor-title"
  >
    <span v-if="mode === 'editing'">Редактирование</span>
    <span v-else>Создание</span>

    <span class="ml-2">{{ name }}</span>

    <span v-if="date" class="ml-2"> {{ getDateFormatWithTime(date) }}</span>
  </h2>
</template>

<script>
import { useDatesChanger } from "@/composables/datesChanger";

export default {
  props: {
    name: {
      type: String,
      requied: true,
    },
    mode: {
      type: String,
      requied: true,
    },
    date: {
      type: String,
      required: false,
    },
  },
  setup() {
    const { getDateFormatWithTime } = useDatesChanger();

    return { getDateFormatWithTime };
  },
};
</script>

<style lang="scss">
.editor-title {
  line-height: 40px;
}
</style>
