import { defineStore } from "pinia";

export const useTutorStore = defineStore("tutor", {
  state: () => ({
    currentChild: null,
    currentSwot: null,
    currentLog: null,
  }),

  actions: {
    setCurrentChild(child) {
      this.currentChild = child;
    },
    clearCurrentChild() {
      this.currentChild = null;
    },
    setCurrentSwot(swot) {
      this.currentSwot = swot;
    },
    clearCurrentSwot() {
      this.currentSwot = null;
    },
    setCurrentLog(log) {
      this.currentLog = log;
    },
    clearCurrentLog() {
      this.currentLog = null;
    },
    setCurrentTheme(theme) {
      this.currentTheme = theme;
    },
    clearCurrentTheme() {
      this.currentTheme = null;
    },
  },
});
