<template>
  <router-view @update="getMineCognations"></router-view>
</template>

<script setup>
import { onMounted, watch } from "vue";
import { useCognationsStore } from "@/store/cognations";
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { useRoute } from "vue-router";

const { showError } = usePopupStore();

const route = useRoute();

const { setCognations } = useCognationsStore();

onMounted(getMineCognations);

watch(() => route, getMineCognations)

async function getMineCognations() {
  try {
    const r = await getMineCognationsHandler();
    setCognations(r.data);
  } catch (error) {
    showError(error);
  }
}

async function getMineCognationsHandler() {
  return customAxios.get("/cognation/my");
}
</script>
