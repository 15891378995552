<template>

  <v-container class="wrapper">
    <v-card class="mb-10">
      <v-card-title class="bg-primary">
        <h3 class="ma-2 text-shades-white">Компетенции</h3>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                  v-model="selectedClassId"
                  :items="classes"
                  item-title="scheduleClassName"
                  item-value="id"
                  dense
                  label="Предмет"
                  @click:append="getData()"
                  append-icon="mdi-note-search"
                  theme="light"
                  class="bg-shades-white"
                  variant="underlined"
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item
                      v-bind="props"
                      :title="item.raw.scheduleClassName"
                  ></v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <TableElement v-if="!!selectedClass & selectedClassId===lastSelectedId">
      <template v-slot:tabs>
        <v-tabs bg-color="primary-dark"  v-model="part">
          <v-tab :value="0">Линии</v-tab>
          <v-tab  disabled :value="1">Разделы</v-tab>
          <v-tab disabled :value="2">Умения</v-tab>
        </v-tabs>
      </template>
      <template v-slot:window >
        <v-card-text  class="align-self-stretch ma-0 pa-0">
          <v-window v-model="part" class="align-self-stretch h-75">
            <v-window-item :value="0" class="align-self-stretch">
              <v-card-title class="card_ligth_header">
                <h3 class="ma-2">Линия умений для "{{selectedClass.scheduleClassName}}"</h3>
              </v-card-title>
              <v-card-title class="card_ligth_header" style="min-height: 5vh">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="competenceLineSearchBody"
                        label="Найти"
                        variant="outlined"
                        clearable
                        hint="Введите ключевое слово для поиска"
                        class="ma-2"
                        rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                        @click="competenceLineDialog = true"
                        variant="outlined"
                        color="primary"
                        rounded
                        class="ma-2"
                    >Добавить
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="min-height: 80vh">
                <div class="d-none d-sm-block">
                  <Vue3EasyDataTable
                      :headers="competenceLineHeaders"
                      :items="competenceLines"
                      :search-value="competenceLineSearchBody"
                      multi-sort
                      alternating
                      show-index
                      :rows-per-page-message="'Записей на страницу'"
                      :rows-per-page = "10"
                      :rows-items="[10,15,20,25]"
                      :rows-of-page-separator-message	="'из'"
                      buttons-pagination
                      :empty-message="'Данные не добавлены'"
                  >
                    <template #item-createDateTime="{ createDateTime }">
                      <p>{{ createDateTime.replace("T", " ") }}</p>
                    </template>

                    <template #item-active="{active}">
                      <span v-if="!!active">Да</span>
                      <span v-else>Нет</span>
                    </template>

                    <template #item-actions="item">
                      <v-btn
                          @click="openCompetenceLineChapters(item)"
                          color="primary"
                          class="ma-2"
                          size="small"
                          variant="outlined"
                          rounded
                      >
                        Разделы
                      </v-btn>
                      <v-btn
                          @click="editCompetenceLine(item)"
                          color="primary"
                          class="ma-2"
                          size="small"
                          variant="outlined"
                          rounded
                      >
                        Редактировать
                      </v-btn>
                      <v-btn
                          @click="deleteCompetenceLine(item)"
                          class="ma-2"
                          size="small"
                          variant="outlined"
                          color="warning"
                          rounded
                      >
                        Удалить
                      </v-btn>
                    </template>
                  </Vue3EasyDataTable>
                </div>
              </v-card-text>
              <v-divider></v-divider>
            </v-window-item>
            <v-window-item :value="1" class="align-self-stretch">
              <v-card-title class="card_ligth_header">
                <h3 class="ma-2">Разделы линии "{{selectedCompetencaLine.competenceLineName}}"</h3>
              </v-card-title>
              <v-card-title class="card_ligth_header" style="min-height: 5vh">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="competenceChapterSearchBody"
                        label="Найти"
                        variant="outlined"
                        clearable
                        hint="Введите ключевое слово для поиска"
                        rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                        @click="backToLines"
                        variant="outlined"
                        color="primary"
                        size="small"
                        rounded
                        class="ma-2"
                    >
                      Назад
                    </v-btn>
                    <v-btn
                        @click="competenceChapterDialog = true"
                        variant="outlined"
                        color="primary"
                        size="small"
                        rounded
                        class="ma-2"
                    >
                      Добавить
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="min-height: 80vh">
                <div class="d-none d-sm-block">
                  <Vue3EasyDataTable
                      :headers=competenceChapterHeaders
                      :items="competenceLineChapters"
                      :search-value="competenceChapterSearchBody"
                      multi-sort
                      alternating
                      show-index
                      :rows-per-page-message="'Записей на страницу'"
                      :rows-per-page = "10"
                      :rows-items="[10,15,20,25]"
                      :rows-of-page-separator-message	="'из'"
                      buttons-pagination
                      :empty-message="'Данные не добавлены'"
                  >
                    <template #item-createDateTime="{ createDateTime }">
                      <p>{{ createDateTime.replace("T", " ") }}</p>
                    </template>

                    <template #item-active="{active}">
                      <span v-if="!!active">Да</span>
                      <span v-else>Нет</span>
                    </template>

                    <template #item-competenceLine="{competenceLine}">
                      <span v-if="!!competenceLine">
                        {{competenceLine.competenceLineName}}
                      </span>
                      <span v-else>Не задано</span>
                    </template>

                    <template #item-actions="item">
                      <v-btn
                          @click="openChapterCompetence(item)"
                          color="primary"
                          size="small"
                          class="ma-2"
                          variant="outlined"
                          rounded
                      >
                        Умения
                      </v-btn>
                      <v-btn
                          @click="editCompetenceChapter(item)"
                          color="primary"
                          size="small"
                          class="ma-2"
                          variant="outlined"
                          rounded
                      >
                        Редактировать
                      </v-btn>
                      <v-btn
                          @click="deleteCompetenceChapter(item)"
                          class="ma-2"
                          size="small"
                          variant="outlined"
                          color="warning"
                          rounded
                      >
                        Удалить
                      </v-btn>
                    </template>
                  </Vue3EasyDataTable>
                </div>
              </v-card-text>
              <v-divider></v-divider>
            </v-window-item>
            <v-window-item :value="2" class="align-self-stretch">
              <v-card-title class="card_ligth_header">
                <h3 class="ma-2">Умения раздела "{{selectedCompetenceChapter.competenceChapterName}}" </h3>
              </v-card-title>
              <v-card-title class="card_ligth_header" style="min-height: 5vh">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="CompetenceearchBody"
                        label="Найти"
                        variant="outlined"
                        clearable
                        hint="Введите ключевое слово для поиска"
                        rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                        @click="backToChapters"
                        variant="outlined"
                        color="primary"
                        rounded
                        class="ma-2"
                    >
                      Назад
                    </v-btn>
                    <v-btn
                        @click="competenceDialog = true"
                        variant="outlined"
                        color="primary"
                        rounded
                        class="ma-2"
                    >
                      Добавить
                    </v-btn>
                    <v-btn
                        @click="competenceListDialog = true"
                        variant="outlined"
                        color="primary"
                        rounded
                        class="ma-2"
                    >
                      Добавить списком
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="min-height: 80vh">
                <div class="d-none d-sm-block">
                  <Vue3EasyDataTable
                      :headers="competenceHeaders"
                      :items="Competence"
                      :search-value="CompetenceearchBody"
                      multi-sort
                      alternating
                      show-index
                      :rows-per-page-message="'Записей на страницу'"
                      :rows-per-page = "10"
                      :rows-items="[10,15,20,25]"
                      :rows-of-page-separator-message	="'из'"
                      buttons-pagination
                      :empty-message="'Данные не добавлены'"
                  >
                    <template #item-createDateTime="{ createDateTime }">
                      <p>{{ createDateTime.replace("T", " ") }}</p>
                    </template>

                    <template #item-active="{active}">
                      <span v-if="!!active">Да</span>
                      <span v-else>Нет</span>
                    </template>

                    <template #item-chapter="{chapter}">
                      <span v-if="!!chapter">
                        {{chapter.competenceChapterName}}
                      </span>
                      <span v-else>Не задано</span>
                    </template>


                    <template #item-actions="item">
                      <v-btn
                          @click="editCompetence(item)"
                          color="primary"
                          class="ma-2"
                          size="small"
                          variant="outlined"
                          rounded
                      >
                        Редактировать
                      </v-btn>
                      <v-btn
                          @click="deleteCompetence(item)"
                          class="ma-2"
                          size="small"
                          variant="outlined"
                          color="warning"
                          rounded
                      >
                        Удалить
                      </v-btn>
                    </template>
                  </Vue3EasyDataTable>
                </div>
              </v-card-text>
              <v-divider></v-divider>
            </v-window-item>
          </v-window>
        </v-card-text>
      </template>
    </TableElement>
    <v-row justify="center">
      <v-dialog v-model="competenceChapterDialog" scrollable persistent>
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Раздел компетенции для {{selectedClass.scheduleClassName}}</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="competenceChaptertForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                        label="Название*"
                        v-model="competenceChapterName"
                        :rules="competenceChapterNameRules"
                        variant="outlined"
                        :counter="100"
                        hint="Укажите название раздела"
                        required
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                        label="Описание*"
                        v-model="competenceChapterDescription"
                        :rules="competenceChapterDescriptionRules"
                        variant="outlined"
                        :counter="1000"
                        hint="Укажите описание раздела"
                        required
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12">
                    <v-row class="px-0" fluid>
                      <v-switch
                          v-model="active"
                          :label="`Доступна к использованию`"
                          color="success"
                      >
                      </v-switch>
                      <v-chip v-if="active">Да</v-chip>
                      <v-chip v-else> Нет</v-chip>
                    </v-row>
                  </v-col>

                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn rounded variant="outlined" @click="closeCompetenceChapter">
              Отмена
            </v-btn>
            <v-btn rounded variant="outlined" @click="saveCompetenceChapter">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="competenceDialog" scrollable persistent>
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Компетенция для {{selectedCompetenceChapter.competenceChapterName}}</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="competenceForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Название*"
                        v-model="competenceName"
                        :rules="competenceNameRules"
                        variant="outlined"
                        hint="Укажите название компетенции"
                        :counter="250"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                        label="Описание*"
                        v-model="competenceDescription"
                        :rules="competenceDescriptionRules"
                        variant="outlined"
                        :counter="255"
                        hint="Укажите описание компетенции"
                        required
                    ></v-textarea>
                  </v-col>


                  <v-col cols="12">
                    <v-row class="px-0" fluid>
                      <v-switch
                          v-model="active"
                          :label="`Доступна к использованию`"
                          color="success"
                      >
                      </v-switch>
                      <v-chip v-if="active">Да</v-chip>
                      <v-chip v-else> Нет</v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn rounded variant="outlined" @click="closeCompetence">
              Отмена
            </v-btn>
            <v-btn rounded variant="outlined" @click="saveCompetence">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="competenceLineDialog" scrollable persistent>
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Линия умений для {{selectedClass.scheduleClassName}}</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="competenceLineForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                        label="Название*"
                        v-model="competenceLineName"
                        :rules="competenceLineNameRules"
                        variant="outlined"
                        :counter="100"
                        hint="Укажите название темы"
                        required
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                        label="Описание*"
                        v-model="competenceLineDescription"
                        :rules="competenceLineDescriptionRules"
                        variant="outlined"
                        :counter="1000"
                        hint="Укажите описание темы"
                        required
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12">
                    <v-row class="px-0" fluid>
                      <v-switch
                          v-model="active"
                          :label="`Доступна к использованию`"
                          color="success"
                      >
                      </v-switch>
                      <v-chip v-if="active">Да</v-chip>
                      <v-chip v-else> Нет</v-chip>
                    </v-row>
                  </v-col>

                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn rounded variant="outlined" @click="closeCompetenceLine">
              Отмена
            </v-btn>
            <v-btn rounded variant="outlined" @click="saveCompetenceLine">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="competenceListDialog" scrollable persistent>
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Умения для {{selectedCompetenceChapter.competenceChapterName}}</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="competenceForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                        label="Названия*"
                        v-model="competenceListName"
                        :rules="competenceListNameRules"
                        variant="outlined"
                        hint="Укажите названия умений, разделитель - ;"
                        :counter="100"
                        required
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                        label="Описание*"
                        v-model="competenceDescription"
                        :rules="competenceDescriptionRules"
                        variant="outlined"
                        :counter="255"
                        hint="Укажите описание компетенции"
                        required
                    ></v-textarea>
                  </v-col>


                  <v-col cols="12">
                    <v-row class="px-0" fluid>
                      <v-switch
                          v-model="active"
                          :label="`Доступна к использованию`"
                          color="success"
                      >
                      </v-switch>
                      <v-chip v-if="active">Да</v-chip>
                      <v-chip v-else> Нет</v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn rounded variant="outlined" @click="closeCompetence">
              Отмена
            </v-btn>
            <v-btn rounded variant="outlined" @click="saveCompetenceList">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog v-model="showLoader" persistent
      ><v-alert
      ><span class="text-h5 text-primary">Загрузка данных
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular></span
      ></v-alert>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>

import {customAxios} from "@/service/customAxios";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

import TableElement from "../elements/TableElement.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";

export default {
  name: "AdminCompetence",
  components: { TableElement, Vue3EasyDataTable },
  props: [],



  data: () => ({

    competenceListName: '',
    competenceListNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) => (v && v.length <= 1000) || "Значение до должно превышать 100 символов!",
    ],
    competenceListDialog:false,

    part:0,
    competenceLineSearchBody: '',
    competenceLineDialog: false,
    competenceLineName: '',
    competenceLineDescription:'',
    competenceLineNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) => (v && v.length <= 100) || "Значение до должно превышать 100 символов!",
    ],
    competenceLineDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) => (v && v.length <= 1000) || "Значение до должно превышать 1000 символов!",
    ],
    competenceLineHeaders:[
      { text: "Название", value: "competenceLineName", sortable: true },
      { text: "Описание", value: "competenceLineDescription" },
      { text: "Активен", value: "active" , sortable: true},
      { text: "Добавлен", value: "createDateTime", sortable: true },
      { text: "Действие", value: "actions" , with:"180"  },
    ],
    competenceLines:[],
    selectedLine: undefined,

    competenceLineChapters:[],

    selectedCompetenceChapter: undefined,


    lastSelectedId: undefined,
    subjectSubjectId: undefined,
    classes:[],
    Competence:[],

    selectedClassId:undefined,
    chapterId: undefined,

    competenceChapterSearchBody:'',
    competenceChapterHeaders:[
      { text: "Название", value: "competenceChapterName", sortable: true },
      { text: "Описание", value: "competenceChapterDescription" },
      { text: "Линия", value: "competenceLine" },
      { text: "Активен", value: "active" , sortable: true},
      { text: "Добавлен", value: "createDateTime", sortable: true },
      { text: "Действие", value: "actions", with:"180" },
    ],
    competenceChapterName:'',
    competenceChapterNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) => (v && v.length <= 100) || "Значение до должно превышать 100 символов!",
    ],
    competenceChapterDescription:'',
    competenceChapterDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) => (v && v.length <= 1000) || "Значение до должно превышать 1000 символов!",
    ],
    competenceChapterDialog: false,
    selectedCompetencaLine: undefined,



    CompetenceearchBody:'',
    competenceHeaders:[
      { text: "Название", value: "competenceName", sortable: true },
      { text: "Описание", value: "competenceDescription" },
      { text: "Раздел", value: "chapter"},
      { text: "Активен", value: "active" , sortable: true},
      { text: "Добавлен", value: "createDateTime", sortable: true },
      { text: "Действие", value: "actions" , with:"180" },
    ],
    competenceName:'',
    competenceNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) => (v && v.length <= 250) || "Значение до должно превышать 100 символов!",
    ],
    competenceDescription:'',
    competenceDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) => (v && v.length <= 1000) || "Значение до должно превышать 1000 символов!",
    ],
    active: false,
    competenceDialog: false,

    CompetenceubjectDialog: false,


    competenceChapters:[],


    competenceChaptertId:undefined,


    showLoader: false,

  }),

  methods: {
    backToChapters(){
      this.part = 1;
    },
    backToLines(){
      this.part = 0;
    },
    openCompetenceLineChapters(item){
      this.selectedLine = item;
      
      customAxios
          .get( "/schedule/competence/chapter/by_line/" + item.id)
          .then((responce) => {
            this.competenceLineChapters = responce.data;
            this.selectedCompetencaLine = item
            this.part = 1;

          })
          .catch((error) => {
            console.log(error);
          });
    },

    openChapterCompetence(item){
      this.selectedCompetenceChapter = item;

      customAxios
          .get( "/schedule/competence/by_chapter/" + item.id)
          .then((responce) => {
            this.Competence = responce.data;
            this.part = 2;

          })
          .catch((error) => {
            console.log(error);
          });
    },

    editCompetenceLine(item) {
      this.id = item.id;
      this.competenceLineName = item.competenceLineName;
      this.competenceLineDescription = item.competenceLineDescription;
      this.active = item.active;
      this.competenceLineDialog = true;
    },

    deleteCompetenceLine(item){

      if (confirm("Вы точно хотите удалить запись?")) {
        customAxios
            .delete( "/schedule/competence/line/" + item.id)
            .then((response) => {
              if (response.status === 202) {
                let index = getIndex(this.competenceLines, item.id);
                this.competenceLines.splice(index, 1);
              } else {
                console.log(response);
              }
            })

      }


      },

    saveCompetenceLine() {


      this.$refs.competenceLineForm.validate().then((result) => {
        console.log("result: " + result);
        if (result.valid) {
          if(this.selectedClassId instanceof Object){
            this.selectedClassId = this.selectedClassId.id
          }
          let competenceLine = {
            competenceLineName: capitalizeFirstLetter(this.competenceLineName),
            competenceLineDescription: capitalizeFirstLetter(this.competenceLineDescription),
            active: this.active,
            classId: this.selectedClassId
          };
          if (this.id) {
            customAxios.put("/schedule/competence/line/" + this.id, competenceLine)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.competenceLines, this.id);
                    this.competenceLines.splice(index, 1, response.data);
                    this.closeCompetenceLine()
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

          }
          else {
            customAxios.post( "/schedule/competence/line", competenceLine)
                .then((response) => {
                  if (response.status === 201) {
                    this.competenceLines.push(response.data);
                    this.closeCompetenceLine()
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

          }
        }
      })

    },

    closeCompetenceLine() {
      this.competenceLineName = "";
      this.competenceLineDescription = "";
      this.active = false;
      this.competenceLineDialog = false;
      this.competenceLineDialog = false;
      this.id=undefined
    },

    closeCompetence() {
      this.competenceName = "";
      this.competenceDescription = "";
      this.active = false;
      this.competenceDialog = false;
      this.chapterId = undefined;
      this.competenceListName = "";
      this.competenceListDialog = false;
      this.id=undefined
    },

    editCompetence(item) {
      this.id = item.id;
      this.competenceName = item.competenceName;
      this.competenceDescription = item.competenceDescription;
      this.active = item.active;
      this.competenceDialog = true;
    },

    saveCompetence() {
      this.$refs.competenceForm.validate().then((result) => {
        console.log("result: " + result);
        if (result.valid) {
          let competence = {
            competenceName: capitalizeFirstLetter(this.competenceName),
            competenceDescription: capitalizeFirstLetter(this.competenceDescription),
            active: this.active,
            chapterId: this.selectedCompetenceChapter.id
          };
          if (this.id) {
            customAxios.put("/schedule/competence/" + this.id, competence)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.Competence, this.id);
                    this.Competence.splice(index, 1, response.data);
                    this.closeCompetence()
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

          }
          else {
            customAxios
                .post( "/schedule/competence", competence)
                .then((response) => {
                  if (response.status === 201) {
                    this.Competence.push(response.data);
                    this.closeCompetence()
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

          }
        }
      })

    },

    saveCompetenceList() {

      this.$refs.competenceForm.validate().then((result) => {
        console.log("result: " + result);
        if (result.valid) {
          let competence = {
            competenceList: capitalizeFirstLetter(this.competenceListName),
            competenceDescription: capitalizeFirstLetter(this.competenceDescription),
            active: this.active,
            chapterId: this.selectedCompetenceChapter.id
          };

            customAxios
                .post( "/schedule/competence/from_list", competence)
                .then((response) => {
                  if (response.status === 201) {
                    this.Competence = this.Competence.concat(response.data);
                    this.closeCompetence()
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

          }

      })

    },

    deleteCompetence(item) {

      if (confirm("Вы точно хотите удалить запись?")) {
        customAxios
            .delete( "/schedule/competence/" + item.id)
            .then((response) => {
              if (response.status === 202) {
                let index = getIndex(this.Competence, item.id);
                this.Competence.splice(index, 1);
              } else {
                console.log(response);
              }
            })

      }
    },

    closeCompetenceChapter() {
      this.competenceChapterName = "";
      this.competenceChapterDescription = "";
      this.active = false;
      this.competenceChapterDialog = false;
      this.id=undefined
    },

    editCompetenceChapter(item) {
      this.id = item.id;
      this.competenceChapterName = item.competenceChapterName;
      this.competenceChapterDescription = item.competenceChapterDescription;
      this.active = item.active;
      this.competenceChapterDialog = true;
    },

    saveCompetenceChapter() {

      this.$refs.competenceChaptertForm.validate().then((result) => {
        console.log("result: " + result);
        if (result.valid&&!this.dateRangeAlert) {
          let competenceChapter = {
            competenceChapterName: capitalizeFirstLetter(this.competenceChapterName),
            competenceChapterDescription: capitalizeFirstLetter(this.competenceChapterDescription),
            competenceLineId: this.selectedCompetencaLine.id,
            active: this.active,
            classTypeId: this.selectedClassId
          };
          if (this.id) {
            customAxios.put("/schedule/competence/chapter/" + this.id, competenceChapter)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.competenceLineChapters, this.id);
                    this.competenceLineChapters.splice(index, 1, response.data);
                    this.closeCompetenceChapter()
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

          }
          else {
            customAxios
                .post( "/schedule/competence/chapter", competenceChapter)
                .then((response) => {
                  if (response.status === 201) {
                    this.competenceLineChapters.push(response.data);
                    this.closeCompetenceChapter()
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

          }
        }
      })



    },

    deleteCompetenceChapter(item) {

      if (confirm("Вы точно хотите удалить запись?")) {
        customAxios
            .delete( "/schedule/competence/chapter/" + item.id)
            .then((response) => {
              if (response.status === 202) {
                let index = getIndex(this.competenceLineChapters, item.id);
                this.competenceLineChapters.splice(index, 1);
              } else {
                console.log(response);
              }
            })

      }
    },

    getData(){

      if(this.selectedClassId>0){
        this.showLoader = true;


        customAxios
            .get( "/schedule/competence/line/" + this.selectedClassId)
            .then((responce) => {
              this.competenceLines = responce.data;
              this.lastSelectedId = this.selectedClassId
              this.showLoader = false;
            })
            .catch((error) => {
              this.showLoader = false;
              console.log(error);
            });
      }
      else {
        alert("Необходимо выбрать класс!")
      }

    }

  },

  computed: {
    levels(){
      let opts = [];
      for(let i = 0; i <= 100; i++){
        opts.push({text:i, value:i});
      }
      return opts
    },
    selectedClass(){
      if(this.selectedClassId!==undefined){
        return getById(this.classes,this.selectedClassId )
      } else {
        return undefined
      }
    },

    loggedIn() {
      return this.$store.state.authentication.status.loggedIn;
    },

    user() {
      return this.$store.state.authentication.user;
    },

    authentication() {
      return this.$store.state.authentication;
    },
  },
  created: function () {
    this.showLoader = true;


    function getClasses( customAxios) {
      return customAxios.get( "/schedule/class");
    }


    Promise.all([
      getClasses(customAxios),

    ])
        .then((results) => {
          if (results[0].status === 200) {
            this.classes = results[0].data;
          }
          this.showLoader = false;
        })
        .catch(() => {
          this.showLoader = false;
          alert("Ошибка при загрузке данных, обратитесь к администратору!");

        });
  },
};



function getById(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return list[i];
    }
  }
  return -1;
}

</script>
