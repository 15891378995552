<template>
  <tr class="competence-editor">
    <td>{{ item.competence.competenceName }}</td>

    <td>
      <AppButton
        @click="changeSelect('workingTowards')"
        :class="{ selected: currentSelect === 'workingTowards' }"
      >
        <CIcon icon="check-icon" />
      </AppButton>
    </td>
    <td>
      <AppButton
        @click="changeSelect('achieved')"
        :class="{ selected: currentSelect === 'achieved' }"
      >
        <CIcon icon="check-icon" />
      </AppButton>
    </td>
  </tr>
</template>

<script setup>
import { onMounted } from "vue";
import { ref, computed, defineEmits, defineProps } from "vue";

import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";

const emits = defineEmits(["changeStatus"]);
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const currentSelect = ref(null);

const result = computed(() => ({
  achieved: currentSelect.value === "achieved",
  workingTowards: currentSelect.value === "workingTowards",
}));

function changeSelect(status) {
  currentSelect.value = status;

  emits("changeStatus", {
    result: result.value,
    item: props.item,
  });
}

onMounted(() => {
  if (props.item.achieved === true) {
    currentSelect.value = "achieved";
  }

  if (props.item.workingTowards === true) {
    currentSelect.value = "workingTowards";
  }
});
</script>

<style lang="scss">
.competence-editor {
  svg {
    stroke: rgba($secondary, 0.3);
    stroke-width: 3px;
  }

  .selected svg {
    stroke: $main;
    stroke-width: 4px;
  }
}
</style>
