<template>
  <div>
    <PageTitle title="Отчёт: Английский Контрольные (по критерию)" />

    <div class="g-col g-col--1fr-max">
      <FilterByYearAndGroup />
    </div>

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <p class="mb-3">Здесь отобразится отчёт.</p>

        <v-label> Выставьте фильтры</v-label>
        <ol class="pl-5">
          <li><strong>год</strong></li>
          <li><strong>класс</strong></li>
        </ol>
      </template>
    </TogglerOfWrapper>

    <StatusWrapper
      :loadingStatus="loading"
      :isDataExisted="sortedReports.length > 0"
    >
      <div v-for="(report, idx) in sortedReports" :key="report.id">
        <div class="d-flex justify-end my-2">
          <AppButton
            @click="
              downloadExcel(
                `Отчёт_${setTitle(idx)}_${group}`,
                `english-report-${idx}`
              )
            "
            class="accent-button"
            >Скачать отчёт</AppButton
          >
        </div>
        <div class="page-wrapper my-3">
          <div class="table-wrapper">
            <table :id="`english-report-${idx}`" class="table-custom">
              <thead>
                <tr>
                  <th :colspan="report.headers.length">
                    <p>Отчёт по критерию: {{ setTitle(idx) }}</p>
                  </th>
                </tr>
                <tr>
                  <th :colspan="report.headers.length" class="pb-5">
                    <span v-if="group">{{ group }}</span>
                    <span v-if="year">({{ year }})</span>
                  </th>
                </tr>

                <tr class="table-custom__headers">
                  <th v-for="(h, i) in report.headers" :key="i">{{ h }}</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(r, i) in report.dataRows" :key="i">
                  <td>
                    <p v-if="r.child">
                      {{ r.child.lastname }} {{ r.child.firstname }}
                    </p>
                    <p v-else>Среднее значение класса</p>
                  </td>

                  <td v-for="(v, i) in r.dataValue" :key="i">
                    {{ v }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { sortByChildLastname } from "@/util/common";
import { customAxios } from "@/service/customAxios";

import { usePopupStore } from "@/store/popup";
import { useRoute } from "vue-router";
import { useUserData } from "@/composables/userData";

import { useFiltersStore } from "@/store/filters";
import { storeToRefs } from "pinia";

import StatusWrapper from "@/components/StatusWrapper.vue";
import AppButton from "@/elements/AppButton.vue";
import FilterByYearAndGroup from "@/components/filtersElements/FilterByYearAndGroup.vue";

import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import PageTitle from "@/elements/PageTitle.vue";

const { group, year } = storeToRefs(useFiltersStore());
const { showError } = usePopupStore();
const { downloadExcel } = useUserData();

const route = useRoute();
const loading = ref("nothing");
const reports = ref([]);

const sortedReports = computed(() => {
  if (!reports.value.length) {
    return [];
  }

  return reports.value.map((r) => {
    const filteredDataRows = r.dataRows.filter((d) => d.child !== null);
    const middleValue = r.dataRows.find((d) => d.child === null);

    return {
      ...r,
      dataRows: [...sortByChildLastname(filteredDataRows), middleValue],
    };
  });
});

function setTitle(idx) {
  let title;
  switch (idx) {
    case 0:
      title = "Writing/Reading";
      break;
    case 1:
      title = "Listening";
      break;
    case 2:
      title = "Speaking";
      break;
    default:
      title = "";
  }
  return title;
}

watch(() => route.query.filterByYear, getReport);
watch(() => route.query.filterByGroupId, getReport);

onMounted(getReport);

async function getReport() {
  if (!route.query.filterByGroupId || !route.query.filterByYear) {
    return;
  }
  loading.value = "loading";
  const competences = [1, 2, 3];
  const requests = competences.map((id) => getReportHandler(id));

  try {
    const results = await Promise.all(requests);
    reports.value = results.map((v) => v.data);
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getReportHandler(competenceType) {
  return customAxios.post("/foreign_checklist/report/by_group", {
    competenceType: competenceType,
    personGroupId: Number(route.query.filterByGroupId),
    studyYearId: Number(route.query.filterByYear),
  });
}
</script>
