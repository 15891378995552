<template>
  <div v-if="note">
    <PageTitle title="Заметка о классе" />

    <div v-if="isTutor" class="d-flex align-center justify-end my-2">
      <router-link
        :to="{
          name: 'group-note-edit',
          query: {
            noteId: $route.query.noteId,
            filterByGroupId: $route.query.filterByGroupId,
          },
        }"
        class="gradient-button"
        >Редактировать</router-link
      >
    </div>
    <div class="page-wrapper">
      <div class="d-flex align-center justify-end text-grey mb-3">
        <span>{{ getDateFormatWithoutTime(note.noteDateTime) }}</span>
      </div>
      <h2 class="mb-5 text-center">{{ note.noteName }}</h2>
      <div v-html="note.noteBody"></div>
    </div>

    <div v-if="note.fileList" class="page-wrapper mt-3">
      <h3>Файлы</h3>

      <FilesPreview
        :files="note.fileList"
        :links="{
          downloadUrl: '/tutor/note/class/file/',
          deleteUrl: '/tutor/note/class/files/delete/',
        }"
      />
    </div>
  </div>
  <LoaderRing v-else />
</template>

<script setup>
import LoaderRing from "@/elements/LoaderRing.vue";
import PageTitle from "@/elements/PageTitle.vue";

import { useDatesChanger } from "@/composables/datesChanger";
import { useNotesStore } from "@/store/notes";
import { computed } from "vue";

import { useRoute } from "vue-router";
import { useAuthStore } from "@/store/auth";
import FilesPreview from "../FilesPreview.vue";
import { storeToRefs } from "pinia";

const { isTutor } = useAuthStore();

const route = useRoute();
const { getDateFormatWithoutTime } = useDatesChanger();
const { notes } = storeToRefs(useNotesStore());

const note = computed(() => {
  if (!notes.value.length) {
    return null;
  }

  let result;

  notes.value.forEach((v) => {
    if (v.id && v.id === Number(route.query.noteId)) {
      result = v;
    }

    if (v.classNote && v.classNote.id === Number(route.query.noteId)) {
      result = v.classNote;
    }
  });

  return result;
});
</script>
