<template>
  <div v-if="filteredWorkshops.length">
    <ComponentWithPagination
      :list="filteredWorkshops"
      @changeList="paginatedData = $event"
    >
      <ul v-if="paginatedData.length">
        <li
          v-for="w in paginatedData"
          :key="w.id"
          class="gradient-list-item"
          :class="{ disabled: isExpiredDate(w.endDateTime) }"
        >
          <div
            v-if="isExpiredDate(w.endDateTime)"
            class="gradient-list-item__content gradient-list-item__link-with-period"
          >
            <h4 v-if="w.workshopName">{{ w.workshopName }}</h4>
            <div class="gradient-list-item__period">Завершена</div>
          </div>

          <router-link
            v-else
            :to="{
              name: 'workshop-page',
              params: { workshopId: w.id },
              query: { filterByYear: $route.query.filterByYear },
            }"
            class="gradient-list-item__link-with-period gradient-list-item__content"
          >
            <h4 v-if="w.workshopName">
              {{ w.workshopName }}
              <span v-if="isAdmin">(ID: {{ w.id }})</span>
            </h4>

            <div class="gradient-list-item__period">
              <CIcon icon="calendar-icon" />
              {{ getDateFormatWithoutTime(w.beginDateTime) }} -
              {{ getDateFormatWithoutTime(w.endDateTime) }}
            </div>
          </router-link>
        </li>
      </ul>
    </ComponentWithPagination>
  </div>
  <NothingFound v-else />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useWorkshopStore } from "@/store/workshop";
import { useWorkshopFilters } from "@/composables/workshopFilters";

import { isExpiredDate } from "@/util/common";
import { computed, ref } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

import NothingFound from "@/elements/NothingFound.vue";
import CIcon from "@/elements/CIcon.vue";
import ComponentWithPagination from "@/components/ComponentWithPagination.vue";
import { useAuthStore } from "@/store/auth";

const { getDateFormatWithoutTime } = useDatesChanger();
const { workshops } = storeToRefs(useWorkshopStore());
const { filterByCommonFilters } = useWorkshopFilters();
const { isAdmin } = storeToRefs(useAuthStore());

const paginatedData = ref([]);

const filteredWorkshops = computed(() => {
  return workshops.value.length
    ? workshops.value
        .filter((w) => {
          if (isExpiredDate(w.endDateTime)) {
            return false;
          }
          return filterByCommonFilters(w);
        })

        .sort((a, b) => {
          if (a.endDateTime > b.endDateTime) {
            return -1;
          }

          if (a.endDateTime < b.endDateTime) {
            return 1;
          }
          return 0;
        })
    : [];
});
</script>
