<template>
  <CustomForm
    title="Задания"
    @create="create"
    @remove="remove"
    :saveIsDisable="v$.name.$model.length === 0 || v$.name.$errors.length > 0"
  >
    <div
      :class="{
        error: v$.name.$errors.length,
        success: !v$.name.$errors.length && v$.name.$model && v$.name.$dirty,
      }"
      class="form__item"
    >
      <p class="form__label">№ задания</p>

      <input
        v-model="v$.name.$model"
        type="text"
        @focus="v$.name.$touch"
        placeholder="Введите номер задания"
        maxlength="100"
        class="form__input"
      />

      <div
        :class="{ 'showing-tip': v$.name.$dirty && v$.name.$model.length }"
        class="form__tip-wrapper"
      >
        <div class="form__tip form__tip--counter showing">
          <span>Любые символы </span>
          <span> {{ v$.name.$model.length }} / 100 </span>
        </div>
      </div>

      <div
        class="input-errors"
        v-for="error of v$.name.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <div class="form__item">
      <p class="form__label">Уровень</p>
      <CompactSelect :options="options" v-model="level" />
    </div>

    <div class="form__item">
      <p class="form__label">Компетенции</p>
      <ChildCompetenceEditor v-model="competence" />
    </div>
  </CustomForm>
</template>

<script setup>
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { useRoute, useRouter } from "vue-router";

import { computed, ref, watch, defineEmits } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";

import ChildCompetenceEditor from "@/components/ChildCompetenceEditor.vue";
import CustomForm from "@/components/CustomForm.vue";
import CompactSelect from "@/elements/CompactSelect.vue";

import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";

const options = [
  { value: 1, title: "1" },
  { value: 2, title: "2" },
  { value: 3, title: "3" },
];

const rules = {
  name: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
};

const emits = defineEmits(["updateLists"]);

const { closePopup, showToastPopup, showError, showConfirmPopup } =
  usePopupStore();

const { listById } = useSubjectsJournalStore();

const route = useRoute();
const router = useRouter();

const currentList = computed(() => listById(route.query.listId));

const currentTask = computed(() => {
  if (!currentList.value || !currentList.value.skillListSkills.length) {
    return null;
  }

  return currentList.value.skillListSkills.find(
    (v) => v.id === Number(route.query.taskId)
  );
});

const name = ref(currentTask.value ? currentTask.value.skillDescription : "");
const level = ref(currentTask.value ? currentTask.value.skillLevel : 1);
const competence = ref(
  currentTask.value ? currentTask.value.competenceList : []
);

const v$ = useVuelidate(rules, {
  name: name,
});

async function create() {
  try {
    await createHandler();
    showToastPopup("Сохранено");
    update();
  } catch (e) {
    showError(e);
  }
}

const data = computed(() => ({
  skillListId: currentList.value.id,
  skillDescription: name.value,
  competenceList: competence.value,
  skillLevel: level.value,
}));

async function createHandler() {
  return customAxios.post(`/teacher/log/skill_list/skill/`, data.value);
}

watch(name, edit);
watch(level, edit);
watch(competence, edit);

async function edit() {
  if (route.query.taskId) {
    try {
      await editHandler();
      showToastPopup("Сохранено");
    } catch (e) {
      showError(e);
    }
  }
}

async function editHandler() {
  return customAxios.put(
    `/teacher/log/skill_list/skill/${route.query.taskId}`,
    {
      skillDescription: name.value,
      competenceList: competence.value,
      skillLevel: level.value,
    }
  );
}

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();

      await removeTask();
      showToastPopup("Задание удалено");
      update();
    } else {
      closePopup();
    }
  } catch (e) {
    showError(e);
  }
}

function update() {
  router.push({
    name: "rating-sheet-edit",
    query: {
      journalId: route.query.journalId,
      listId: route.query.listId,
      dayId: route.query.dayId,
    },
  });

  emits("updateLists");
}

async function customConfirm() {
  return showConfirmPopup("Удалить Задание");
}

async function removeTask() {
  return customAxios.delete(
    `/teacher/log/skill_list/skill/${route.query.taskId}`
  );
}
</script>
