<template>
  <AppButton
    class="d-flex justify-space-between bordered-item"
    @click="$emit('close')"
  >
    <span>Закрыть</span>
  </AppButton>
</template>

<script>
import AppButton from "@/elements/AppButton.vue";

export default {
  emits: ["close"],
  components: { AppButton },
};
</script>
