<template>
  <div>
    <PhotoTip />
    <CustomForm
      title="общеклассовой заметки"
      @create="create"
      @remove="remove"
      :saveIsDisable="saveIsDisabled"
    >
      <div v-if="note">
        <h3 class="text-deep-purple-darken-1 font-weight-bold mb-5">
          {{ note.childGroup.personGroupName }}
        </h3>
        <div
          v-if="lastUpdate"
          class="text-grey d-flex align-center justify-end mb-3"
        >
          <p>Последнее обновление: {{ getDateFormatWithTime(lastUpdate) }}</p>
        </div>
        <div class="mb-5">
          <p>Дата создания: {{ getDateFormatWithoutTime(createDateTime) }}</p>
        </div>
      </div>
      <div v-else>
        <div class="mb-5">
          <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
            >Класс*</v-label
          >
          <div class="g-col g-col--2-1fr">
            <v-select
              v-model="selectedChildrenGroupId"
              :items="childrenGroupsSelector"
              variant="outlined"
              density="compact"
              hide-details
            ></v-select>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
          >Заголовок*</v-label
        >
        <v-text-field
          v-model="noteName"
          variant="outlined"
          density="compact"
          hide-details
        ></v-text-field>
      </div>

      <VisabilitySwitcher v-model="showForParent" />

      <NoteContent />
    </CustomForm>

    <FilesEditor
      v-if="$route.path.includes('edit') && note"
      @update="emits('update')"
      :files="note.fileList"
      :links="{
        saveUrl: `/tutor/note/class/files/${$route.query.noteId}`,
        downloadUrl: '/tutor/note/class/file/',
        deleteUrl: '/tutor/note/class/files/delete/',
      }"
    />
  </div>
</template>

<script setup>
import { useChildrenGroupsData } from "@/composables/childrenGroups";
import { useNotesStore } from "@/store/notes";
import { defineEmits, computed, onMounted, ref, watch } from "vue";

import { useRoute, useRouter } from "vue-router";
import { usePopupStore } from "@/store/popup";
import { customAxios } from "@/service/customAxios";

import { useDatesChanger } from "@/composables/datesChanger";
import { storeToRefs } from "pinia";
import { debounce } from "@/util/common";

import CustomForm from "../CustomForm.vue";
import NoteContent from "./components/NoteContent.vue";
import FilesEditor from "../FilesEditor.vue";

import PhotoTip from "@/elements/PhotoTip.vue";
import VisabilitySwitcher from "@/elements/VisabilitySwitcher.vue";

const emits = defineEmits(["update"]);

const { getDateFormatWithoutTime, getDateFormatWithTime } = useDatesChanger();

const {
  showErrorWithCustomText,
  showToastPopup,
  showConfirmPopup,
  closePopup,
} = usePopupStore();

const {
  getActiveChildGroups,
  selectedChildrenGroupId,
  childrenGroupsSelector,
} = useChildrenGroupsData();

const { getNoteById, changeNoteBody } = useNotesStore();
const { noteBody } = storeToRefs(useNotesStore());

const route = useRoute();
const router = useRouter();
const showForParent = ref(false);
const createDateTime = ref("");
const lastUpdate = ref("");
const noteName = ref("");

const note = computed(() => {
  if (!route.query.noteId) {
    return null;
  }

  console.log(route.query.noteId);
  return getNoteById(Number(route.query.noteId));
});

const isValid = computed(() => {
  if (!note.value || !noteName.value.length || !noteBody.value.length) {
    return false;
  }
  return true;
});

const debounceSave = debounce(save, 1000);

watch(noteName, (c) => {
  if (!note.value || c === note.value.noteName) {
    return;
  }

  debounceSave();
});

watch(noteBody, (c) => {
  if (!note.value || c === note.value.noteBody) {
    return;
  }

  debounceSave();
});

watch(showForParent, (c) => {
  if (!note.value || c === note.value.showForParent) {
    return;
  }

  save();
});

async function save() {
  if (!isValid.value) {
    return;
  }

  try {
    const r = await saveHandler();

    if (r.status === 202) {
      showToastPopup("Изменения сохранены");
      setLastUpdate();
    }
  } catch (error) {
    showErrorWithCustomText(error);
  }
}

async function saveHandler() {
  return customAxios.put(`/tutor/note/class/${route.query.noteId}`, {
    noteBody: noteBody.value,
    noteName: noteName.value,
    showForParent: showForParent.value,
    childGroupId: note.value.childGroupId,
    noteDateTime: note.value.createDateTime,
  });
}

function setLastUpdate() {
  const d = new Date();
  lastUpdate.value = d;
}

onMounted(async () => {
  if (note.value) {
    setCurrentData();
  } else {
    getActiveChildGroups();
  }
});

function setCurrentData() {
  selectedChildrenGroupId.value = note.value.childGroupId;
  changeNoteBody(note.value.noteBody);
  noteName.value = note.value.noteName;
  showForParent.value = note.value.showForParent;
  createDateTime.value = note.value.createDateTime;
}

const saveIsDisabled = computed(() => {
  const noteIsValid =
    noteBody.value.length > 0 && noteBody.value.length <= 15000;

  if (noteName.value.length && noteIsValid && selectedChildrenGroupId.value) {
    return false;
  }
  return true;
});

async function create() {
  try {
    await createChildNoteHandler();
    showToastPopup("Заметка сохранена");
    router.push({ name: "group-notes-list" });
  } catch (e) {
    showErrorWithCustomText(e);
  }
}

async function createChildNoteHandler() {
  return customAxios.post(`/tutor/note/class`, {
    childGroupId: selectedChildrenGroupId.value,
    noteDateTime: new Date(),
    noteBody: noteBody.value,
    noteName: noteName.value,
    showForParent: showForParent.value,
  });
}

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();
      await removeHandler();
      router.push({ name: "group-notes-list" });
      showToastPopup("Заметка удалена");
    } else {
      closePopup();
    }
  } catch (error) {
    showErrorWithCustomText(error);
  }
}

async function customConfirm() {
  return showConfirmPopup("Удалить заметку?");
}

async function removeHandler() {
  return await customAxios.delete(`/tutor/note/class/${route.query.noteId}`);
}
</script>
