<template>
    <CustomForm title="темы дня" @remove="remove" @create="create">
        <div class="form__item mb-4">
            <div class="form__label">Время начала:</div>
            <p> {{ commonStartTime }}</p>
        </div>

        <div v-if="currentTheme" class="form__item">
            <div class="form__label">Дата и время:</div>
            <p>{{ getDateFormatWithTime(currentTheme.eventDateTimeBegin) }}</p>
        </div>
        <div v-else>
            <div class="form__item">
                <div class="form__label">Дата:</div>
                {{ date }}
                <DatePicker inline-with-input v-model="date" autoApply locale="ru" :enable-time-picker="false"
                    ignore-time-validation :disabled-week-days="[6, 0]" :allowedDates="allowedDates"
                    :start-date="allowedDates[0]" :format="getOneDayUIFormat" class="mb-4"></DatePicker>
            </div>


        </div>

        <div class="form__item">
            <div class="form__label">Тема:</div>
            <textarea v-model="v$.theme.$model" type="text" @focus="v$.theme.$touch" placeholder="Тема" maxlength="100"
                class="form__input"></textarea>
        </div>
        <div class="form__item">
            <div class="form__label">Коммент:</div>
            <textarea v-model="v$.comment.$model" type="text" @focus="v$.comment.$touch" placeholder="Коммент"
                maxlength="250" class="form__input"></textarea>
        </div>
    </CustomForm>
</template>

<script>

import { useWorkshopStore } from "@/store/workshop";
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import { useRoute, useRouter } from 'vue-router';
import { useVuelidate } from "@vuelidate/core";
import { useDatesChanger } from '@/composables/datesChanger';

import { required, maxLength, helpers } from "@vuelidate/validators";
import { customAxios } from '@/service/customAxios';
import { usePopupStore } from '@/store/popup';
import { eachDayOfInterval } from "date-fns";

import CustomForm from '@/components/CustomForm.vue';
import { useDatesData } from "@/composables/datesData";
import { debounce } from "@/util/common";

const rules = {
    theme: {
        required: helpers.withMessage("Обязательное поле", required),
        max: helpers.withMessage("Не более 100 символов", maxLength(100)),
    },
    comment: {
        max: helpers.withMessage("Не более 250 символов", maxLength(100)),
    }
}

export default {
    emit: ['updateWorkshops'],
    components: { CustomForm },
    setup(props, { emit }) {

        const { getTime } = useDatesData()


        const { addZero, getDateFormatWithTime, getOneDayUIFormat } =
            useDatesChanger();

        const { showError, showToastPopup } = usePopupStore()
        const route = useRoute();
        const router = useRouter();


        const { getWorkshopById } = storeToRefs(useWorkshopStore());

        const currentWorkshop = computed(() =>
            getWorkshopById.value(route.params.workshopId)
        );
        const currentTheme = computed(() =>
            currentWorkshop.value.workshopDays.find(v => v.id === Number(route.params.themeId))
        )
        const commonStartTime = ref(
            currentWorkshop.value && currentWorkshop.value.workshopDays.length ? getTime(currentWorkshop.value.workshopDays[0].eventDateTimeBegin) : null
        );

        const theme = ref(
            currentTheme.value && currentTheme.value.eventSubject ? currentTheme.value.eventSubject : ""
        );

        const comment = ref(
            currentTheme.value && currentTheme.value.eventComment ? currentTheme.value.eventComment : ""
        );

        const v$ = useVuelidate(rules, {
            theme: theme,
            comment: comment,
        });


        const date = ref(null)

        const allowedDates = computed(() => {
            const days = eachDayOfInterval({
                start: new Date(currentWorkshop.value.beginDateTime),
                end: new Date(currentWorkshop.value.endDateTime),
            });

            return days;
        });

        const data = computed(() => ({
            eventComment: comment.value,
            eventSubject: theme.value,
            workshopId: currentWorkshop.value.id,
        }))

        async function create() {
            try {
                await saveNewThemeHandler({ ...data.value, eventDateTimeBegin: getDateTimeOfBegin(), })
                showToastPopup("Тема сохранена");
            } catch (e) {
                showError(e);
            }
        }

        async function saveNewThemeHandler(data) {
            return customAxios.post(`/workshop/day`, data)
        }

        function getDateTimeOfBegin() {
    
            const idx = commonStartTime.value.split("").indexOf(":");
            const hours = commonStartTime.value.substring(0, idx);
            const minutes = commonStartTime.value.substring(idx + 1);

            const dateWithTime = new Date(date.value);
            const result = `${addZero(dateWithTime.getFullYear())}-${addZero(
                dateWithTime.getMonth() + 1
            )}-${addZero(dateWithTime.getDate())}T${hours}:${minutes}:00.000Z`;

            console.log(result);

            return result;
        }

        const debouncedEditTheme = debounce(edit, 1000);

        watch(comment, () => { if (route.name.includes('edit')) { debouncedEditTheme() } })
        watch(theme, () => { if (route.name.includes('edit')) { debouncedEditTheme() } })

        async function edit() {
            try {
                await editThemeHandler({ ...data.value, eventDateTimeBegin: currentTheme.value.eventDateTimeBegin, })
                showToastPopup("Сохранено");
            } catch (e) {
                showError(e);
            }

        }

        async function editThemeHandler(data) {
            return customAxios.put(`/workshop/day/${currentTheme.value.id}`, data)
        }

        async function remove() {
            try {
                await removeThemeHandler()

                showToastPopup("Тема удалена");
                router.push({ name: 'workshop-edit', params: { workshopId: route.params.workshopId } })

                emit("updateWorkshops");
            } catch (e) {
                showError(e);
            }
        }

        async function removeThemeHandler() {
            return customAxios.delete(`/workshop/day/${currentTheme.value.id}`);
        }

        return { commonStartTime, allowedDates, getOneDayUIFormat, getDateFormatWithTime, v$, currentTheme, theme, comment, create, remove, date };
    }
};
</script>