<template>
  <div v-if="$route.path.includes('create')" class="mb-2">
    <div
      class="text-deep-purple-lighten-1 font-weight-bold bg-white pa-2 rounded"
    >
      <p>Фото/видео можно будет прикрепить после создания Достижения</p>
      <p>(На странице редактирования)</p>
    </div>
  </div>
</template>
