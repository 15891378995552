<template>
  <div>
    <div>
      <label
        v-for="t in togglers"
        :key="t.id"
        @change="toggleWorkshopDaysOfWeek(t.id)"
        class="container"
      >
        <input type="checkbox" :checked="setCheckedStatus(t.id)" />

        <span class="checkmark"></span>
        {{ t.title }}
      </label>
    </div>

    <div class="mt-2">
      <ClosableChip
        v-for="(d, i) in workshopDates"
        :key="i"
        :data="getOneDayUIFormat(d)"
        @closeChip="removeWorkshopDate(d)"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { eachDayOfInterval } from "date-fns";
import { toggleArrayItem } from "@/util/common";

import { useDatesChanger } from "@/composables/datesChanger";

import ClosableChip from "./ClosableChip.vue";

const togglersTitles = [
  { id: 0, title: "воскресенье", active: false },
  { id: 1, title: "понедельник", active: true },
  { id: 2, title: "вторник", active: true },
  { id: 3, title: "среда", active: true },
  { id: 4, title: "четверг", active: true },
  { id: 5, title: "пятница", active: true },
  { id: 6, title: "суббота", active: false },
];

export default {
  emit: ["changeWorkshopDates"],
  components: { ClosableChip },
  props: {
    range: {
      type: Array,
      required: true,
    },
  },
  setup(props, ctx) {
    const { getOneDayUIFormat } = useDatesChanger();

    const workshopDaysOfWeek = ref([]);
    const workshopDates = ref([]);

    const datesFromRange = computed(() => {
      if (!props.range || !props.range.length) {
        return null;
      }
      return eachDayOfInterval({
        start: new Date(props.range[0]),
        end: new Date(props.range[1]),
      });
    });

    const togglers = computed(() => {
      if (!datesFromRange.value.length) {
        return null;
      }

      const result = [];

      datesFromRange.value.filter((date) => {
        const d = new Date(date);
        const item = togglersTitles[d.getDay()];

        if (item && item.active) {
          result.push(item);
        }
      });

      return result.reduce((acc, item) => {
        if (acc.includes(item)) {
          return acc;
        }

        return [...acc, item];
      }, []);
    });

    watch(
      () => props.range,
      () => {
        workshopDates.value = [];
        workshopDaysOfWeek.value = [];
      },
      { deep: true }
    );

    function setCheckedStatus(dayId) {
      return workshopDaysOfWeek.value.indexOf(dayId) >= 0;
    }

    function toggleWorkshopDaysOfWeek(dayId) {
      workshopDaysOfWeek.value = toggleArrayItem(
        workshopDaysOfWeek.value,
        dayId
      );
      changeWorkshopDates();
    }

    function changeWorkshopDates() {
      workshopDates.value = datesFromRange.value.filter((date) => {
        const d = new Date(date);
        const result = workshopDaysOfWeek.value.filter((day) => {
          return d.getDay() === day;
        });

        return result.length && true;
      });

      ctx.emit("changeWorkshopDates", workshopDates.value);
    }

    function removeWorkshopDate(day) {
      workshopDates.value = workshopDates.value.filter((v) => v !== day);
      ctx.emit("changeWorkshopDates", workshopDates.value);
    }

    return {
      workshopDates,
      togglers,
      setCheckedStatus,
      toggleWorkshopDaysOfWeek,
      getOneDayUIFormat,
      removeWorkshopDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: $main-2;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
