import axios from "axios";

export function useErrorsData() {
  function getErrorMessage(error) {
    const isAxiosError = axios.isAxiosError(error);

    let errorText = "Неизвестная ошибка";

    if (!error.response.data) {
      return errorText;
    }

    const errorMessage = error.response.data.message;
    const errorData = error.response.data.error;

    if (isAxiosError) {
      if (errorMessage) {
        errorText = errorMessage;
      } else {
        errorText = errorData;
      }
    }

    return errorText;
  }
  return {
    getErrorMessage,
  };
}
