<template>
  <div v-if="cognation">
    <div class="page-wrapper my-3">
      <h3 class="mb-4">Изображения</h3>

      <v-label class="text-deep-purple-darken-1 font-weight-bold"
        >Файлы</v-label
      >
      <FilesPreview
        @update="emits('update')"
        :files="cognation.cognationFiles"
        :links="{
          downloadUrl: '/downloadFile/cognation/',
          deleteUrl: '/cognation/my/file/delete/',
        }"
      />
      <v-label class="text-deep-purple-darken-1 font-weight-bold mb-4"
        >Добавление изображения к Достижению</v-label
      >

      <v-file-input
        v-if="uploadPercentage === 0"
        show-size
        v-model="image"
        accept="image/png, image/bmp, image/jpeg,.pdf"
        clearable
        variant="outlined"
        counter
        rounded
        label="Нажмите, чтобы добавить изображение"
        density="compact"
      ></v-file-input>
      <div v-else class="mt-5">
        <v-label class="text-pink font-weight-bold mb-2"
          >Грузим файл. Подождите, пожалуйста</v-label
        >
        <v-progress-linear color="pink" v-model="uploadPercentage" height="25">
          <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
        </v-progress-linear>
      </div>
    </div>

    <div class="page-wrapper my-3">
      <h3 class="mb-4">Видео</h3>

      <FilesPreview
        @update="emits('update')"
        :files="cognation.cognitionVideos"
        :links="{
          deleteUrl: '/video/upload/',
        }"
        class="mb-5"
      />

      <v-label class="text-deep-purple-darken-1 font-weight-bold mb-4"
        >Добавление видео к Достижению</v-label
      >

      <v-file-input
        v-if="videosUploadPercentage === 0"
        show-size
        v-model="video"
        accept="video/mp4,video/x-m4v,video/*"
        clearable
        variant="outlined"
        counter
        rounded
        label="Загрузить файл*"
      ></v-file-input>
      <div v-else class="mt-5">
        <v-label class="text-pink font-weight-bold mb-2"
          >Грузим файл. Подождите, пожалуйста</v-label
        >
        <v-progress-linear
          color="pink"
          v-model="videosUploadPercentage"
          height="25"
        >
          <strong>{{ Math.ceil(videosUploadPercentage) }}%</strong>
        </v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, computed } from "vue";
import { useCognationsStore } from "@/store/cognations";
import { customAxios } from "@/service/customAxios";

import { uuidv4 } from "@/util/common";
import { useRoute } from "vue-router";
import { usePopupStore } from "@/store/popup";

import FilesPreview from "@/components/FilesPreview.vue";

const emits = defineEmits(["update"]);

const route = useRoute();
const { getCognationById } = useCognationsStore();
const { showError, showToastPopup } = usePopupStore();

const uploadPercentage = ref(0);
const videosUploadPercentage = ref(0);

const image = ref(null);
const video = ref(null);

const cognation = computed(() => getCognationById(route.params.cognationId));

const randomFileName = computed(() => {
  if (!image.value || !image.value.name) {
    return "";
  }

  const extension = image.value.name.slice(
    image.value.name.lastIndexOf(".") + 1
  );

  return "sakhalin_school_files_" + uuidv4() + "." + extension;
});

watch(image, async (f) => {
  if (f) {
    saveImage();
  }
});

watch(video, async (f) => {
  if (f) {
    saveVideo();
  }
});

async function saveImage() {
  try {
    await saveImageHandler();
    showToastPopup("Файл загружен");
    uploadPercentage.value = 0;
    image.value = null;
    emits("update");
  } catch (error) {
    showError(error);
  }
}

async function saveImageHandler() {
  let formData = new FormData();
  formData.append("files", image.value, randomFileName.value);

  return customAxios.post(
    `/cognation/files/my/${route.params.cognationId}`,
    formData,
    {
      onUploadProgress: function (progressEvent) {
        uploadPercentage.value = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
      },
    }
  );
}

async function saveVideo() {
  try {
    await saveVideoHandler();
    showToastPopup("Файл загружен");
    uploadPercentage.value = 0;
    video.value = null;
    emits("update");
  } catch (error) {
    console.log(error);
    showError(error);
  }
}

async function saveVideoHandler() {
  let formData = new FormData();
  formData.append("file", video.value);

  return customAxios.post(
    `/video/upload/${route.params.cognationId}`,
    formData,
    {
      onUploadProgress: function (progressEvent) {
        videosUploadPercentage.value = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
      },
    }
  );
}
</script>
