<template>
  <td>
    <div>
      <v-select
        v-model="result"
        :items="ratingStatuses"
        variant="outlined"
        hide-details
        placeholder="Итог"
        class="mb-1"
        density="compact"
        clearable="true"
        bg-color="white"
      ></v-select>
      <CommentCell v-model="comment" />
    </div>
  </td>
</template>

<script setup>
const ratingStatuses = [
  { id: 1, value: 1, title: "A" },
  { id: 2, value: 2, title: "B" },
  { id: 3, value: 3, title: "C" },
  { id: 4, value: 4, title: "D" },
];

import CommentCell from "@/components/CommentCell.vue";

import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { watch, defineProps, ref } from "vue";
import { useRoute } from "vue-router";
import { debounce } from "@/util/common";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});
const { showToastPopup, showError } = usePopupStore();

const route = useRoute();

const result = ref(props.item.finalLevel);
const comment = ref(props.item.finalComment);

async function editFinalResult() {
  try {
    await handleEditFinalResult();
    showToastPopup("Результат сохранен");
  } catch (e) {
    showError(e);
  }
}

async function handleEditFinalResult() {
  await customAxios.put(`/workshop/day/child/result/${props.item.id}`, {
    finalLevel: result.value,
    finalComment: comment.value,
    workshopId: Number(route.params.id),
  });
}
const debouncedEditResult = debounce(editFinalResult, 1000);

watch(result, editFinalResult);
watch(comment, debouncedEditResult);
</script>
