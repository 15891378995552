<template>
  <div v-if="currentList">
    <SheetEditor @updateLists="emits('updateLists')" class="mt-2" />
    <FinalRatingTable @updateLists="$emit('updateLists')" class="mt-2" />
    <SheetTasks class="mt-2" />
  </div>
</template>

<script setup>
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { computed, onUnmounted, defineEmits, onMounted } from "vue";
import { useRoute } from "vue-router";

import SheetEditor from "./SheetEditor.vue";
import SheetTasks from "./SheetTasks.vue";
import FinalRatingTable from "./FinalRatingTable.vue";

const emits = defineEmits(["updateLists"]);

const route = useRoute();
const { clearList, listById } = useSubjectsJournalStore();

const currentList = computed(() => listById(route.query.listId));

onMounted(() => {
  emits("updateLists");
});

onUnmounted(clearList);
</script>
