<template>
  <div v-if="props.list.length" class="component-with-pagination">
    <div ref="listRef">
      <slot></slot>
    </div>
    <div v-if="paginationLength > 1">
      <v-pagination
        :length="paginationLength"
        rounded="circle"
        v-model="currentPageIdx"
        class="mt-10"
        color="#fa9db7"
      ></v-pagination>
    </div>
  </div>
  <NothingFound v-else />
</template>

<script setup>
import { computed, onMounted, ref, watch, defineProps, defineEmits } from "vue";
import NothingFound from "@/elements/NothingFound.vue";

const emit = defineEmits(["changeList"]);

const props = defineProps({ list: { type: Array } });

const listRef = ref();

const showingCardsCount = ref(25);
const currentPageIdx = ref(1);

const paginationLength = computed(() => {
  if (!props.list.length) {
    return 1;
  }

  return Math.ceil(props.list.length / showingCardsCount.value);
});

const paginatedData = computed(() => {
  if (!props.list.length) {
    return [];
  }

  return props.list.slice(
    (currentPageIdx.value - 1) * showingCardsCount.value,
    currentPageIdx.value * showingCardsCount.value
  );
});

onMounted(() => {
  emit("changeList", paginatedData.value);
});

watch(paginatedData, (list) => {
  emit("changeList", list);
});

watch(currentPageIdx, () => {
  const wrapper = document.querySelector(".js-scrolling-wrapper");
  wrapper.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});
</script>
