<template>
  <div>
    <SectionPromo class="mb-5" />

    <div v-if="user">
      <WChild v-if="isChild" class="mb-10" />
      <WCommonCognations />
      <WLastPhotos />

      <SchoolNews />
    </div>

    <div v-else>
      <SectionAbout />
      <SectionBenefits />
      <SectionContactUs />
    </div>

    <SiteFooter />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/auth";

import SchoolNews from "./SchoolNews.vue";
import SectionAbout from "./sections/SectionAbout.vue";
import SectionBenefits from "./sections/SectionBenefits.vue";

import SectionContactUs from "./sections/SectionContactUs.vue";
import SectionPromo from "./sections/SectionPromo.vue";
import SiteFooter from "./sections/SiteFooter.vue";

import WChild from "@/widgets/WChild.vue";
import WCommonCognations from "@/widgets/WCommonCognations.vue";
import WLastPhotos from "@/widgets/WLastPhotos.vue";

const { user, isChild } = storeToRefs(useAuthStore());
</script>
