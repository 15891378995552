<template>
  <div v-if="childrenGroupsSelector.length" class="g-col g-col--2-1fr">
    <v-select
      v-model="selectedChildrenGroupId"
      :items="childrenGroupsSelector"
      variant="outlined"
      density="compact"
      hide-details
      placeholder="Класс"
      bg-color="white"
    ></v-select>

    <v-autocomplete
      v-if="sortedActualGroupList.length"
      v-model="selectedChild"
      :items="sortedActualGroupList"
      variant="outlined"
      clearable
      @click:clear="selectedChild = null"
      placeholder="Ребенок"
      hide-details
      item-title="title"
      item-value="value"
      density="compact"
      bg-color="white"
    >
    </v-autocomplete>
    <div v-else class="empty-select"></div>
  </div>
  <div v-else class="g-col g-col--2-1fr">
    <div class="empty-select"></div>
  </div>
</template>

<script setup>
import { useChildrenGroupsData } from "@/composables/childrenGroups";
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { removeFilterFromURL, pushFilterToURL } from "@/service/router";
import { useFiltersStore } from "@/store/filters";

const { setFilterGroup, setFilterChild } = useFiltersStore();
const route = useRoute();

const {
  selectedChild,
  sortedActualGroupList,
  getActiveChildGroups,
  selectedChildrenGroupId,
  childrenGroupsSelector,
} = useChildrenGroupsData();

onMounted(async () => {
  await getActiveChildGroups();
  setInputs();
});

watch(selectedChildrenGroupId, (id) => {
  id
    ? pushFilterToURL({ name: "filterByGroupId", value: id })
    : removeFilterFromURL("filterByGroupId");
});

watch(selectedChild, (id) =>
  id
    ? pushFilterToURL({ name: "filterByChildId", value: id })
    : removeFilterFromURL("filterByChildId")
);

watch(() => route.query.filterByChildId, setInputs);
watch(() => route.query.filterByGroupId, setInputs);

function setInputs() {
  setGroup();
  setChild();
}

function setGroup() {
  if (!childrenGroupsSelector.value.length || !route.query.filterByGroupId) {
    clearGroup();
    return;
  }

  selectedChildrenGroupId.value =
    childrenGroupsSelector.value.findIndex(
      (v) => v.value === Number(route.query.filterByGroupId)
    ) >= 0
      ? Number(route.query.filterByGroupId)
      : null;

  setFilterGroup(childrenGroupsSelector.value, selectedChildrenGroupId.value);
}

function setChild() {
  if (!sortedActualGroupList.value.length || !route.query.filterByChildId) {
    clearChild();
    return;
  }

  selectedChild.value =
    sortedActualGroupList.value.findIndex(
      (v) => v.value === Number(route.query.filterByChildId)
    ) >= 0
      ? Number(route.query.filterByChildId)
      : null;

  setFilterChild(sortedActualGroupList.value, selectedChild.value);
}

function clearChild() {
  selectedChild.value = null;
}

function clearGroup() {
  selectedChildrenGroupId.value = null;
}
</script>
