<template>
  <div class="page-wrapper">
    <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
      >Прикрепленные файлы ({{ props.files.length }})</v-label
    >

    <FilesPreview
      @update="emits('update')"
      :files="props.files"
      :links="props.links"
      class="mb-5"
    />

    <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
      >Добавление новых файлов</v-label
    >

    <v-file-input
      v-if="uploadPercentage === 0"
      show-size
      v-model="filesInput"
      ref="files"
      accept="image/png, image/bmp, image/jpeg,.pdf"
      clearable
      multiple
      variant="outlined"
      counter
      rounded
      label="Нажмите, чтобы прикрепить файлы"
      density="compact"
    ></v-file-input>

    <div v-else class="mt-5">
      <v-label class="text-pink font-weight-bold mb-2"
        >Грузим файл. Подождите, пожалуйста</v-label
      >
      <v-progress-linear color="pink" v-model="uploadPercentage" height="25">
        <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
      </v-progress-linear>
    </div>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { defineEmits, defineProps, ref, watch } from "vue";
import FilesPreview from "./FilesPreview.vue";

const props = defineProps({ files: { type: Array }, links: { type: Object } });
const emits = defineEmits(["update"]);

const { showInfoPopup, showError, showToastPopup } = usePopupStore();

const filesInput = ref([]);
const uploadPercentage = ref(0);

watch(filesInput, saveFiles);

async function saveFiles() {
  if (filesInput.value.length === 0) {
    return;
  }
  const formData = new FormData();
  filesInput.value.forEach((f) => {
    let file = f;
    formData.append("files", file);
  });

  try {
    const r = await saveFilesHandler(formData);

    if (r.status === 201) {
      showToastPopup("Файл добавлен");
      emits("update");
    }

    if (r.status === 207) {
      showInfoPopup(r.data.message);
    }

    clear();
  } catch (e) {
    showError(e);
  }
}

function clear() {
  uploadPercentage.value = 0;
  filesInput.value = [];
}

async function saveFilesHandler(formData) {
  return customAxios.post(props.links.saveUrl, formData, {
    onUploadProgress: function (progressEvent) {
      uploadPercentage.value = parseInt(
        Math.round((progressEvent.loaded / progressEvent.total) * 100)
      );
    },
  });
}
</script>
