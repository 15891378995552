import { customAxios } from "@/service/customAxios";

export function useWorkshopsData() {
  function updateTimetableHandler({ beginDate, endDate, childId }) {
    const data = { beginDate: beginDate, endDate: endDate };

    if (childId) {
      data.childId = childId;
    }

    return customAxios.post("/workshop/schedule/by_range", data);
  }
  
  return {
    updateTimetableHandler,
  };
}
