import { ref } from "vue";
import { usePopupStore } from "@/store/popup";
import { useRoute } from "vue-router";
import { useUserData } from "@/composables/userData";

export function useChildData() {
  const { getUserById } = useUserData();

  const { showError } = usePopupStore();
  const route = useRoute();
  const currentChild = ref(null);

  async function updateCurrentChild() {
    try {
      const r = await getUserById(route.params.childId);
      currentChild.value = r.data;
    } catch (e) {
      showError(e);
    }
  }

  return {
    updateCurrentChild,
    currentChild,
  };
}
