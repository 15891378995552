<template>
  <v-card>
    <v-card-title class="bg-deep-purple-lighten-2 d-flex justify-center">
      <h4>Заметки</h4>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <h3 v-if="currentChild">
            Ученик: {{ currentChild.firstname }} {{ currentChild.lastname }}
          </h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-label class="mb-2">Добавление новой заметки</v-label>
          <v-textarea variant="outlined" v-model="note"></v-textarea>
          <AppButton
            :disabled="!note.length"
            @click="saveNote"
            class="mb-2 bordered-item"
            >Добавить заметку</AppButton
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-label class="mb-2">Заметки</v-label>

          <v-expansion-panels v-if="currentSwot.noteList.length">
            <v-expansion-panel v-for="(n, i) in currentSwot.noteList" :key="i">
              <v-expansion-panel-title>
                <v-col cols="6" class="d-flex justify-start">
                  Заметка {{ getDateFormatWithoutTime(n.createDateTime) }}
                </v-col>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-if="isNoteEditing"
                      v-model="editedNote.noteBody"
                      variant="outlined"
                      hide-details
                    ></v-textarea>
                    <p v-else>{{ n.noteBody }}</p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <AppButton
                      v-if="!isNoteEditing"
                      @click="startNoteEditing(n)"
                      class="bordered-item mr-2"
                    >
                      <CIcon icon="pen-icon"></CIcon>
                    </AppButton>
                    <AppButton
                      v-if="!isNoteEditing"
                      @click="removeNote(n.id)"
                      class="bordered-item"
                    >
                      <CIcon icon="cross-icon"></CIcon>
                    </AppButton>
                    <AppButton
                      v-if="isNoteEditing"
                      @click="editNote(n.id)"
                      class="bordered-item mr-2"
                    >
                      <CIcon icon="check-icon"></CIcon>
                    </AppButton>
                    <AppButton
                      v-if="isNoteEditing"
                      @click="cancelNoteEditing"
                      class="bordered-item"
                    >
                      <CIcon icon="cross-icon"></CIcon>
                    </AppButton>
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <ClosingButton @close="$emit('close')" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref } from "vue";
import { useTutorStore } from "@/store/tutor";
import { storeToRefs } from "pinia";

import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";
import ClosingButton from "@/elements/ClosingButton.vue";
import { onUnmounted } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

export default {
  emits: ["close", "saveNote", "editNote", "removeNote"],
  setup(_, ctx) {
    const tutorStore = useTutorStore();
    const { currentChild, currentSwot } = storeToRefs(tutorStore);

    const { getDateFormatWithoutTime } = useDatesChanger();

    const editedNote = ref(null);
    const isNoteEditing = ref(false);

    const note = ref("");

    function startNoteEditing(item) {
      isNoteEditing.value = true;
      editedNote.value = item;
    }

    function removeNote(id) {
      if (confirm("Вы точно хотите удалить заметку?")) {
        ctx.emit("removeNote", id);
      }
    }

    function startEditing() {
      isNoteEditing.value = false;
      editedNote.value = null;
    }

    function editNote(id) {
      ctx.emit("editNote", {
        id: id,
        options: {
          noteBody: editedNote.value.noteBody,
          noteType: 0,
        },
      });
    }

    function saveNote() {
      ctx.emit("saveNote", {
        createDateTime: new Date(),
        noteBody: note.value,
        noteType: 0,
      });

      note.value = "";
    }

    function cancelNoteEditing() {
      isNoteEditing.value = false;
      editedNote.value = null;
    }

    onUnmounted(() => {
      editedNote.value = null;
      note.value = "";
    });

    return {
      currentSwot,
      currentChild,
      saveNote,
      cancelNoteEditing,
      editNote,
      removeNote,
      startNoteEditing,
      isNoteEditing,
      getDateFormatWithoutTime,
      editedNote,
      note,
      startEditing,
    };
  },
  components: { CIcon, AppButton, ClosingButton },
};
</script>

<style lang="scss"></style>
