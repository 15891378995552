<template>
  <div>
    <PageTitle title="Отчёт: Я-студия (по месяцу)" />

    <div class="g-col g-col--3-1fr">
      <FilterByYear />
      <FilterByGroupId />
      <FilterByMonth />
    </div>

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <p class="mb-3">Здесь отобразится отчёт.</p>

        <v-label> Выставьте фильтры</v-label>
        <ol class="pl-5">
          <li><strong>год</strong></li>
          <li><strong>класс</strong></li>
          <li><strong>месяц</strong></li>
        </ol>
        <div class="mt-3 d-flex align-center flex-wrap">
          Значок
          <div class="bordered-item mx-2">
            <CIcon icon="file-icon" />
          </div>

          обозначает, что мастер оставил комментарий. Нажмите на него, чтобы
          просмотреть.
        </div>

        <div class="mt-3 d-flex align-center flex-wrap">
          Значок <CIcon icon="minus-icon" class="mx-2" /> обозначает, что у
          ребёнка нет данных за этот период.
        </div>
      </template>
    </TogglerOfWrapper>

    <StatusWrapper
      :loadingStatus="loading"
      :isDataExisted="sortedReport.length > 0"
    >
      <SHeader
        @download="
          downloadExcel(`отчёт_я_студия_${title}`, 'studio-by-month-report')
        "
      />

      <div class="page-wrapper">
        <div class="table-wrapper">
          <table class="table-custom" id="studio-by-month-report">
            <thead>
              <tr>
                <th></th>
                <th :colspan="events.length">{{ title }}</th>
              </tr>
              <tr class="table-custom__headers">
                <th></th>
                <th v-for="e in events" :key="e.eventId">
                  {{ e.eventName }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="c in sortedReport" :key="c.child.id">
                <td>{{ c.child.lastname }} {{ c.child.firstname }}</td>

                <td v-for="e in c.childEventsDataSet" :key="e.eventId">
                  <div
                    v-if="e.isSuccessResult === null"
                    class="text-grey d-flex align-center justify-center"
                  >
                    <CIcon icon="minus-icon" />
                    <span class="visually-hidden">&#8722;</span>
                  </div>
                  <ResultCell
                    v-else
                    :result="e.isSuccessResult"
                    :comment="e.masterComment"
                  />
                </td>
                <td v-if="c.childEventsDataSet.length === 0">
                  <div class="text-grey d-flex align-center justify-center">
                    <CIcon icon="minus-icon" />

                    <span class="visually-hidden">&#8722;</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { monthDictWithoutSummer, sortByChildLastname } from "@/util/common";
import { useUserData } from "@/composables/userData";

import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { useRoute } from "vue-router";

import StatusWrapper from "@/components/StatusWrapper.vue";
import ResultCell from "./ResultCell.vue";
import SHeader from "./SHeader.vue";

import CIcon from "@/elements/CIcon.vue";
import FilterByYear from "@/components/filtersElements/FilterByYear.vue";
import FilterByGroupId from "@/components/filtersElements/FilterByGroupId.vue";

import FilterByMonth from "@/components/filtersElements/FilterByMonth.vue";
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import PageTitle from "@/elements/PageTitle.vue";

const { downloadExcel } = useUserData();
const { showError } = usePopupStore();
const route = useRoute();

const loading = ref("nothing");
const report = ref([]);

const title = computed(() => {
  if (!report.value.length) {
    return null;
  }

  const isExisted = monthDictWithoutSummer.find(
    (v) => v.value === Number(route.query.filterByMonth)
  );

  return isExisted ? isExisted.title : "";
});

const sortedReport = computed(() => {
  if (!report.value.length) {
    return [];
  }
  return sortByChildLastname(report.value);
});

const events = computed(() =>
  sortedReport.value.length ? sortedReport.value[0].childEventsDataSet : []
);

watch(() => route.query.filterByMonth, getReport);
watch(() => route.query.filterByGroupId, getReport);
watch(() => route.query.filterByYear, getReport);

onMounted(getReport);

async function getReport() {
  if (
    !route.query.filterByMonth ||
    !route.query.filterByGroupId ||
    !route.query.filterByYear
  ) {
    report.value = [];
    return;
  }
  loading.value = "loading";
  try {
    const r = await getReportHandler();
    report.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getReportHandler() {
  return customAxios.post("/i_studio/for_report/by_year_and_group_and_mounth", {
    studyYearId: Number(route.query.filterByYear),
    personGroupId: Number(route.query.filterByGroupId),
    monthIndex: Number(route.query.filterByMonth),
  });
}
</script>
