<template>
  <div>
    <PageTitle title="Мой профиль" />

    <div v-if="profile">
      <AvatarWithCropper
        :avatar="profile.personAvatar"
        :id="profile.id"
        @update="$emit('updateProfile')"
      />

      <div class="page-wrapper my-3">
        <h3 class="mb-3">
          {{ profile.firstname }} {{ profile.lastname }} {{ profile.secname }}
        </h3>

        <form>
          <div
            class="mb-2"
            :class="{
              error: v$.mail.$errors.length,
              success:
                !v$.mail.$errors.length && v$.mail.$model && v$.mail.$dirty,
            }"
          >
            <v-label>Электронная почта</v-label>
            <v-text-field
              hide-details
              @blur="v$.mail.$touch"
              density="compact"
              ref="email"
              v-model="mail"
              placeholder="my@mail.addres"
              variant="outlined"
              required
            ></v-text-field>

            <div
              class="input-errors"
              v-for="error of v$.mail.$errors"
              :key="error.$uid"
            >
              <div class="error-msg form__tip form__tip--error showing">
                {{ error.$message }}
              </div>
            </div>
          </div>

          <div
            class="mb-2"
            :class="{
              error: v$.login.$errors.length,
              success:
                !v$.login.$errors.length && v$.login.$model && v$.login.$dirty,
            }"
          >
            <v-label>Логин (для входа на сайт)</v-label
            ><v-text-field
              hide-details
              density="compact"
              ref="username"
              v-model="login"
              placeholder="Логин"
              variant="outlined"
              required
            ></v-text-field>
            <div
              class="input-errors"
              v-for="error of v$.login.$errors"
              :key="error.$uid"
            >
              <div class="error-msg form__tip form__tip--error showing">
                {{ error.$message }}
              </div>
            </div>
          </div>

          <div
            class="mb-2"
            :class="{
              error: v$.phoneNumber.$errors.length,
              success:
                !v$.phoneNumber.$errors.length &&
                v$.phoneNumber.$model &&
                v$.phoneNumber.$dirty,
            }"
          >
            <v-label>Телефон</v-label
            ><v-text-field
              hide-details
              density="compact"
              ref="phone"
              v-model="phoneNumber"
              placeholder="+7-(999)-99-99-999"
              counter="18"
              variant="outlined"
              required
            ></v-text-field>
            <div
              class="input-errors"
              v-for="error of v$.phoneNumber.$errors"
              :key="error.$uid"
            >
              <div class="error-msg form__tip form__tip--error showing">
                {{ error.$message }}
              </div>
            </div>
          </div>

          <div class="d-flex justify-center mt-4">
            <AppButton
              :disabled="v$.$invalid"
              @click="save"
              class="bordered-item"
              >Сохранить изменения</AppButton
            >
          </div>
        </form>
      </div>

      <div class="page-wrapper">
        <h4 class="mb-2">Пароль</h4>

        <div
          :class="{
            error: v2$.password.$errors.length,
            success:
              !v2$.password.$errors.length &&
              v2$.password.$model &&
              v2$.password.$dirty,
          }"
        >
          <v-text-field
            hide-details
            @blur="v2$.password.$touch"
            class="mb-2"
            density="compact"
            v-model="password"
            contenteditable="false"
            type="text"
            persistent
            variant="outlined"
            placeholder="Пароль"
          >
          </v-text-field>
          <div
            class="input-errors"
            v-for="error of v2$.password.$errors"
            :key="error.$uid"
          >
            <div class="error-msg form__tip form__tip--error showing">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <v-btn
          variant="outlined"
          text
          rounded
          @click="generatePassword"
          class="mt-2"
        >
          Сгенерировать пароль*
        </v-btn>
        <p class="mt-3 text-grey">
          *Сгенерированный пароль появится в окошке для ввода
        </p>

        <div class="d-flex justify-center mt-4">
          <AppButton
            :disabled="v2$.$invalid"
            @click="save"
            class="bordered-item"
            >Сохранить новый пароль</AppButton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";
import { required, maxLength, helpers, minLength } from "@vuelidate/validators";
const emits = defineEmits(["updateProfile"]);

const mailRegexp = helpers.withMessage(
  "Формат почты my@mail.addres",
  helpers.regex(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
);
const passwordRegexp = helpers.withMessage(
  "В пароле должны быть: заглавная буква, чило, спец символ(!@#$%^&*). Без пробелов.",
  helpers.regex(/(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])(?!.* )/)
);

const rules = {
  phoneNumber: {
    max: helpers.withMessage("Не более 18 символов", maxLength(18)),
  },
  mail: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 255 символов", maxLength(255)),
    mailRegexp,
  },
  login: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 50 символов", maxLength(50)),
  },
  password: {
    max: helpers.withMessage("Не менее 8 символов", minLength(8)),
    passwordRegexp,
  },
};

import PageTitle from "@/elements/PageTitle.vue";
import AvatarWithCropper from "@/components/AvatarWithCropper.vue";

import useVuelidate from "@vuelidate/core";
import AppButton from "@/elements/AppButton.vue";

import { ref } from "vue";
import { usePopupStore } from "@/store/popup";
import { customAxios } from "@/service/customAxios";

import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
import { generatePasswordHandler } from "@/util/common";

const { showError, showInfoPopup, showWarningPopup } = usePopupStore();
const { userId, profile } = storeToRefs(useAuthStore());
const { logout } = useAuthStore();

const mail = ref(profile.value.personEmail);
const login = ref(profile.value.username);
const password = ref("");
const phoneNumber = ref(profile.value.personMobilePhone);

const v$ = useVuelidate(rules, {
  mail: mail,
  login: login,
});

const v2$ = useVuelidate(rules, {
  mail: mail,
  login: login,
  password: password,
});

async function save() {
  if (!userId.value) {
    return;
  }

  try {
    const r = await saveHandler();

    if (r.status === 202) {
      profile.value = r.data;
      password.value = "";

      emits('updateProfile')
    } else if (r.status === 207) {
      showWarningPopup(r.data.message);
    } else {
      showWarningPopup("Попробуйте ещё раз");
    }
  } catch (e) {
    if (e.response.status === 401) {
      logout();
    } else {
      showError(e);
    }
  }
}

async function saveHandler() {
  const data = {
    id: userId.value,
    personEmail: mail.value,
    personMobilePhone: phoneNumber.value,
    username: login.value,
  };

  if (password.value.length) {
    data.password = password.value;
  }

  return customAxios.put("/user/i/" + userId.value, data);
}

function generatePassword() {
  const pass = generatePasswordHandler();
  password.value = pass;
  copyToClipboard(pass);
}

async function copyToClipboard(text) {
  try {
    navigator.clipboard.writeText(text);
    showInfoPopup("Ваш пароль скопирован в буфер обмена");
  } catch (e) {
    showWarningPopup(
      "Не удалось скопировать в буфер обмена. Скопируйте новый пароль вручную, пожалуйста"
    );
  }
}
</script>
