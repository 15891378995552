<template>
  <v-card class="page-wrapper">
    <div class="d-flex justify-space-between mb-4">
      <div>
        <h4 v-if="points && points.length">{{ points[0].tagType.name }}</h4>
      </div>

      <AppButton @click="$emit('close')" class="bordered-item"
        >Закрыть</AppButton
      >
    </div>

    <div v-if="points && points.length">
      <ul class="list">
        <li v-for="p in points" :key="p.id" class="custom-checkbox">
          <input
            type="checkbox"
            :id="p.id"
            :name="p.id"
            :value="p"
            class="mr-2"
            v-model="selectedPoints"
          />
          <div class="custom-checkbox__checkmark"></div>
          <label :for="p.id">{{ p.name }}</label>
        </li>
      </ul>

      <div class="d-flex justify-center mt-2">
        <AppButton @click="$emit('save', selectedPoints)" class="bordered-item"
          >Сохранить</AppButton
        >
      </div>
    </div>
    <p v-else>Пока не добавили критерии в админке...</p>
  </v-card>
</template>

<script setup>
import { useSwotData } from "@/composables/swot";
import { useSwotStore } from "@/store/swot";
import { storeToRefs } from "pinia";

import { defineProps, defineEmits, ref, computed } from "vue";

import AppButton from "@/elements/AppButton.vue";

const { points } = storeToRefs(useSwotStore());
const { filterListById } = useSwotData();

const props = defineProps({
  tagId: { type: Number, required: true },
  note: { type: Object, required: true },
});

defineEmits(["close", "save"]);

const addedPoints = computed(() => {
  if (!props.note || props.note.tagList.length === 0) {
    return [];
  }
  return filterListById(props.note.tagList, props.tagId);
});

const selectedPoints = ref(addedPoints.value);
</script>
