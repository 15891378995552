<template>
  <div>
    <PageTitle title="Журнал посещения" />

    <div v-if="journal">
      <div class="page-wrapper">
        <h3 class="mb-3">{{ journal.name }}</h3>
        <p v-if="journal.teacherLogDescription">
          {{ journal.description }}
        </p>

        <ul>
          <li class="gradient-chip">{{ journal.group.personGroupName }}</li>
          <li class="gradient-chip">
            дней: {{ journal.attendanceDays.length }}
          </li>
          <li class="gradient-chip">
            {{ getDateFormatWithoutTime(journal.beginDateTime) }} -
            {{ getDateFormatWithoutTime(journal.endDateTime) }}
          </li>
        </ul>
      </div>

      <div class="page-wrapper mt-3">
        <TableLegend
          class="mb-4"
          :titles="{
            positive: 'Коллизий нет',
            negative: 'Коллизии присутствуют (есть расхождения журналов)',
          }"
        />
        <AttendanceTable :journal="journal" @update="getJournalData" />
      </div>
    </div>
    <LoaderRing v-else />

    <v-dialog v-model="isPopupVisible" scrollable persistent>
      <AttendancePopup  @update="getJournalData" />
    </v-dialog>
  </div>
</template>

<script setup>
import { useDatesChanger } from "@/composables/datesChanger";
import { useRoute } from "vue-router";
import { customAxios } from "@/service/customAxios";
import { onMounted, ref } from "vue";

import TableLegend from "@/components/table-elements/TableLegend.vue";
import PageTitle from "@/elements/PageTitle.vue";
import AttendanceTable from "./AttendanceTable.vue";
import LoaderRing from "@/elements/LoaderRing.vue";
import AttendancePopup from "./AttendancePopup.vue";
import { useAttendanceStore } from "@/store/attendance-journal";
import { storeToRefs } from "pinia";

const { isPopupVisible } = storeToRefs(useAttendanceStore());

const route = useRoute();
const journal = ref("");

onMounted(getJournalData);

async function getJournalData() {
  try {
    const r = await getJournalHandler();
    journal.value = r.data;
  } catch (e) {
    console.log(e);
  }
}

async function getJournalHandler() {
  return customAxios.get(`/attendance/${route.params.id}`);
}

const { getDateFormatWithoutTime } = useDatesChanger();
</script>
