import { customAxios } from "@/service/customAxios";
import { computed, ref } from "vue";

export function useEventsTypesData() {
  const selectedEventType = ref(null);

  const eventsTypes = ref([]);

  const eventsTypesSelector = computed(() => {
    if (!eventsTypes.value.length) {
      return [];
    }

    return eventsTypes.value.filter(v => v.isActive).map((v) => {
      return {
        title: v.scheduleClassTypeName,
        value: v.id,
        id: v.id,
      };
    });
  });

  async function getEventsTypes() {
    try {
      const r = await getEventsTypesHandler();
      eventsTypes.value = r.data;
    } catch (e) {
      console.log(e);
    }
  }

  async function getEventsTypesHandler() {
    return customAxios.get("/schedule/class/type/active");
  }

  return { selectedEventType, eventsTypesSelector, getEventsTypes };
}
