<template>
  <div>
    <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
      >Поиск</v-label
    >
    <v-text-field
      v-model="searchBody"
      placeholder="Введите слово"
      variant="outlined"
      clearable
      @click:clear="searchBody = ''"
      density="compact"
      hide-details
      class="mb-3"
    ></v-text-field>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { removeFilterFromURL, pushFilterToURL } from "@/service/router";

const searchBody = ref("");
const route = useRoute();

onMounted(setCurrentSearch);

watch(searchBody, (text) =>
  text
    ? pushFilterToURL({ name: "filterBySearch", value: text })
    : removeFilterFromURL("filterBySearch")
);

function setCurrentSearch() {
  if (route.query.filterBySearch) {
    searchBody.value = route.query.filterBySearch;
  }
}
</script>
