<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  }
};
</script>

<style lang="scss"></style>