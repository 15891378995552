<template>
  <div class="table-wrapper">
    <table class="table-custom">
      <thead class="table-custom__headers">
        <tr>
          <th></th>
          <th v-for="d in props.journalDays" :key="d.id">
            {{ getDateDayMonth(d.eventDateTimeBegin) }}
          </th>

          <th>Итоги</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(c, i) in children"
          :key="c.id"
          @click="lastEditRowId = i"
          :class="{ 'last-visited': lastEditRowId === i }"
        >
          <td class="pa-2">
            <div>{{ c.name }}</div>
          </td>
          <td v-for="d in c.days" :key="d.childDayId">
            <CommentStatusCell
              :comment="d.masterComment === null ? '' : d.masterComment"
              :isChecked="d.visitStatus === 1"
              :id="d.childDayId"
              @editComment="editChildResult"
              @editStatus="editChildResult"
            />
          </td>

          <FinalResultsCell :item="c.finalResult" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits, ref } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import { sortByName } from "@/util/common";

import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";

import FinalResultsCell from "./FinalResultsCell.vue";
import CommentStatusCell from "@/components/CommentStatusCell.vue";

const { showToastPopup, showError } = usePopupStore();

const props = defineProps({
  journalDays: {
    type: Object,
    required: true,
  },
  currentWorkshop: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(["update"]);

const lastEditRowId = ref(null);

const childrenWorkshopFinalResults = computed(
  () => props.currentWorkshop.workshopChildResultList
);

const children = computed(() => {
  const children = props.journalDays[0].workshopDayChildrens.map((v) => ({
    childId: v.childId,
    child: v.child,
    name: `${v.child.lastname} ${v.child.firstname}`,
    days: [],
    finalResult: null,
  }));

  props.journalDays.forEach((v1) => {
    v1.workshopDayChildrens.forEach((v2, idx) => {
      children[idx].days.push({
        dayId: v1.id,
        childDayId: v2.id,
        date: v1.eventDateTimeBegin,
        masterComment: v2.masterComment,
        visitStatus: v2.visitStatus,
      });
    });
  });

  childrenWorkshopFinalResults.value.forEach((v1) => {
    children.forEach((v2, idx) => {
      if (v1.childId === v2.childId) {
        children[idx].finalResult = v1;
      }
    });
  });

  return sortByName(children);
});

const { getDateDayMonth } = useDatesChanger();

async function editChildResult(data) {
  try {
    await handleEditChildResult(data);
    showToastPopup("Результат сохранен");
    emits("update");
  } catch (e) {
    showError(e);
  }
}

async function handleEditChildResult(data) {
  await customAxios.put(`/workshop/day/child/${data.id}`, {
    masterComment: data.comment,
    visitStatus: data.isChecked ? 1 : 0,
  });
}
</script>
