import { customAxios } from "@/service/customAxios";
import { capitalizeFirstLetter, areTwoDatesEqual } from "@/util/common";

export function useJournalData() {
  function getDayIdByLogDays(journalDaysWithIds, date) {
    return journalDaysWithIds.filter((v) =>
      areTwoDatesEqual(v.teacherLogDayDate, date)
    )[0].teacherLogDayId;
  }

  function getDayDateByLogDayId(journalDaysWithIds, dayId) {
    const isCurrentDayDate = journalDaysWithIds.filter(
      (v) => v.teacherLogDayId === dayId
    )[0].teacherLogDayDate;

    return isCurrentDayDate;
  }

  async function saveEditedJournal({
    journalName,
    journalDescription,
    isActive,
    id,
  }) {
    const options = {
      teacherLogName: capitalizeFirstLetter(journalName),
      teacherLogDescription: capitalizeFirstLetter(journalDescription),
      isActive: isActive,
    };
    await customAxios.put(`/teacher/log/${id}`, options);
  }

  function removeEmptyArrays(arr) {
    if (!arr.length) {
      return [];
    }

    let list = [];

    arr.forEach((v) => {
      if (!v.data.length) {
        return;
      }

      list = [...list, ...v.data];
    });

    return list;
  }

  async function getAllData(path, journal) {
    try {
      const list = journal.days.map(async (d) => {
        return customAxios.get(`/${path}/${d.id}`);
      });

      const res = await Promise.all(list)
        .then((v) => v)
        .catch((e) => console.log(e));

      return res;
    } catch (e) {
      console.log(e);
    }
  }

  async function getAllJournalsHandler() {
    return customAxios.get(`/teacher/log/all`);
  }

  return {
    getAllData,
    removeEmptyArrays,
    saveEditedJournal,
    getDayIdByLogDays,
    getDayDateByLogDayId,
    getAllJournalsHandler,
  };
}
