<template>
  <div class="side-menu-sub-menu">
    <ul>
      <li>
        <SideMenuLink
          @click="isVisible = !isVisible"
          :class="{ open: isVisible }"
          :data="{
            icon: data.icon,
            title: data.title,
          }"
        />

        <Transition name="list">
          <ul v-if="isVisible" class="side-menu-sub-menu__sub-menu">
            <li v-for="(l, i) in data.links" :key="i">
              <router-link :to="{ name: l.name, params: { ...l.params } }" @click="$emit('close')">
                <SideMenuLink
                  class="side-menu-sub-menu__sublist-item"
                  :data="{
                    icon: l.icon,
                    title: l.title,
                  }"
                />
              </router-link>
            </li>
          </ul>
        </Transition>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from "vue";
import SideMenuLink from "./SideMenuLink.vue";

export default {
  emits: ["close"],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const isVisible = ref(false);

    return { isVisible };
  },
  components: { SideMenuLink },
};
</script>

<style lang="scss">
.side-menu-sub-menu {
  a {
    text-decoration: none;
  }

  &__sublist-item.side-menu-link svg:first-of-type {
    width: 10px;
    height: 10px;
  }

  &__sub-menu {
    padding-left: 16px;
  }
}
</style>
