<template>
  <div class="page-wrapper">
    <div class="d-flex justify-space-between mb-4">
      <h3>Таблица компетенций</h3>

      <AppButton
        v-if="currentChild"
        @click="currentChildId = null"
        class="bordered-item"
        >Назад ко всем детям</AppButton
      >
    </div>
    <ChildCompetenceTable
      v-if="currentChild"
      @changeStatus="changeStatus"
      :child="currentChild"
    />
    <div v-else class="table-simple">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              <p>Writing</p>
              <p>Reading</p>
            </th>
            <th>Listening</th>
            <th>Speaking</th>

            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <SheetRow
            v-for="(child, i) in children"
            :key="child.id"
            :idx="i"
            :child="child"
            @editChildsResult="edit"
            @startChildEditing="currentChildId = child.id"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineEmits } from "vue";
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";

import { sortByName } from "@/util/common";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { useRoute } from "vue-router";

import ChildCompetenceTable from "./ChildCompetenceTable.vue";
import SheetRow from "./SheetRow.vue";
import AppButton from "@/elements/AppButton.vue";

const emits = defineEmits(["updateLists"]);

const route = useRoute();
const currentChildId = ref(null);

const { showError, showToastPopup } = usePopupStore();
const { listById } = useSubjectsJournalStore();

const currentList = computed(() => listById(route.query.listId));
const children = computed(() => {
  if (!currentList.value || !currentList.value.childList.length) {
    return [];
  }

  return sortByName(
    currentList.value.childList.map((v) => ({
      ...v,
      name: `${v.child.firstname} ${v.child.lastname}`,
    }))
  );
});

const currentChild = computed(() => {
  if (!currentChildId.value || !children.value.length) {
    return;
  }
  return children.value.find((v) => v.id === currentChildId.value);
});

async function changeStatus(data) {
  try {
    await editChildsCompetenceStatus(data);
    showToastPopup("Успешно сохранено");
    emits("updateLists");
  } catch (e) {
    showError(e);
  }
}

async function edit(data) {
  try {
    await editChildsResult(data);
    showToastPopup("Успешно сохранено");
    emits("updateLists");
  } catch (e) {
    showError(e);
  }
}

async function editChildsResult({ options, id }) {
  return customAxios.put(`/foreign_checklist/child/${id}`, options);
}

async function editChildsCompetenceStatus({ item, result }) {
  return customAxios.put(
    `/foreign_checklist/child/competence/${item.id}`,
    result
  );
}
</script>
