<template>
  <div>
    <PageTitle title="Заметки по классам" />
    <div class="page-wrapper">
      <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
        >Фильтры</v-label
      >
      <div class="g-col g-col--2-1fr my-2">
        <FilterByGroupId />
      </div>

      <SearchFilter />
      <CSortByDate />
    </div>

    <div v-if="isTutor" class="d-flex align-center justify-end my-2">
      <router-link :to="{ name: 'group-note-create' }" class="gradient-button"
        >Создать</router-link
      >
    </div>

    <ComponentWithPagination
      v-if="$route.query.filterByGroupId"
      :list="filteredSortedItems"
      @changeList="paginatedList = $event"
    >
      <ul>
        <li
          v-for="n in paginatedList"
          :key="n.id"
          class="bg-white my-2 rounded"
        >
          <router-link
            :to="{
              name: 'group-note-page',
              query: {
                filterByGroupId: $route.query.filterByGroupId,
                noteId: n.id,
              },
            }"
            class="link pa-2 d-block"
          >
            <NotePreview :note="n" />
          </router-link>
        </li>
      </ul>
    </ComponentWithPagination>
    
    <div v-else class="page-wrapper mt-3 py-10">
      <p class="text-red font-weight-bold text-center">
        Для отображения данных, пожалуйста, выберите класс
      </p>
    </div>
  </div>
</template>

<script setup>
import { useNotesStore } from "@/store/notes";
import { computed } from "vue";
import { storeToRefs } from "pinia";

import { useAuthStore } from "@/store/auth";
import { useNotes } from "@/composables/notes";

import SearchFilter from "../SearchFilter.vue";
import ComponentWithPagination from "../ComponentWithPagination.vue";
import PageTitle from "@/elements/PageTitle.vue";

import CSortByDate from "../CSortByDate.vue";
import FilterByGroupId from "@/components/filtersElements/FilterByGroupId.vue";
import NotePreview from "./components/NotePreview.vue";

const { isTutor } = useAuthStore();
const { getFilteredSortedItems, paginatedList } = useNotes();
const { notes } = storeToRefs(useNotesStore());

const filteredSortedItems = computed(() => getFilteredSortedItems(notes.value));
</script>
