<template>
  <v-container class="wrapper">
    <TableElement>
      <template v-slot:window>
        <v-card-title  class="card_ligth_header">
          <h5>Дети и родители</h5>
        </v-card-title>
        <v-card-title class="card_ligth_header" style="min-height: 5vh">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="searchBody"
                label="Найти"
                variant="outlined"
                clearable
                hint="Введите ключевое слово для поиска"
                rounded
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="card_ligth_header">
          <v-row>
            <v-col cols="12">
              <v-btn
                @click="showFiltersDialog = true"
                variant="outlined"
                color="primary"
                rounded
                class="mb-4"
              >
                Фильтры
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="ma-0 pa-0">
          <div class="d-sm-none d-flex align-center flex-column">
            <v-card
              variant="tonal"
              class="ma-5 mx-5 w-100"
              v-for="item in paginatedData"
              :key="item.id"
            >
              <v-card-title class="card_ligth_header">
                <span
                  >{{ item.lastname }} {{ item.firstname }}
                  {{ item.secname }}</span
                >
              </v-card-title>
              <v-card-text v-if="!item.personChilds.length" class="mx-4">
                <v-chip size="small" class="bg-red text-shades-white">
                  Нет детей
                </v-chip>
              </v-card-text>
              <v-card-text v-else class="mx-4">
                <v-chip-group active-class="primary--text" column>
                  <v-chip
                    size="small"
                    v-for="child in item.personChilds"
                    :key="child"
                    >{{ child.firstname }} {{ child.secname }}
                    {{ child.lastname }}</v-chip
                  >
                </v-chip-group>
              </v-card-text>
              <v-card-actions class="card_ligth_actions">
                <v-btn
                  @click="editUser(item)"
                  class="ma-1"
                  color="primary"
                  variant="outlined"
                  rounded
                >
                  Редактировать
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
          <div class="d-none d-sm-block">
            <Vue3EasyDataTable
              :headers="parentHeaders"
              :items="users"
              :search-value="searchBody"
              multi-sort
              alternating
              show-index
            >
              <template #item-parent="{ lastname, firstname, secname }">
                {{ lastname }} {{ firstname }} {{ secname }}
              </template>
              <template #item-personChilds="{ personChilds }">
                <v-chip
                  size="small"
                  v-if="!personChilds.length"
                  class="bg-red text-shades-white"
                >
                  Нет детей
                </v-chip>
                <v-chip-group v-else active-class="primary--text" column>
                  <v-chip
                    size="small"
                    v-for="child in personChilds"
                    :key="child"
                  >
                    {{ child.lastname }} {{ child.firstname }}
                    {{ child.secname }}
                  </v-chip>
                </v-chip-group>
              </template>

              <template #item-actions="item">
                <v-btn
                  @click="editUser(item)"
                  class="ma-1"
                  color="primary"
                  variant="outlined"
                  rounded
                >
                  Редактировать
                </v-btn>
              </template>
            </Vue3EasyDataTable>
          </div>
        </v-card-text>
        <v-card-actions
          class="card_ligth_actions d-sm-none"
          style="min-height: 5vh"
        >
          <v-container class="text-center">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="3"
              size="small"
            ></v-pagination>
          </v-container>
        </v-card-actions>
      </template>
    </TableElement>
    <v-card class="mx-auto ma-10"> </v-card>
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Дети пользователя {{ fullName }}</h5>
        </v-card-title>
        <v-card-text>
          <v-form ref="userForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="personChilds"
                    :items="childs"
                    :custom-filter="childFilter"
                    filled
                    chips
                    clerable
                    small-chips
                    closable-chips
                    label="Выбрать детей"
                    item-title="name"
                    item-value="name"
                    multiple
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                        v-bind="props"
                        :text="
                          item.raw.firstname +
                          ' ' +
                          item.raw.secname +
                          ' ' +
                          item.raw.lastname
                        "
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                        v-bind="props"
                        :title="
                          item.raw.firstname +
                          ' ' +
                          item.raw.secname +
                          ' ' +
                          item.raw.lastname
                        "
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn rounded variant="outlined" @click="closePersonForm">
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="save"> Сохранить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showLoader" persistent
      ><v-alert
        ><span class="text-h5 text-primary"
          >Загрузка данных
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular></span
      ></v-alert>
    </v-dialog>
    <v-dialog v-model="showFiltersDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Фильтры</h5>
        </v-card-title>
        <v-card-text>
          <v-form ref="filterForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="userGroupFilterBody"
                    :items="userGroups"
                    :custom-filter="userGroupFilter"
                    outlined
                    chips
                    small-chips
                    label="Группа пользователей"
                    clearable
                    item-title="personGroupName"
                    item-value="id"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                        v-bind="props"
                        :text="item.raw.personGroupName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                        v-bind="props"
                        :title="item.raw.personGroupName"
                        :subtitle="item.raw.personGroupDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn rounded variant="outlined" @click="closeFilters">
            Закрыть
          </v-btn>
          <v-btn rounded variant="outlined" @click="clearFilters">
            Очистить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import {  userService } from "@/service/user.service";
import Vue3EasyDataTable from "vue3-easy-data-table";
import TableElement from "../elements/TableElement.vue";
import {customAxios} from "@/service/customAxios";

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: "AdminUserChildPage",
  components: {
    Vue3EasyDataTable,
    TableElement,
  },

  props: [],
  data: () => ({
    parentHeaders: [
      { text: "Родитель", value: "parent", sortable: true },
      { text: "Дети", value: "personChilds" },
      { text: "Действие", value: "actions", sortable: true },
    ],
    showLoader: false,
    showFiltersDialog: false,
    userGroupFilterBody: undefined,

    fullName: "",
    page: 1,
    noteCount: 20,
    valid: true,
    dialog: false,
    searchBody: "",
    showInput: false,
    loading: false,
    users: [],
    childs: [],
    personChilds: [],
    userGroups: [],
  }),
  methods: {
    childFilter(item, queryText, itemText) {
      console.log("filter item: " + item);
      const textFirstname = itemText.raw.firstname.toLowerCase();
      const textLastname = itemText.raw.lastname.toLowerCase();
      const textSecname = itemText.raw.secname.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
          textFirstname.indexOf(searchText) > -1 ||
          textLastname.indexOf(searchText) > -1 ||
          textSecname.indexOf(searchText) > -1
      );
    },
    closeFilters() {
      this.showFiltersDialog = false;
    },
    clearFilters() {
      this.showFiltersDialog = false;
      this.userGroupFilter = undefined;
    },
    userGroupFilter(item, queryText, itemText) {
      return (
        itemText.raw.personGroupName
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        itemText.raw.personGroupDescription
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },

    closePersonForm() {
      this.roles = [];
      this.personGroups = [];
      this.id = "";
      this.dialog = false;
    },
    clearSearch() {
      this.searchBody = "";
    },
    save() {
      
      if (this.$refs.userForm.validate()) {
        let childsIds = [];

        for (let i = 0; i < this.personChilds.length; i++) {
          childsIds[i] = this.personChilds[i].id;
        }

        customAxios.put( "/user/child/" + this.id, childsIds)
          .then((response) => {
            userService.handleResponse(response);
            if (response.status === 202) {
              let index = getIndex(this.users, response.data.id);
              this.users.splice(index, 1, response.data);
              this.dialog = false;
              this.fullName = "";
             // this.personChilds = [];
              this.id = "";
            } else {
              alert("Что-то пошло не так");
              console.log(response);
            }
          })
        
      }
    },
    editUser: function (event) {
      this.id = event.id;
      this.fullName =
        event.lastname + " " + event.firstname + " " + event.secname;
      this.personChilds = event.personChilds;
      this.dialog = true;
    },
  },
  computed: {
    filteredUsersByUserGroup() {
      let tempUsers = this.users;

      if (this.userGroupFilterBody) {
        return tempUsers.filter((sortedUser) => {
          if (sortedUser.personGroups) {
            if (
              getIndex(sortedUser.personGroups, this.userGroupFilterBody) > -1
            ) {
              return sortedUser;
            }
          }
        });
      } else {
        return tempUsers;
      }
    },

    filteredUsers() {
      const ss = this.searchBody.toLowerCase();

      let tempUsers = this.filteredUsersByUserGroup;

      return tempUsers.filter((item) =>
        _.some(item, (v) => {
          if (!(v instanceof Object)) {
            return _.toLower(v).indexOf(ss) > -1;
          }
        })
      );
    },
    loggedIn() {
      return this.$store.state.authentication.status.loggedIn;
    },
    user() {
      return this.$store.state.authentication.user;
    },
    authentication() {
      return this.$store.state.authentication;
    },
    pageCount() {
      let l = this.filteredUsers.length,
        s = parseInt(this.noteCount);
      console.log("Page count: " + Math.ceil(l / s));

      return Math.ceil(l / s);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * parseInt(this.noteCount),
        end = start + parseInt(this.noteCount);

      return this.filteredUsers.slice(start, end);
    },
    currentPage() {
      if (this.pageCount < this.page) {
        return 1;
      } else {
        return this.page;
      }
    },
  },
  created: function () {
    this.showLoader = true;
    
    function getParents(customAxios) {
      return customAxios.get( "/user/parent");
    }
    function getChilds(customAxios) {
      return customAxios.get( "/user/child");
    }
    function getUserGroups(customAxios) {
      return customAxios.get( "/user_group");
    }

    Promise.all([
      getParents(customAxios),
      getChilds(customAxios),
      getUserGroups(customAxios),
    ])
      .then((results) => {
        if (results[0].status === 200) {
          this.users = results[0].data;
        }
        if (results[1].status === 200) {
          this.childs = results[1].data;
        }
        if (results[2].status === 200) {
          this.userGroups = results[2].data;
        }
        this.showLoader = false;
      })
      .catch((error) => {
        this.showLoader = false;
        alert("Что-то пошло не так");
        console.log(error);
       
      });
  },
};
</script>
<style></style>
