<template>
  <div class="my-3">
    <AppButton @click="visible = !visible" class="accent-button mb-2">
      <CIcon v-if="visible" icon="cross-icon" />
      <CIcon v-else icon="paper-clip-icon" />

      <p class="ml-2">Фильтры</p>
    </AppButton>

    <Transition>
      <div v-if="visible" class="page-wrapper">
        <slot name="content"></slot>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";

import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const visible = ref(false);

onMounted(setVisible)

function setVisible() {
  visible.value = Object.keys(route.query).length > 0
}
</script>

<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
