<template>
  <div>
    <PageTitle title="Отчёт: мастерские ребенка" />
    <FiltersForChildsReport>
      <FilterByPeriod />
    </FiltersForChildsReport>

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <p class="mb-3">Здесь отобразится отчёт.</p>

        <v-label> Выставьте фильтры</v-label>

        <ol class="pl-5">
          <li v-if="userGlobalRole === 'employee'"><strong>класс</strong></li>
          <li v-if="isParent || userGlobalRole === 'employee'">
            <strong>ребёнок</strong>
          </li>
          <li><strong>период</strong></li>
        </ol>
      </template>
    </TogglerOfWrapper>

    <StatusWrapper
      :loadingStatus="loading"
      :isDataExisted="report !== null && report.weekDays.length > 0"
    >
      <h3 class="my-3 text-center">
        Отчёт для ребёнка
        <span>{{ report.childFullName }}</span>
      </h3>

      <div v-if="report.weekDays.length">
        <div class="d-flex justify-end">
          <AppButton
            @click="downloadExcel(title, 'workshop-child-report')"
            class="accent-button"
            >Скачать отчёт</AppButton
          >
        </div>
        <div class="page-wrapper mt-3">
          <h4 class="mb-3">Финальные результаты</h4>

          <div class="table-default">
            <table id="workshop-child-report">
              <thead>
                <tr>
                  <th>Мастерская</th>
                  <th>Коммент</th>
                  <th>Результат</th>
                </tr>
              </thead>
              <tbody v-for="w in uniqWorkshops" :key="w.workshopId">
                <tr v-for="r in w.workshopChildResultList" :key="r.workshopId">
                  <td>{{ w.workshopName }}</td>
                  <td>
                    <p v-if="r.finalComment">{{ r.finalComment }}</p>
                    <p v-else class="text-grey">Нет комментария</p>
                  </td>
                  <td>
                    <p v-if="r.finalLevel">
                      {{ RATING_TITLES[r.finalLevel - 1] }}
                    </p>
                    <p v-else class="text-grey">Нет результата</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="filteredComments.length" class="mt-3">
        <div class="d-flex justify-end">
          <AppButton
            @click="downloadExcel(title, 'workshop-child-comments-report')"
            class="accent-button"
            >Скачать отчёт</AppButton
          >
        </div>

        <div class="page-wrapper mt-3">
          <h4>Промежуточные комментарии</h4>

          <div class="g-col g-col--2-1fr my-2">
            <v-autocomplete
              v-if="uniqWorkshops && uniqWorkshops.length"
              v-model="selectedWorkshop"
              :items="uniqWorkshops"
              variant="outlined"
              placeholder="Все мастерские"
              item-value="workshopId"
              item-title="workshopName"
              density="compact"
              hide-details
              clearable
            ></v-autocomplete>
          </div>

          <ComponentWithPagination
            :list="filteredComments"
            @changeList="paginatedList = $event"
          >
            <div class="table-default">
              <table id="workshop-child-comments-report">
                <thead>
                  <tr>
                    <th></th>
                    <th>Коммент</th>
                    <th>Мастерская</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="w in paginatedList" :key="w.id" class="my-2">
                    <td>{{ w.dayDate }}</td>

                    <td>
                      <p v-if="w.workshop.masterComment">
                        {{ w.workshop.masterComment }}
                      </p>
                      <p v-else class="text-grey">Нет комментария</p>
                    </td>
                    <td>{{ w.workshop.workshopName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ComponentWithPagination>
        </div>
      </div>

      <div
        v-else
        class="text-center my-5 font-weight-bold text-deep-purple-lighten-3"
      >
        Данные на этот период отсутствуют...
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { computed, onMounted, ref, watch } from "vue";

import { useRoute } from "vue-router";
import { useDatesChanger } from "@/composables/datesChanger";
import { useUserData } from "@/composables/userData";

import { useAuthStore } from "@/store/auth";
import { pushFilterToURL } from "@/service/router";

import AppButton from "@/elements/AppButton.vue";
import PageTitle from "@/elements/PageTitle.vue";

import ComponentWithPagination from "@/components/ComponentWithPagination.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";

import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import FilterByPeriod from "@/components/filtersElements/FilterByPeriod.vue";
import FiltersForChildsReport from "../FiltersForChildsReport.vue";

const RATING_TITLES = ["A", "B", "C", "D"];

const { getDateFormatWithoutTime } = useDatesChanger();
const { downloadExcel } = useUserData();
const { showError } = usePopupStore();
const { isParent, isChild, userId, userGlobalRole } = useAuthStore();

const route = useRoute();

const report = ref(null);
const loading = ref("nothing");
const paginatedList = ref([]);
const selectedWorkshop = ref(null);

const uniqWorkshops = computed(() => {
  if (!report.value || !report.value.weekDays.length) {
    return [];
  }

  const ids = [];

  report.value.weekDays.forEach((v) => {
    v.workshops.forEach((w) => {
      if (!ids.find((v) => v.workshopId === w.workshopId)) {
        ids.push(w);
      }
    });
  });

  return ids;
});

const allComments = computed(() => {
  if (!report.value || !report.value.weekDays.length) {
    return [];
  }

  const comments = [];

  report.value.weekDays.forEach((v) => {
    v.workshops.forEach((w) => {
      comments.push({
        workshop: w,
        dayDate: v.dayDate,
      });
    });
  });

  return comments;
});

const filteredComments = computed(() => {
  if (!allComments.value || !allComments.value.length) {
    return [];
  }

  return allComments.value.filter((v) => {
    if (!selectedWorkshop.value) {
      return true;
    }
    return filterById(v);
  });
});

onMounted(async () => {
  if (isChild) {
    pushFilterToURL({ name: "filterByChildId", value: userId });
  }

  getData();
});

function filterById(n) {
  return n.workshop.workshopId === Number(selectedWorkshop.value);
}

watch(() => route.query.startDate, getData);
watch(() => route.query.endDate, getData);

async function getData() {
  if (
    !route.query.filterByChildId ||
    !route.query.startDate ||
    !route.query.endDate
  ) {
    return;
  }

  loading.value = "loading";

  try {
    const r = await getReport();
    report.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getReport() {
  return await customAxios.post("/workshop/child_report", {
    beginPeriodDateTime: route.query.startDate + "T00:00:00.000Z",
    endPeriodDateTime: route.query.endDate + "T00:00:00.000Z",
    childId: Number(route.query.filterByChildId),
  });
}

const title = computed(
  () =>
    `отчёт_мастерcкие_${report.value.childFullName}_${getDateFormatWithoutTime(
      route.query.startDate
    )}-${getDateFormatWithoutTime(route.query.endDate)}`
);
</script>
