<template>
  <div class="bg-white pa-4 my-2 note-preview">
    <div
      v-if="note.noteDateTime"
      class="d-flex align-center justify-end text-grey mb-2"
    >
      <span>{{ getDateFormatWithoutTime(note.noteDateTime) }}</span>
    </div>

    <NoteTextPreview v-if="note.noteName" :noteBody="note.noteName" />

    <NoteTextPreview v-if="note.noteBody" :noteBody="note.noteBody" />

    <div
      v-if="note.child"
      class="d-flex align-center mb-3 flex-wrap"
      :class="{
        'text-deep-purple-lighten-2 font-weight-bold':
          $route.query.filterByChildId !== undefined,
        'text-grey': $route.query.filterByChildId === undefined,
      }"
    >
      <span class="mr-1">Ребенок:</span>
      <span class="mr-3"
        >{{ note.child.firstname }} {{ note.child.secname }}
        {{ note.child.lastname }}</span
      >
    </div>

    <div
      v-if="note.childGroup"
      class="d-flex align-center mb-3 flex-wrap"
      :class="{
        'text-deep-purple-lighten-2 font-weight-bold':
          $route.query.filterByGroupId !== undefined,
        'text-grey': $route.query.filterByGroupId === undefined,
      }"
    >
      <span class="mr-3">{{ note.childGroup.personGroupName }} </span>
    </div>

    <div
      v-if="note.creator"
      class="d-flex align-center mb-3 flex-wrap"
      :class="{
        'text-deep-purple-lighten-2 font-weight-bold':
          $route.query.filterByAuthorId !== undefined,
        'text-grey': $route.query.filterByAuthorId === undefined,
      }"
    >
      <span class="mr-1">Автор:</span>
      <span class="mr-3"
        >{{ note.creator.firstname }} {{ note.creator.secname }}
        {{ note.creator.lastname }}</span
      >
    </div>

    <div class="g-col g-col--2-1fr mt-5">
      <div v-if="note.fileList" class="d-flex align-center">
        <CIcon icon="file-icon" />
        <span class="text-grey">файлов: {{ note.fileList.length }}</span>
      </div>
      <div v-if="isTutor">
        <div v-if="note.showForParent" class="d-flex align-center">
          <CIcon icon="eye-icon" />
          <span class="text-grey">показано родителям</span>
        </div>
        <div v-else class="d-flex align-center">
          <CIcon icon="close-eye-icon"></CIcon>
          <span class="text-grey">скрыто от родителей</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDatesChanger } from "@/composables/datesChanger";
import { defineProps } from "vue";
import { useAuthStore } from "@/store/auth";

import NoteTextPreview from "./NoteTextPreview.vue";
import CIcon from "@/elements/CIcon.vue";
const { getDateFormatWithoutTime } = useDatesChanger();

defineProps({ note: Object });
const { isTutor } = useAuthStore();
</script>

<style lang="scss">
.note-preview {
  box-shadow: $shadow;
  border-radius: 16px;
}
</style>