<template>
  <div v-if="currentWorkshop">
    <PageTitle :title="'Журнал мастерской ' + currentWorkshop.workshopName" />

    <div class="page-wrapper">
      <StatusWrapper
        :loadingStatus="loadingStatus"
        :isDataExisted="isDataExisted"
      >
        <div v-if="hasChildren">
          <WorkshopJournalTable
            :journalDays="journalDays"
            @update="update"
            :currentWorkshop="currentWorkshop"
          />
        </div>

        <p v-else class="pa-2">
          Таблица Журнала отобразится после того, как дети присоединятся к
          Мастерской
        </p>
      </StatusWrapper>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { customAxios } from "@/service/customAxios";
import { useRoute } from "vue-router";

import WorkshopJournalTable from "@/workshops/WorkshopJournalTable.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";
import PageTitle from "@/elements/PageTitle.vue";

import { usePopupStore } from "@/store/popup";
import { useWorkshopStore } from "@/store/workshop";
import { onMounted } from "vue";

const { getWorkshopById } = useWorkshopStore();
const { showError } = usePopupStore();

const loadingStatus = ref("loading");
const route = useRoute();
const journalDays = ref([]);

const hasChildren = computed(
  () =>
    journalDays.value[journalDays.value.length - 1].workshopDayChildrens.length
);

const currentWorkshop = computed(() =>
  getWorkshopById(Number(route.params.id))
);

const isDataExisted = computed(
  () => !!(journalDays.value.length && currentWorkshop.value)
);

onMounted(update);

async function update() {
  try {
    const r = await getJournalDays();
    journalDays.value = r.data;
  } catch (e) {
    showError(e.data.message);
  } finally {
    loadingStatus.value = "loaded";
  }
}

async function getJournalDays() {
  return customAxios.get(`/workshop/days/${route.params.id}`);
}
</script>
