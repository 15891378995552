<template>
  <div>
    <PageTitle :title="title" />

    <div v-if="isChild" class="d-flex my-3 justify-end">
      <router-link :to="{ name: 'cognation-create' }" class="accent-button"
        >Добавить достижение</router-link
      >
    </div>

    <div class="page-wrapper">
      <div v-if="isParent" class="g-col g-col--3-1fr mb-3">
        <FilterByChild />
      </div>

      <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
        >Поиск по достижениям</v-label
      >
      <v-text-field
        v-model="searchInput"
        hide-details
        density="compact"
        clearable
        persistent-clear
        variant="outlined"
        append-inner-icon="mdi-magnify"
        placeholder="Введи слово из названия или описания"
      />
    </div>

    <ComponentWithPagination
      :list="filteredCognations"
      @changeList="paginatedList = $event"
    >
      <ul class="cards-grid list">
        <li v-for="c in paginatedList" :key="c.id" class="mt-3">
          <router-link
            :to="{
              name: isParent ? 'my-child-cognation-page' : 'cognation-page',
              params: { cognationId: c.id },
            }"
            class="text-decoration-none link"
          >
            <CognationPreview :item="c" />
          </router-link>
        </li>
      </ul>
    </ComponentWithPagination>
  </div>
</template>

<script setup>
import { useCognationsStore } from "@/store/cognations";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

import PageTitle from "@/elements/PageTitle.vue";
import CognationPreview from "./CognationPreview.vue";
import ComponentWithPagination from "@/components/ComponentWithPagination.vue";

import { useAuthStore } from "@/store/auth";
import { useRoute } from "vue-router";
import FilterByChild from "@/components/filtersElements/FilterByChild.vue";

const { isParent, isChild } = useAuthStore();
const route = useRoute();
const { cognations } = storeToRefs(useCognationsStore());

const searchInput = ref("");
const paginatedList = ref([]);

const title = computed(() =>
  isParent ? "Достижения ребёнка" : "Управление моими достижениями"
);

const filteredCognations = computed(() => {
  if (!cognations.value.length) {
    return [];
  }

  return cognations.value.filter((c) => {
    if (route.query.filterByChildId && !filterByChildId(c)) {
      return false;
    }

    if (searchInput.value && !filterBySearchInput(c)) {
      return false;
    }
    return true;
  });
});

function filterBySearchInput(item) {
  if (
    item.cognationName.toLowerCase().includes(searchInput.value.toLowerCase())
  ) {
    return true;
  }

  if (
    item.cognationDescription
      .toLowerCase()
      .includes(searchInput.value.toLowerCase())
  ) {
    return true;
  }

  return false;
}

function filterByChildId(item) {
  return item.childId === Number(route.query.filterByChildId);
}
</script>
