<template>
  <router-view v-if="$route.path === '/login'"></router-view>
  <MainLayout v-else>
    <router-view></router-view>
  </MainLayout>
</template>

<script setup>
import { onMounted } from "vue";
import { useUserData } from "@/composables/userData";

import MainLayout from "./components/MainLayout.vue";

const { updateUserInfo } = useUserData();

onMounted(updateUserInfo);
</script>

<style lang="scss">
@import "./styles/animations.scss";
@import "./styles/common.scss";
@import "./styles/table.scss";
#app {
  font-family: "Noto Sans Display", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

#app,
html,
body {
  height: 100%;
}
</style>
