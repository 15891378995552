import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

export const useSwotStore = defineStore("swot", () => {
  const route = useRoute();

  const swot = ref(null);
  const tagsTitles = ref([]);
  const points = ref([]);
  const swots = ref([]);

  function setActiveSwot(data) {
    swot.value = data;
  }
  function setTagsTitles(list) {
    tagsTitles.value = list;
  }
  function setPoints(list) {
    points.value = list;
  }
  function setAllSwots(list) {
    swots.value = list;
  }

  const currentSwot = computed(() => {
    if (swots.value.length === 0 || swot.value === null) {
      return null;
    }

    if (route.query.swotId) {
      return swots.value.find((v) => v.id === Number(route.query.swotId));
    }
    return swot.value;
  });

  const currentNote = computed(() => {
    if (!currentSwot.value) {
      return null;
    }
  
    if (!route.query.noteId && currentSwot.value.lastNoteList.length > 0) {
      return currentSwot.value.lastNoteList[0];
    }
  
    if (route.query.noteId && currentSwot.value.noteList.length > 0) {
      const note = currentSwot.value.noteList.find(
        (v) => v.id === Number(route.query.noteId)
      );
      
      return note !== undefined ? note : null;
    }
  
    return null;
  })

  const getPointByTagId = computed(() => {
    return (id) => points.value.filter((p) => p.tagTypeId === Number(id));
  });

  const getNoteById = computed(() => {
    return (id) => swot.value.noteList.find((v) => v.id === Number(id));
  });

  const getTagListByNoteId = computed(() => {
    return (id) => {
      const note = getNoteById(id);

      if (!note) {
        return null;
      }

      return note.tagList;
    };
  });

  return {
    getTagListByNoteId,
    getNoteById,
    getPointByTagId,
    currentSwot,
    currentNote,
    setActiveSwot,
    setTagsTitles,
    setPoints,
    setAllSwots,
    swot,
    tagsTitles,
    points,
    swots,
  };
});
