<template>
  <div class="user-login wrapper">
    <div class="user-login__form form">
      <div class="user-login__top">
        <span class="gradient-logo-title">Эврика</span>
        <p>Добро пожаловать 👋</p>
      </div>

      <div v-if="errorVisible" class="user-login__error-text">
        <p>{{ errorText }} !</p>
      </div>

      <form class="user-login__content" @submit.prevent="loginToAccount">
        <div
          :class="{
            error: v$.login.$errors.length,
            success:
              !v$.login.$errors.length && v$.login.$model && v$.login.$dirty,
          }"
          class="form__item"
        >
          <input
            v-model="v$.login.$model"
            type="text"
            @focus="v$.login.$touch"
            placeholder="Логин"
            maxlength="100"
            class="form__input"
          />

          <div
            class="input-errors"
            v-for="error of v$.login.$errors"
            :key="error.$uid"
          >
            <div class="error-msg form__tip form__tip--error showing">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div
          :class="{
            error: v$.password.$errors.length,
            success:
              !v$.password.$errors.length &&
              v$.password.$model &&
              v$.password.$dirty,
          }"
          class="form__item"
        >
          <div class="form__input-with-icon">
            <input
              v-model="v$.password.$model"
              :type="passwordVisible ? 'text' : 'password'"
              @focus="v$.password.$touch"
              placeholder="Пароль"
              maxlength="100"
              class="form__input"
            />
            <AppButton
              @click="passwordVisible = !passwordVisible"
              class="form__visibility-changer"
            >
              <CIcon v-if="passwordVisible" icon="eye-icon" />
              <CIcon v-else icon="close-eye-icon" />
            </AppButton>
          </div>

          <div
            class="input-errors"
            v-for="error of v$.password.$errors"
            :key="error.$uid"
          >
            <div class="error-msg form__tip form__tip--error showing">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <AppButton
          class="user-login__login-button"
          type="submit"
          :disabled="saveIsDisable"
          >Войти</AppButton
        >
      </form>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

import { customAxios } from "@/service/customAxios";
import { useErrorsData } from "@/composables/errorsData";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/store/auth";
import { store as vuexStore } from "@/store";
import { useUserData } from "@/composables/userData";

import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";

const rules = {
  login: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  password: {
    required: helpers.withMessage("Обязательное поле", required),
  },
};

const router = useRouter();

const { setUser } = useAuthStore();
const { updateUserInfo } = useUserData();
// eslint-disable-next-line no-unused-vars
const { getErrorMessage } = useErrorsData();

const login = ref("");
const password = ref("");
const errorText = ref("");

const passwordVisible = ref(false);
const errorVisible = ref(false);
const v$ = useVuelidate(rules, {
  login: login,
  password: password,
});

async function loginToAccount() {
  try {
    const r = await signIn();
    setUser(r.data);
    await updateUserInfo();
    vuexStore.commit("authentication/loginSuccess", r.data);
    router.push("/");
  } catch (e) {
    console.log(e)
    // if (e && e.response.status !== 401) {
    //   errorText.value = getErrorMessage(e);
    // } else {
    //   errorText.value = 'Неверный логин или пароль'
    // }
    errorVisible.value = true;
    login.value = ''
    password.value = ''
  }
}

async function signIn() {
  return customAxios.post("/auth/signin", {
    password: password.value,
    username: login.value,
  });
}

const saveIsDisable = computed(() => {
  v$.value.$touch();

  return v$.value.$invalid;
});
</script>

<style lang="scss" scoped>
@import "@/styles/form.scss";

.user-login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  &__form {
    max-width: 400px;
    padding: 32px;
    position: relative;
    background-color: #fff;
    border-radius: 16px;

    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 50%;
      height: 50%;
      z-index: -1;
      border-radius: 16px;
    }

    &::after {
      background-image: $light-gradient;
      top: 0;
      left: 0;
      transform: translate(-40%, -10%);
      filter: opacity(0.2);
    }

    &::before {
      right: 0;
      bottom: 0;
      background-image: url("../assets/school-pattern.png");
      transform: translate(40%, 10%);
      filter: opacity(0.5);
    }
  }

  &__top {
    text-align: center;
    margin-bottom: 32px;

    p {
      margin-top: 16px;
      color: #5d596c;
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__login-button {
    background-image: $light-gradient;
    color: #fff;
    border-radius: 8px;
    width: 100%;
    padding: 6px 12px;
    margin-top: 16px;
    background-size: 200% auto;
    transition: 0.2s;
    background-position: 0 0;

    &:disabled {
      background-image: none;
      background-color: rgba(#000, 0.1);
      cursor: not-allowed;
    }

    &:hover {
      background-position: right center;
    }
  }

  &__error-text {
    background-color: rgba($error, 0.1);
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;

    p {
      color: $error;
      text-transform: capitalize;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    &::after {
      content: "";
      position: absolute;
      background-image: url("../assets/school-pattern.png");
      filter: opacity(0.5);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__form {
      max-width: none;
      width: 100%;
      z-index: 1;

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
</style>
