<template>
  <td v-if="item">
    <StatusCell v-model="status" />
    <CommentCell class="mt-1" v-model="comment" />
  </td>

  <td v-else class="comment-visit-status-cell__empty"></td>
</template>

<script setup>
import { watch, ref, defineEmits, defineProps } from "vue";
import CommentCell from "@/components/CommentCell.vue";
import StatusCell from "./StatusCell.vue";
import { debounce } from "@/util/common";

const props = defineProps({
  item: {
    type: Object,
    required: false,
  },
  comment: {
    type: String,
    required: false,
  },
  status: {
    type: Number,
    required: false,
  },
});
const emits = defineEmits(["editVisitStatus", "editChildComment"]);

const comment = ref(props.comment);
const status = ref(props.status);

const debouncedEditChildEventComment = debounce(editChildEventComment, 1000);

watch(comment, (v) => {
  if (v !== props.comment) {
    debouncedEditChildEventComment();
  }
});

watch(status, (v) => {
  if (v !== props.status) {
    editVisitStatus();
  }
});

function editChildEventComment() {
  emits("editChildComment", { item: props.item, comment: comment.value });
}

function editVisitStatus() {
  emits("editVisitStatus", {
    item: props.item,
    status: status.value,
  });
}
</script>
