import { customAxios } from "@/service/customAxios";
import { computed, ref } from "vue";
import { useDatesChanger } from "./datesChanger";

export function useChildSchedueData() {
  const { getTimeFromWeek } = useDatesChanger();

  const childEvents = ref(null);

  const daysHeaders = computed(() => {
    if (childEvents.value === null) {
      return [];
    }

    const emptySaturday =
      childEvents.value.scheduleWeekEventsByDay[5].dayHourEvents.length === 0;

    if (emptySaturday && childEvents.value.headers.length === 6) {
      return childEvents.value.headers.slice(0, 5);
    } else {
      return childEvents.value.headers;
    }
  });

  const weekSchedule = computed(() => {
    if (!childEvents.value || !childEvents.value.weekEvents.length) {
      return [];
    }

    const hasEmptySaturday = childEvents.value.weekEvents.every(
      (v) => v.hourEventsByDay[5].length === 0
    );

    const filteredEvents = hasEmptySaturday
      ? childEvents.value.weekEvents.map((ev) => {
          return {
            ...ev,
            hourEventsByDay: ev.hourEventsByDay.slice(0, 5),
          };
        })
      : childEvents.value.weekEvents;

    return filteredEvents.map((v, idx) => {
      const id = idx + 1;
      const time = getTimeFromWeek(v.hourEventsByDay);

      const events = v.hourEventsByDay.map((lessons) => {
        return lessons.map((l) => {
          const backgroundColor =
            l.scheduleClass.scheduleClassBackgroundColor || "#fff";

          const textColor = l.scheduleClass.scheduleClassTextColor || "#000";

          return {
            title: l.scheduleClass.scheduleClassName,
            backgroundColor: backgroundColor,
            textColor: textColor,
            teacher: `${l.eventOrganizer.firstname} ${l.eventOrganizer.secname} ${l.eventOrganizer.lastname} `,
          };
        });
      });

      return {
        id,
        time,
        events,
      };
    });
  });

  async function getChildEventsByRange(data) {
    const options = setDataToOptions(data);
    const r = await customAxios.post(
      "/schedule/events_by_range/for_child/table",
      options
    );

    childEvents.value = r.data;
  }

  async function getChildCurrentWeekEvents({ childId, classTypes }) {
    const options = {
      classTypes: [],
      employees: [],
      childGroups: [],
      childId: childId,
    };

    if (classTypes !== -1) {
      options.classTypes.push(classTypes);
    }
    console.log("Options: " + options);

    const r = await customAxios.post(
      "/schedule/week_events/for_child/table",
      options
    );
    childEvents.value = r.data;
  }

  function setDataToOptions(data) {
    const options = {
      classTypes: [],
    };

    if (!!data && data.range && data.range.length) {
      options.beginDateTime = data.range[0];
      options.endDateTime = data.range[1];
    }

    if (data.childId !== null) {
      options.childId = data.childId;
    }

    if (data.classTypes) {
      options.classTypes.push(data.classTypes);
    } else {
      options.classTypes = [];
    }

    options.employees = [];

    options.childGroups = [];

    return options;
  }

  return {
    weekSchedule,
    daysHeaders,
    getChildEventsByRange,
    childEvents,
    getChildCurrentWeekEvents,
  };
}
