<template>
  <div class="side-menu-link">
    <CIcon :icon="data.icon" />
    <span> {{ data.title }}</span>
    <CIcon icon="arrow-icon" />
  </div>
</template>

<script>
import CIcon from "@/elements/CIcon.vue";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {};
  },
  components: { CIcon },
};
</script>

<style lang="scss">
.side-menu-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
  color: $text;
  padding: 8px;

  background-color: #fff;
  transition: all 0.2s;

  &:hover {
    background-color: #f8f8f8;
    border-radius: 4px;
  
  }
  svg {
    stroke: $text;
    stroke-width: 1px;
    flex-shrink: 0;
  }

  svg:first-of-type {
    width: 22px;
    height: 22px;
    stroke-width: 1px;
    margin-right: 16px;
  }

  svg:last-of-type {
    width: 16px;
    height: 16px;
    transform: rotate(-90deg);
    stroke-width: 2px;
    margin-left: auto;
    margin-right: 0;
    transition: 0.2s;
  }

  &.open svg:last-of-type {
    transform: rotate(0deg);
  }
}
</style>
