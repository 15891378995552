<template>
  <v-card>
    <v-dialog v-model="showLoader"><LoaderRing></LoaderRing></v-dialog>
    <v-dialog v-model="showLocationOptionDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Параметр помещения</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="locationOptionForm">
            <v-col cols="12">
              <v-text-field
                label="Название*"
                v-model="optionName"
                :rules="optionNameRules"
                hint="Укажите название"
                :counter="30"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Описание*"
                v-model="optionDescription"
                :rules="optionDescriptionRules"
                :counter="255"
                hint="Укажите описание"
                required
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-row class="px-6" fluid>
                <v-switch v-model="isActive" :label="'Активно'" color="success">
                </v-switch>
                <v-chip v-if="isActive">Да</v-chip><v-chip v-else>Нет</v-chip>
              </v-row>
            </v-col>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn rounded variant="outlined" @click="closeLocationOptionDialog">
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveLocationOption">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <h3 class="ma-2">Параметры помещений</h3>

    <v-card-title class="card_ligth_header" style="min-height: 5vh">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="locationOptionSearchBody"
            label="Найти"
            variant="outlined"
            clearable
            hint="Введите ключевое слово для поиска"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
            @click="showLocationOptionDialog = true"
            variant="outlined"
            color="primary"
            class="mb-4"
          >
            Добавить
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <div class="d-sm-none d-flex align-center flex-column">
        <v-card
          class="ma-5 mx-5 w-100"
          v-for="item in locationOptionPaginatedData"
          :key="item"
        >
          <v-card-title class="card_ligth_header">
            <h5>
              Параметр №
              {{ locationOptionPaginatedData.indexOf(item) + 1 }}
            </h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list-item lines="two">
              <v-list-item-title>Название:</v-list-item-title>
              <v-list-item-subtitle>{{ item.optionName }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Описание:</v-list-item-title>
              <v-list-item-subtitle>{{
                item.optionDescription
              }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Добавлено:</v-list-item-title>
              <v-list-item-subtitle>{{
                item.createDate.replace("T", " ")
              }}</v-list-item-subtitle>
            </v-list-item>

            <v-list-item lines="two">
              <v-list-item-title>Активно:</v-list-item-title>
              <v-list-item-subtitle
                ><v-chip size="small" v-if="item.isActive">Да</v-chip
                ><v-chip v-else size="small">Нет</v-chip></v-list-item-subtitle
              >
            </v-list-item>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-btn
              @click="editLocationOption(item)"
              class="ma-1"
              color="primary"
              variant="outlined"
              rounded
            >
              Редактировать
            </v-btn>
            <v-btn
              @click="deleteLocationOption(item)"
              class="ma-1"
              color="warning"
              variant="outlined"
              rounded
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div class="d-none d-sm-block">
        <v-table fixed-header class="h-100">
          <thead>
            <tr>
              <th class="text-left">
                <b>№</b>
              </th>
              <th class="text-left">
                <b>Название</b>
              </th>
              <th class="text-left">
                <b>Описание</b>
              </th>
              <th class="text-left">
                <b>Добавлено</b>
              </th>
              <th class="text-left">
                <b>Активно</b>
              </th>
              <th class="text-left">
                <b>Действие</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in locationOptionPaginatedData" :key="item">
              <td>
                <p>
                  {{ locationOptionPaginatedData.indexOf(item) + 1 }}
                </p>
              </td>
              <td>
                <p>{{ item.optionName }}</p>
              </td>
              <td>
                <p>{{ item.optionDescription }}</p>
              </td>
              <td>
                <p>{{ item.createDate.replace("T", " ") }}</p>
              </td>
              <td>
                <v-chip size="small" v-if="item.isActive">Да</v-chip
                ><v-chip v-else size="small">Нет</v-chip>
              </td>
              <td>
                <p>
                  <v-btn
                    @click="editLocationOption(item)"
                    variant="outlined"
                    color="primary"
                    class="ma-1"
                    >Редактировать</v-btn
                  >
                  <v-btn
                    @click="deleteLocationOption(item)"
                    variant="outlined"
                    color="warning"
                    class="ma-1"
                    >Удалить
                  </v-btn>
                </p>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="card_ligth_actions" style="min-height: 5vh">
      <v-container class="text-center">
        <v-pagination
          v-model="locationOptionPage"
          :length="locationOptionPageCount"
          :total-visible="3"
          size="small"
        ></v-pagination>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import LoaderRing from "@/elements/LoaderRing.vue";
import _ from "lodash";
import {customAxios} from "@/service/customAxios";

export default {
  name: "ScheduleLocationOptionLIst",
  props: [],
  components: { LoaderRing },
  data: () => ({
    noteCount: 10,
    isActive: false,
    showLoader: false,
    locationOptionSearchBody: "",
    showLocationOptionDialog: false,
    locationOptions: [],

    locationOptionPage: 1,
    optionName: "",
    optionDescription: "",
    optionNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    optionDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 255) || "Значение до должно превышать 255 символов!",
    ],
    optionId: undefined,
  }),
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },

    filteredLocationOptions() {
      const ss = this.locationOptionSearchBody.toLowerCase();

      let tempLocationOptions = this.locationOptions;

      return tempLocationOptions.filter((item) =>
        _.some(item, (v) => {
          if (!(v instanceof Object)) {
            return _.toLower(v).indexOf(ss) > -1;
          }
        })
      );
    },
    locationOptionPageCount() {
      let l = this.filteredLocationOptions.length,
        s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },
    locationOptionPaginatedData() {
      const start =
          (this.currentLocationOptionPage - 1) * parseInt(this.noteCount),
        end = start + parseInt(this.noteCount);

      return this.filteredLocationOptions.slice(start, end);
    },
    currentLocationOptionPage() {
      if (this.locationOptionPageCount < this.locationOptionPage) {
        return 1;
      } else {
        return this.locationOptionPage;
      }
    },
  },
  methods: {
    closeLocationOptionDialog() {
      this.optionName = "";
      this.optionDescription = "";
      this.isActive = false;
      this.showLocationOptionDialog = false;
      this.optionId = undefined;
    },
    saveLocationOption() {
      this.$refs.locationOptionForm.validate().then((result) => {
        if (result.valid) {
          let option = {
            optionName: capitalizeFirstLetter(this.optionName),
            optionDescription: capitalizeFirstLetter(this.optionDescription),
            isActive: this.isActive,
          };

          if (this.optionId) {
            customAxios.put("/schedule/location/option/" + this.optionId, option)
              .then((response) => {
                if (response.status === 202) {
                  let index = getIndex(this.locationOptions, response.data.id);
                  this.locationOptions.splice(index, 1, response.data);
                  this.optionName = "";
                  this.optionDescription = "";
                  this.isActive = false;
                  this.showLocationOptionDialog = false;
                  this.optionId = "";
                } else {
                  alert("Что-то пошло не так");
                  console.log(response);
                }
              })
              
          } else {
            customAxios.post("/schedule/location/option", option)
              .then((response) => {
                if (response.status === 201) {
                  this.locationOptions.push(response.data);
                  this.optionName = "";
                  this.optionDescription = "";
                  this.isActive = false;
                  this.showLocationOptionDialog = false;
                } else {
                  alert("Что-то пошло не так");
                  console.log(response);
                }
              })

          }
        }
      });
    },
    editLocationOption(item) {
      this.optionName = item.optionName;
      this.optionDescription = item.optionDescription;
      this.isActive = item.isActive;
      this.showLocationOptionDialog = true;
      this.optionId = item.id;
    },
    deleteLocationOption(item) {

      customAxios
        .delete( "/schedule/location/option/" + item.id)
        .then((response) => {
          if (response.status === 202) {
            this.locationOptions.splice(this.locationOptions.indexOf(item), 1);
          } else {
            alert("Что-то пошло не так");
            console.log(response);
          }
        })

    },
  },
  created: function () {
    this.showLoader = true;

    function getLocationOption(customAxios) {
      return customAxios.get( "/schedule/location/option");
    }

    Promise.all([getLocationOption(customAxios)])
      .then((results) => {
        if (results[0].status === 200) {
          this.locationOptions = results[0].data;
        }
        this.showLoader = false;
      })
      .catch(() => {
        this.showLoader = false;
        alert("Ошибка загрузки данных, обратитесь к администратору системы!");
      });
  },

  setup() {
    return {};
  },
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}
</script>

<style lang="scss">
.form-element {
  box-shadow: $shadow;
  display: grid;
  grid-template-columns: 40% 1fr;
  overflow: hidden;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: $gradient-1;
  }

  &__card.v-card {
    box-shadow: none;
    padding: 30px;
  }

  &__title.v-card-title {
    color: $main;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
  }

  .v-text-field__input {
    input::placeholder {
      font-size: 18px;
      line-height: 22px;
    }

    label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #6e6d94;
    }

    .v-field__outline {
      color: rgba(#6e6d94, 0.5);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;

    &__img {
      display: none;
    }
  }
}
</style>
