<template>
  <v-card class="ma-2">
    <h3 class="ma-2">Учебные годы (периоды)</h3>

    <v-card-title class="card_ligth_header" style="min-height: 5vh">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="studyYearSearchBody"
            label="Найти"
            variant="outlined"
            clearable
            hint="Введите ключевое слово для поиска"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
            @click="showStudyYearDialog = true"
            variant="outlined"
            color="primary"
            rounded
            class="mb-4"
          >
            Добавить
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text style="min-height: 80vh">
      <div class="d-sm-none d-flex align-center flex-column">
        <v-card
          class="ma-4 mx-4 w-100"
          v-for="item in studyYearPaginatedData"
          :key="item"
        >
          <v-card-title class="text-pink-lighten-2 bg-pink-lighten-5">
            <h5>Период № {{ studyYearPaginatedData.indexOf(item) + 1 }}</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list-item lines="two">
              <v-list-item-title>Название:</v-list-item-title>
              <v-list-item-subtitle>{{
                item.studyYearName
              }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Описание:</v-list-item-title>
              <v-list-item-subtitle>{{
                item.studyYearDescription
              }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Создано:</v-list-item-title>
              <v-list-item-subtitle>{{
                item.createDate.replace("T", " ")
              }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Начало:</v-list-item-title>
              <v-list-item-subtitle>{{
                item.beginDate.replace("T", " ")
              }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Окончание:</v-list-item-title>
              <v-list-item-subtitle>{{
                item.endDate.replace("T", " ")
              }}</v-list-item-subtitle>
            </v-list-item>

            <v-list-item lines="two">
              <v-list-item-title>Активно:</v-list-item-title>
              <v-list-item-subtitle
                ><v-chip size="small" v-if="item.isActive">Да</v-chip
                ><v-chip v-else size="small">Нет</v-chip></v-list-item-subtitle
              >
            </v-list-item>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="text-pink-lighten-2 bg-pink-lighten-5">
            <v-spacer></v-spacer>
            <v-btn
              @click="editYear(item)"
              class="ma-2"
              color="primary"
              variant="outlined"
              rounded
            >
              Редактировать
            </v-btn>
            <v-btn
              @click="deleteYear(item)"
              class="ma-2"
              color="warning"
              variant="outlined"
              rounded
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div class="d-none d-sm-block">
        <v-table fixed-header class="h-100">
          <thead>
            <tr>
              <th class="text-left">
                <b>№</b>
              </th>
              <th class="text-left">
                <b>Название</b>
              </th>
              <th class="text-left">
                <b>Описание</b>
              </th>
              <th class="text-left">
                <b>Создано</b>
              </th>
              <th class="text-left">
                <b>Начало</b>
              </th>
              <th class="text-left">
                <b>Окончание</b>
              </th>
              <th class="text-left">
                <b>Активно</b>
              </th>
              <th class="text-left">
                <b>Действие</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in studyYearPaginatedData" :key="item">
              <td>
                <p>{{ studyYearPaginatedData.indexOf(item) + 1 }}</p>
              </td>
              <td>
                <p>{{ item.studyYearName }}</p>
              </td>
              <td>
                <p>{{ item.studyYearDescription }}</p>
              </td>
              <td>
                <p>{{ item.createDate.replace("T", " ") }}</p>
              </td>
              <td>
                <p>{{ item.beginDate.replace("T", " ") }}</p>
              </td>
              <td>
                <p>{{ item.endDate.replace("T", " ") }}</p>
              </td>

              <td>
                <v-chip size="small" v-if="item.isActive">Да</v-chip
                ><v-chip v-else size="small">Нет</v-chip>
              </td>
              <td>
                <v-btn
                  @click="editYear(item)"
                  class="ma-1"
                  color="primary"
                  variant="outlined"
                  rounded
                >
                  Редактировать
                </v-btn>
                <v-btn
                  @click="deleteYear(item)"
                  class="ma-1"
                  color="warning"
                  variant="outlined"
                  rounded
                >
                  Удалить
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="card_ligth_actions" style="min-height: 5vh">
      <v-container class="text-center">
        <v-pagination
          v-model="studyYearPage"
          :length="studyYearPageCount"
          :total-visible="3"
          size="small"
        ></v-pagination>
      </v-container>
    </v-card-actions>
    <v-dialog v-model="showLoader"><LoaderRing></LoaderRing></v-dialog>

    <v-dialog v-model="showStudyYearDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h3>Период</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="studyYearForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Название*"
                    v-model="studyYearName"
                    :rules="studyYearNameRules"
                    hint="Укажите название"
                    :counter="30"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Описание*"
                    v-model="studyYearDescription"
                    :rules="studyYearDescriptionRules"
                    :counter="255"
                    hint="Укажите описание"
                    required
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-label>Дата начала</v-label>
                  <DatePicker
                    v-model="beginDate"
                    autoApply
                    textInput
                    locale="ru"
                  />
                  <v-alert
                    v-if="yearBeginDateAlert === true"
                    border="top"
                    color="red lighten-2"
                    dark
                  >
                    Укажите дату!
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-label>Дата окончания</v-label>
                  <DatePicker
                    v-model="endDate"
                    autoApply
                    textInput
                    locale="ru"
                  />
                  <v-alert
                    v-show="yearEndDateAlert === true"
                    border="top"
                    color="red lighten-2"
                    dark
                  >
                    Укажите дату!
                  </v-alert>
                  <v-alert
                    v-show="yearDateAlert === true"
                    border="top"
                    color="red lighten-2"
                    dark
                  >
                    Дата окончания периода должна быть позже даты начала!
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-row class="px-6" fluid>
                    <v-switch
                      v-model="isActive"
                      :label="'Активно'"
                      color="success"
                    >
                    </v-switch>
                    <v-chip v-if="isActive">Да</v-chip
                    ><v-chip v-else>Нет</v-chip>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn rounded variant="outlined" @click="closeYearDialog">
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveYear">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import LoaderRing from "@/elements/LoaderRing.vue";
import _ from "lodash";
import {customAxios} from "@/service/customAxios";

export default {
  name: "ScheduleStudyYearLIst",
  props: [],
  components: { LoaderRing },
  data: () => ({
    noteCount: 10,
    showLoader: false,
    studyYearSearchBody: "",
    showStudyYearDialog: false,
    studyYears: [],
    yearId: "",
    studyYearPage: 1,
    studyYearName: "",
    studyYearDescription: "",
    yearBeginDateAlert: false,
    yearEndDateAlert: false,
    studyYearNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    studyYearDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 255) || "Значение до должно превышать 255 символов!",
    ],
    beginDate: null,
    endDate: null,
    yearDateAlert: false,
  }),
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
    filteredStudyYear() {
      const ss = this.studyYearSearchBody.toLowerCase();

      let tempStudyYears = this.studyYears;

      return tempStudyYears.filter((item) =>
        _.some(item, (v) => {
          if (!(v instanceof Object)) {
            return _.toLower(v).indexOf(ss) > -1;
          }
        })
      );
    },
    studyYearPageCount() {
      let l = this.filteredStudyYear.length,
        s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },
    studyYearPaginatedData() {
      const start = (this.currentStudyYearPage - 1) * parseInt(this.noteCount),
        end = start + parseInt(this.noteCount);

      return this.filteredStudyYear.slice(start, end);
    },
    currentStudyYearPage() {
      if (this.studyYearPageCount < this.studyYearPage) {
        return 1;
      } else {
        return this.studyYearPage;
      }
    },
  },
  methods: {
    editYear(item) {
      this.studyYearName = item.studyYearName;
      this.studyYearDescription = item.studyYearDescription;
      this.isActive = item.isActive;
      this.beginDate = item.beginDate;
      this.endDate = item.endDate;
      this.yearId = item.id;
      this.showStudyYearDialog = true;
    },
    deleteYear(item) {
      if (confirm("Вы точно хотите удалить учебный период?")) {
        customAxios.delete( "/schedule/year/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              this.studyYears.splice(this.studyYears.indexOf(item), 1);
            } else {
              console.log(response);
            }
          })

      }
    },
    closeYearDialog() {
      this.showStudyYearDialog = false;
      this.isActive = false;
      this.studyYearName = "";
      this.studyYearDescription = "";
      this.beginDate = null;
      this.endDate = null;
      this.yearId = undefined;
      this.yearBeginDateAlert = false;
      this.yearEndDateAlert = false;
      this.yearDateAlert = false;
    },
    saveYear() {
      this.yearBeginDateAlert =
        this.beginDate === null || this.beginDate === undefined;

      this.yearEndDateAlert =
        this.endDate === null || this.endDate === undefined;

      if (!this.yearBeginDateAlert && !this.yearEndDateAlert) {
        this.yearDateAlert =
          Date.parse(this.beginDate) >= Date.parse(this.endDate);
      }


      this.$refs.studyYearForm.validate().then((responce) => {
        if (responce.valid) {
          if (
            !this.yearBeginDateAlert &&
            !this.yearEndDateAlert &&
            !this.yearDateAlert
          ) {
            let studyYear = {
              studyYearName: capitalizeFirstLetter(this.studyYearName),
              studyYearDescription: capitalizeFirstLetter(
                this.studyYearDescription
              ),
              beginDate: this.beginDate,
              isActive: this.isActive,
              endDate: this.endDate,
            };

            if (this.yearId) {
              customAxios.put("/schedule/year/" + this.yearId, studyYear)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.studyYears, response.data.id);
                    this.studyYears.splice(index, 1, response.data);
                    this.studyYearName = "";
                    this.studyYearDescription = "";
                    this.isActive = false;
                    this.beginDate = null;
                    this.endDate = null;
                    this.yearId = undefined;
                    this.showStudyYearDialog = false;
                    this.yearDateAlert = false;
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

            } else {
              customAxios.post( "/schedule/year", studyYear)
                .then((response) => {
                  if (response.status === 201) {
                    this.studyYears.push(response.data);
                    this.studyYearName = "";
                    this.studyYearDescription = "";
                    this.isActive = false;
                    this.beginDate = null;
                    this.endDate = null;
                    this.showStudyYearDialog = false;
                    this.yearDateAlert = false;
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

            }
          }
        }
      });
    },
  },
  created: function () {
    this.showLoader = true;

    function getStudyYears(customAxios) {
      return customAxios.get( "/schedule/year");
    }

    Promise.all([getStudyYears(customAxios)])
      .then((results) => {
        if (results[0].status === 200) {
          this.studyYears = results[0].data;
        }
        this.showLoader = false;
      })
      .catch(() => {
        this.showLoader = false;
        alert("Ошибка загрузки данных, обратитесь к администратору системы!");

      });
  },

  setup() {
    return {};
  },
};
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}
</script>

<style lang="scss">
.form-element {
  box-shadow: $shadow;
  display: grid;
  grid-template-columns: 40% 1fr;
  overflow: hidden;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: $gradient-1;
  }

  &__card.v-card {
    box-shadow: none;
    padding: 30px;
  }

  &__title.v-card-title {
    color: $main;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
  }

  .v-text-field__input {
    input::placeholder {
      font-size: 18px;
      line-height: 22px;
    }

    label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #6e6d94;
    }

    .v-field__outline {
      color: rgba(#6e6d94, 0.5);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;

    &__img {
      display: none;
    }
  }
}
</style>
