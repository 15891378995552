<template>
  <ListWithDayIdSelectorLayout>
    <template v-slot:title><BackToTable>Оценочные листы</BackToTable></template>

    <template v-slot:content>
      <router-link
        v-if="$route.query.dayId"
        :to="{
          name: 'rating-sheet-create',
          query: {
            journalId: $route.query.journalId,
            dayId: $route.query.dayId,
          },
        }"
        class="gradient-button"
        >Добавить лист</router-link
      >

      <ul v-if="lists.length > 0">
        <li v-for="l in lists" :key="l.id" class="gradient-list-item mt-4">
          <router-link
            class="gradient-list-item__content"
            :to="{
              name: 'rating-sheet-edit',
              query: {
                journalId: $route.query.journalId,
                dayId: $route.query.dayId,
                listId: l.id,
              },
            }"
          >
            <h4>{{ l.skillListDescription }}</h4>
          </router-link>
        </li>
      </ul>

      <v-label
        v-else
        class="d-flex text-deep-purple-darken-1 font-weight-bold mt-5"
        >Список пуст</v-label
      >
    </template>
  </ListWithDayIdSelectorLayout>
</template>

<script setup>
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { storeToRefs } from "pinia";

import BackToTable from "@/subjectJournal/components/BackToTable.vue";
import ListWithDayIdSelectorLayout from "../components/ListWithDayIdSelectorLayout.vue";

const { lists } = storeToRefs(useSubjectsJournalStore());
</script>
