<template>
  <v-container class="rounded-xl pa-0 form-element">
    <v-container class="pa-0 form-element__img">
      <slot name="img" />
    </v-container>
    <v-card theme="none" class="form-element__card">
      <v-card-title class="pa-0 mb-10 form-element__title">
        <slot name="card-title"></slot>
      </v-card-title>
      <slot name="form"></slot>
    </v-card>
  </v-container>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.form-element {
  box-shadow: $shadow;
  display: grid;
  grid-template-columns: 40% 1fr;
  overflow: hidden;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: $gradient-1;
  }

  &__card.v-card {
    box-shadow: none;
    padding: 30px;
  }

  &__title.v-card-title {
    color: $main;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
  }

  .v-text-field__input {
    input::placeholder {
      font-size: 18px;
      line-height: 22px;
    }

    label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #6e6d94;
    }

    .v-field__outline {
      color: rgba(#6e6d94, 0.5);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;

    &__card.v-card {
      padding: 8px;
    }

    &__img {
      display: none;
    }
  }
}
</style>
