<template>
  <section id="about-school" class="section-about">
    <div class="wrapper">
      <CObserverAnimate threshold="1">
        <div class="section-about__title">
          <p>О школе</p>
          <p>
            Основная цель школы - воспитание человека, способного к
            самореализации в хрупком, тревожном, нелинейном и непостижимом
            (BANI) мире.
          </p>
        </div>
      </CObserverAnimate>

      <v-container
        class="section-about__content pa-0 rounded-lg"
        v-intersect="onIntersect"
        :class="{ animated: isIntersecting }"
      >
        <v-card theme="none" class="pa-4">
          <v-img
            v-intersect="onIntersectIcon"
            src="pens.png"
            width="48"
            height="48"
            class="section-about__animated-icon mb-10"
            :class="{ animated: iconIsIntersecting }"
          />

          <div class="section-about__description">
            <p>
              "Образовательный процесс в каждом классе осуществляет целая
              команда педагогов: учитель развивающего образования, ведущий
              некоторые предметы на русском языке, Teacher (ведущий предметы на
              английском языке), тьютор-воспитатель, сопровождающий
              индивидуальное развитие и самоопределение каждого ребенка.
            </p>

            <p>
              В расписании каждого класса есть как уроки, так и Не-уроки
              (различные полилингвальные формы внеурочной деятельности, работа в
              тематических предметных студиях в разновозрастных группах по
              выбору детей). К таким Не-урокам привлекаются мастера -
              профессионалы в области тех видов деятельности, которые
              организуются в студиях и лабораториях."
            </p>
          </div>

          <v-container class="section-about__name pa-0">
            <p>{{ principal.name }}</p>
            <p>{{ principal.job }}</p>
          </v-container>
        </v-card>

        <v-card theme="none">
          <v-img
            :cover="true"
            src="Principal.jpg"
            alt="Антонова Алла Александровна"
            class="section-about__photo"
          />
        </v-card>
      </v-container>
    </div>
  </section>
</template>

<script>
import CObserverAnimate from "../observers/CObserverAnimate.vue";

const principal = {
  name: `Антонова Алла Александровна`,
  job: `Директор Образовательного центра «Эврика»`,
};
export default {
  data() {
    return {
      principal,
      isIntersecting: false,
      iconIsIntersecting: false,
    };
  },

  methods: {
    onIntersectIcon(isIntersecting) {
      this.iconIsIntersecting = isIntersecting;
    },
    onIntersect(isIntersecting) {
      this.isIntersecting = isIntersecting;
    },
  },
  components: { CObserverAnimate },
};
</script>

<style lang="scss">
.section-about {
  margin-top: 50px;
  overflow: hidden;

  .v-card {
    box-shadow: none;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 360px;
    box-shadow: $shadow;
    overflow: hidden;
  

    opacity: 0;
    -webkit-transform: translate3d(20%, 0, 0);
    transform: translate3d(20%, 0, 0);

    &.animated {
      -webkit-animation: slideFade 0.4s cubic-bezier(0.12, 0, 0.39, 0) 0.7s 1
        forwards;
      animation: slideFade 0.4s cubic-bezier(0.12, 0, 0.39, 0) 0.7s 1 forwards;
    }
  }

  &__photo {
    height: 100%;
  }

  &__name {
    p:first-child {
      color: $main-1;
      font-weight: 500;
    }

    p:last-child {
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    font-style: italic;

    p + p {
      margin-top: 20px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 40px;

    p:last-child {
      -webkit-transform: translate3d(10%, 0, 0);
      transform: translate3d(10%, 0, 0);
      font-style: italic;
    }

    p:first-child {
      -webkit-transform: translate3d(-10%, 0, 0);
      transform: translate3d(-10%, 0, 0);
      white-space: nowrap;
      position: relative;
      margin-right: 16px;
      padding-right: 16px;
      color: $main-2;
      font-weight: 600;
      text-transform: uppercase;

      &::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 100%;
        transform: scale(0);
        transition: transform 0.3s;
        background: $light-gradient;
        top: 0;
        right: 0;
      }
    }

    h2 {
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    h2,
    p {
      opacity: 0;
    }

    &.animated {
      p:first-child::after {
        -webkit-transform: scale(1);
        transform: scale(1);
        transition-delay: 0.3s;
      }

      p {
        -webkit-animation: slideFade 0.2s cubic-bezier(0.61, 1, 0.88, 1) 0s 1
          forwards;

        animation: slideFade 0.2s cubic-bezier(0.61, 1, 0.88, 1) 0s 1 forwards;
      }

      p:first-child {
        animation-delay: 0.3s;
      }

      p:last-child {
        animation-delay: 0.5s;
        animation-duration: 0.4s;
      }
    }
  }

  &__animated-icon.animated {
    -webkit-animation: slideTopSlideDown 2s cubic-bezier(0.37, 0, 0.63, 1) 0s
      infinite;

    animation: slideTopSlideDown 2s cubic-bezier(0.37, 0, 0.63, 1) 0s infinite;
  }

  @media (max-width: 768px) {
    &__content {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 480px) {
    &__title {
      display: block;

      p:first-child {
        padding-right: 0;
        margin-right: 0;
        padding-bottom: 8px;
        margin-bottom: 8px;

        &::after {
          width: 100%;
          height: 3px;
          top: auto;
          left: 0;
          right: auto;
          bottom: 0;
        }
      }
    }
  }
}
</style>
