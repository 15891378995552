<template>
  <div>
    <div>
      <div v-if="isParent" class="g-col g-col--3-1fr">
        <FilterByChild class="mb-4" />
      </div>

      <FilterByGroupAndChild v-if="isTutor" class="mb-4" />

      <div v-if="isTutor" class="g-col g-col--2-1fr mb-4">
        <v-switch
          v-model="onlyMyNotes"
          :label="`${onlyMyNotes ? 'Только мои записи' : 'От всех авторов'}`"
          color="success"
          hide-details
        >
        </v-switch>

        <FilterByEmployee v-if="onlyMyNotes" />
      </div>
    </div>

    <SearchFilter />

    <div class="d-flex align-end">
      <CSortByDate />
      <FilterByRecipient v-if="!isTutor" class="ml-4" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { removeFilterFromURL } from "@/service/router";
import { useAuthStore } from "@/store/auth";

import SearchFilter from "@/components/SearchFilter.vue";
import CSortByDate from "@/components/CSortByDate.vue";
import FilterByChild from "@/components/filtersElements/FilterByChild.vue";

import FilterByGroupAndChild from "@/components/filtersElements/FilterByGroupAndChild.vue";
import FilterByRecipient from "@/components/filtersElements/FilterByRecipient.vue";
import FilterByEmployee from "@/components/filtersElements/FilterByEmployee.vue";

const { isParent, isTutor } = useAuthStore();
const onlyMyNotes = ref(false);

watch(onlyMyNotes, (v) => {
  if (!v) {
    removeFilterFromURL("filterByEmployee");
  }
});
</script>
