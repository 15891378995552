<template>
  <v-select
    v-if="subjectsSelector.length"
    v-model="selectedSchoolSubjectId"
    :items="subjectsSelector"
    variant="outlined"
    density="compact"
    hide-details
    placeholder="Предмет"
  >
  </v-select>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { removeFilterFromURL, pushFilterToURL } from "@/service/router";
import { onMounted, watch } from "vue";
import { useSchoolSubjectsData } from "@/composables/schoolSubjects";
import { useRoute } from "vue-router";

const route = useRoute();

const { subjectsSelector, getSchoolSubjects, selectedSchoolSubjectId } =
  useSchoolSubjectsData();

onMounted(async () => {
  await getSchoolSubjects();
  if (route.query.filterBySubjectId) {
    selectedSchoolSubjectId.value = Number(route.query.filterBySubjectId);
  }
});

watch(selectedSchoolSubjectId, (id) =>
  id
    ? pushFilterToURL({ name: "filterBySubjectId", value: id })
    : removeFilterFromURL("filterBySubjectId")
);
</script>
