<template>
  <div>
    <TableElement>
      <template v-slot:tabs>
        <v-tabs v-model="part" bg-color="primary-dark">
          <v-tab @click="part = 1" value="1">Альбомы</v-tab>
        </v-tabs>
      </template>
      <template v-slot:window>
        <v-window v-model="part" class="align-self-stretch h-75" :touch="{left: null, right: null}">
          <v-window-item :value="1" class="align-self-stretch">
            <v-card-title class="card_ligth_header">
              <h3 class="ma-2">Альбомы</h3>
            </v-card-title>
            <v-card-title class="card_ligth_header" style="min-height: 5vh">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="albumSearchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn
                      @click="albumDialog = true"
                      class="mb-2"
                      variant="outlined"
                      color="primary"
                  >Добавить альбом</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text class="ma-0 pa-0">
              <div class="d-sm-none d-flex align-center flex-column">
                <v-card
                    variant="tonal"
                    class="ma-5 mx-5 w-100"
                    v-for="item in albumPaginatedData"
                    :key="item.id"
                >
                  <v-card-title class="card_ligth_header">
                    <h5>Альбом № {{ sortedAlbums.indexOf(item) + 1 }}</h5>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-list-item lines="two">

                        <v-list-item-title>Название:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.albumName
                          }}</v-list-item-subtitle>

                    </v-list-item>
                    <v-list-item lines="two">

                        <v-list-item-title>Описание:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.albumDescription
                          }}</v-list-item-subtitle>

                    </v-list-item>
                    <v-list-item lines="two">

                        <v-list-item-title>Создан:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.createDate.replace("T", " ")
                          }}</v-list-item-subtitle>

                    </v-list-item>
                    <v-list-item lines="two">

                        <v-list-item-title>Опубликован:</v-list-item-title>
                        <v-list-item-subtitle
                        ><v-chip size="small" v-if="item.isPublished"
                        >Да</v-chip
                        ><v-chip v-else size="small"
                        >Нет</v-chip
                        ></v-list-item-subtitle
                        >

                    </v-list-item>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="card_ligth_actions">
                    <v-btn
                        @click="albumPhotos(item)"
                        class="ma-1"
                        rounded
                        variant="outlined"
                        color="primary"
                    >
                      Смотреть</v-btn
                    >
                    <v-btn
                        @click="editAlbum(item)"
                        class="ma-1"
                        rounded
                        variant="outlined"
                        color="primary-dark"
                    >
                      Редактировать</v-btn
                    >
                    <v-btn
                        @click="deleteAlbum(item)"
                        class="ma-1"
                        rounded
                        variant="outlined"
                        color="warning"
                    >
                      Удалить</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </div>
              <div class="d-none d-sm-block">
                <Vue3EasyDataTable
                    :headers="albumHeaders"
                    :items="sortedAlbums"

                    alternating
                    show-index
                >
                  <template #item-createDate="{ createDate }">
                    <v-chip size="small">{{
                        createDate.replace("T", " ")
                      }}</v-chip>
                  </template>

                  <template #item-isPublished="{ isPublished }">
                    <v-chip size="small" v-if="!!isPublished"
                    >Опубликован</v-chip
                    >
                    <v-chip size="small" v-if="!isPublished"
                    >Не опубликован</v-chip
                    >
                  </template>

                  <template #item-actions="item">
                    <v-btn
                        @click="albumPhotos(item)"
                        class="ma-1"
                        rounded
                        variant="outlined"
                        color="primary"
                    >
                      Смотреть</v-btn
                    >
                    <v-btn
                        @click="editAlbum(item)"
                        class="ma-1"
                        rounded
                        variant="outlined"
                        color="primary-dark"
                    >
                      Редактировать</v-btn
                    >
                    <v-btn
                        @click="deleteAlbum(item)"
                        class="ma-1"
                        rounded
                        variant="outlined"
                        color="warning"
                    >
                      Удалить</v-btn
                    >
                  </template>
                </Vue3EasyDataTable>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions
                class="card_ligth_actions d-sm-block"
                style="min-height: 5vh"
            >
              <v-container class="text-center">
                <v-pagination
                    v-model="albumPage"
                    :length="albumPageCount"
                    :total-visible="3"
                    size="small"
                ></v-pagination>
              </v-container>
            </v-card-actions>
          </v-window-item>
          <v-window-item :value="2" class="align-self-stretch">
            <v-card-title class="card_ligth_header">
              <v-col cols="12">
                <h3 class="my-2">
                  Фотографии альбома "{{ selectedAlbum.albumName }}"
                </h3>
              </v-col>
            </v-card-title>
            <v-card-title class="card_ligth_header" style="min-height: 5vh">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="photoSearchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn
                      @click="backPart"
                      class="ma-2"
                      variant="outlined"
                      color="primary"
                  >
                    К альбомам
                  </v-btn>
                  <v-btn
                      @click="photoDialog = true"
                      class="ma-2"
                      variant="outlined"
                      color="primary"
                  >Добавить фото</v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text flat tile class="d-flex">
              <v-row cols="12" sm="9" offset-sm="1">
                <v-col
                    v-for="n in photos"
                    :key="n"
                    class="d-flex child-flex"
                    cols="4"
                >
                  <v-img
                      v-if="n.lazyFileName!==null"
                      :src="`https://school.eurekacenter.ru/photo/${n.fileName}`"
                      :lazy-src="`https://school.eurekacenter.ru/photo/${n.lazyFileName}`"
                      aspect-ratio="1"
                      cover
                      class="bg-grey-lighten-2"
                      v-on:click="showPhoto(n)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="grey-lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-img
                      v-else
                      :src="`https://school.eurekacenter.ru/photo/${n.fileName}`"
                      aspect-ratio="1"
                      cover
                      class="bg-grey-lighten-2"
                      v-on:click="showPhoto(n)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="grey-lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
          </v-window-item>
        </v-window>
      </template>
    </TableElement>
    <!-- <v-card class="mx-auto ma-10">
      <v-card class="align-self-stretch ma-0 pa-0"> </v-card>
    </v-card> -->
    <v-row justify="center">
      <v-dialog v-model="albumDialog" scrollable persistent>
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Альбом</h5>
          </v-card-title>
          <v-card-text>
            <v-form ref="albumForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Название*"
                        v-model="albumName"
                        :rules="albumNameRules"
                        variant="outlined"
                        hint="Укажите название достижения"
                        :counter="30"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                        label="Описание*"
                        v-model="albumDescription"
                        :rules="albumDescriptionRules"
                        variant="outlined"
                        :counter="255"
                        hint="Укажите описание"
                        required
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12">
                    <v-row class="px-6" fluid>
                      <v-switch
                          v-model="isPublished"
                          :label="`Статус`"
                          color="success"
                      >
                      </v-switch>
                      <v-chip v-if="isPublished"> Опубликован</v-chip
                      ><v-chip v-else> Формируется</v-chip>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-autocomplete
                        v-model="personGroups"
                        :items="userGroups"
                        outlined
                        dense
                        chips
                        small-chips
                        closable-chips
                        multiple
                        label="Группы просмотра"
                    >
                      <template v-slot:chip="{ props, item }">
                        <v-chip
                            v-bind="props"
                            :text="item.raw.personGroupName"
                        ></v-chip>
                      </template>
                      <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            :title="item.raw.personGroupName"
                        ></v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn rounded variant="outlined" @click="closeAlbum">
              Отмена
            </v-btn>
            <v-btn rounded variant="outlined" @click="saveAlbum">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="photoDialog" scrollable persistent>
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Загрузка фото</h5>
          </v-card-title>
          <v-card-text>
            <v-form ref="photoForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                        show-size
                        v-model="fileBody"
                        ref="fileBody"
                        accept="image/jpeg,image/gif,image/jpg,image/png,image/bmp"
                        clearable
                        variant="outlined"
                        counter
                        multiple
                        rounded
                        label="Загрузить файлы*"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-progress-linear
                        v-if="uploadPercentage>0"
                        color="amber"
                        v-model="uploadPercentage"
                        height="25"
                    >

                      <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                    </v-progress-linear>
                  </v-col>
                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn rounded variant="outlined" @click="closePhoto">
              Отмена
            </v-btn>
            <v-btn
                rounded
                variant="outlined"
                class="small"
                style="margin-left: 5px"
                :disabled="downloadAnimation"
                @click="savePhotoFiles"
            >
              <font-awesome-icon
                  v-if="downloadAnimation === false"
                  icon="fas fa-floppy-disk"
              />
              <v-progress-circular
                  v-if="downloadAnimation"
                  indeterminate
              ></v-progress-circular>
              Загрузить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="viewPhoto">
        <v-card>
          <v-divider></v-divider>
          <v-img :src="`https://school.eurekacenter.ru/photo/${selectedPhoto.fileName}`">
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular
                    color="grey-lighten-4"
                    indeterminate
                ></v-progress-circular>
              </div>
            </template>
          </v-img>

          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">

           <v-spacer></v-spacer>
            <v-btn
                @click="deletePhoto(selectedPhoto)"
                style="margin: 5px"
                ma-2
                variant="outlined"
                rounded
            ><font-awesome-icon icon="fa-solid fa-trash-alt" />Удалить</v-btn>
            <v-btn
                @click="downloadFile(selectedPhoto)"
                ma-2
                variant="outlined"
                rounded
            ><font-awesome-icon icon="fa-solid fa-download" />Скачать</v-btn
            >
            <v-btn
                @click="viewPhoto = false"
                ma-2
                variant="outlined"
                rounded
            ><font-awesome-icon icon="fa-solid fa-close" />Закрыть</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showLoader" persistent
      ><v-alert
      ><span class="text-h5 text-primary"
      >Загрузка данных
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular></span
      ></v-alert>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import {userService} from "@/service/user.service";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}
import _ from "lodash";
import Vue3EasyDataTable from "vue3-easy-data-table";
import TableElement from "../elements/TableElement.vue";
import download from "js-file-download";
import {customAxios} from "@/service/customAxios";

export default {
  name: "AdminPhoto",
  components: {
    Vue3EasyDataTable,
    TableElement,
  },

  props: [],
  data: () => ({
    selectedAlbum: null,
    uploadPercentage:0,
    albumHeaders: [
      { text: "Название", value: "albumName" },
      { text: "Описание", value: "albumDescription" },
      { text: "Создан", value: "createDate" },
      { text: "Опубликован", value: "isPublished" },
      { text: "Действие", value: "actions" },
    ],

    showLoader: false,
    photoId: null,
    personGroups: [],
    photoSearchBody: "",
    viewPhoto: false,
    selectedPhoto: null,
    part: 0,
    albumDialog: false,
    albumName: "",
    albumNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 30) || "Значение до должно превышать 30 символов!",
    ],
    albumDescription: "",
    albumDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 255) || "Значение до должно превышать 30 символов!",
    ],
    isPublished: false,

    photoDialog: false,

    photoDescription: "",
    photoDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 255) || "Значение до должно превышать 30 символов!",
    ],

    fileBody: [],
    fileRules: [
      (v) => !!v || "Необходимо прикрепить файл!",
      (v) =>
          !!v & (v[0].size < 200000000) ||
          "Размер файла не должен превышать 200 MB!",
    ],
    downloadAnimation: false,
    albumSearchBody: "",
    photos: [],

    lastAlbumSortKey: "createDate",
    albumSortKey: "createDate",
    albumSortOrder: -1,
    noteCount: 20,
    albumPage: 1,

    albums: [],
    userGroups: [],
  }),

  methods: {
    backPart() {
      this.part = 1;
    },
    showPhoto(n) {
      this.selectedPhoto = n;
      this.viewPhoto = true;
    },
    closePhoto() {
      this.photoDialog = false;
      this.fileBody = [];
      this.photoDescription = "";
    },

    savePhotoFiles() {

      this.$refs.photoForm.validate().then((result) => {
        if (result.valid) {
          if (this.subject instanceof Object) this.subject = this.subject.id;

          let formData = new FormData();

          for (var i = 0; i < this.$refs.fileBody.files.length; i++ ){
            let file = this.$refs.fileBody.files[i];
            formData.append('files', file);
          }


          this.axios.post("/album/photo/files/" +this.albumId , formData,  {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + this.user.token,
            },
            onUploadProgress: function( progressEvent ) {
              this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)


          })
              .then((response) => {
                if(response.status===201){
                  this.photos = response.data;
                  this.photoDialog = false;
                  this.uploadPercentage = 0
                  this.fileBody = [];
                } else if(response.status===207){
                  alert(response.data.message);
                  this.photos= response.data;
                  this.photoDialog = false;
                  this.uploadPercentage = 0
                  this.fileBody = [];
                  console.log(response);
                } else {
                  alert("Что-то пошло не так");
                  console.log(response);
                }
              })
              .catch((error) => {
                console.log("error: " + error);
              });

        }
      });
    },

    saveFile() {
      this.$refs.photoForm.validate().then((result) => {
        if (result.valid) {
          this.downloadAnimation = true;
          let formData = new FormData();
          this.ext = this.fileName.substr(this.fileName.lastIndexOf(".") + 1);

          formData.append("file", this.$refs.fileBody.files[0], this.fileName);

          this.axios
              .post("/upload/photo", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer " + this.user.token,
                },
              })
              .then((res) => {
                if (res.status === 201) {
                  let photo = {
                    fileName: res.data.message,
                    photoDescription: capitalizeFirstLetter(
                        this.photoDescription
                    ),
                    albumId: this.albumId,
                  };
                  customAxios.post("/album/photo", photo)
                      .then((response) => {
                        if (response.status === 201) {
                          this.photos.push(response.data);
                          this.downloadAnimation = false;
                          this.photoDialog = false;
                          this.photoDescription = [];
                          this.fileBody = [];
                        } else {
                          alert("Что-то пошло не так");
                          this.downloadAnimation = false;
                          console.log(response);
                        }
                      })
                }
              })
              .catch(function () {
                console.log("Ошибка загрузки файла");
                alert("Ошибка загрузки файла, попробуйте еще раз!");
              });
        }
      });
    },

    closeAlbum() {
      this.albumName = "";
      this.albumId = null;
      this.albumDescription = "";
      this.isPublished = false;
      this.personGroups = null;
      this.albumDialog = false;
    },
    editAlbum(item) {
      this.albumId = item.id;
      this.albumName = item.albumName;
      this.albumDescription = item.albumDescription;
      this.isPublished = item.isPublished;
      this.personGroups = item.personGroups;
      this.albumDialog = true;
    },

    saveAlbum() {
      if (this.$refs.albumForm.validate()) {
        if (this.subject instanceof Object) this.subject = this.subject.id;

        let album = {
          albumName: capitalizeFirstLetter(this.albumName),
          albumDescription: capitalizeFirstLetter(this.albumDescription),
          isPublished: this.isPublished,
          personGroups: this.personGroups,
        };
        if (this.albumId) {
          customAxios.put("/album/" + this.albumId, album)
              .then((response) => {
                if (response.status === 202) {
                  let index = getIndex(this.albums, response.data.id);
                  this.albums.splice(index, 1, response.data);
                  this.albumName = "";
                  this.albumId = null;
                  this.albumDescription = "";
                  this.personGroups = null;
                  this.isPublished = false;
                  this.albumDialog = false;
                } else {
                  alert("Что-то пошло не так");
                  console.log(response);
                }
              })

        } else {
          customAxios.post("/album", album)
              .then((response) => {
                if (response.status === 201) {
                  this.albums.push(response.data);
                  this.albumName = "";
                  this.albumId = null;
                  this.albumDescription = "";
                  this.personGroups = null;
                  this.isPublished = false;
                  this.albumDialog = false;
                } else {
                  alert("Что-то пошло не так");
                  console.log(response);
                }
              })

        }
      }
    },

    deleteAlbum(item) {
      if (confirm("Вы точно хотите удалить альбом?")) {
        customAxios.delete("/album/" + item.id)
            .then((response) => {
              if (response.status === 202) {
                this.albums.splice(this.albums.indexOf(item), 1);
              } else {
                console.log(response);
              }
            })

      }
    },
    downloadFile: function (item) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
        responseType: "blob"

      };
      this.axios.get("/downloadFile/album/" + item.fileName,
          config
      ).then((resp) => {
        userService.handleResponse(resp);
        download(resp.data, item.fileName);
      });
    },
    deletePhoto(selectedPhoto) {
      if (confirm("Вы точно хотите удалить фото?")) {
        customAxios.delete("/album/photo/" + selectedPhoto.id)
            .then((response) => {
              if (response.status === 202) {
                this.photos.splice(this.photos.indexOf(selectedPhoto), 1);
                this.viewPhoto = false;
              } else {
                console.log(response);
              }
            })

      }
    },

    albumPhotos(item) {
      this.albumId = item.id;
      this.selectedAlbum = item;
      this.showLoader = true;

      customAxios.get("/album/photo/" + item.id)
          .then((response) => {
            if (response.status === 200) {
              this.photos = response.data;
              this.showLoader = false;
            } else {
              this.showLoader = false;
              alert("Что-то пошло не так");
              console.log(response);
            }
          })
          .then((this.part = 2))
          .catch(() => {
            this.showLoader = false;
            alert("Ошибка загрузки данных, обратитесь к администратору системы!");

          });
      this.part = 2;
    },

    byAlbumId() {
      if (this.lastAlbumSortKey.includes("id")) {
        this.albumSortOrder = this.albumSortOrder * -1;
      }
      this.albumSortKey = "id";
      this.lastAlbumSortKey = this.albumSortKey;
    },
    byAlbumName() {
      if (this.lastAlbumSortKey.includes("albumName")) {
        this.albumSortOrder = this.albumSortOrder * -1;
      }
      this.albumSortKey = "albumName";
      this.lastAlbumSortKey = this.albumSortKey;
    },
    byAlbumDescription() {
      if (this.lastAlbumSortKey.includes("albumDescription")) {
        this.albumSortOrder = this.albumSortOrder * -1;
      }
      this.albumSortKey = "albumDescription";
      this.lastAlbumSortKey = this.albumSortKey;
    },
    byCreateDate() {
      if (this.lastAlbumSortKey.includes("createDate")) {
        this.albumSortOrder = this.albumSortOrder * -1;
      }
      this.albumSortKey = "createDate";
      this.lastAlbumSortKey = this.albumSortKey;
    },
    byIsPublished() {
      if (this.lastAlbumSortKey.includes("isPublished")) {
        this.albumSortOrder = this.albumSortOrder * -1;
      }
      this.albumSortKey = "isPublished";
      this.lastAlbumSortKey = this.albumSortKey;
    },
  },

  computed: {
    filteredAlbums() {
      const ss = this.albumSearchBody.toLowerCase();

      let tempAlbums = this.albums;

      return tempAlbums.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );
    },

    sortedAlbums() {
      let tempAlbums = this.filteredAlbums;
      return tempAlbums.sort(
          dynamicSort(this.albumSortKey, this.albumSortOrder)
      );
    },

    albumPageCount() {
      let l = this.sortedAlbums.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },

    albumPaginatedData() {
      const start = (this.currentAlbumPage - 1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.sortedAlbums.slice(start, end);
    },

    currentAlbumPage() {
      if (this.albumPageCount < this.albumPage) {
        return 1;
      } else {
        return this.albumPage;
      }
    },

    loggedIn() {
      return this.$store.state.authentication.status.loggedIn;
    },

    user() {
      return this.$store.state.authentication.user;
    },

    authentication() {
      return this.$store.state.authentication;
    },
  },
  created: function () {
    this.showLoader = true;
    function getAlbums(customAxios) {
      return customAxios.get("/album");
    }
    function getUserGroups(customAxios) {
      return customAxios.get("/user_group");
    }

    Promise.all([
      getAlbums(customAxios),
      getUserGroups(customAxios),
    ])
        .then((results) => {
          if (results[0].status === 200) {
            this.albums = results[0].data;
          }
          if (results[1].status === 200) {
            this.userGroups = results[1].data;
          }
          this.showLoader = false;
        })
        .catch(() => {
          this.showLoader = false;
          alert("Ошибка загрузки данных, обратитесь к администратору системы!");

        });
  },
};
function dynamicSort(property, sortOrder) {
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
</script>
