<template>
  <v-switch v-model="onlyMine" label="Только мои" color="success" hide-details>
  </v-switch>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { pushFilterToURL, removeFilterFromURL } from "@/service/router";

const onlyMine = ref(true);

watch(onlyMine, (v) =>
  v
    ? pushFilterToURL({ name: "filterByOwner", value: "mine" })
    : removeFilterFromURL("filterByOwner")
);

onMounted(setOwner);

function setOwner() {
  if (onlyMine.value) {
    pushFilterToURL({
      name: "filterByOwner",
      value: "mine",
    });
  } else {
    removeFilterFromURL("filterByOwner");
    onlyMine.value = false;
  }
}
</script>
