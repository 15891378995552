<template>
  <div>
    <PageTitle title="Отчёт мастерских по классу" />

    <div class="g-col g-col--2-1fr">
      <FilterByGroupId />
      <WeekRangeFilter v-model="period" />
    </div>

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <p class="mb-3">Здесь отобразится отчёт.</p>

        <v-label> Выставьте фильтры</v-label>
        <ol class="pl-5">
          <li><strong>класс</strong></li>
          <li><strong>неделя</strong></li>
        </ol>
        <div class="mt-3 d-flex align-center flex-wrap">
          Значок
          <div class="mx-2 d-flex align-center">
            <CIcon icon="cross-icon" />
          </div>

          обозначает, что у ребёнка в этот день не было мастерских
        </div>
      </template>
    </TogglerOfWrapper>

    <StatusWrapper :loadingStatus="loading" :isDataExisted="reports.length > 0">
      <div class="d-flex justify-end my-3">
        <AppButton class="accent-button" @click="download"
          >Скачать отчёт</AppButton
        >
      </div>

      <div class="page-wrapper">
        <div class="table-wrapper">
          <table class="table-custom" id="workshop-report-table">
            <thead>
              <tr class="table-custom__headers">
                <th></th>
                <th v-for="day in reports[0].weekDays" :key="day.id">
                  {{ day.dayDate }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="child in reports" :key="child.childId">
                <td v-if="child.childFullName">{{ child.childFullName }}</td>
                <td v-for="d in child.weekDays" :key="d.id">
                  <p v-if="d.workshops.length > 0">
                    <span v-for="(w, i) in d.workshops" :key="i"
                      >{{ w.workshopName }}
                    </span>
                  </p>
                  <p v-else>
                    <CIcon icon="cross-icon" />
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { onMounted, ref, watch } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

import { useUserData } from "@/composables/userData";
import { usePopupStore } from "@/store/popup";
import { useRoute } from "vue-router";

import StatusWrapper from "@/components/StatusWrapper.vue";
import WeekRangeFilter from "@/components/WeekRangeFilter.vue";
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";
import FilterByGroupId from "@/components/filtersElements/FilterByGroupId.vue";

import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import PageTitle from "@/elements/PageTitle.vue";

const { getCurrentWeek } = useDatesChanger();
const { downloadExcel } = useUserData();
const { getDateFormatWithoutTime } = useDatesChanger();
const { showError } = usePopupStore();

const period = ref(getCurrentWeek());
const reports = ref([]);
const loading = ref("nothing");
const route = useRoute();

watch(period, getData);
watch(() => route.query.filterByGroupId, getData);

onMounted(getData);

async function getData() {
  if (!route.query.filterByGroupId) {
    return;
  }
  loading.value = "loading";

  try {
    const r = await getReports();
    reports.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getReports() {
  return await customAxios.post("/workshop/tutor_report", {
    userGroupId: Number(route.query.filterByGroupId),
    beginPeriodDateTime: period.value[0],
    endPeriodDateTime: period.value[1],
  });
}

function download() {
  const title =
    "отчёт_тьютор_" +
    getDateFormatWithoutTime(period.value[0]) +
    "-" +
    getDateFormatWithoutTime(period.value[1]);

  downloadExcel(title, "workshop-report-table");
}
</script>
