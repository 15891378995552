<template>
  <tr class="dictation-row">
    <td>{{ child.child.lastname }} {{ child.child.firstname }}</td>

    <td
      class="cell-input"
      :class="{
        error: v$.wordsCount.$errors.length,
      }"
    >
      <input v-model="v$.wordsCount.$model" type="number" />
    </td>

    <td
      class="cell-input"
      :class="{
        error: v$.failsCount.$errors.length,
      }"
    >
      <input v-model="v$.failsCount.$model" type="number" />
    </td>

    <td class="bordered-cell">
      {{ Math.round(level) }}
    </td>

    <td
      class="dictation-row__competence"
      :class="{ 'bordered-cell': failCompetenceList.length }"
    >
      <ChildCompetenceEditor
        v-model="failCompetenceList"
      />
    </td>
  </tr>
</template>

<script setup>
import { computed, ref, watch, defineEmits, defineProps, onMounted } from "vue";

import { useVuelidate } from "@vuelidate/core";
import { maxValue, minValue } from "@vuelidate/validators";

import ChildCompetenceEditor from "@/components/ChildCompetenceEditor.vue";
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";

const rules = {
  wordsCount: {
    minValueValue: minValue(0),
    maxValueValue: maxValue(100),
  },
  failsCount: {
    minValueValue: minValue(0),
    maxValueValue: maxValue(100),
  },
};

const emits = defineEmits(["updateLists"]);

const props = defineProps({
  child: {
    type: Object,
    required: true,
  },
});

const { showError, showToastPopup } = usePopupStore();

const wordsCount = ref(null);
const failsCount = ref(null);
const level = ref(null);
const failCompetenceList = ref([]);

onMounted(setCurrentData);

function setCurrentData() {
  if (!props.child) {
    return;
  }
  wordsCount.value = props.child.wordCount;
  failsCount.value = props.child.fuckupCount;
  level.value = props.child.resultLevel;
  failCompetenceList.value = props.child.failCompetenceList;
}

const v$ = useVuelidate(rules, {
  wordsCount: wordsCount,
  failsCount: failsCount,
});

const canSend = computed(() => {
  const haveErrors =
    v$.value.wordsCount.$errors.length > 0 &&
    v$.value.failsCount.$errors.length > 0;

  const haveIntegers =
    Number.isInteger(wordsCount.value) && Number.isInteger(failsCount.value);

  return !haveErrors && haveIntegers;
});

async function editChildsResult() {
  try {
    if (canSend.value) {
      await editChildsResultHandler();
      showToastPopup("Сохранено");
      emits("updateLists");
    }
  } catch (e) {
    showError(e);
  }
}

async function editChildsResultHandler() {
  return customAxios.put(`/foreign_dictation/child/${props.child.id}`, {
    fuckupCount: failsCount.value,
    wordCount: wordsCount.value,
    resultLevel: level.value,
    failCompetenceList: failCompetenceList.value,
  });
}

watch(
  failsCount,
  async (v) => {
    if (props.child && props.child.fuckupCount !== v) {
      editChildsResult();
    }
  },
  { deep: true }
);

watch(
  wordsCount,
  async (v) => {
    if (props.child && props.child.wordCount !== v) {
      editChildsResult();
    }
  },
  { deep: true }
);

watch(
  failCompetenceList,
  async (v) => {
    if (props.child && props.child.failCompetenceList !== v) {
      editChildsResult();
    }
  },
  { deep: true }
);
</script>
