<template>
  <div>
    <PageTitle :title="`Отчёт по ${subjectTitle} за уровень усвоения`" />
    <FiltersForChildsReport>
      <FilterByYear />
    </FiltersForChildsReport>

    <TipForChildFilters>
      <p class="mt-3">
        Чтобы открыть подробности о работе - нажмите на
        <strong>название</strong> или <strong>кружок светофора</strong>
      </p>
    </TipForChildFilters>

    <StatusWrapper
      :isDataExisted="lines.length > 0"
      :loadingStatus="loadingStatus"
    >
      <RLegend class="mt-1" />

      <RThemeResults
        v-for="l in lines"
        :key="l.id"
        :line="l"
        class="my-3"
        @openPopup="openPopup"
      />
    </StatusWrapper>

    <v-dialog v-model="visible">
      <ChecklistPopup @close="visible = false" :popup="popup" />
    </v-dialog>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { customAxios } from "@/service/customAxios";

import { usePopupStore } from "@/store/popup";
import { useRussianReportsData } from "@/composables/reports";
import { useAuthStore } from "@/store/auth";

import { pushFilterToURL } from "@/service/router";

import RLegend from "./RLegend.vue";
import RThemeResults from "./RThemeResults.vue";
import FilterByYear from "@/components/filtersElements/FilterByYear.vue";

import ChecklistPopup from "./ChecklistPopup.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";
import PageTitle from "@/elements/PageTitle.vue";

import TipForChildFilters from "@/components/tips/TipForChildFilters.vue";
import FiltersForChildsReport from "../FiltersForChildsReport.vue";

const { showError } = usePopupStore();
const { subjectTitle } = useRussianReportsData();
const route = useRoute();
const { isChild, userId } = useAuthStore();

watch(() => route.params.subjectId, clear);
watch(() => route.query.filterByGroupId, getReport);
watch(() => route.query.filterByChildId, getReport);
watch(() => route.query.filterByYear, getReport);

const visible = ref(false);
const popup = ref(null);
const report = ref([]);
const loadingStatus = ref("nothing");

const lines = computed(() => {
  if (!report.value.length) {
    return [];
  }

  const lines = [];

  report.value.forEach((r) => {
    r.lines.forEach((l) => {
      const line = lines.find((v) => v.lineId === l.lineId);

      const checklist = {
        checklistDateTime: r.checklistDateTime,
        checklistName: r.checklistName,
        id: r.id,
        line: l,
      };

      if (line === undefined) {
        lines.push({
          lineId: l.lineId,
          lineName: l.LineName,
          checklists: [checklist],
        });
      } else {
        line.checklists.push(checklist);
      }
    });
  });

  return lines;
});

onMounted(async () => {
  if (isChild) {
    pushFilterToURL({ name: "filterByChildId", value: userId });
  }
  getReport();
});

async function getReport() {
  if (!route.query.filterByChildId || !route.query.filterByYear) {
    return;
  }

  loadingStatus.value = "loading";

  try {
    const r = await getReportHandler();
    report.value = r.data;
  } catch (error) {
    showError(error);
  } finally {
    loadingStatus.value = "loaded";
  }
}

async function getReportHandler() {
  return customAxios.post(
    "/teacher/log/checklists/report/competention/by_child_and_subject_and_study_year",
    {
      subjectId: Number(route.params.subjectId),
      studyYearId: Number(route.query.filterByYear),
      childrenId: Number(route.query.filterByChildId),
    }
  );
}

function clear() {
  report.value = [];
}

function openPopup(e) {
  visible.value = true;
  popup.value = e;
}
</script>
