<template>
  <div>
    <div class="page-wrapper">
      <h3 class="ma-2 mb-5">Ожидаемые и текущие уровни освоения предметов</h3>
      <div class="g-col g-col--2-1fr">
        <v-select
          v-model="metricView"
          :items="metricViewData"
          label="Вид графика"
          item-title="name"
          item-value="value"
          variant="outlined"
          class="small"
        ></v-select>
        <v-autocomplete
          v-model="selectedChildId"
          :items="myChilds"
          filled
          variant="outlined"
          label="Группы пользователей"
          item-value="id"
          item-title="firstname"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="item.raw.firstname + ' ' + item.raw.lastname"
            ></v-list-item>
          </template>
        </v-autocomplete>
      </div>
      <v-btn rounded variant="outlined" @click="getChildMetrics()"
        >Открыть</v-btn
      >
    </div>

    <div v-if="chartDatas !== null">
      <Bar v-if="metricView === 'bar'" :data="chartDatas"></Bar>

      <Radar v-else :data="chartDatas"></Radar>
    </div>
  </div>
</template>
<script>
import { Bar, Radar } from "vue-chartjs";
import { apiUrl } from "@/service/user.service";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
);

export default {
  name: "ProfileChildClassLevels",

  props: [],

  components: {
    Bar,
    Radar,
  },
  data: () => ({
    metricViewData: [
      { name: "Паутинка", value: "radar" },
      { name: "Столбики", value: "bar" },
    ],
    metricView: "radar",

    showLoader: false,
    chartData: [],
    myProfile: {},
    myChilds: [],
    selectedChildId: "",
    selectedData: null,
    showMetrics: false,
    lastDate: null,
    chartDatas: null,
  }),

  methods: {
    getChildMetrics: function () {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .get(
          apiUrl + "/child/metric/by_child/" + this.selectedChildId,
          jwtTokenConfig
        )
        .then((response) => {
          if (response.status === 200) {
            this.selectedData = response.data;
            console.log("this.selectedData:" + this.selectedData);

            this.metricsData(this.selectedData);
          } else {
            this.showLoader = false;
            console.log(response);
          }
        })
        .catch((error) => {
          this.showLoader = false;
          console.log(error);
        });
    },

    metricsData(chartData) {
      this.selectedChart = chartData;
      let labelSet = [];
      let childDataSet = [];
      let teacherDataSet = [];
      let lastDate = "";

      chartData.forEach((element) => {
        labelSet.push(element.scheduleClass.scheduleClassName);
        childDataSet.push(element.myLevel);
        teacherDataSet.push(element.teacherLevel);
        lastDate = element.lastUpdateDateTime;
      });

      this.chartDatas = {
        labels: labelSet,
        datasets: [
          {
            label:
              "Планируемый уровень на" +
              " (" +
              lastDate.replace("T", " ") +
              ")",
            backgroundColor: "rgba(255,99,132,0.2)",
            borderColor: "rgba(255,99,132,1)",
            pointBackgroundColor: "rgba(255,99,132,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(255,99,132,1)",
            data: childDataSet,
          },
          {
            label:
              "Фактический уровень на" +
              " (" +
              lastDate.replace("T", " ") +
              ")",
            backgroundColor: "rgba(104,99,255,0.2)",
            borderColor: "rgb(115,99,255)",
            pointBackgroundColor: "rgb(99,208,255)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(99,107,255)",
            data: teacherDataSet,
          },
        ],
      };
      this.chartValues = {
        responsive: true,
      };

      this.showMetrics = true;
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.authentication.status.loggedIn;
    },
    user() {
      return this.$store.state.authentication.user;
    },
    authentication() {
      return this.$store.state.authentication;
    },
  },
  created: function () {
    this.showLoader = true;
    let jwtTokenConfig = {
      headers: {
        Authorization: "Bearer " + this.user.token,
      },
    };
    function getI(jwtTokenConfig, axios) {
      return axios.get(apiUrl + "/user/i", jwtTokenConfig);
    }

    Promise.all([getI(jwtTokenConfig, this.axios)])
      .then((results) => {
        if (results[0].status === 200) {
          this.myProfile = results[0].data;
          this.myChilds = results[0].data.personChilds;
        }

        this.showLoader = false;
      })
      .catch((error) => {
        this.showLoader = false;
        alert("Ошибка загрузки данных, обратитесь к администратору системы!");
        if (error.response.status === 401) {
          this.$store.dispatch("authentication/logout");
          this.$store.dispatch("alert/clear");
          localStorage.clear();
          location.reload();
        }
      });
  },
};
</script>
