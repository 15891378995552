import { defineStore } from "pinia";
import { useDatesChanger } from "@/composables/datesChanger";
export const useSubjectsJournalStore = defineStore("subjectsJournal", {
  state: () => ({
    // journalTasksForOneDay: [],
    // editingTask: null, //
    // editingSkillList: null,
    // editingTaskOfSkillList: null,
    // editingRatingList: null,

    //
    lists: [],
    list: null,
    journal: null,
    journalDaysWithIds: [],
  }),
  getters: {
    dateFromJournal(state) {
      return (id) => {
        const { getDateFormatWithTime } = useDatesChanger();

        const day = state.journalDaysWithIds.find(
          (v) => v.teacherLogDayId === Number(id)
        );

        if (!day) {
          return null;
        }

        return getDateFormatWithTime(day.teacherLogDayDate);
      };
    },
    listById(state) {
      return (id) => state.lists.find((v) => v.id === Number(id));
    },
    isForeignJournal() {
      return (
        this.journal &&
        this.journal.scheduleClass.scheduleClassName.toLowerCase() ===
          "английский язык"
      );
    },
    allowedDates() {
      if (!this.journalDaysWithIds) {
        return [];
      }
      return this.journalDaysWithIds.map((v) => v.teacherLogDayDate);
    },
    minDate() {
      if (!this.journal) {
        return null;
      }
      const d = new Date(this.journal.days[0].dayDate);
      return d;
    },
    existedKids() {
      if (!this.journal || (this.journal && !this.journal.days.length)) {
        return [];
      }

      return this.journal.days[0].childs;
    },
    journalKidsForTable() {
      if (!this.journal || (this.journal && !this.journal.days.length)) {
        return [];
      }

      if (this.journal) {
        return this.journal.days[0].childs.map((child) => {
          return {
            childId: child.child.id,
            dates: setChildListOfDates(child.child.id, this.journal.days),
            name: `${child.child.lastname} ${child.child.firstname}`,
          };
        });
      }

      function setChildListOfDates(id, days) {
        const result = [];

        days.forEach((day) => {
          day.childs.forEach((child) => {
            if (id === child.childId) {
              result.push({
                date: day.dayDate,
                dayChildId: child.id,
                teacherComment: child.teacherComment,
                attendanceStatus: child.attendanceStatus,
              });
            }
          });
        });

        return result;
      }
    },
  },
  actions: {
    setList(list) {
      this.list = list;
    },
    clearList() {
      this.list = null;
    },
    setLists(lists) {
      this.lists = lists;
    },
    clearJournal() {
      this.journal = null;
    },
    setJournal(journal) {
      this.journal = journal;
    },
    setJournalDaysWithIds(ids) {
      this.journalDaysWithIds = ids;
    },
    clearJournalDaysWithIds() {
      this.journalDaysWithIds = [];
    },
    //
    setJournalTasksForOneDay(tasks) {
      this.journalTasksForOneDay = tasks;
    },
    clearJournalTasksForOneDay() {
      this.journalTasksForOneDay = [];
    },
    setEditingTask(task) {
      this.editingTask = task;
    },
    clearEditingTask() {
      this.editingTask = null;
    },
  },
});
