<template>
  <CustomForm
    title="диктанта"
    @create="create"
    @remove="remove"
    :saveIsDisable="saveIsDisable"
  >
    <div v-if="dateTitle" class="form__item">
      <div class="form__label">Дата</div>
      <v-chip class="pa-2">{{ dateTitle }}</v-chip>
    </div>

    <div
      :class="{
        error: v$.name.$errors.length,
        success: !v$.name.$errors.length && v$.name.$model && v$.name.$dirty,
      }"
      class="form__item"
    >
      <div class="form__label">Название</div>
      <input
        v-model="v$.name.$model"
        type="text"
        @focus="v$.name.$touch"
        placeholder="Название"
        maxlength="100"
        class="form__input"
      />

      <div
        :class="{ 'showing-tip': v$.name.$dirty && v$.name.$model.length }"
        class="form__tip-wrapper"
      >
        <div class="form__tip form__tip--counter showing">
          <span>Любые символы </span>
          <span> {{ v$.name.$model.length }} / 100 </span>
        </div>
      </div>

      <div
        class="input-errors"
        v-for="error of v$.name.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <div class="form__item">
      <v-switch
        v-model="isActive"
        color="success"
        :label="isActive ? 'активен' : 'не активен'"
      />
    </div>
  </CustomForm>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref, watch, defineEmits } from "vue";
import { useVuelidate } from "@vuelidate/core";

import { required, maxLength, helpers } from "@vuelidate/validators";
import { useSubjectsJournalStore } from "@/store/subjects-journal";

import { usePopupStore } from "@/store/popup";
import { useRoute, useRouter } from "vue-router";
import { customAxios } from "@/service/customAxios";

import CustomForm from "@/components/CustomForm.vue";
import { debounce } from "@/util/common";
import { useAuthStore } from "@/store/auth";
const rules = {
  name: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
};

const emits = defineEmits(["updateLists"]);
const { userId } = storeToRefs(useAuthStore());
const route = useRoute();
const router = useRouter();

const { journal, lists, dateFromJournal } = storeToRefs(
  useSubjectsJournalStore()
);

const dateTitle = computed(() => dateFromJournal.value(route.query.dayId));

const currentList = computed(() =>
  lists.value.find((v) => v.id === Number(route.query.listId))
);

const name = ref("");
const isActive = ref(false);

setCurrentData();

function setCurrentData() {
  if (!currentList.value) {
    return;
  }
  name.value = currentList.value.dictationName;
  isActive.value = currentList.value.isActive;
}

const popupStore = usePopupStore();
const {
  showWarningPopup,
  showToastPopup,
  showError,
  showConfirmPopup,
  closePopup,
} = popupStore;

const v$ = useVuelidate(rules, {
  name: name,
});

const saveIsDisable = computed(() => {
  return name.value.length === 0 || v$.value.name.$errors.length > 0;
});

async function create() {
  try {
    await createHandler();
    showToastPopup("Диктант создан");
    update();
  } catch (e) {
    showError(e);
  }
}

async function createHandler() {
  return customAxios.post("/dictation/", {
    dictationName: name.value,
    scheduleClassId: Number(route.query.dayId),
    personGroupId: journal.value.personGroupId,
    isActive: isActive.value ? 1 : 0,
    teacherLogId: journal.value.id,
    teacherLogDayId: Number(route.query.dayId),
  });
}

const canRemove = computed(() => {  
  return currentList.value.creatorId === userId.value});

async function remove() {
  try {
    if (canRemove.value) {
      const res = await customConfirm();

      if (res === "yes") {
        closePopup();

        await removeList();
        showToastPopup("диктант удален");
        update();
      } else {
        closePopup();
      }
    } else {
      showWarningPopup("Только автор может удалить диктант");
    }
  } catch (e) {
    showError(e);
  }
}

async function customConfirm() {
  return showConfirmPopup("Удалить диктант?");
}

async function removeList() {
  return customAxios.delete(`/dictation/${route.query.listId}`);
}

function update() {
  router.push({
    name: "dictations",
    query: { journalId: route.query.journalId, dayId: route.query.dayId },
  });
  emits("updateLists");
}

const debounceUpdate = debounce(updateList, 1000);

watch(isActive, async (v) => {
  if (currentList.value && v !== currentList.value.dictationName) {
    updateList();
  }
});

watch(name, async (v) => {
  if (currentList.value && v !== currentList.value.isActive) {
    debounceUpdate();
  }
});

async function updateList() {
  if (route.query.listId) {
    try {
      await updateListHandler();
      showToastPopup("Успешно сохранено");
    } catch (e) {
      showError(e);
    }
  }
}

async function updateListHandler() {
  return customAxios.put(`/dictation/${route.query.listId}`, {
    isActive: isActive.value,
    dictationName: name.value,
  });
}
</script>
