<template>
  <v-select
    v-if="schoolYearsOptions.length"
    :items="schoolYearsOptions"
    variant="outlined"
    density="compact"
    hide-details
    placeholder="Учебный год"
    bg-color="white"
    :modelValue="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  ></v-select>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { onMounted, defineProps, defineEmits } from "vue";
import { useSchoolData } from "@/composables/schoolData";

defineProps(["modelValue"]);
const emits = defineEmits(["update:modelValue"]);

const { schoolYearsOptions, getSchoolYears } = useSchoolData();

onMounted(async () => {
  await getSchoolYears();
  setYear();
});

function setYear() {
  if (!schoolYearsOptions.value.length) {
    return;
  }
  emits(
    "update:modelValue",
    schoolYearsOptions.value[schoolYearsOptions.value.length - 1].value
  );
}
</script>
