<template>
  <tr v-if="item">
    <td>{{ item.scheduleClass.scheduleClassName }}</td>
    <td class="cell-input-num">
      <input type="number" :value="item.myLevel" @input="debouncedSaveResult" />
    </td>
    <td>
      <p class="text-deep-purple font-weight-bold text-right">
        {{ item.teacherLevel }}
      </p>
    </td>
  </tr>
</template>

<script setup>
import { defineProps } from "vue";
import { debounce } from "@/util/common";
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";

const { showError, showToastPopup } = usePopupStore();
const props = defineProps({ item: { type: Object, required: true } });

const debouncedSaveResult = debounce(saveResult, 1000);

async function saveResult(e) {
  if (e.target.value > 100) {
    return
  }
 
  try {
    await saveResultHandler(e.target.value);
    showToastPopup("Результат сохранён");
  } catch (error) {
    showError(error);
  }
}

function saveResultHandler(level) {
  return customAxios.put("/child/metric/metric/" + props.item.id, {
    myLevel: Number(level),
  });
}
</script>
