<template>
  <v-autocomplete
    v-if="children.length"
    v-model="selectedChildId"
    :items="children"
    variant="outlined"
    placeholder="Ребенок"
    item-value="value"
    item-title="title"
    density="compact"
    hide-details
    bg-color="white"
  ></v-autocomplete>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { onMounted, watch } from "vue";
import { removeFilterFromURL, pushFilterToURL } from "@/service/router";
import { useRoute } from "vue-router";

import { useParentData } from "@/composables/parent";
import { useFiltersStore } from "@/store/filters";

const { setFilterChild } = useFiltersStore();

const route = useRoute();
const { getUser, children, selectedChildId } = useParentData();

watch(selectedChildId, (id) =>
  id
    ? pushFilterToURL({ name: "filterByChildId", value: id })
    : removeFilterFromURL("filterByChildId")
);

watch(() => route.query.filterByChildId, setChild);

onMounted(async () => {
  await getUser();

  setChild();
});

function setChild() {
  if (!children.value.length) {
    return;
  } else if (!route.query.filterByChildId) {
    selectedChildId.value = children.value[0].value;
  }

  selectedChildId.value =
    children.value.findIndex(
      (v) => v.value === Number(route.query.filterByChildId)
    ) >= 0
      ? Number(route.query.filterByChildId)
      : null;

  setFilterChild(children.value, selectedChildId.value);
}
</script>
