<template>
  <div>
    <StatusWrapper
      :loadingStatus="loadingStatus"
      :isDataExisted="currentSwot !== null"
    >
      <router-view></router-view>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { useSwotStore } from "@/store/swot";
import { usePopupStore } from "@/store/popup";
import { storeToRefs } from "pinia";
import StatusWrapper from "@/components/StatusWrapper.vue";

const { setAllSwots, setActiveSwot } = useSwotStore();
const { showError } = usePopupStore();
const { currentSwot } = storeToRefs(useSwotStore());

const route = useRoute();

const loadingStatus = ref("loading");

watch(() => route.query.swotId, update);
onMounted(update);

async function update() {
  if (!route.params.childId) {
    return;
  }

  loadingStatus.value = "loading";
  try {
    await getActiveSwot();
    await getSwotsByChildId();
  } catch (error) {
    showError(error);
  } finally {
    loadingStatus.value = "loaded";
  }
}

async function getActiveSwot() {
  const r = await getChildActiveSwotHandler();
  setActiveSwot(r.data);
}

async function getSwotsByChildId() {
  const r = await getSwotsByChildIdHandler();
  setAllSwots(r.data);
}

async function getSwotsByChildIdHandler() {
  return customAxios.get("/tutor/swot/by_child/" + route.params.childId);
}

async function getChildActiveSwotHandler() {
  return customAxios.get("/tutor/swot/by_child/active/" + route.params.childId);
}
</script>
