<template>
  <v-autocomplete
    v-if="mastersOptions.length"
    v-model="selectedEmployee"
    :items="mastersOptions"
    placeholder="Мастер"
    item-title="title"
    item-value="value"
    density="compact"
    variant="outlined"
    hide-details
    bg-color="white"
  />
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { useEmployeeData } from "@/composables/employeeData";
import { pushFilterToURL, removeFilterFromURL } from "@/service/router";
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";

const { getMasters, mastersOptions, selectedEmployee } = useEmployeeData();
const route = useRoute();

onMounted(async () => {
  await getMasters();
  setMaster();
});

watch(selectedEmployee, (id) => {
  id
    ? pushFilterToURL({ name: "filterByMaster", value: id })
    : removeFilterFromURL("filterByMaster");
});

function setMaster() {
  if (!mastersOptions.value.length) {
    return;
  } else if (route.query.filterByMaster) {
    selectedEmployee.value =
      mastersOptions.value.findIndex(
        (v) => v.value === Number(route.query.filterByMaster)
      ) >= 0
        ? Number(route.query.filterByMaster)
        : mastersOptions.value[0].value;
  }
}
</script>

<style lang="scss"></style>
