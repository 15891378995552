<template>
  <div>
    <AppButton @click="$router.go(-1)" class="bordered-item mb-3"
      >Назад к студии</AppButton
    >

    <CustomForm
      class="form"
      :title="`cобытия студии ${selectedStudio.title}`"
      @create="create"
      @remove="remove"
      :saveIsDisable="saveIsDisable"
    >
      <div
        :class="{
          error: v$.name.$errors.length,
          success: !v$.name.$errors.length && v$.name.$model && v$.name.$dirty,
        }"
        class="form__item my-3"
      >
        <v-label class="form__label">Название</v-label>
        <input
          v-model="v$.name.$model"
          type="text"
          @focus="v$.name.$touch"
          maxlength="100"
          class="form__input"
        />

        <div
          :class="{ 'showing-tip': v$.name.$dirty && v$.name.$model.length }"
          class="form__tip-wrapper"
        >
          <div class="form__tip form__tip--counter showing">
            <span>Любые символы </span>
            <span> {{ name.length }} / 100 </span>
          </div>
        </div>

        <div
          class="input-errors"
          v-for="error of v$.name.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div
        :class="{
          error: v$.masters.$errors.length,
          success:
            !v$.masters.$errors.length &&
            v$.masters.$model &&
            v$.masters.$dirty,
        }"
        class="form__item my-3"
      >
        <v-label class="form__label">Кто может редактировать</v-label>
        <SelectorByStudioMasters v-model="v$.masters.$model" />

        <div
          class="input-errors"
          v-for="error of v$.masters.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <h4 v-if="event" class="my-3 mt-10">Информация о событии</h4>

      <div
        :class="{
          error: v$.groups.$errors.length,
          success:
            !v$.groups.$errors.length && v$.groups.$model && v$.groups.$dirty,
        }"
        class="form__item my-5"
      >
        <div v-if="!event" class="form__label form__label--counter">
          <span>Классы</span>
          <span>Добавлено: {{ v$.groups.$model.length }}</span>
        </div>

        <GroupsSelector
          v-model="v$.groups.$model"
          :readonly="!!event"
          :variant="!!event ? 'plain' : 'outlined'"
        />

        <div
          class="input-errors"
          v-for="error of v$.groups.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div
        :class="{
          error: v$.month.$errors.length,
          success:
            !v$.month.$errors.length && v$.month.$model && v$.month.$dirty,
        }"
        class="form__item my-3"
      >
        <div v-if="!event" class="form__label form__label--counter">
          <span>Месяцы</span>
          <span>Добавлено: {{ v$.month.$model.length }}</span>
        </div>
        <MonthSelector
          v-model="v$.month.$model"
          :readonly="!!event"
          :variant="!!event ? 'plain' : 'outlined'"
        />

        <div
          class="input-errors"
          v-for="error of v$.month.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div
        :class="{
          error: v$.year.$errors.length,
          success: !v$.year.$errors.length && v$.year.$model && v$.year.$dirty,
        }"
        class="form__item my-3"
      >
        <div v-if="!event" class="form__label">Год</div>

        <YearsSelector
          v-model="v$.year.$model"
          :readonly="!!event"
          :variant="!!event ? 'plain' : 'outlined'"
        />

        <div
          class="input-errors"
          v-for="error of v$.year.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>
    </CustomForm>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref, watch, onMounted, defineProps } from "vue";
import { customAxios } from "@/service/customAxios";

import { useIamStudioStore } from "@/store/iamstudio";
import { usePopupStore } from "@/store/popup";
import { useRouter, useRoute } from "vue-router";

import { useVuelidate } from "@vuelidate/core";

import { required, maxLength, helpers } from "@vuelidate/validators";
import { debounce } from "@/util/common";
import { useIstudio } from "@/composables/istudio";

import CustomForm from "@/components/CustomForm.vue";
import AppButton from "@/elements/AppButton.vue";

import SelectorByStudioMasters from "@/components/filtersElements/SelectorByStudioMasters.vue";
import YearsSelector from "@/components/filtersElements/YearsSelector.vue";
import GroupsSelector from "@/components/filtersElements/GroupsSelector.vue";
import MonthSelector from "@/components/filtersElements/MonthSelector.vue";

const rules = {
  name: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
  masters: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  groups: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  month: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  year: {
    required: helpers.withMessage("Обязательное поле", required),
  },
};

const { updateStudios } = useIstudio();

const props = defineProps({ event: { type: Object, required: false } });

const router = useRouter();
const route = useRoute();

const {
  showWarningPopup,
  showError,
  showToastPopup,
  showConfirmPopup,
  closePopup,
} = usePopupStore();

const { studiosOptions } = storeToRefs(useIamStudioStore());

const selectedStudio = computed(() => {
  return studiosOptions.value.find((v) => v.value === Number(route.params.id));
});

const name = ref("");
const groups = ref([]);
const masters = ref([]);
const month = ref(true);
const year = ref(null);

const v$ = useVuelidate(rules, {
  name: name,
  masters: masters,
  groups: groups,
  month: month,
  year: year,
});

function checkInvalidation() {
  v$.value.$touch();
  return v$.value.$invalid;
}

const saveIsDisable = computed(() => {
  return checkInvalidation();
});

onMounted(setData);

function setData() {
  if (props.event) {
    setEventData();
  } else {
    setDefaultData();
  }
}

function setDefaultData() {
  name.value = selectedStudio.value.title;
}

function setEventData() {
  name.value = props.event.eventName;
  masters.value = props.event.studioEventEditors.map((v) => v.id);
  groups.value = [props.event.personGroupId];
  month.value = props.event.monthIndex;
}

async function create() {
  const advice =
    "Вы можете отредактировать ранее добавленные события на странице Я-студии.";

  try {
    const r = await createEventPack();

    const warningText = `Создано событий: ${r.data.createdEvents.length} из ${
      groups.value.length * month.value.length
    }.`;

    if (r.data.createdEvents.length === 0) {
      showWarningPopup(
        "События не добавлены! Причина: все новые события являлись дублями." +
          "" +
          advice
      );
    } else if (r.data.collisionCount > 0) {
      showWarningPopup(
        warningText + "Некоторые новые события являлись дублями" + "" + advice
      );
    } else {
      showToastPopup(warningText);
    }
    update();
  } catch (e) {
    showError(e);
  }
}

async function createEventPack() {
  return customAxios.post("/i_studio/event/pack", {
    studioId: selectedStudio.value.value,
    personGroupIdList: groups.value,
    studyYearId: year.value,
    monthIndexList: month.value,
    studioEventEditors: masters.value,
    eventName: name.value,
  });
}

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();
      await removeStudioEvent();
      showToastPopup("Событие удалено");
      update();
    } else {
      closePopup();
    }
  } catch (e) {
    showError(e);
  }
}

const debouncedEditEvent = debounce(editEvent, 1000);

watch(name, (v) => {
  if (props.event && v.length && v !== props.event.eventName) {
    debouncedEditEvent();
  }
});

watch(masters, (v, prev) => {
  if (props.event) {
    if (!prev.length) {
      return;
    } else {
      editEvent();
    }
  }
});

async function editEvent() {
  try {
    editEventHandler();
    showToastPopup("Сохранено");
  } catch (e) {
    showError(e);
  }
}

function editEventHandler() {
  return customAxios.put(`/i_studio/event/${route.params.eventId}`, {
    eventName: name.value,
    studioEventEditors: masters.value,
  });
}

async function customConfirm() {
  return showConfirmPopup("Удалить событие Я-студии?");
}

function removeStudioEvent() {
  return customAxios.delete(`/i_studio/event/${route.params.eventId}`);
}

async function update() {
  await updateStudios();
  router.back();
}
</script>
