<template>
  <div class="c-top">
    <div class="d-flex justify-center">
      <div class="c-top__list">
        <div v-if="bestChildren.length > 0" class="c-top__item">
          <div class="c-top__winners">
            <p v-for="c in bestChildren" :key="c.id" class="my-2">
              {{ c.firstname }} {{ c.lastname }}
            </p>
          </div>
          <div class="c-top__award">
            <p>ученики достигаторы</p>
          </div>
        </div>

        <div v-if="categories.length > 0" class="c-top__item">
          <div class="c-top__winners">
            <p v-for="c in categories" :key="c.id" class="my-2">{{ c.name }}</p>
          </div>
          <div class="c-top__award">
            <p>популярные категории</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCognationsStore } from "@/store/cognations";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const { cognations } = storeToRefs(useCognationsStore());

const children = computed(() => {
  if (!cognations.value.length) {
    return [];
  }

  return cognations.value.map((v) => v.child);
});

const categories = computed(() => {
  if (!cognations.value.length) {
    return [];
  }

  const uniqCat = {};
  let highScore = 0;

  cognations.value.forEach((v) => {
    if (uniqCat[v.cognationCategory.id]) {
      uniqCat[v.cognationCategory.id].score = +1;
    } else {
      uniqCat[v.cognationCategory.id] = {
        score: 1,
        name: v.cognationCategory.name,
      };
      highScore = 1;
    }
  });

  for (const i in uniqCat) {
    if (uniqCat[i].score > highScore) {
      highScore = uniqCat[i].score;
    }
  }

  const winners = [];

  for (const i in uniqCat) {
    if (uniqCat[i].score === highScore) {
      winners.push(uniqCat[i]);
    }
  }

  return winners;
});

const bestChildren = computed(() => {
  if (!children.value.length) {
    return [];
  }

  const childrenIdsCounter = {};
  let topRating = 0;
  let topIds = [];
  const childrenWithTopRating = [];

  const ids = children.value.map((v) => v.id);

  ids.forEach((id) => {
    if (id in childrenIdsCounter) {
      childrenIdsCounter[id] = childrenIdsCounter[id] + 1;
    } else {
      childrenIdsCounter[id] = 1;
    }
  });

  for (let i in childrenIdsCounter) {
    if (childrenIdsCounter[i] > topRating) {
      topRating = childrenIdsCounter[i];
      topIds = [];
    }

    if (childrenIdsCounter[i] === topRating) {
      topIds.push(i);
    }
  }

  topIds.forEach((v) => {
    const existed = children.value.find((c) => Number(c.id) === Number(v));

    existed && childrenWithTopRating.push(existed);
  });

  return childrenWithTopRating;
});
</script>

<style lang="scss">
.c-top {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 300px));
    grid-gap: 16px;

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }

  &__winners {
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 4px;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $main-2;
    border: 3px solid #ffda00;
    filter: drop-shadow(0 0 0.15rem $text);

    p {
      color: #fff;
    }
  }

  &__item {
    position: relative;
    margin: 16px;
    margin-bottom: 190px;

    &:nth-child(2) {
      .c-top__winners {
        background-color: $main;
      }
    }
  }

  &__award {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50px);
    width: 220px;
    height: 220px;
    background: url("../../assets/award.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: block;
    filter: drop-shadow(0 0 0.15rem $text);

    p {
      text-transform: uppercase;
      text-align: center;
      top: 25%;
      position: absolute;
      z-index: 1;
      font-size: 20px;
      font-weight: 600;
      background: -webkit-linear-gradient(#fe04a4, #c62df6);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
</style>
