<template>
  <div v-if="person" class="person-card form">
    <h3 class="mb-4">
      Личная карточка
      <span v-if="isChild">ученика</span>
      <span v-else>представителя</span>
    </h3>

    <div class="person-card__inputs">
      <div>
        <div
          :class="{
            error: v$.firstname.$errors.length,
            success:
              !v$.firstname.$errors.length &&
              v$.firstname.$model &&
              v$.firstname.$dirty,
          }"
        >
          <div class="person-card__input">
            <input
              placeholder="Имя"
              type="text"
              v-model="v$.firstname.$model"
              @focus="v$.firstname.$touch"
            />
          </div>

          <div
            :class="{
              'showing-tip': v$.firstname.$dirty && v$.firstname.$model.length,
            }"
            class="form__tip-wrapper"
          >
            <div class="form__tip form__tip--counter showing">
              <span>Любые символы </span>
              <span> {{ v$.firstname.$model.length }} / 100 </span>
            </div>
          </div>

          <div
            class="input-errors"
            v-for="error of v$.firstname.$errors"
            :key="error.$uid"
          >
            <div class="error-msg form__tip form__tip--error showing">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div
          :class="{
            error: v$.lastname.$errors.length,
            success:
              !v$.lastname.$errors.length &&
              v$.lastname.$model &&
              v$.lastname.$dirty,
          }"
        >
          <div class="person-card__input">
            <input
              placeholder="Фамилия"
              type="text"
              v-model="v$.lastname.$model"
              @focus="v$.lastname.$touch"
            />
          </div>

          <div
            :class="{
              'showing-tip': v$.lastname.$dirty && v$.lastname.$model.length,
            }"
            class="form__tip-wrapper"
          >
            <div class="form__tip form__tip--counter showing">
              <span>Любые символы </span>
              <span> {{ v$.lastname.$model.length }} / 100 </span>
            </div>
          </div>

          <div
            class="input-errors"
            v-for="error of v$.lastname.$errors"
            :key="error.$uid"
          >
            <div class="error-msg form__tip form__tip--error showing">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div
          :class="{
            error: v$.secname.$errors.length,
            success:
              !v$.secname.$errors.length &&
              v$.secname.$model &&
              v$.secname.$dirty,
          }"
        >
          <div class="person-card__input">
            <input
              placeholder="Отчество"
              type="text"
              v-model="v$.secname.$model"
              @focus="v$.secname.$touch"
            />
          </div>

          <div
            :class="{
              'showing-tip': v$.secname.$dirty && v$.secname.$model.length,
            }"
            class="form__tip-wrapper"
          >
            <div class="form__tip form__tip--counter showing">
              <span>Любые символы </span>
              <span> {{ v$.secname.$model.length }} / 100 </span>
            </div>
          </div>

          <div
            class="input-errors"
            v-for="error of v$.secname.$errors"
            :key="error.$uid"
          >
            <div class="error-msg form__tip form__tip--error showing">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div
          :class="{
            error: v$.username.$errors.length,
            success:
              !v$.username.$errors.length &&
              v$.username.$model &&
              v$.username.$dirty,
          }"
        >
          <div class="person-card__input">
            <input
              placeholder="Логин для входа"
              type="text"
              v-model="v$.username.$model"
              @focus="v$.username.$touch"
            />
          </div>

          <div
            :class="{
              'showing-tip': v$.username.$dirty && v$.username.$model.length,
            }"
            class="form__tip-wrapper"
          >
            <div class="form__tip form__tip--counter showing">
              <span>Любые символы </span>
              <span> {{ v$.username.$model.length }} / 100 </span>
            </div>
          </div>

          <div
            class="input-errors"
            v-for="error of v$.username.$errors"
            :key="error.$uid"
          >
            <div class="error-msg form__tip form__tip--error showing">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="person-card__input">
          <input placeholder="Email" type="email" v-model="personEmail" />
        </div>

        <div class="person-card__input">
          <input
            placeholder="Телефон"
            type="text"
            v-model="personMobilePhone"
          />
        </div>

        <DatePicker
          v-model="birthday"
          placeholder="День рождения"
          autoApply
          locale="ru"
          class="w-100"
        />
      </div>

      <AvatarWithCropper :avatar="person.personAvatar" :id="person.id" />
    </div>

    <div v-if="lastVisitDate" class="person-card__info">
      <span>Дата последнего входа:</span>
      <span>{{ getDateFormatWithTime(lastVisitDate) }}</span>
    </div>

    <div v-if="personGroups.length" class="person-card__info">
      <span>Группы:</span>
      <span v-for="g in personGroups" :key="g.id">{{ g.personGroupName }}</span>
    </div>

    <div
      v-if="person.personChilds && person.personChilds.length"
      class="person-card__info"
    >
      <span>Дети:</span>
      <span v-for="c in person.personChilds" :key="c.id"
        >{{ c.firstname }} {{ c.lastname }}</span
      >
    </div>

    <div class="d-flex">
      <AppButton @click="getLoginLink(id)" class="bordered-item mr-4">
        Получить ссылку для входа</AppButton
      >

      <AppButton
        @click="generatePassword"
        class="bordered-item bordered-item--primary"
      >
        Сгенерировать пароль</AppButton
      >
    </div>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

import { capitalizeFirstLetter, debounce } from "@/util/common";
import { useDatesChanger } from "@/composables/datesChanger";
import { computed, ref, watch, defineProps } from "vue";

import AppButton from "@/elements/AppButton.vue";
import AvatarWithCropper from "@/components/AvatarWithCropper.vue";

import { usePopupStore } from "@/store/popup";
import { useUserData } from "@/composables/userData";
const { getLoginLink } = useUserData();

const rules = {
  lastname: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  firstname: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  secname: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  username: {
    required: helpers.withMessage("Обязательное поле", required),
  },
};

const props = defineProps({
  person: {
    type: Object,
    required: true,
  },
});

const { showError, showInfoPopup, showToastPopup } = usePopupStore();
const { getDateFormatWithTime } = useDatesChanger();

const id = ref(props.person ? props.person.id : "");
const lastname = ref(props.person ? props.person.lastname : "");
const firstname = ref(props.person ? props.person.firstname : "");
const secname = ref(props.person ? props.person.secname : "");
const username = ref(props.person ? props.person.username : "");
const personEmail = ref(props.person ? props.person.personEmail : "");
const personMobilePhone = ref(
  props.person ? props.person.personMobilePhone : ""
);
const birthday = ref(props.person ? props.person.birthday : null);
const lastVisitDate = ref(props.person ? props.person.lastVisitDate : null);
const personGroups = ref(props.person ? props.person.personGroups : []);

const password = ref("");

const v$ = useVuelidate(rules, {
  lastname: lastname,
  firstname: firstname,
  secname: secname,
  username: username,
});

const isChild = computed(() => props.person.roles.indexOf("ROLE_CHILD") >= 0);

async function generatePassword() {
  const password = generateNewPassword();
  await navigator.clipboard.writeText(password);
  showInfoPopup(
    "Пароль сгенерирован: " + password + " (скопирован в буфер обмена!)"
  );
}

function generateNewPassword() {
  let text = "";
  let possible = "ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz";
  let possibleSimbols = "!@#$%&*";
  let possibleDigits = "123456789";

  for (var i = 0; i < 8; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  text += possibleSimbols.charAt(
    Math.floor(Math.random() * possibleSimbols.length)
  );
  text += possibleDigits.charAt(
    Math.floor(Math.random() * possibleDigits.length)
  );

  return text;
}

const debounceUpdate = debounce(updateUser, 1000);

watch(firstname, debounceUpdate);
watch(lastname, debounceUpdate);
watch(secname, debounceUpdate);
watch(username, debounceUpdate);

watch(password, debounceUpdate);
watch(personEmail, debounceUpdate);
watch(personMobilePhone, debounceUpdate);
watch(birthday, debounceUpdate);

async function updateUser() {
  if (
    v$.value.firstname.$errors.length ||
    v$.value.lastname.$errors.length ||
    v$.value.secname.$errors.length ||
    v$.value.username.$errors.length
  ) {
    return;
  } else {
    try {
      await saveUser();
      showToastPopup("Данные пользователя изменены");
    } catch (e) {
      showError(e);
    }
  }
}

async function saveUser() {
  const user = {
    password: password.value,
    lastname: capitalizeFirstLetter(lastname.value),
    secname: capitalizeFirstLetter(secname.value),
    firstname: capitalizeFirstLetter(firstname.value),
    username: username.value,
    personEmail: personEmail.value,
    personMobilePhone: personMobilePhone.value,
    birthday: birthday.value,
  };

  return saveUserHandler(user);
}

async function saveUserHandler(user) {
  return await customAxios.put(`/user/for_tutor/${id.value}`, user);
}
</script>

<style lang="scss">
.person-card {
  h3 {
    color: $main-1;
    text-align: center;
  }

  &__info {
    margin: 16px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    span {
      background-color: $main-1;
      margin: 4px;
      color: #fff;
      padding: 2px 4px;
      border-radius: 4px;
    }

    span:first-child {
      color: $light-text;
      background-color: transparent;
    }
  }

  &__input {
    margin: 16px 0;

    p {
      color: $light-text;
    }

    input {
      width: 100%;
      border: 1px solid $light-text;
      padding: 8px;
      border-radius: 8px;
    }
  }

  &__inputs {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 16px;
  }
}
</style>
