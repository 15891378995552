<template>
  <div class="page-wrapper">
    <div class="g-col g-col--2-1fr">
      <FilterBySubject />
      <FilterByPeriods v-model="period" />
    </div>

    <v-label
      v-if="!$route.query.filterBySubjectId || !period.length"
      class="text-deep-purple-darken-1 font-weight-bold mt-2 mb-5"
      >Выберите сначала Предмет и четверть</v-label
    >

    <StatusWrapper
      :isDataExisted="isDataExisted"
      :loadingStatus="loadingStatus"
    >
      <div v-if="Number($route.query.filterBySubjectId) === 13">
        <ul v-if="list.foreignChecklistList.length > 0">
          <li
            v-for="l in list.foreignChecklistList"
            :key="l.id"
            class="gradient-list-item"
          >
            <router-link
              class="gradient-list-item__content"
              :to="{
                name: 'foreign-rating-sheet-edit',
                query: {
                  journalId: l.teacherLogId,
                  listId: l.id,
                  dayId: l.teacherLogDayId,
                },
              }"
            >
              <h4 v-if="l.listDescription">{{ l.listDescription }}</h4>
              <h4 v-else>Нет названия</h4>
            </router-link>
          </li>
        </ul>
      </div>

      <div v-else>
        <ul v-if="list.teacherLogSkillListList.length > 0">
          <li
            v-for="l in list.teacherLogSkillListList"
            :key="l.id"
            class="gradient-list-item"
          >
            <router-link
              class="gradient-list-item__content"
              :to="{
                name: 'rating-sheet-edit',
                query: {
                  listId: l.id,
                  journalId: l.teacherLogId,
                  dayId: l.teacherLogDayId,
                },
              }"
            >
              <h4 v-if="l.skillListDescription">
                {{ l.skillListDescription }}
              </h4>
              <h4 v-else>Нет названия</h4>
            </router-link>
          </li>
        </ul>
      </div>
    </StatusWrapper>
  
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { computed, ref, watch } from "vue";

import { useRatingSheetsStore } from "@/store/rating-sheets";
import { storeToRefs } from "pinia";
import { usePopupStore } from "@/store/popup";

import { useRoute } from "vue-router";

import StatusWrapper from "@/components/StatusWrapper.vue";
import FilterBySubject from "@/components/filtersElements/FilterBySubject.vue";
import FilterByPeriods from "@/components/filtersElements/FilterByPeriods.vue";

const { showError } = usePopupStore();
const { setList } = useRatingSheetsStore();
const { list } = storeToRefs(useRatingSheetsStore());

const loadingStatus = ref("nothing");
const route = useRoute();
const period = ref([]);

const isDataExisted = computed(() => {
  if (!list.value) {
    return false;
  }

  return list.value.foreignChecklistList.length > 0 ||
    list.value.teacherLogSkillListList.length > 0;
});

watch(period, async () => {
  if (route.query.filterBySubjectId) {
    updateList();
  }
});

watch(
  () => route.query,
  async (r) => {
    if (r && period.value.length) {
      updateList();
    }
  }
);

async function updateList() {
  try {
    loadingStatus.value = "loading";
    const r = await getRatingSheetsHandler();
    setList(r.data);
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}

async function getRatingSheetsHandler() {
  return customAxios.post(`/teacher/log/checklists/by_subject_and_range`, {
    beginDate: period.value[0],
    endDate: period.value[1],
    subjectId: route.query.filterBySubjectId,
  });
}
</script>
