<template>
  <div>
    <v-card-text class="align-self-stretch h-75 ma-0 pa-0">
      <v-card-title class="card_ligth_header">
        <h3>Пользователи</h3>
      </v-card-title>
      <v-card-title style="min-height: 5vh" class="card_ligth_header">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="searchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded
                      @click:clear="clearSearch"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                        v-model="personGroupsForFilter"
                        :items="userGroups"
                        :custom-filter="userGroupFilter"
                        filled
                        @click:clear="personGroupsForFilter=''"
                        variant="outlined"
                        clearable
                        label="Группа пользователей"
                        item-title="personGroupName"
                        item-text="personGroupName"
                        item-value="id"

                    >

                      <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            :title="item.raw.personGroupName"
                            :subtitle="item.raw.personGroupDescription"
                        ></v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="dialog = true"
                      variant="outlined"
                      color="primary"
                      class="mb-4"
                      rounded
                    >
                      Добавить
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-0 pa-0">
        <div class="d-none d-sm-block">
          <Vue3EasyDataTable
                      :headers="userHeaders"
                      :items="sortedUsersByGroups"
                      :search-value="searchBody"
                      multi-sort
                      alternating
                      show-index
                  >
                    <template #item-personEmail="{ personEmail }">
                      <p  style="max-width: 100px;">{{personEmail}}</p>
                    </template>
                    <template #item-username="{ username }">
                      <p  style="max-width: 100px;">{{username}}</p>
                    </template>
                    <template #item-registrationDate="{registrationDate}">
                      <p  style="max-width: 100px;">{{ formatDate(registrationDate)}}</p>
                    </template>
                    <template #item-roles="{ roles }">
                      <v-chip-group active-class="primary--text" column>
                        <v-chip size="x-small" v-for="rol in roles" :key="rol">{{
                            rol
                          }}</v-chip>
                      </v-chip-group>
                    </template>
                    <template #item-personGroups="{ personGroups }">
                      <v-chip-group active-class="primary--text" column style="max-width: 100px;">
                        <v-chip
                            size="x-small"
                            v-for="rol in personGroups"
                            :key="rol"
                        >{{ rol.personGroupName }}</v-chip
                        >
                      </v-chip-group>
                    </template>
                    <template #item-fullName="{ firstname, lastname, secname }">
                      {{ firstname }} {{ secname }} {{ lastname }}
                    </template>
                    <template #item-actions="item">
                      <v-btn
                          @click="editUser(item)"
                          color="primary"
                          class="ma-2"
                          size="small"
                          variant="outlined"
                          rounded
                      >
                        Изменить
                      </v-btn>
                      <v-btn
                          @click="deleteUser(item)"
                          class="ma-2"
                          size="small"
                          variant="outlined"
                          color="warning"
                          rounded
                      >
                        Удалить
                      </v-btn>
                    </template>
                  </Vue3EasyDataTable>
        </div>
        <div class="d-sm-none d-flex align-center flex-column">
                  <v-card
                    class="ma-4 mx-4 w-100"
                    v-for="item in paginatedData"
                    :key="item"
                  >
                    <v-card-title class="text-pink-lighten-2 bg-pink-lighten-5 ">
                      <h5>
                        Пользователь № {{ filteredUsers.indexOf(item) + 1 }}
                      </h5>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list-item lines="two">
                        <v-avatar
                            v-if="item.personAvatar"
                            size="x-large"
                            class="mt-4 mb-6"
                        >
                          <v-img :src="'https://school.eurekacenter.ru/avatar/' + item.personAvatar"
                              :alt="`${item.firstname} ${item.lastname}`"
                          ></v-img>
                        </v-avatar>
                        <v-list-item-title>ФИО: </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.lastname }}
                          {{ item.firstname }}
                          {{ item.secname }}
                        </v-list-item-subtitle
                        >
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>E-mail: </v-list-item-title>
                        <v-list-item-subtitle>
                          {{item.personEmail }}
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Логин: </v-list-item-title>
                        <v-list-item-subtitle>
                          {{item.username }}
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title
                          >Дата регистрации:
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="item.registrationDate"
                          >{{ item.registrationDate }}
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Роли: </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-chip-group active-class="primary--text" column>
                            <v-chip
                              size="small"
                              v-for="rol in item.roles"
                              :key="rol"
                              >{{ rol }}</v-chip
                            >
                          </v-chip-group>
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Группы: </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-chip-group active-class="primary--text" column>
                            <v-chip
                              size="small"
                              v-for="pg in item.personGroups"
                              :key="pg"
                              >{{ pg.personGroupName }}</v-chip
                            >
                          </v-chip-group>
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="bg-pink-lighten-5">
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="editUser(item)"
                        color="primary"
                        class="ma-2"
                        size="small"
                        variant="outlined"
                        rounded
                        >Редактировать
                      </v-btn>
                      <v-btn
                        @click="deleteUser(item)"
                        color="warning"
                        class="ma-2"
                        size="small"
                        variant="outlined"
                        rounded
                        >Удалить
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
      </v-card-text>
      <v-divider></v-divider>
      <div class="d-sm-none d-flex align-center flex-column">
        <v-card-actions style="min-height: 5vh" class="d-sm-none card_ligth_actions">
                  <v-container class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        :total-visible="3"
                        size="small"
                    ></v-pagination>
                  </v-container>
                </v-card-actions>
      </div>
    </v-card-text>
  </div>
  <v-dialog v-model="dialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h3 v-if="!!id">Редактирование пользователя</h3>
        <h3 v-else>Добавление пользователя</h3>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="userForm">
              <v-col cols="12">
                <v-text-field
                  label="Фамилия*"
                  v-model="lastname"
                  :rules="lastnameRules"
                  hint="Укажите фамилию"
                  :counter="25"
                  variant="outlined"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Имя*"
                  v-model="firstname"
                  :rules="firstnameRules"
                  :counter="25"
                  variant="outlined"
                  hint="Укажите имя"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Отчество*"
                  v-model="secname"
                  :rules="secnameRules"
                  variant="outlined"
                  :counter="25"
                  hint="Укажите отчество"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="personEmail"
                  variant="outlined"
                  label="Email*"
                  hint="Укажите e-mail"
                ></v-text-field>
                <v-alert
                  density="compact"
                  type="error"
                  variant="outlined"
                  v-show="existEmail"
                  >E-mail уже занят!
                </v-alert>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="username"
                  :rules="usernameRules"
                  variant="outlined"
                  label="Имя пользователя*"
                  hint="Укажите имя пользователя (используется для входа в систему)"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  label="Пароль*"
                  contenteditable="false"
                  type="text"
                  variant="outlined"
                  hint="Сгенерированный пароль"
                  required
                  persistent
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-btn
                  @click="generatePassword"
                  variant="outlined"
                  color="primary-dark"
                  class="mb-4"
                  rounded
                >
                  Сгенерировать пароль
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  v-model="roles"
                  :items="rolesItems"
                  outlined
                  dense
                  chips
                  small-chips
                  variant="outlined"
                  closable-chips
                  label="Роли"
                  multiple
                ></v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  v-model="personGroups"
                  :items="userGroups"
                  :custom-filter="userGroupNameFilter"
                  filled
                  chips
                  small-chips
                  variant="outlined"
                  closable-chips
                  label="Группы пользователей"
                  item-title="name"
                  item-text="name"
                  return-object
                  multiple
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                      v-bind="props"
                      :text="item.raw.personGroupName"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                      v-bind="props"
                      :title="item.raw.personGroupName"
                      :subtitle="item.raw.personGroupDescription"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn rounded variant="outlined" @click="closePersonForm">
          Отмена
        </v-btn>
        <v-btn rounded variant="outlined" @click="save"> Сохранить </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="showLoader" persistent
    ><v-alert
      ><span class="text-h5 text-primary"
        >Загрузка данных
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular></span
    ></v-alert>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {customAxios} from "@/service/customAxios";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: "AdminUserPage",
  components: {
    Vue3EasyDataTable
  },

  props: [],
  data: () => ({
    subgroupName:'',
    subgroupNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    subgroupDescription:'',

    subgroupDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 250) || "Значение до должно превышать 250 символов!",
    ],
    personGroupId:'',
    members: [],
    subgroups:[],
    subgroupSearchBody:'',

    subgroupDialog:false,

    studyYears:[],
    studyYearId:undefined,
    userHeaders: [
      { text: "ФИО", value: "fullName", sortable: true },
      { text: "E-mail", value: "personEmail"},
      { text: "Логин", value: "username", sortable: true},
      { text: "Телефон", value: "personMobilePhone", sortable: true},
      { text: "Зарегистрирован", value: "registrationDate", sortable: true},
      { text: "Роли", value: "roles"},
      { text: "Группы", value: "personGroups"},
      { text: "Действие", value: "actions"},
    ],

    showLoader: false,
    firstnameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    lastnameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    secnameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    usernameRules: [
      (v) =>
        (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],

    rolesItems: [
      "ROLE_USER",
      "ROLE_ADMIN",
      "ROLE_PARENT",
      "ROLE_CHILD",
      "ROLE_TEACHER",
      "ROLE_TUTOR",
      "ROLE_MASTER",
      "ROLE_I_STUDIO_MASTER"
    ],
    firstname: "",
    page: 1,
    noteCount: 10,
    lastname: "",
    secname: "",
    password: "",
    personEmail: "",
    personGroups: [],
    roles: [],
    //personGroupsSource:[],
    valid: true,
    dialog: false,
    searchBody: "",
    showInput: false,
    loading: false,
    users: [],
    emailForCheck: {},
    existEmail: false,
    username: "",
    isChilds:false,

    groupSearchBody: "",
    groupDialog: false,
    personGroupName: "",
    personGroupDescription: "",
    isLocked: false,
    existGroupError: false,
    personGroupNameForCheck: "",
    groupPage: 1,
    userGroups: [],
    tab: 1,
    personGroupNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 30) || "Значение до должно превышать 30 символов!",
    ],
    personGroupDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 255) || "Значение до должно превышать 255 символов!",
    ],
    userSortKey: "id",
    userLastSortKey: "id",
    userSortOrder: 1,

    userGroupSortKey: "id",
    userGroupLastSortKey: "id",
    userGroupSortOrder: 1,

    scheduleClasses: [],
    classes:[],
    childGroupClasses:[],
    userGroupsForSelect:[],
    groupClasses:{},
    showGroupClassForm: false,
    selectedGroup:null,
    active:false,

    id:null,
    personGroupsForFilter: "",

  }),
  methods: {

    scheduleClassFilter(item, queryText, itemText) {
      return (
          itemText.raw.scheduleClassName
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },

    formatDate(dateString){
      let formatter = new Intl.DateTimeFormat("ru", {
       // weekday: "long",
        year: "numeric",
        month: "numeric",
        day: "numeric",

      });

      return capitalizeFirstLetter(formatter.format(Date.parse(dateString)));

    },


    closePersonForm() {
      this.firstname = "";
      this.lastname = "";
      this.secname = "";
      this.personEmail = "";
      this.password = "";
      this.active = "";
      this.userEmail = "";
      this.roles = [];
      this.personGroups = [];
      this.id = null;
      this.dialog = false;
    },
    clearSearch() {
      this.searchBody = "";
    },
    generatePassword() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (var i = 0; i < 13; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      this.password = text;
    },
    save() {
      this.$refs.userForm.validate().then((responce) => {
        if (responce.valid) {
          if (this.personEmail.length == 0) {

            const ololo = this.personGroups.map(item =>item={"id":item.id} )

            let person = {
              firstname: capitalizeFirstLetter(this.firstname),
              lastname: capitalizeFirstLetter(this.lastname),
              secname: capitalizeFirstLetter(this.secname),
              username: this.username,
              personEmail: this.personEmail,
              password: this.password,
              active: this.active,
              userEmail: this.userEmail,
              roles: this.roles,
              personGroups: ololo,
            };
            if (this.id) {
              customAxios.put( "/user/" + this.id, person)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.users, response.data.id);
                    this.users.splice(index, 1, response.data);
                    this.dialog = false;
                    this.firstname = "";
                    this.lastname = "";
                    this.secname = "";
                    this.personEmail = "";
                    this.password = "";
                    this.active = "";
                    this.userEmail = "";
                    this.username = "";
                    this.roles = [];
                    this.personGroups = [];
                    this.id = null;
                  } else if (response.status === 207) {
                    alert(response.data.message);
                    console.log(response);
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              customAxios.post( "/user", person)
                .then((response) => {
                  if (response.status === 201) {
                    this.users.push(response.data);
                    this.dialog = false;
                    this.firstname = "";
                    this.lastname = "";
                    this.secname = "";
                    this.personEmail = "";
                    this.password = "";
                    this.active = "";
                    this.userEmail = "";
                    this.username = "";
                    this.roles = [];
                    this.personGroups = [];
                  } else if (response.status === 207) {
                    alert(response.data.message);
                    console.log(response);
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            const ololo = this.personGroups.map(item =>item={"id":item.id} )
            let person = {

              firstname: capitalizeFirstLetter(this.firstname),
              lastname: capitalizeFirstLetter(this.lastname),
              secname: capitalizeFirstLetter(this.secname),
              username: this.username,
              personEmail: this.personEmail,
              password: this.password,
              active: this.active,
              userEmail: this.userEmail,
              roles: this.roles,
              personGroups: ololo,
            };

            if (this.id) {
              customAxios.put( "/user/" + this.id, person)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.users, response.data.id);
                    this.users.splice(index, 1, response.data);
                    this.dialog = false;
                    this.firstname = "";
                    this.lastname = "";
                    this.secname = "";
                    this.personEmail = "";
                    this.password = "";
                    this.active = "";
                    this.userEmail = "";
                    this.username = "";
                    this.roles = [];
                    this.personGroups = [];
                    this.id = null;
                  } else if (response.status === 207) {
                    alert(response.data.message);
                    console.log(response);
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              customAxios.post( "/user", person)
                .then((response) => {
                  if (response.status === 201) {
                    this.users.push(response.data);
                    this.dialog = false;
                    this.firstname = "";
                    this.lastname = "";
                    this.secname = "";
                    this.personEmail = "";
                    this.password = "";
                    this.active = "";
                    this.userEmail = "";
                    this.username = "";
                    this.roles = [];
                    this.personGroups = [];
                  } else if (response.status === 207) {
                    alert(response.data.message);
                    console.log(response);
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
            }
          }
        }
      });
    },
    editUser: function (event) {
      this.id = event.id;
      this.firstname = event.firstname;
      this.lastname = event.lastname;
      this.secname = event.secname;
      this.personEmail = event.personEmail;
      this.active = event.active;
      this.userEmail = event.userEmail;
      this.username = event.username;
      this.roles = event.roles;
      this.personGroups = event.personGroups;
      this.dialog = true;
    },
    deleteUser: function (item) {

      customAxios.delete( "/user/" + item.id )
        .then((response) => {
          if (response.status === 202) {
            this.users.splice(this.users.indexOf(item), 1);
          } else {
            console.log(response);
          }
        })

    },
    userGroupFilter(itemText, queryText,item) {
      console.log("filter item: " + item);
      console.log("filter itemText: " + itemText);
      const textPersonGroupName = item.raw.personGroupName.toLowerCase();
      const textPersonGroupDescription = item.raw.personGroupDescription.toLowerCase();

      const searchText = queryText!==null ? queryText.toLowerCase() : "";

      return (
        textPersonGroupName.indexOf(searchText) > -1 ||
        textPersonGroupDescription.indexOf(searchText) > -1
      );
    },
    userGroupNameFilter(item, queryText) {
      console.log("filter item: " + item);
      const textPersonGroupName = item.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
          textPersonGroupName.indexOf(searchText) > -1
      );
    },
  },
  computed: {

    personGroupsSource() {
      let tempGroups = this.userGroups;
      return tempGroups.filter((item) => item.isLocked === false);
    },
    filteredUserGroups() {
      const ss = this.groupSearchBody.toLowerCase();

      let tempGroups = this.userGroups;

      return tempGroups.filter((item) =>
        _.some(item, (v) => {
          if (!(v instanceof Object)) {
            return _.toLower(v).indexOf(ss) > -1;
          }
        })
      );
    },
    sortedUserGroups() {
      //const k = this.sortKey;
      let tempGroups = this.filteredUserGroups;
      return tempGroups.sort(
        dynamicSort(this.userGroupSortKey, this.userGroupSortOrder)
      );
    },
    groupPageCount() {
      let l = this.filteredUserGroups.length,
        s = parseInt(this.noteCount);
      console.log("Page count: " + Math.ceil(l / s));

      return Math.ceil(l / s);
    },
    groupsPaginatedData() {
      const start = (this.currentGroupPage - 1) * parseInt(this.noteCount),
        end = start + parseInt(this.noteCount);

      return this.sortedUserGroups.slice(start, end);
    },
    currentGroupPage() {
      if (this.groupPageCount < this.groupPage) {
        return 1;
      } else {
        return this.groupPage;
      }
    },

    filteredUsers() {
      let tempUsers = this.users;

        const ss = this.searchBody.toLowerCase();
        return tempUsers.filter((item) =>
            _.some(item, (v) => {
              if (!(v instanceof Object)) {
                return _.toLower(v).indexOf(ss) > -1;
              }
            })
        );


    },
    sortedUsers() {
      let tempUsers = this.filteredUsers;
      return tempUsers.sort(dynamicSort(this.userSortKey, this.userSortOrder));
    },
    sortedUsersByGroups(){
      let source = this.sortedUsers;
      if(this.personGroupsForFilter!==""){
        let resultUsers = []
        source.forEach(item =>{

         item.personGroups.forEach(item2 => {
            if(item2.id ===this.personGroupsForFilter){
              resultUsers.push(item);
            }
          })
        })
        return resultUsers;
      } else {
        return source;
      }

    },
    pageCount() {
      let l = this.sortedUsersByGroups.length,
        s = parseInt(this.noteCount);
      console.log("Page count: " + Math.ceil(l / s));
      return Math.ceil(l / s);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * parseInt(this.noteCount),
        end = start + parseInt(this.noteCount);
      return this.sortedUsersByGroups.slice(start, end);
    },
    currentPage() {
      if (this.pageCount < this.page) {
        return 1;
      } else {
        return this.page;
      }
    },

    loggedIn() {
      return this.$store.state.authentication.status.loggedIn;
    },
    user() {
      return this.$store.state.authentication.user;
    },
    authentication() {
      return this.$store.state.authentication;
    },
  },
  created: function () {
    this.showLoader = true;

    function getUsers(customAxios) {
      return customAxios.get( "/user");
    }

    function getUserGroups(customAxios) {
      return customAxios.get( "/user_group");
    }


    Promise.all([
      getUsers(customAxios),
      getUserGroups(customAxios),


    ])
      .then((results) => {
        if (results[0].status === 200) {
          this.users = results[0].data;
        }
        if (results[1].status === 200) {
          this.userGroups = results[1].data;
        }

        this.showLoader = false;
      })
      .catch(() => {
        this.showLoader = false;
        alert("Ошибка загрузки данных, обратитесь к администратору системы!");

      });
  },
};

function dynamicSort(property, sortOrder) {
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
</script>
