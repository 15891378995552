<template>
  <div>
    <div class="page-wrapper mb-4">
      <h3 class="mb-6">Классы</h3>
      <CompactSelect
        v-if="childrenGroupsSelector.length"
        v-model="selectedChildrenGroupId"
        :options="childrenGroupsSelector"
      />
    </div>
    <StatusWrapper
      :isDataExisted="existedChildrenFromCurrentGroup.length > 0"
      :loadingStatus="loadingStatus"
    >
      <Vue3EasyDataTable
        :headers="groupHeaders"
        :items="existedChildrenFromCurrentGroup"
        multi-sort
        alternating
        show-index
        :rows-per-page-message="'Записей на страницу'"
        :rows-per-page="25"
        :rows-items="[10, 15, 20, 25]"
        :rows-of-page-separator-message="'из'"
        buttons-pagination
        :empty-message="'Данные не добавлены'"
      >
        <template #item-fio="{ lastname, firstname, secname }">
          {{ lastname }} {{ firstname }} {{ secname }}
        </template>

        <template #item-actions="item">
          <v-btn
            @click="openMetricboard(item)"
            color="primary"
            class="ma-2"
            size="small"
            variant="outlined"
            rounded
          >
            Метрики
          </v-btn>
          <v-btn
            @click="openDynamicBoard(item)"
            color="primary"
            class="ma-2"
            size="small"
            variant="outlined"
            rounded
          >
            Динамика
          </v-btn>
          <v-btn
            @click="openChildCard(item)"
            color="primary"
            class="ma-2"
            size="small"
            variant="outlined"
            rounded
          >
            Карточка
          </v-btn>
          <v-btn
            @click="openChildSwot(item)"
            class="ma-2"
            size="small"
            variant="outlined"
            color="warning"
            rounded
          >
            Профиль
          </v-btn>
          <v-btn
            @click="getLoginLink(item)"
            class="ma-2"
            size="small"
            variant="outlined"
            color="warning"
            rounded
          >
            Ссылка для входа
          </v-btn>
        </template>
      </Vue3EasyDataTable>

      <!-- <v-row class="mt-3">
          <v-col cols="2">
            <AppButton class="bordered-item">Отчёты</AppButton>
          </v-col>
        </v-row> -->
    </StatusWrapper>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useTutorStore } from "@/store/tutor";
import { useRouter } from "vue-router";

import StatusWrapper from "../StatusWrapper.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";

import { useChildrenGroupsData } from "@/composables/childrenGroups";
import { customAxios } from "@/service/customAxios";
import CompactSelect from "@/elements/CompactSelect.vue";

export default {
  components: {
    CompactSelect,
    Vue3EasyDataTable,
    StatusWrapper,
  },

  setup() {
    const loadingStatus = ref("nothing");
    const router = useRouter();
    const tutorStore = useTutorStore();
    const { clearCurrentChild } = tutorStore;
    const groupHeaders = ref([
      { text: "ФИО", value: "fio" },
      { text: "Действие", value: "actions", width: 550 },
    ]);

    const childrenGroupId = ref("");

    const {
      selectedChildrenGroupId,
      childrenGroupsSelector,
      getChildrenGroups,
      existedChildrenFromCurrentGroup,
    } = useChildrenGroupsData();

    const showChildCard = ref(false);

    onMounted(async () => {
      loadingStatus.value = "loading";
      await getChildrenGroups();
      await parseLocalStore();

      loadingStatus.value = "loaded";
    });

    async function parseLocalStore() {
      if (JSON.parse(localStorage.getItem("selectedChildrenGroupId"))) {
        console.log(
          "parsed id: " +
            JSON.parse(localStorage.getItem("selectedChildrenGroupId"))
        );
        selectedChildrenGroupId.value = JSON.parse(
          localStorage.getItem("selectedChildrenGroupId")
        );
      }
    }

    function openChildCard(child) {
      clearCurrentChild();
      router.push({ path: `child_card/${child.id}` });
    }

    function openChildSwot(child) {
      clearCurrentChild();
      router.push({ path: `child/${child.id}` });
    }

    function openMetricboard(child) {
      clearCurrentChild();
      router.push({ path: `metrics/${child.id}` });
    }
    function openDynamicBoard(child) {
      clearCurrentChild();
      router.push({ path: `metrics_by_subject/${child.id}` });
    }

    function closeChildCard() {
      showChildCard.value = false;
      clearCurrentChild();
    }
    async function getLoginLink(item) {
      try {
        const r = await customAxios.get("/auth/ololo/get_uuid/" + item.id);
        if (r.status === 200) {
          let loginUrl = "https://school.eurekacenter.ru/login/" + r.data.uuid;
          //let loginUrl = "http://localhost:8080/login/"+r.data.uuid;
          await navigator.clipboard.writeText(loginUrl);
          alert(
            "Ссылка для входа: " + loginUrl + " (скопировна в буфер обмена!)"
          );
        }
      } catch (e) {
        alert("Ошибка при выполнении запроса: " + e);
        console.log(e);
      }
    }

    watch(
      selectedChildrenGroupId,
      (to) => {
        if (selectedChildrenGroupId) {
          localStorage.setItem("selectedChildrenGroupId", JSON.stringify(to));
          console.log(
            "selectedChildrenGroupId: " + selectedChildrenGroupId.value
          );
        }
      },
      { deep: true }
    );

    return {
      openMetricboard,
      getLoginLink,
      openDynamicBoard,
      closeChildCard,
      showChildCard,
      openChildCard,
      openChildSwot,
      loadingStatus,
      existedChildrenFromCurrentGroup,
      childrenGroupsSelector,
      selectedChildrenGroupId,
      groupHeaders,
      childrenGroupId,
    };
  },
};
</script>
