<template>
  <div>
    <PageTitle title="Все журналы" />
    <div class="page-wrapper">
      <div class="g-col g-col--2-1fr">
        <FilterByGroupId />
        <FilterBySubject />
      </div>
      <!-- :key="version" -->
      <StatusWrapper
        :loadingStatus="loadingStatus"
        :isDataExisted="journals.length > 0"
      >
        <ComponentWithPagination
          :list="filteredJournals"
          @changeList="paginatedData = $event"
        >
          <ul v-if="paginatedData" class="mt-4">
            <li
              v-for="j in paginatedData"
              :key="j.id"
              class="gradient-list-item"
            >
              <router-link
                class="gradient-list-item__link-with-period gradient-list-item__content"
                :to="{
                  name: 'subject-journal-main',
                  query: { journalId: j.id },
                }"
              >
                <div>
                  <v-label>{{ j.scheduleClass.scheduleClassName }}</v-label>
                  <h4>
                    {{ j.teacherLogName }} ({{ j.personGroup.personGroupName }})
                  </h4>
                </div>

                <div class="gradient-list-item__period">
                  <CIcon icon="calendar-icon" />
                  <span
                    >{{ getDateFormatWithoutTime(j.beginDateTime) }} -
                    {{ getDateFormatWithoutTime(j.endDateTime) }}</span
                  >
                </div>
              </router-link>
            </li>
          </ul>
        </ComponentWithPagination>
      </StatusWrapper>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import { useRoute } from "vue-router";

import { usePopupStore } from "@/store/popup";
import { useJournalData } from "@/composables/journalData";

import StatusWrapper from "@/components/StatusWrapper.vue";
import CIcon from "@/elements/CIcon.vue";
import ComponentWithPagination from "@/components/ComponentWithPagination.vue";

import PageTitle from "@/elements/PageTitle.vue";
import FilterByGroupId from "@/components/filtersElements/FilterByGroupId.vue";
import FilterBySubject from "@/components/filtersElements/FilterBySubject.vue";
import { pushFilterToURL } from "@/service/router";

// const version = ref(1);

// function updateVersion() {
//   version.value = version.value + 1;
//   // console.log("version:" + version.value);
// }
const { getAllJournalsHandler } = useJournalData();
const { getDateFormatWithoutTime } = useDatesChanger();
const { showError } = usePopupStore();

const route = useRoute();

const loadingStatus = ref("loading");
const journals = ref([]);
const paginatedData = ref([]);

onMounted(async () => {
  parseLocalStore();
  getAllJournals();
});

async function getAllJournals() {
  loadingStatus.value = "loading";

  try {
    const r = await getAllJournalsHandler();
    journals.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}

const filteredJournals = computed(() => {
  if (!journals.value.length) {
    return [];
  }

  return journals.value.filter((j) => {
    if (route.query.filterByGroupId && !filterByGroup(j)) {
      return false;
    }

    if (route.query.filterBySubjectId && !filterBySubject(j)) {
      return false;
    }

    return true;
  });
});

function filterByGroup(j) {
  return j.personGroupId === Number(route.query.filterByGroupId);
}

function filterBySubject(j) {
  return j.scheduleClassId === Number(route.query.filterBySubjectId);
}

watch(() => route.query.filterByGroupId, setInputsToLocalStore);
watch(() => route.query.filterBySubjectId, setInputsToLocalStore);

function setInputsToLocalStore() {
  if (route.query.filterBySubjectId) {
    localStorage.setItem(
      "selectedJournalSchoolSubjectId",
      JSON.stringify(route.query.filterBySubjectId)
    );
  } else {
    localStorage.removeItem("selectedJournalSchoolSubjectId");
  }

  if (route.query.filterByGroupId) {
    localStorage.setItem(
      "selectedJournalChildrenGroupId",
      JSON.stringify(route.query.filterByGroupId)
    );
  } else {
    localStorage.removeItem("selectedJournalChildrenGroupId");
  }
}

function parseLocalStore() {
  const localGroupId = localStorage.getItem("selectedJournalChildrenGroupId");
  const localSubjectId = localStorage.getItem("selectedJournalSchoolSubjectId");

  if (localGroupId) {
    pushFilterToURL({ name: "filterByGroupId", value: localGroupId });
  }
  if (localSubjectId) {
    pushFilterToURL({ name: "filterBySubjectId", value: localSubjectId });
  }
}
</script>
