<template>
  <div>
    <PageTitle title="Отчёт: Я-студия (по ребенку)" />

    <FiltersForChildsReport>
      <FilterByYear />
    </FiltersForChildsReport>

    <TipForChildFilters>
      <div class="mt-3 d-flex align-center flex-wrap">
        Значок
        <div class="bordered-item mx-2">
          <CIcon icon="file-icon" />
        </div>

        обозначает, что мастер оставил комментарий. Нажмите на него, чтобы
        просмотреть.
      </div>

      <div class="mt-3 d-flex align-center flex-wrap">
        Значок <CIcon icon="minus-icon" class="mx-2" /> обозначает, что у
        ребёнка нет данных за этот период.
      </div>
    </TipForChildFilters>

    <StatusWrapper
      :loadingStatus="loading"
      :isDataExisted="report !== null && report.reportDataList.length > 0"
    >
      <SHeader
        @download="
          downloadExcel(`отчёт_я_студия_${title}`, 'studio-report-by-child')
        "
        class="my-3"
      />

      <div class="page-wrapper">
        <div class="table-wrapper">
          <table id="studio-report-by-child" class="table-custom">
            <thead>
              <tr>
                <th></th>
                <th :colspan="month.length">
                  {{ report.child.lastname }} {{ report.child.firstname }}
                </th>
              </tr>
              <tr class="table-custom__headers">
                <th></th>
                <th v-for="(m, i) in month" :key="i">
                  {{ monthDict[m] }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(m, i) in reportByMonth" :key="i">
                <td>{{ m.eventName }}</td>

                <td v-for="(c, i) in m.childDataSet" :key="i">
                  <ResultCell
                    v-if="c.event"
                    :result="c.event.isSuccessResult"
                    :comment="c.event.masterComment"
                  />

                  <div
                    v-else
                    class="text-grey d-flex align-center justify-center"
                  >
                    <CIcon icon="minus-icon" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { computed, onMounted, ref, watch } from "vue";

import { monthDict } from "@/util/common";
import { useUserData } from "@/composables/userData";
import { useRoute } from "vue-router";

import { usePopupStore } from "@/store/popup";
import { useAuthStore } from "@/store/auth";
import { pushFilterToURL } from "@/service/router";

import CIcon from "@/elements/CIcon.vue";
import SHeader from "./SHeader.vue";

import StatusWrapper from "@/components/StatusWrapper.vue";
import ResultCell from "./ResultCell.vue";
import FilterByYear from "@/components/filtersElements/FilterByYear.vue";

import PageTitle from "@/elements/PageTitle.vue";
import TipForChildFilters from "@/components/tips/TipForChildFilters.vue";
import FiltersForChildsReport from "../FiltersForChildsReport.vue";
import { storeToRefs } from "pinia";

const { downloadExcel } = useUserData();
const { showError } = usePopupStore();
const route = useRoute();
const { isChild, userId } = storeToRefs(useAuthStore());

const loading = ref("nothing");
const report = ref(null);

const month = [0, 1, 2, 3, 4, 8, 9, 10, 11];

const title = computed(() => {
  if (!report.value) {
    return "";
  }
  return `${report.value.child.lastname}_${report.value.child.firstname}`;
});

const reportByMonth = computed(() => {
  if (!report.value) {
    return;
  }

  return report.value.reportDataList.map((v) => {
    const eventsByMonth = month.map((m) => {
      return {
        month: m,
        event: null,
      };
    });

    v.childDataSet.forEach((c) => {
      const idx = eventsByMonth.findIndex((m) => m.month === c.monthIndex);
      eventsByMonth[idx].event = c;
    });

    return { ...v, childDataSet: eventsByMonth };
  });
});

onMounted(async () => {
  if (isChild.value) {
    pushFilterToURL({ name: "filterByChildId", value: userId.value });
  }
  await getReport();
});

watch(() => route.query.filterByChildId, getReport);
watch(() => route.query.filterByGroupId, getReport);
watch(() => route.query.filterByYear, getReport);

async function getReport() {
  if (!route.query.filterByChildId || !route.query.filterByYear) {
    report.value = null;
    return;
  }

  loading.value = "loading";
  try {
    const r = await getReportHandler();
    report.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getReportHandler() {
  return customAxios.post("/i_studio/for_report/by_year_and_child", {
    studyYearId: Number(route.query.filterByYear),
    childId: Number(route.query.filterByChildId),
    monthIndexList: month,
  });
}
</script>
