<template>
  <div class="page-header" :class="{ 'desktop-hidden': user }">
    <router-link to="/" class="gradient-logo-title">Эврика</router-link>
    <router-link v-if="!user" to="/login" class="page-header__login">
      Войти
    </router-link>
    <AppButton v-else @click="show">
      <CIcon icon="menu-icon" />
    </AppButton>
  </div>
</template>

<script setup>
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
import { useSideMenuStore } from "@/store/side-menu";

const { user } = storeToRefs(useAuthStore());
const { show } = useSideMenuStore();
</script>

<style lang="scss">
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: $shadow;
  padding: 12px 16px;
  position: sticky;
  z-index: 99;
  background-color: #fff;
  width: 100%;
  top: 0;
  left: 0;

  &__login {
    text-decoration: none;
    color: $main-2;

    &:hover {
      color: $main-1;
    }
  }

  @media (min-width: 1024px) {
    &.desktop-hidden {
      display: none;
    }
  }
}
</style>
