<template>
  <div>
    <PhotoTip />
    <CustomForm
      title="индивидуальной заметки"
      @create="create"
      @remove="remove"
      :saveIsDisable="saveIsDisabled"
    >
      <div v-if="note">
        <h3 class="text-deep-purple-darken-1 font-weight-bold mb-5">
          {{ note.child.lastname }} {{ note.child.firstname }}
          {{ note.child.secname }}
        </h3>

        <div
          v-if="lastUpdate"
          class="d-flex align-center justify-end text-grey mb-3"
        >
          <p>Последнее обновление: {{ getDateFormatWithTime(lastUpdate) }}</p>
        </div>

        <div class="text-grey mb-5">
          <p>Дата создания: {{ getDateFormatWithoutTime(createDateTime) }}</p>
          <p>Автор: {{ author }}</p>
        </div>
      </div>

      <div v-else>
        <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
          >Ребенок*</v-label
        >

        <div class="g-col g-col--2-1fr mb-5">
          <v-select
            v-if="childrenGroupsSelector.length"
            v-model="selectedChildrenGroupId"
            :items="childrenGroupsSelector"
            variant="outlined"
            density="compact"
            placeholder="Класс"
            hide-details
          ></v-select>
          <LoaderRing v-else />
          
          <v-autocomplete
            v-model="selectedChild"
            :items="sortedActualGroupList"
            variant="outlined"
            placeholder="Ребенок"
            item-value="value"
            density="compact"
            hide-details
          ></v-autocomplete>
        </div>
      </div>

      <v-switch
        v-model="showForParent"
        :label="`${showForParent ? 'П' : 'Не п'}оказывать`"
        color="success"
        hide-details
      >
      </v-switch>

      <NoteContent />

      <v-label class="text-deep-purple-darken-1 font-weight-bold mt-5"
        >Обязательные поля*</v-label
      >
    </CustomForm>

    <FilesEditor
      v-if="$route.path.includes('edit') && note"
      @update="emits('update')"
      :files="note.fileList"
      :links="{
        saveUrl: `/tutor/note/child/files/${$route.query.noteId}`,
        downloadUrl: '/tutor/note/child/file/',
        deleteUrl: '/tutor/note/child/files/delete/',
      }"
      class="mt-3"
    />
  </div>

</template>

<script setup>
import { defineEmits, computed, onMounted, ref, watch } from "vue";
import { useChildrenGroupsData } from "@/composables/childrenGroups";
import { usePopupStore } from "@/store/popup";

import LoaderRing from "@/elements/LoaderRing.vue";
import CustomForm from "../CustomForm.vue";

import { customAxios } from "@/service/customAxios";
import { useNotesStore } from "@/store/notes";
import { useRoute, useRouter } from "vue-router";

import { useDatesChanger } from "@/composables/datesChanger";
import { storeToRefs } from "pinia";

import NoteContent from "./components/NoteContent.vue";

import FilesEditor from "../FilesEditor.vue";
import PhotoTip from "@/elements/PhotoTip.vue";

import { debounce } from "@/util/common";
const emits = defineEmits(["update"]);

const { showError, showToastPopup, showConfirmPopup, closePopup } =
  usePopupStore();

const { getNoteById, changeNoteBody } = useNotesStore();

const { noteBody } = storeToRefs(useNotesStore());

const { getDateFormatWithoutTime, getDateFormatWithTime } = useDatesChanger();

const route = useRoute();
const router = useRouter();

const lastUpdate = ref("");
const note = computed(() => getNoteById(route.query.noteId));
const showForParent = ref(false);
const createDateTime = ref("");
const author = ref("");

const debounceUpdate = debounce(save, 2000);

const {
  sortedActualGroupList,
  getActiveChildGroups,
  selectedChildrenGroupId,
  childrenGroupsSelector,
  selectedChild,
} = useChildrenGroupsData();

const saveIsDisabled = computed(() => {
  if (noteBody.value.length && selectedChild.value) {
    return false;
  }
  return true;
});

onMounted(async () => {
  await getActiveChildGroups();

  if (note.value) {
    setCurrentData();
  }
});

function setCurrentData() {
  selectedChild.value = Number(note.value.child.id);
  changeNoteBody(note.value.noteBody);

  showForParent.value = note.value.showForParent;
  createDateTime.value = note.value.createDateTime;
  author.value = `${note.value.creator.firstname} ${note.value.creator.secname} ${note.value.creator.lastname}`;
}

const noteData = computed(() => ({
  childId: selectedChild.value,
  noteBody: noteBody.value,
  noteDateTime: new Date(),
  showForParent: showForParent.value,
  //   fileList: [...filesInput.value, ...fileList.value],
  fileList: [],
}));

async function create() {
  try {
    await createChildNoteHandler();
    showToastPopup("Заметка сохранена");
    router.push({ name: "tutor-child-notes" });
  } catch (e) {
    showError(e);
  }
}

async function createChildNoteHandler() {
  return customAxios.post(`/tutor/note/child`, noteData.value);
}

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();
      await removeHandler();
      router.push({ name: "tutor-child-notes" });
      showToastPopup("Заметка удалена");
    } else {
      closePopup();
    }
  } catch (error) {
    showError(error);
  }
}

async function customConfirm() {
  return showConfirmPopup("Удалить заметку?");
}

async function removeHandler() {
  return await customAxios.delete(`/tutor/note/child/${route.query.noteId}`);
}

watch(noteBody, (n) => {
  if (checkValidation() && n !== note.value.noteBody) {
    debounceUpdate();
  }
});

function checkValidation() {
  if (!note.value || !selectedChild.value || !noteBody.value.length) {
    return false;
  }

  return true;
}

async function save() {
  try {
    const r = await saveHandler();

    if (r.status === 202) {
      showToastPopup("Изменения сохранены");
      setLastUpdate();
    }
  } catch (error) {
    showError(error);
  }
}

function setLastUpdate() {
  const d = new Date();
  lastUpdate.value = d;
}

async function saveHandler() {
  return customAxios.put(
    `/tutor/note/child/${route.query.noteId}`,
    noteData.value
  );
}

// async function addNoteAboutChild() {
//   noteForm.value.validate().then(async (responce) => {
//     if (responce.valid) {
//       const data = {
//         childId: childId.value,
//         noteBody: noteBody.value,
//         noteDateTime: new Date(),
//         showForParent: showForParent.value,
//         fileList: [],
//       };
//       try {
//         if (currentNoteId.value) {
//           let r = await customAxios.put(
//             `/tutor/note/child/${currentNoteId.value}`,
//             data
//           );

//           if (r.status === 202) {
//             console.log("edited: " + r.data);
//             let index = getIndex(currentChildNotes.value, r.data.id);
//             currentChildNotes.value.splice(index, 1, r.data);
//           }
//         } else {
//           let r = await customAxios.post("/tutor/note/child", data);
//           if (r.status === 201) {
//             console.log("created: " + r.data);
//             currentChildNotes.value.push(r.data);
//           }
//         }
//         alert("Успешно сохранено");
//         // clear();
//       } catch (e) {
//         console.log(e);
//         alert("Что-то пошло не так");
//       }
//     }
//   });
// }
</script>
