<template>
  <td class="table-cell">
    <CompactSelect v-model="selectedLevel" :options="options" class="h-100" />
  </td>
</template>

<script setup>
import CompactSelect from "@/elements/CompactSelect.vue";
import { defineEmits, defineProps, watch, ref, onMounted } from "vue";

const options = [
  { title: "0", value: 0.0 },
  { title: "0.5", value: 0.5 },
  { title: "1", value: 1 },
];

const emits = defineEmits(["setGrade"]);
const props = defineProps({
  skill: {
    type: Object,
    required: false,
  },
});

const selectedLevel = ref(0);

onMounted(() => {
  if (props.skill) {
    selectedLevel.value = options.find(
      (v) => Number(v.value) === Number(props.skill.teacherGrade)
    ).value;
  }
});

watch(selectedLevel, (c) => {
  if (c !== Number(props.skill.teacherGrade)) {
    emits("setGrade", {
      id: props.skill.id,
      teacherLogSkillListSkillId: props.skill.teacherLogSkillListSkillId,
      skillListChildId: props.skill.skillListChildId,
      teacherGrade: c,
    });
  }
});
</script>

<style lang="scss">
.table-cell {
  width: 60px;
  min-width: 60px;

  select {
    border-color: $main-2;
  }
}
</style>
