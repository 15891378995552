<template>
  <div v-if="currentList">
    <SheetEditor />
    <SheetCompetence @updateLists="emits('updateLists')" class="mt-5" />
    <SheetTable @updateLists="emits('updateLists')" class="mt-2" />
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, defineEmits } from "vue";
import { useRoute } from "vue-router";

import { useSubjectsJournalStore } from "@/store/subjects-journal";

import SheetEditor from "./SheetEditor.vue";
import SheetTable from "./SheetTable.vue";
import SheetCompetence from "./SheetCompetence.vue";

const emits = defineEmits(["updateLists"]);

const route = useRoute();

const { clearList, listById } = useSubjectsJournalStore();

const currentList = computed(() => listById(route.query.listId));

onMounted(() => {
  emits("updateLists");
});

onUnmounted(clearList);
</script>
