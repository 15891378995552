<template>
  <div>
    <PageTitle :title="`Отчёт по ${subjectTitle} за диктанты`" />

    <FiltersForChildsReport>
      <FilterByYear />
    </FiltersForChildsReport>

    <TipForChildFilters />

    <StatusWrapper
      :isDataExisted="report !== null && report.headers.length > 0"
      :loadingStatus="loadingStatus"
    >
      <div class="page-wrapper my-3">
        <Line :data="config"></Line>
      </div>

      <div class="d-flex justify-end my-3">
        <AppButton
          @click="downloadExcel(getReportTitle(title), `rus-lit-report-table`)"
          class="accent-button"
          >Скачать отчёт</AppButton
        >
      </div>
      <div class="page-wrapper my-3">
        <div class="table-wrapper">
          <table class="table-custom" id="rus-lit-report-table">
            <thead>
              <tr class="table-custom__headers">
                <th>Работа</th>
                <th v-for="(h, i) in report.headers" :key="i">{{ h }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Дата работы</td>
                <td v-for="(d, i) in report.logDayDate" :key="i">
                  {{ getDateFormatWithTime(d) }}
                </td>
              </tr>

              <tr>
                <td>Уровень</td>
                <td v-for="(l, i) in report.dictationLevelList" :key="i">
                  {{ l }}
                </td>
              </tr>

              <tr>
                <td>Ошибки</td>
                <td v-for="(f, i) in report.failCompetenceCountList" :key="i">
                  {{ f }}
                </td>
              </tr>

              <tr>
                <td>Компетенции</td>
                <td v-for="(f, i) in report.failCompetenceList" :key="i">
                  <ol v-if="f.length" class="pa-3">
                    <li v-for="v in f" :key="v.id" class="my-3">
                      {{ v.competenceName }}
                    </li>
                  </ol>
                  <p v-else>Не добавлены</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/store/auth";
import { pushFilterToURL } from "@/service/router";
import { useRussianReportsData } from "@/composables/reports";

import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { useDatesChanger } from "@/composables/datesChanger";

import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { Line } from "vue-chartjs";

import FilterByYear from "@/components/filtersElements/FilterByYear.vue";
import TipForChildFilters from "@/components/tips/TipForChildFilters.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";

import PageTitle from "@/elements/PageTitle.vue";
import FiltersForChildsReport from "../FiltersForChildsReport.vue";

import { useUserData } from "@/composables/userData";
import { useReportsData } from "@/store/reports";

const { subjectTitle } = useRussianReportsData();
const { showError } = usePopupStore();
const { getDateFormatWithTime } = useDatesChanger();
const { getReportTitle } = useReportsData();

const { downloadExcel } = useUserData();
const { isChild, userId } = useAuthStore();
const route = useRoute();

const loadingStatus = ref("nothing");
const report = ref(null);
const title = computed(() => {
  return `Отчет диктанты ${
    route.params.id === 53 ? "по русскому" : "по литературе"
  }`;
});

const config = computed(() => {
  if (report.value === null) {
    return {};
  }

  return {
    type: "line",
    labels: report.value.headers,
    datasets: [
      {
        label: "Отчёт диктанты",
        data: report.value.dictationLevelList,
        backgroundColor: ["pink"],
      },
    ],
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };
});

watch(() => route.query.filterByGroupId, getReport);
watch(() => route.query.filterByChildId, getReport);
watch(() => route.query.filterByYear, getReport);
watch(() => route.params.subjectId, clear);

onMounted(async () => {
  if (isChild) {
    pushFilterToURL({ name: "filterByChildId", value: userId });
  }
  getReport();
});

function clear() {
  report.value = null;
}

async function getReport() {
  if (!route.query.filterByChildId || !route.query.filterByYear) {
    return;
  }

  loadingStatus.value = "loading";

  try {
    const r = await getReportHandler();
    report.value = r.data;
  } catch (error) {
    showError(error);
  } finally {
    loadingStatus.value = "loaded";
  }
}

async function getReportHandler() {
  return customAxios.post("/dictation/report/by_child_and_year", {
    scheduleClassId: Number(route.params.subjectId),
    studyYearId: Number(route.query.filterByYear),
    personId: Number(route.query.filterByChildId),
  });
}
</script>
