<template>
  <div class="page-title">
    <h2>{{ title }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.page-title {
  position: relative;
  padding: 50px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: $shadow;
  background-color: $main-2;

  h2 {
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 1;
    line-height: 30px;
  }

  &::after {
    content: "";
    position: absolute;
    background-image: url("../assets/school-pattern.png");
    background-size: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  @media (max-width: 480px) {
    padding: 30px 16px;
    h2 {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
</style>
