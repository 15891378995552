<template>
  <div>
    <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
      >Получатель</v-label
    >
    <v-select
      v-model="recipient"
      :items="recipientTypes"
      variant="outlined"
      density="compact"
      hide-details
      placeholder="Для кого"
    >
    </v-select>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { onMounted, ref, watch } from "vue";
import { removeFilterFromURL, pushFilterToURL } from "@/service/router";

const route = useRoute();

const recipientTypes = [
  { title: "Не указано", value: "all" },
  { title: "Индивидуальные", value: "individual" },
  { title: "Общеклассовые", value: "common" },
];

const recipient = ref(recipientTypes[0].value);

onMounted(setCurrentFilters);

function setCurrentFilters() {
  if (route.query.filterByRecepient) {
    recipient.value = route.query.filterByRecepient;
  } else {
    recipient.value = recipientTypes[0].value;
  }
}

watch(recipient, (id) =>
  id
    ? pushFilterToURL({ name: "filterByRecepient", value: id })
    : removeFilterFromURL("filterByRecepient")
);
</script>

<style lang="scss"></style>
