import { storeToRefs } from "pinia";
import { useFiltersStore } from "./filters";

export function useReportsData() {
  function getReportTitle(start) {
    const { group, year, child } = storeToRefs(useFiltersStore());
    if (child.value && group.value) {
      return `${start}_${child.value}_${group.value}`;
    }

    if (child.value && !group.value) {
      return `${start}_${child.value}_${year.value}`;
    }

    return `${start}_${year.value}`;
  }
  return {
    getReportTitle,
  };
}
