<template>
  <div class="g-col g-col--2-1fr">
    <FilterByYear />

    <v-select
      v-if="childrenGroupsSelector.length"
      :items="childrenGroupsSelector"
      v-model="selectedChildrenGroupId"
      variant="outlined"
      density="compact"
      hide-details
      placeholder="Класс"
      bg-color="white"
    ></v-select>
    <div v-else class="empty-select"></div>
  </div>
</template>

<script setup>
import { onMounted, watch } from "vue";
import { useChildrenGroupsData } from "@/composables/childrenGroups";
import { useRoute } from "vue-router";

import { pushFilterToURL, removeFilterFromURL } from "@/service/router";
import { useFiltersStore } from "@/store/filters";

import FilterByYear from "./FilterByYear.vue";

const { setFilterGroup } = useFiltersStore();
const { selectedChildrenGroupId, childrenGroupsSelector, getChildrenGroups } =
  useChildrenGroupsData();
const route = useRoute();

onMounted(async () => {
  await getChildrenGroups();
  setGroup();
});

function setGroup() {
  if (!childrenGroupsSelector.value.length) {
    return;
  } else if (!route.query.filterByGroupId) {
    selectedChildrenGroupId.value = childrenGroupsSelector.value[0].value;
    return;
  }

  selectedChildrenGroupId.value =
    childrenGroupsSelector.value.findIndex(
      (v) => v.value === Number(route.query.filterByGroupId)
    ) >= 0
      ? Number(route.query.filterByGroupId)
      : childrenGroupsSelector.value[0].value;

  setFilterGroup(childrenGroupsSelector.value, selectedChildrenGroupId.value);
}

watch(() => route.query.filterByGroupId, setGroup);

watch(selectedChildrenGroupId, (id) => {
  id
    ? pushFilterToURL({ name: "filterByGroupId", value: id })
    : removeFilterFromURL("filterByGroupId");
});
</script>
