<template>
  <v-select
    v-if="schoolYearsOptions.length"
    :items="schoolYearsOptions"
    v-model="selectedSchoolYear"
    variant="outlined"
    density="compact"
    hide-details
    placeholder="Учебный год"
    bg-color="white"
  ></v-select>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { onMounted, watch } from "vue";
import { useSchoolData } from "@/composables/schoolData";
import { useRoute } from "vue-router";

import { pushFilterToURL, removeFilterFromURL } from "@/service/router";
import { useFiltersStore } from "@/store/filters";

const { selectedSchoolYear, schoolYearsOptions, getSchoolYears } =
  useSchoolData();
const { setFilterYear } = useFiltersStore();

const route = useRoute();

onMounted(async () => {
  await getSchoolYears();
  setYear();
});

function setYear() {
  if (!schoolYearsOptions.value.length && !route.query.filterByYear) {
    selectedSchoolYear.value = null;
    return;
  }

  const isExisted =
    schoolYearsOptions.value.findIndex(
      (v) => v.value === Number(route.query.filterByYear)
    ) >= 0;

  if (isExisted) {
    selectedSchoolYear.value = Number(route.query.filterByYear);
  }

  setFilterYear(schoolYearsOptions.value, selectedSchoolYear.value);
}

watch(() => route.query.filterByYear, setYear);

watch(selectedSchoolYear, (id) => {
  id
    ? pushFilterToURL({ name: "filterByYear", value: id })
    : removeFilterFromURL("filterByYear");
});
</script>
