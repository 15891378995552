<template>
  <div class="d-flex flex-wrap">
    <div class="result-box ma-1">
      <span class="ml-2">{{ titles.positive }}</span>
    </div>
    <div class="result-box result-box__negative ma-1">
      <span class="ml-2">{{ titles.negative }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titles: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>
