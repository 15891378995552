<template>
  <div class="can-booking">
    <div v-if="alreadyFollow">
      <AppButton @click="$emit('unfollow')" class="accent-button"
        >Отписаться</AppButton
      >
    </div>
    <div v-else>
      <div v-if="cantBook" class="can-booking__warning">
        <p>Записи нет</p>
        <p>Не расстраивайся 😉 попробуй записаться на другую мастерскую</p>
      </div>

      <div v-else-if="workshop.sameDaysBooked" class="can-booking__warning">
        <p>Записаться нельзя</p>
        <p>Вы уже записаны на этот день на другую мастерскую (клубОК) !</p> 
      </div>

      <div v-else>
        <AppButton @click="$emit('follow')" class="accent-button">Записаться</AppButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import AppButton from "@/elements/AppButton.vue";

import { computed, defineProps, defineEmits } from "vue";
// import { isExpiredDate } from "@/util/common";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/auth";
const { userId } = storeToRefs(useAuthStore());
const props = defineProps({
  workshop: {
    type: Object,
    required: true,
  },
});

defineEmits(["unfollow", "follow"]);

const alreadyFollow = computed(
  () =>
    props.workshop.workshopChildrens.findIndex((v) => v.id === userId.value) !==
    -1
);

// const isExpired = computed(() => isExpiredDate(props.workshop.beginDateTime));

const hasFreePlaces = computed(() => {
  return (
    props.workshop.workshopChildrens.length < props.workshop.maxChildrenCount
  );
});
const cantBook = computed(() => {
  // return isExpired.value || !hasFreePlaces.value;
  return !hasFreePlaces.value;

});
</script>

<style lang="scss">
.can-booking {
  &__warning {
    padding: 16px;
    margin-bottom: 16px;
    background-color: rgba($error, 0.2);
    border-radius: 8px;
    color: $error;
  }
}
</style>
