<template>
  <v-card class="ma-2">
    <v-dialog v-model="showLoader"><LoaderRing></LoaderRing></v-dialog>
    <v-dialog v-model="showClassTypeDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Тип занятия</h5>
        </v-card-title>
        <v-card-text>
          <v-form ref="scheduleClassTypeForm">
            <v-container>
              <v-row>
                <v-col cols="12" class="ma-2">
                  <v-text-field
                    label="Название*"
                    v-model="scheduleClassTypeName"
                    :rules="scheduleClassTypeNameRules"
                    hint="Укажите название"
                    :counter="30"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="ma-2">
                  <v-textarea
                    label="Описание*"
                    v-model="scheduleClassTypeDescription"
                    :rules="scheduleClassTypeDescriptionRules"
                    :counter="255"
                    hint="Укажите описание"
                    required
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="ma-2">
                  <v-row class="px-6" fluid>
                    <v-switch
                      v-model="isActive"
                      :label="'Активно'"
                      color="success"
                    ></v-switch>
                    <v-chip v-if="isActive">Да</v-chip
                    ><v-chip v-else>Нет</v-chip>
                  </v-row>
                </v-col>

                <v-col cols="12" class="ma-2">
                  <v-row fluid class="px-6">
                    <v-label> Цвет фона </v-label>
                  </v-row>
                  <v-row fluid class="px-6">
                    <v-color-picker
                      show-swatches
                      v-model="backgroundColor"
                    ></v-color-picker>
                  </v-row>
                </v-col>

                <v-col cols="12" class="ma-2">
                  <v-row fluid class="px-6">
                    <v-label> Цвет текста </v-label>
                  </v-row>
                  <v-row fluid class="px-6">
                    <v-color-picker
                      show-swatches
                      v-model="textColor"
                    ></v-color-picker>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn rounded variant="outlined" @click="closeClassTypeDialog">
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveClassType">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title class="card_ligth_header">
      <h3 class="ma-2">Параметры занятий</h3>
    </v-card-title>
    <v-card-title class="card_ligth_header" style="min-height: 5vh">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="classTypeSearchBody"
            label="Найти"
            variant="outlined"
            clearable
            hint="Введите ключевое слово для поиска"
            rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
            @click="showClassTypeDialog = true"
            variant="outlined"
            color="primary"
            class="mb-4"
          >
            Добавить
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :key="listKey">
      <div class="d-sm-none d-flex align-center flex-column">
        <v-card
          class="ma-5 mx-5 w-100"
          v-for="item in classTypePaginatedData"
          :key="item"
        >
          <v-card-title class="card_ligth_header">
            <h5>
              Параметр №
              {{ sortedClassTypes.indexOf(item) + 1 }}
            </h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list-item lines="two">
              <v-list-item-title>Название:</v-list-item-title>
              <v-list-item-subtitle>{{
                item.scheduleClassTypeName
              }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Описание:</v-list-item-title>
              <v-list-item-subtitle>{{
                item.scheduleClassTypeDescription
              }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Добавлено:</v-list-item-title>
              <v-list-item-subtitle>{{
                item.createDate.replace("T", " ")
              }}</v-list-item-subtitle>
            </v-list-item>

            <v-list-item lines="two">
              <v-list-item-title>Активно:</v-list-item-title>
              <v-list-item-subtitle
                ><v-chip size="small" v-if="item.isActive">Да</v-chip
                ><v-chip v-else size="small">Нет</v-chip></v-list-item-subtitle
              >
            </v-list-item>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-btn
              @click="editClassType(item)"
              class="ma-1"
              color="primary"
              variant="outlined"
              rounded
            >
              Редактировать
            </v-btn>
            <v-btn
              @click="deleteClassType(item)"
              class="ma-1"
              color="warning"
              variant="outlined"
              rounded
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div class="d-none d-sm-block">
        <v-table fixed-header class="h-100">
          <thead>
            <tr>
              <th class="text-left">
                <b>№</b>
              </th>
              <th class="text-left" v-on:click="byScheduleClassTypeName">
                <b>Название</b>
                <font-awesome-icon icon="fa-solid fa-sort" />
              </th>
              <th class="text-left" v-on:click="byScheduleClassTypeDescription">
                <b>Описание </b>
                <font-awesome-icon icon="fa-solid fa-sort" />
              </th>
              <th class="text-left" v-on:click="byClassTypeCreateDate">
                <b>Добавлено </b>
                <font-awesome-icon icon="fa-solid fa-sort" />
              </th>
              <th class="text-left" v-on:click="byClassTypeActive">
                <b>Активно </b>
                <font-awesome-icon icon="fa-solid fa-sort" />
              </th>
              <th class="text-left">
                <b>Действие</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in classTypePaginatedData" :key="item">
              <td>
                <p>{{ classTypePaginatedData.indexOf(item) + 1 }}</p>
              </td>
              <td>
                <p>{{ item.scheduleClassTypeName }}</p>
              </td>
              <td>
                <p>{{ item.scheduleClassTypeDescription }}</p>
              </td>
              <td>
                <p>{{ item.createDate.replace("T", " ") }}</p>
              </td>
              <td>
                <v-chip size="small" v-if="item.isActive">Да</v-chip
                ><v-chip v-else size="small">Нет</v-chip>
              </td>
              <td>
                <v-btn
                  @click="editClassType(item)"
                  class="ma-1"
                  color="primary"
                  variant="outlined"
                  rounded
                >
                  Редактировать
                </v-btn>
                <v-btn
                  @click="deleteClassType(item)"
                  class="ma-1"
                  color="warning"
                  variant="outlined"
                  rounded
                >
                  Удалить
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="card_ligth_actions" style="min-height: 5vh">
      <v-container class="text-center">
        <v-pagination
          v-model="classTypePage"
          :length="classTypePageCount"
          :total-visible="3"
          size="small"
        ></v-pagination>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import LoaderRing from "@/elements/LoaderRing.vue";
import _ from "lodash";
import {customAxios} from "@/service/customAxios";

export default {
  name: "ScheduleClassOptionList",
  props: [],
  components: { LoaderRing },
  data: () => ({
    listKey: 0,

    noteCount: 2,
    isActive: false,
    showLoader: false,

    scheduleClassTypeSortOrder: 1,
    scheduleClassTypeSortKey: "scheduleClassTypeName",
    lastScheduleClassTypeSortKey: "",

    showClassTypeDialog: false,
    scheduleClassTypes: [],
    classTypeSearchBody: "",
    classTypePage: 1,
    scheduleClassTypeName: "",
    scheduleClassTypeDescription: "",
    scheduleClassTypeId: undefined,
    scheduleClassTypeNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    scheduleClassTypeDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 255) || "Значение до должно превышать 255 символов!",
    ],
    backgroundColor: "",
    textColor: "",
  }),
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
    filteredClassTypes() {
      const ss = this.classTypeSearchBody.toLowerCase();

      let tempClassTypes = this.scheduleClassTypes;
      return tempClassTypes.filter((item) =>
        _.some(item, (v) => {
          if (!(v instanceof Object)) {
            return _.toLower(v).indexOf(ss) > -1;
          }
        })
      );
    },
    sortedClassTypes() {
      let tempClassTypes = this.filteredClassTypes;
      return tempClassTypes.sort(
        dynamicSort(
          this.scheduleClassTypeSortKey,
          this.scheduleClassTypeSortOrder
        )
      );
    },
    classTypePageCount() {
      let l = this.sortedClassTypes.length,
        s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },
    classTypePaginatedData() {
      const start = (this.currentClassTypePage - 1) * parseInt(this.noteCount);
      const end = start + parseInt(this.noteCount);
      return this.sortedClassTypes.slice(start, end);
    },
    currentClassTypePage() {
      if (this.classTypePageCount < this.classTypePage) {
        return 1;
      } else {
        return this.classTypePage;
      }
    },
  },
  methods: {
    forceRerender() {
      this.listKey += 1;
    },
    byScheduleClassTypeName() {
      if (this.lastScheduleClassTypeSortKey.includes("scheduleClassTypeName")) {
        this.scheduleClassTypeSortOrder = this.scheduleClassTypeSortOrder * -1;
      }
      this.scheduleClassTypeSortKey = "scheduleClassTypeName";
      this.lastScheduleClassTypeSortKey = this.scheduleClassTypeSortKey;
      this.forceRerender();
    },
    byScheduleClassTypeDescription() {
      if (
        this.lastScheduleClassTypeSortKey.includes(
          "scheduleClassTypeDescription"
        )
      ) {
        this.scheduleClassTypeSortOrder = this.scheduleClassTypeSortOrder * -1;
      }
      this.scheduleClassTypeSortKey = "scheduleClassTypeDescription";
      this.lastScheduleClassTypeSortKey = this.scheduleClassTypeSortKey;
      this.forceRerender();
    },
    byClassTypeCreateDate() {
      if (this.lastScheduleClassTypeSortKey.includes("createDate")) {
        this.scheduleClassTypeSortOrder = this.scheduleClassTypeSortOrder * -1;
      }
      this.scheduleClassTypeSortKey = "createDate";
      this.lastScheduleClassTypeSortKey = this.scheduleClassTypeSortKey;
      this.forceRerender();
    },
    byClassTypeActive() {
      if (this.lastScheduleClassTypeSortKey.includes("isActive")) {
        this.scheduleClassTypeSortOrder = this.scheduleClassTypeSortOrder * -1;
      }
      this.scheduleClassTypeSortKey = "isActive";
      this.lastScheduleClassTypeSortKey = this.scheduleClassTypeSortKey;
      this.forceRerender();
    },

    saveClassType() {
      
      this.$refs.scheduleClassTypeForm.validate().then((result) => {
        if (result.valid) {
          let classType = {
            scheduleClassTypeName: capitalizeFirstLetter(
              this.scheduleClassTypeName
            ),
            scheduleClassTypeDescription: capitalizeFirstLetter(
              this.scheduleClassTypeDescription
            ),
            isActive: this.isActive,
            backgroundColor: this.backgroundColor,
            textColor: this.textColor,
          };

          if (this.scheduleClassTypeId) {
            customAxios
              .put(
                "/schedule/class/type/" + this.scheduleClassTypeId,
                classType
              )
              .then((response) => {
                if (response.status === 202) {
                  let index = getIndex(
                    this.scheduleClassTypes,
                    response.data.id
                  );
                  this.scheduleClassTypes.splice(index, 1, response.data);
                  this.showClassTypeDialog = false;
                  this.textColor = "";
                  this.backgroundColor = "";
                  this.scheduleClassTypeId = "";
                  this.scheduleClassTypeName = "";
                  this.scheduleClassTypeDescription = "";
                  this.isActive = false;
                } else {
                  alert("Что-то пошло не так");
                  console.log(response);
                }
              })
           
          } else {
            customAxios
              .post("/schedule/class/type", classType)
              .then((response) => {
                if (response.status === 201) {
                  this.scheduleClassTypes.push(response.data);
                  this.showClassTypeDialog = false;
                  this.scheduleClassTypeId = "";
                  this.scheduleClassTypeName = "";
                  this.scheduleClassTypeDescription = "";
                  this.textColor = "";
                  this.backgroundColor = "";
                  this.isActive = false;
                } else {
                  alert("Что-то пошло не так");
                  console.log(response);
                }
              })
             
          }
        }
      });
    },
    closeClassTypeDialog() {
      this.showClassTypeDialog = false;
      this.scheduleClassTypeId = "";
      this.scheduleClassTypeName = "";
      this.scheduleClassTypeDescription = "";
      this.isActive = false;
    },
    editClassType(item) {
      this.scheduleClassTypeName = item.scheduleClassTypeName;
      this.scheduleClassTypeDescription = item.scheduleClassTypeDescription;
      this.textColor = item.textColor;
      this.backgroundColor = item.backgroundColor;
      this.creatorId = item.creatorId;
      this.createDate = item.createDate;
      this.isActive = item.isActive;
      this.scheduleClassTypeId = item.id;
      this.showClassTypeDialog = true;
    },
    deleteClassType(item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      if (confirm("Вы точно хотите тип занятия?")) {
        customAxios
          .delete("/schedule/class/type/" + item.id, jwtTokenConfig)
          .then((response) => {
            if (response.status === 202) {
              this.scheduleClassTypes.splice(
                this.scheduleClassTypes.indexOf(item),
                1
              );
            } else {
              console.log(response);
            }
          })
        
      }
    },
  },
  created: function () {
    this.showLoader = true;
    
    function getUsers(axios) {
      return axios.get("/user/minimal");
    }
    function getUserGroups(axios) {
      return axios.get("/user_group");
    }
    function getScheduleClassTypes(axios) {
      return axios.get("/schedule/class/type");
    }
    function getScheduleClasses(axios) {
      return axios.get("/schedule/class");
    }

    Promise.all([
      getUsers(customAxios),
      getUserGroups(customAxios),
      getScheduleClassTypes(customAxios),
      getScheduleClasses(customAxios),
    ])
      .then((results) => {
        if (results[0].status === 200) {
          this.users = results[0].data;
        }
        if (results[1].status === 200) {
          this.userGroups = results[1].data;
        }
        if (results[2].status === 200) {
          this.scheduleClassTypes = results[2].data;
        }
        if (results[3].status === 200) {
          this.scheduleClasses = results[3].data;
        }

        this.showLoader = false;
      })
      .catch(() => {
        this.showLoader = false;
        alert("Ошибка загрузки данных, обратитесь к администратору системы!");

      });
  },

  setup() {
    return {};
  },
};
function dynamicSort(property, sortOrder) {
  return function (a, b) {
    let result;
    result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}
</script>

<style lang="scss">
.form-element {
  box-shadow: $shadow;
  display: grid;
  grid-template-columns: 40% 1fr;
  overflow: hidden;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: $gradient-1;
  }

  &__card.v-card {
    box-shadow: none;
    padding: 30px;
  }

  &__title.v-card-title {
    color: $main;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
  }

  .v-text-field__input {
    input::placeholder {
      font-size: 18px;
      line-height: 22px;
    }

    label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #6e6d94;
    }

    .v-field__outline {
      color: rgba(#6e6d94, 0.5);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;

    &__img {
      display: none;
    }
  }
}
</style>
