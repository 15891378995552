<template>
  <div class="toast-popup">
    <div class="toast-popup__progress" ref="progressRef">
      <div class="toast-popup__content">
        <p>{{ text }}</p>
        <AppButton @click="closePopup"><CIcon icon="cross-icon" /></AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { usePopupStore } from "@/store/popup";
import { storeToRefs } from "pinia";
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";
import { ref } from "vue";

export default {
  emits: ["close"],
  setup() {
    const { closePopup } = usePopupStore();
    const { text } = storeToRefs(usePopupStore());

    const progressRef = ref();

    function close() {
      progressRef.value.classList.add("showProgress");
      setTimeout(() => closePopup(), 2000);
    }
    onMounted(close);

    return {
      text,
      progressRef,
      closePopup,
    };
  },
  components: { AppButton, CIcon },
};
</script>

<style lang="scss">
.toast-popup {
  font-weight: 600;
  border-radius: 16px;
  box-shadow: $shadow;
  max-width: 300px;
  overflow: hidden;

  &__content {
    display: flex;
    align-items: center;
    color: $secondary;
    justify-content: space-between;
    padding: 16px;

    svg {
      stroke: $secondary;
    }
  }

  &__progress {
    overflow: hidden;
    position: relative;
    border-radius: 16px;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      bottom: 0;
      left: -90%;
      background-image: $main-gradient-to-right;
    }

    &.showProgress::after {
      animation-name: progress;
      animation-duration: 2s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes progress {
  100% {
    left: 0;
  }
}
</style>
