<template>
  <div>
    <PageTitle title="Отчёт: Я-студия (по студии)" />

    <div class="g-col g-col--3-1fr">
      <FilterByStudio />
      <FilterByYear />
      <FilterByGroupId />
    </div>

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <p class="mb-3">Здесь отобразится отчёт.</p>
        <p>
          Выставьте: <strong>студию</strong>, <strong>учебный год</strong> и
          <strong>класс</strong>.
        </p>

        <v-label> Выставьте фильтры</v-label>
        <ol class="pl-5">
          <li><strong>студию</strong></li>
          <li><strong>учебный год</strong></li>
          <li><strong>класс</strong></li>
        </ol>

        <div class="mt-3 d-flex align-center flex-wrap">
          Значок
          <div class="bordered-item mx-2">
            <CIcon icon="file-icon" />
          </div>

          обозначает, что мастер оставил комментарий. Нажмите на него, чтобы
          просмотреть.
        </div>

        <div class="mt-3 d-flex align-center flex-wrap">
          Значок <CIcon icon="minus-icon" class="mx-2" /> обозначает, что у
          ребёнка нет данных за этот период.
        </div>
      </template>
    </TogglerOfWrapper>

    <StatusWrapper
      :loadingStatus="loading"
      :isDataExisted="sortedReport && sortedReport.length > 0"
    >
      <SHeader
        @download="downloadExcel(`отчёт_я_студия`, 'by-studio-report')"
      />

      <div class="page-wrapper">
        <div class="table-wrapper">
          <table id="by-studio-report" class="table-custom">
            <thead>
              <tr class="table-custom__headers">
                <th></th>
                <th v-for="(m, i) in month" :key="i">
                  {{ m }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="c in sortedReport" :key="c.child.id">
                <td>{{ c.child.lastname }} {{ c.child.firstname }}</td>
                <td v-for="s in c.childDataSet" :key="s.monthIndex">
                  <div
                    v-if="s.isSuccessResult === null"
                    class="text-grey d-flex align-center justify-center"
                  >
                    <CIcon icon="minus-icon" />
                    <span class="visually-hidden">&#8722;</span>
                  </div>
                  <ResultCell
                    v-else
                    :result="s.isSuccessResult"
                    :comment="s.masterComment"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import {
  sortByChildLastname,
  monthDict,
  getPreviosSchoolMonth,
} from "@/util/common";

import { useUserData } from "@/composables/userData";
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";

import { useRoute } from "vue-router";

import StatusWrapper from "@/components/StatusWrapper.vue";
import CIcon from "@/elements/CIcon.vue";
import SHeader from "./SHeader.vue";

import ResultCell from "./ResultCell.vue";
import FilterByYear from "@/components/filtersElements/FilterByYear.vue";
import FilterByGroupId from "@/components/filtersElements/FilterByGroupId.vue";
import FilterByStudio from "@/components/filtersElements/FilterByStudio.vue";

import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import PageTitle from "@/elements/PageTitle.vue";

const { showError } = usePopupStore();
const { downloadExcel } = useUserData();
const route = useRoute();

const loading = ref("nothing");
const report = ref([]);

const sortedReport = computed(() => {
  if (!report.value.length) {
    return [];
  }

  return sortByChildLastname(report.value);
});

const month = computed(() =>
  sortedReport.value.length
    ? sortedReport.value[0].childDataSet.map((v) => monthDict[v.monthIndex])
    : []
);

onMounted(getReport);

watch(() => route.query.filterByGroupId, getReport);
watch(() => route.query.filterByYear, getReport);
watch(() => route.query.filterByStudio, getReport);

async function getReport() {
  if (
    !route.query.filterByStudio ||
    !route.query.filterByGroupId ||
    !route.query.filterByYear
  ) {
    return;
  }

  loading.value = "loading";
  try {
    const r = await getReportHandler();
    report.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getReportHandler() {
  return customAxios.post("/i_studio/for_report/by_year_and_group_and_type", {
    studyYearId: Number(route.query.filterByYear),
    personGroupId: Number(route.query.filterByGroupId),
    studioId: Number(route.query.filterByStudio),
    monthIndexList: getPreviosSchoolMonth(),
  });
}
</script>
