<template>
  <div class="list-preview page-wrapper">
    <div  class="d-flex justify-space-between align-center mb-5">
      <h3>{{ title }}</h3>
      <router-link
        :to="link"
        class="gradient-button d-flex justify-center"
      >
        <span>Перейти в список</span>
      </router-link>
    </div>
    <div class="list-preview__content">
      <AccordeonList
        v-if="list.length > 0"
        :list="list"
        :itemTitleKey="itemTitleKey"
      />
      <v-label v-else class="text-deep-purple-darken-1 font-weight-bold mb-2"
        >Список пока пуст</v-label
      >
    </div>

  </div>
</template>

<script>
import AccordeonList from "@/components/AccordeonList.vue";

import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { storeToRefs } from "pinia";

export default {
  props: {
    link: { type: Object, required: true },
    itemTitleKey: { type: String, required: true },
    title: { type: String, required: true },
    list: { type: Array, required: false },
  },
  components: { AccordeonList },
  setup() {
    const { journal } = storeToRefs(useSubjectsJournalStore());
    return { journal };
  },
};
</script>
