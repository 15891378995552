<template>
  <div>
    <IamStudioEditor />
    <IamStudioEvents :list="filteredEvents" />
  </div>
</template>

<script setup>
import IamStudioEditor from "./IamStudioEditor.vue";
import IamStudioEvents from "./IamStudioEvents.vue";

import { useRoute } from "vue-router";
import { useIamStudioStore } from "@/store/iamstudio";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const route = useRoute();
const { getStudioById } = storeToRefs(useIamStudioStore());

const events = computed(() => {
  if (!route.params.id) {
    return [];
  }

  const studio = getStudioById.value(route.params.id);

  if (!studio) {
    return [];
  }

  const events = studio.eventList;

  return events.length ? events : [];
});

const filteredEvents = computed(() => {
  if (!events.value.length) {
    return [];
  }
  return events.value.filter((v) => {
    if (route.query.filterByYear && v.studyYearId !== Number(route.query.filterByYear)) {
      return false;
    }

    if (
      route.query.filterByGroupId &&
      v.personGroupId !== Number(route.query.filterByGroupId)
    ) {
      return false;
    }
    return true;
  });
});
</script>
