<template>
  <div v-if="childrenGroupsSelector.length">
    <PageTitle title="Мой класс" />

    <div class="page-wrapper">
      <div class="d-flex justify-space-between mb-7 align-center">
        <CompactSelect
          v-model="selectedChildrenGroupId"
          :options="childrenGroupsSelector"
        />

        <router-link
          :to="{
            name: 'common-schedule',
            params: {
              groupId: selectedChildrenGroupId,
            },
          }"
          class="gradient-button"
          >Расписание</router-link
        >
      </div>

      <ul v-if="childrenList.length > 0" class="list">
        <li v-for="child in childrenList" :key="child.id" class="d-flex my-2">
          <div class="gradient-list-item w-100 mr-2">
            <router-link
              :to="{
                name: 'tutor-child',
                query: {
                  filterByGroupId: selectedChildrenGroupId,
                },
                params: {
                  childId: child.id
                },
              }"
              class="gradient-list-item__content"
            >
              <h4>
                {{ child.lastname }}
                {{ child.firstname }}
                {{ child.secname }}
              </h4>
            </router-link>
          </div>

          <AppButton @click="getLoginLink(child.id)" class="gradient-button">
            Ссылка</AppButton
          >
        </li>
      </ul>
    </div>
  </div>
  <LoaderRing v-else />
</template>

<script setup>
import { onMounted } from "vue";
import { useChildrenGroupsData } from "@/composables/childrenGroups";
import { useUserData } from "@/composables/userData";

import CompactSelect from "@/elements/CompactSelect.vue";
import PageTitle from "@/elements/PageTitle.vue";
import LoaderRing from "@/elements/LoaderRing.vue";

import AppButton from "@/elements/AppButton.vue";

const { getLoginLink } = useUserData();
const {
  selectedChildrenGroupId,
  getChildrenGroups,
  childrenGroupsSelector,
  childrenList,
} = useChildrenGroupsData();

onMounted(async () => {
  await getChildrenGroups();

  selectedChildrenGroupId.value = childrenGroupsSelector.value.length
    ? childrenGroupsSelector.value[0].id
    : null;
});
</script>
