<template>
  <LoaderRing v-if="isLoading" />
  <router-view v-else></router-view>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { useNotesStore } from "@/store/notes";
import { onMounted, onUnmounted, ref, watch } from "vue";

import { useRoute } from "vue-router";
import { usePopupStore } from "@/store/popup";
import LoaderRing from "@/elements/LoaderRing.vue";
import { useAuthStore } from "@/store/auth";
import { pushFilterToURL } from "@/service/router";

const { isChild, userId } = useAuthStore();
const { setNotes, clearNotes } = useNotesStore();
const { showErrorWithCustomText } = usePopupStore();

const route = useRoute();
const isLoading = ref(false);

onMounted(async() => {
  if (isChild) {
    pushFilterToURL({ name: "filterByChildId", value: userId })
  }
  update();
});

onUnmounted(clearNotes);

watch(
  () => route.query,
  (v, n) => {
    if (v && v.filterByChildId !== n.filterByChildId) {
      update();
    }
  }
);

async function update() {
  if (!route.query.filterByChildId) {
    return;
  }
  getChildNotes();
}

async function getChildNotes() {
  isLoading.value = true;
  try {
    const groupNotes = await getChildNotesHandler();
    setNotes(groupNotes.data);
  } catch (e) {
    showErrorWithCustomText(e);
  } finally {
    isLoading.value = false;
  }
}

async function getChildNotesHandler() {
  return customAxios.get(
    `/tutor/child_comments/` + route.query.filterByChildId
  );
}
</script>
