import { dayOfWeekDict } from "@/util/common";

export function useDatesChanger() {
  function getRangeUIFormat(dates) {
    let result = "";

    dates.forEach((d, i) => {
      const isDate = d instanceof Date && !isNaN(d.valueOf());
      if (isDate) {
        const day = d.getDate();
        const month = d.getMonth() + 1;
        const year = d.getFullYear();

        const date = `${addZero(day)}/${addZero(month)}/${year}`;

        if (i === 0) {
          result = date;
        } else {
          result = result + " - " + date;
        }
      }
    });

    return result;
  }

  function getOneDayUIFormat(date) {
    return `${addZero(date.getDate())}/${addZero(
      date.getMonth() + 1
    )}/${date.getFullYear()}`;
  }

  function getDateWithWeekday(date) {
    const d = getDateFormatWithoutTime(date);
    const weekday = new Date(date);
    return `${d} (${dayOfWeekDict[weekday.getDay()].title})`;
  }

  function getDateFormatWithoutTime(date) {
    const d = new Date(date);
    return `${addZero(d.getDate())}/${addZero(
      d.getMonth() + 1
    )}/${d.getFullYear()}`;
  }

  function getDateDayMonth(date) {
    const d = new Date(date);
    return `${addZero(d.getDate())}/${addZero(d.getMonth() + 1)}`;
  }

  function getDateFormatWithTime(date) {
    const d = new Date(date);
    return `${addZero(d.getDate())}/${addZero(
      d.getMonth() + 1
    )}/${d.getFullYear()}, ${addZero(d.getHours())}:${addZero(d.getMinutes())}`;
  }

  function getDateFormatDayMonth(date) {
    const d = new Date(date);
    return `${addZero(d.getDate())}/${addZero(d.getMonth() + 1)}`;
  }

  function addZero(v) {
    return v.toString().padStart(2, "0");
  }

  function getTimeFromDate(date) {
    const d = new Date(date);
    return `${addZero(d.getHours())}:${addZero(d.getMinutes())}`;
  }

  function getTimeFromWeek(day) {
    const firstNonEmptyDay = day.find((v) => v.length > 0);
    return `${getTimeFromDate(
      firstNonEmptyDay[0].eventBeginDateTime
    )} - ${getTimeFromDate(firstNonEmptyDay[0].eventEndDateTime)}`;
  }

  function getDayId(date, days, fieldDay, fieldId) {
    if (!days.length) {
      return null;
    }

    const x1 = new Date(date);
    const x2 = `${x1.getDate()}${x1.getMonth()}${x1.getFullYear()}`;

    const info = days.filter((v) => {
      const y1 = new Date(v[fieldDay]);
      const y2 = `${y1.getDate()}${y1.getMonth()}${y1.getFullYear()}`;
      return x2 === y2;
    })[0];

    if (info) {
      return info[fieldId];
    }
  }

  function getCurrentWeek() {
    const today = new Date();
    const lastDay = new Date();
    const firstDay = new Date();

    firstDay.setDate(today.getDate() - (today.getDay() - 1));
    lastDay.setDate(today.getDate() + (5 - today.getDay()));

    firstDay.setHours("00");
    firstDay.setSeconds("00");
    firstDay.setMinutes("00");

    lastDay.setHours("23");
    lastDay.setSeconds("59");
    lastDay.setMinutes("59");

    return [firstDay, lastDay];
  }

  function setDateTime(date) {
    if (!date) {
      return;
    }

    return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(
      date.getDate()
    )}T00:00:00.000Z`;
  }

  function setDateTimeNoSeconds(date) {
    if (!date) {
      return;
    }

    return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(
      date.getDate()
    )}T00:00:00`;
  }

  return {
    setDateTime,
    getCurrentWeek,
    getDayId,
    getTimeFromWeek,
    getTimeFromDate,
    getDateFormatWithTime,
    getRangeUIFormat,
    getDateFormatWithoutTime,
    getOneDayUIFormat,
    addZero,
    getDateDayMonth,
    getDateFormatDayMonth,
    setDateTimeNoSeconds,
    getDateWithWeekday,
  };
}
