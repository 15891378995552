<template>
  <div>
    <PageTitle title="Список студий" />

    <div class="d-flex my-3 justify-end">
      <router-link :to="{ name: 'studio-create' }" class="accent-button"
        >Добавить студию</router-link
      >
    </div>
    
    <ul v-if="studios.length">
      <li v-for="s in studios" :key="s.id" class="gradient-list-item">
        <router-link
          :to="{ name: 'studio-edit', params: { id: s.id } }"
          class="gradient-list-item__link-with-period gradient-list-item__content"
        >
          <h4>{{ s.iStudioName }}</h4>

          <div class="gradient-list-item__period">
            <CIcon icon="calendar-icon" />
            {{ getDateFormatWithoutTime(s.startDateTime) }} -
            {{ getDateFormatWithoutTime(s.endDateTime) }}
          </div>
        </router-link>
      </li>
    </ul>
    <NothingFound v-else />
  </div>
</template>

<script setup>
import { useIamStudioStore } from "@/store/iamstudio";
import { storeToRefs } from "pinia";
import { useDatesChanger } from "@/composables/datesChanger";

import CIcon from "@/elements/CIcon.vue";
import PageTitle from "@/elements/PageTitle.vue";
import NothingFound from "@/elements/NothingFound.vue";

const { getDateFormatWithoutTime } = useDatesChanger();
const { studios } = storeToRefs(useIamStudioStore());
</script>
