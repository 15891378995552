<template>
  <router-view @updateLists="updateLists" @updateJournal="updateJournal"></router-view>
</template>

<script setup>
import { onUnmounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";

const { setLists } = useSubjectsJournalStore();
const { showError } = usePopupStore();

const route = useRoute();

const {
  clearJournalDaysWithIds,
  clearJournal,
  setJournal,
  setJournalDaysWithIds,
} = useSubjectsJournalStore();

watch(
  () => route.query.journalId,
  async (id) => {
    if (id) {
      updateJournal();
    }
  },
  {
    immediate: true,
  }
);

async function updateJournal() {
  try {
    clearJournal();
    await getJournalById();
    await getDaysIdsByJournalId();
  } catch (e) {
    showError(e);
  }
}

async function getJournalById() {
  try {
    const r = await customAxios.get(
      `/teacher/log/by_id/${route.query.journalId}`
    );
    setJournal(r.data);
  } catch (e) {
    showError(e);
  }
}

async function getDaysIdsByJournalId() {
  try {
    const r = await customAxios.get(
      `/teacher/log/log_days_by_log_id/${route.query.journalId}`
    );
    setJournalDaysWithIds(r.data);
  } catch (e) {
    showError(e);
  }
}

watch(
  () => route.query.dayId,
  (dayId) => {
    if (dayId) {
      updateLists();
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => route.path,
  (_, p) => {
    if ((p.includes("edit") || p.includes("create")) && route.query.dayId) {
      updateLists();
    }
  }
);

watch(
  () => route.name,
  (_, n) => {
    if (n === "subject-journal-edit" && route.query.journalId) {
      updateJournal();
    }
  }
);

async function updateLists() {
  try {
    const r = await getSheetsHandler(route.query.dayId);
    setLists(r.data);
  } catch (e) {
    showError(e);
  }
}

async function getSheetsHandler(dayId) {
  if (route.path.includes("foreign-rating-sheet")) {
    return customAxios.get(`/foreign_checklist/by_teacher_log_day_id/${dayId}`);
  }

  if (route.path.includes("foreign-dictation")) {
    return customAxios.get(`/foreign_dictation/by_log_day/${dayId}`);
  }

  if (route.path.includes("/rating-sheet")) {
    return customAxios.get(
      `/teacher/log/skill_list/by_teacher_log_day/${dayId}`
    );
  }

  if (route.path.includes("/dictation")) {
    return customAxios.get(`/dictation/by_log_day/${dayId}`);
  }
}

onUnmounted(clear);

function clear() {
  clearJournal();
  clearJournalDaysWithIds();
}
</script>
