<template>
  <LoaderRing v-if="isLoading" />
  <router-view v-else @update="getNotes"></router-view>
</template>

<script setup>
import { useNotesStore } from "@/store/notes";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useNotes } from "@/composables/notes";

import { useRoute } from "vue-router";
import { usePopupStore } from "@/store/popup";
import { useAuthStore } from "@/store/auth";

import { pushFilterToURL } from "@/service/router";

import LoaderRing from "@/elements/LoaderRing.vue";

const { setNotes, clearNotes } = useNotesStore();
const { showError } = usePopupStore();
const { isChild, userId } = useAuthStore();

const { getChildrenNotesHandler } = useNotes();
const route = useRoute();

const isLoading = ref(false);

onMounted(async () => {
  if (isChild) {
    pushFilterToURL({ name: "filterByChildId", value: userId });
  }
  getNotes();
});

onUnmounted(clearNotes);

watch(
  () => route.query.filterByChildId,
  (c, p) => {
    if (c && c === p) {
      return;
    }
    getNotes();
  }
);

async function getNotes() {
  if (!route.query.filterByChildId) {
    return;
  }
  isLoading.value = true;

  try {
    const r = await getChildrenNotesHandler(route.query.filterByChildId);
    setNotes(r.data);
  } catch (e) {
    showError(e);
  } finally {
    isLoading.value = false;
  }
}
</script>
