<template>
  <div class="d-block bg-white pa-3 rounded-lg card">
    <v-chip class="bg-deep-purple-lighten-1 mb-3">{{
      item.cognationCategory.name
    }}</v-chip>

    <!-- <div class="d-flex justify-center mb-5">
      <img src="/win-child.png" :style="{ width: '80%' }" />
    </div> -->

    <h4 class="mb-3 text-center">
      {{ item.cognationName }}
    </h4>

    <p v-if="item.cognationDescription" class="mb-2">
      {{ item.cognationDescription }}
    </p>

    <div class="text-grey-lighten-1 font-weight-thin mt-5">
      <!-- <p>
        Добавлено: {{ item.creator.firstname }}
        {{ item.creator.secname }}
        {{ item.creator.lastname }}
      </p> -->
      <p>{{ getDateFormatWithoutTime(item.cognationCreateDate) }}</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

const { getDateFormatWithoutTime } = useDatesChanger();
defineProps({ item: Object });
</script>
