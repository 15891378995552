<template>
  <svg class="app-icon">
    <use :xlink:href="`#${icon}`" />
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.app-icon {
  stroke: $main-2;
  flex-shrink: 0;
  stroke-width: 2px;
  width: 24px;
  height: 24px;

  &.icon-grey {
    stroke: #9e9e9e;
  }

  &.icon-dark {
    stroke: $main-1;
  }
}
</style>
