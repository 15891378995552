export const requiredField = [(v) => !!v || "Поле обязательно для заполнения"];
export const requiredFieldInSelector = [
  (v) => v >= 0 || "Поле обязательно для заполнения",
];

export const requiredMultipleSelector = [
  (v) => v.length > 0 || "Поле обязательно для заполнения",
];

export const fieldMaxLength100 = [
  (v) => v.length <= 100 || "Значение не должно превышать 100 символов",
];

export const fieldMaxLength250 = [
  (v) => v.length <= 250 || "Значение не должно превышать 250 символов",
];

export const fieldMaxLength255 = [
  (v) => v.length <= 250 || "Значение не должно превышать 255 символов",
];

export const fieldFrom0to100 = [
  (v) => Number.isInteger(Number(v)) || "Только целые числа",
  (v) => (v >= 0 && v <= 100) || "Значение от 0 до 100",
];

export const moreThenZero = [(v) => v > 0 || "Значение больше 0"];
export const moreThenZeroLength = [
  (v) => v.length > 0 || "Поле обязательно для заполнения",
];

export const fileInput200 = [
  (v) => !!v || "Необходимо прикрепить файл",
  (v) => v[0].size < 200000000 || "Размер файла не должен превышать 200 MB",
];
