<template>
  <div class="result-cell">
    <div class="d-flex justify-center">
      <div
        class="result-box mr-2"
        :class="{
          'result-box__negative': result === false,
        }"
      ></div>

      <AppButton
        v-if="comment && comment.length"
        @click="visible = !visible"
        class="bordered-item"
      >
        <CIcon v-if="visible" icon="cross-icon" />
        <CIcon v-else icon="file-icon" />
      </AppButton>
    </div>
    <div class="visually-hidden">
      <span v-if="result">&#10003;</span>
      <span v-else>&#10007;</span>
    </div>

    <v-dialog v-model="visible">
      <v-card class="pa-2">
        {{ comment }}
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";

import { defineProps, ref } from "vue";

defineProps({
  result: { type: Boolean, required: false },
  comment: { type: String, required: false },
});
const visible = ref(false);
</script>
