<template>
  <div class="table-element pa-0 mb-5">
    <v-card class="mx-auto rounded-lg table-element__card">
      <slot name="tabs"></slot>
      <slot name="window"></slot>
    </v-card>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.table-element {
  .v-table__wrapper {
    font-size: 12px;
    line-height: 20px;
  }

  .v-card-title {
    white-space: normal;
  }
  .v-tabs {
    .v-btn {
      color: #fff;
    }
  }

  .v-card {
    overflow: hidden;
    margin: 0;
  }

  .v-card-text {
    padding: 0;
  }

  .v-divider {
    background-color: $main;
  }

  .v-card .v-card__underlay {
    background-color: #c7c7c7;
  }

  .v-card:nth-of-type(2n) .v-card__underlay {
    background-color: #ffffff;
  }

  .header-item {
    font-size: 12px;
    line-height: 24px;
  }

  .v-table th.text-left {
    font-size: 10px;
    line-height: 22px;
    white-space: nowrap;
  }

  .vue3-easy-data-table td,
  .v-table > .v-table__wrapper > table > tbody > tr > td {
    padding: 16px !important;
  }

  .v-tabs.v-slide-group--is-overflowing:not(.v-slide-group--has-affixes)
    .v-tab:first-child {
    margin-inline-start: 0;
  }

  .v-tabs.v-slide-group--is-overflowing:not(.v-slide-group--has-affixes)
    .v-tab:last-child {
    margin-inline-end: 0;
  }
}

// .v-dialog .v-overlay__content {
//   margin: 0 auto;
// }

@media (max-width: 480px) {
  .v-dialog .v-overlay__content > .v-card > .v-card-text {
    padding: 4px;
  }
}
</style>
