<template>
  <div class="wrapper my-10">
    <h2 class="text-center mb-5">Наши достижения</h2>

    <LoaderRing v-if="loadingStatus === 'loading'" />

    <div v-else class="cards-grid">
      <router-link
        v-for="c in cognations"
        :key="c.id"
        :to="{ name: 'common-cognations' }"
        class="link d-flex"
      >
        <CCognationPreview :item="c" />
      </router-link>
    </div>

    <SEmpty v-if="cognations.length === 0" />
  </div>
</template>

<script setup>
import { useCognations } from "@/composables/cognations";
import { useCognationsStore } from "@/store/cognations";
import { onMounted, ref } from "vue";

import { usePopupStore } from "@/store/popup";
import { storeToRefs } from "pinia";
const { cognations } = storeToRefs(useCognationsStore());

import CCognationPreview from "@/cognations/common/CCognationPreview.vue";
import LoaderRing from "@/elements/LoaderRing.vue";
import SEmpty from "@/swot/SEmpty.vue";

const { setCognations } = useCognationsStore();
const { getCognationsHandler } = useCognations();
const { showError } = usePopupStore();

const loadingStatus = ref("loading");

onMounted(getCognations);

async function getCognations() {
  loadingStatus.value = "loading";
  try {
    const r = await getCognationsHandler();

    const sorted = r.data.sort((a, b) => {
      return new Date(a.cognationCreateDate) - new Date(b.cognationCreateDate);
    });

    setCognations(sorted.slice(0, 3));
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}
</script>

<style lang="scss"></style>
