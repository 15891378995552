import { customAxios } from "@/service/customAxios";
import { monthDict } from "@/util/common";
import { computed, ref } from "vue";

export function useDatesData() {
  const filteredMonth = filterMonth(monthDict);

  function filterMonth(arr) {
    const newArr = [];

    arr.forEach((d, i) => {
      if (i !== 5 && i !== 6 && i !== 7) {
        newArr.push({
          value: i,
          title: d,
        });
      }
    });

    return newArr;
  }

  const getCurrentMonth = () => {
    const d = new Date();
    const month = d.getMonth();
    return month;
  };

  const years = ref([]);

  const yearsOptions = computed(() => {
    if (!years.value.length) {
      return [];
    }
    return years.value.map((v) => ({
      value: v.id,
      title: v.studyYearName,
    }));
  });

  const monthOptions = monthDict.map((v, i) => ({
    value: i,
    title: v,
  }));

  async function getActiveYears() {
    try {
      const res = await getYears();
      years.value = res.data;
    } catch (e) {
      console.log(e);
    }
  }

  function getYears() {
    return customAxios.get("/schedule/year/active");
  }

  function getMonthTitle(monthIdx) {
    return monthDict.find((_, i) => i === monthIdx);
  }

  function getTime(dt) {
    const idx = dt.split("").indexOf("T") + 1;
    return dt.substring(idx, idx + 5);
  }

  return {
    getTime,
    getMonthTitle,
    filteredMonth,
    getActiveYears,
    yearsOptions,
    monthOptions,
    getCurrentMonth,
  };
}
