<template>
  <div class="form">
    <PageTitle
      :title="`${
        $route.name.includes('create') ? 'Создание' : 'Редактирование'
      } ${title}`"
    />

    <div v-if="$route.name.includes('edit')" class="d-flex justify-end my-2">
      <AppButton
        @click="$emit('remove')"
        class="form__button form__button--remove ma-0"
        >Удалить</AppButton
      >
    </div>

    <form class="page-wrapper">
      <slot></slot>

      <div v-if="$route.name.includes('create')" class="form__item mt-5">
        <AppButton
          @click="$emit('create')"
          class="form__button form__button--create"
          :disabled="saveIsDisable"
          >Создать</AppButton
        >
      </div>
    </form>
  </div>
</template>

<script>
import AppButton from "@/elements/AppButton.vue";
import PageTitle from "@/elements/PageTitle.vue";

export default {
  emits: ["create", "remove"],
  props: {
    title: { type: String, required: true },
    saveIsDisable: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    return {};
  },
  components: { AppButton, PageTitle },
};
</script>

<style lang="scss">
@import "@/styles/form.scss";
</style>
