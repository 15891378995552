<template>
  <div class="foreign-dictation-page">
    <DictationEditor @updateLists="$emit('updateLists')" />
    <DictationTable @updateLists="$emit('updateLists')" class="mt-3" />
  </div>
</template>

<script setup>
import { onMounted, defineEmits } from "vue";

import DictationTable from "./DictationTable.vue";
import DictationEditor from "./DictationEditor.vue";

const emits = defineEmits(["updateLists"]);

onMounted(() => {
  emits("updateLists");
});
</script>
