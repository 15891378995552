<template>
  <div class="link pa-2 text-black bg-white d-block rounded">
    <div class="d-flex align-center justify-end text-grey mb-3">
      <span>{{ getDateFormatWithoutTime(comment.dayDate) }}</span>
    </div>

    <p>{{ comment.teacherComment }}</p>
    <div class="mt-4 text-grey">
      <p>Предмет: {{ comment.subjectName }}</p>
      <p>Журнал: {{ comment.journalName }}</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

defineProps({ comment: Object });
const { getDateFormatWithoutTime } = useDatesChanger();
</script>
