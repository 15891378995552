<template>
  <div v-if="photos.length" class="photo-gallery">
    <ComponentWithPagination :list="photos">
      <ul class="photo-gallery__grid">
        <li
          v-for="(photo, idx) in photos"
          :key="photo.id"
          @click="currentPhotoIdx = idx"
        >
          <div class="photo-gallery__img">
            <img
              :src="'https://school.eurekacenter.ru/photo/' + photo.fileName"
            />
          </div>
        </li>
      </ul>
    </ComponentWithPagination>

    <Transition name="list">
      <div v-if="currentPhotoIdx !== null" class="photo-gallery__view">
        <AppButton @click="currentPhotoIdx = null">
          <span>Закрыть</span>
          <CIcon icon="cross-icon" />
        </AppButton>

        <ul class="photo-gallery__controls">
          <li @click="prev"></li>
          <li @click="next"></li>
        </ul>

        <div class="photo-gallery__full-img">
          <img :src="getPhotoById()" />
        </div>
      </div>
    </Transition>
  </div>
  <NothingFound v-else />
</template>

<script setup>
import ComponentWithPagination from "@/components/ComponentWithPagination.vue";
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";
import NothingFound from "@/elements/NothingFound.vue";
import { useGalleryStore } from "@/store/gallery";
import { storeToRefs } from "pinia";
import { onUnmounted } from "vue";
import { onMounted } from "vue";
import { ref } from "vue";

const { photos } = storeToRefs(useGalleryStore());
const currentPhotoIdx = ref(null);

function getPhotoById() {
  const photo = photos.value[currentPhotoIdx.value];

  return photo
    ? "https://school.eurekacenter.ru/photo/" + photo.fileName
    : null;
}

function next() {
  if (currentPhotoIdx.value < photos.value.length - 1) {
    currentPhotoIdx.value = currentPhotoIdx.value + 1;
  }
}

function prev() {
  if (currentPhotoIdx.value > 0) {
    currentPhotoIdx.value = currentPhotoIdx.value - 1;
  }
}

function onKeyboard(e) {
  if (e.key === "ArrowRight") {
    next();
  }

  if (e.key === "ArrowLeft") {
    prev();
  }

  if (e.key === "Escape") {
    currentPhotoIdx.value = null;
  }
}

onMounted(() => {
  document.addEventListener("keydown", onKeyboard);
});

onUnmounted(() => {
  document.removeEventListener("keydown", onKeyboard);
});
</script>

<style lang="scss">
.photo-gallery {
  ul {
    list-style: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 4px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 480px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__img {
    height: 200px;
    background-size: cover;
    background-image: url("../assets/school-pattern.png");
  }

  &__view {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba($main-1, 0.7);
    padding-bottom: 8px;

    button {
      color: #fff;
      text-transform: uppercase;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      z-index: 103;
      padding: 16px;
      text-shadow: 1px 1px 2px $main-1;
    }

    svg {
      stroke: #fff;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__full-img {
    width: 90%;
    height: 90%;
    position: relative;
    z-index: 101;
  }

  &__controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 102;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
