<template>
  <div v-if="currentWorkshop">
    <WorkshopEditor class="my-3" />

    <div class="page-wrapper">
      <h3 class="mb-4">Записавшиеся</h3>
    
      <ul v-if="sortedChildren.length" class="list">
        <li v-for="(child, i) in sortedChildren" :key="child.id">
         
          <p>{{ i + 1 }}. {{ child.lastname }} {{ child.firstname }}</p>
        </li>
      </ul>
      <p v-else>Никто пока не записался</p>
    </div>
    
    <WorkshopThemes
      class="my-3"
      :list="currentWorkshop.workshopDays"
      @updateWorkshops="$emit('updateWorkshops')"
    />
  </div>
</template>

<script setup>
import { useWorkshopStore } from "@/store/workshop";
import { useRoute } from "vue-router";

import { storeToRefs } from "pinia";
import { computed, defineEmits } from "vue";
import { sortByLastName } from "@/util/common";

import WorkshopEditor from "@/workshops/WorkshopEditor.vue";
import WorkshopThemes from "./WorkshopThemes.vue";

const route = useRoute();
const { getWorkshopById } = storeToRefs(useWorkshopStore());

const currentWorkshop = computed(() =>
  getWorkshopById.value(route.params.workshopId)
);

const sortedChildren = computed(() => {
  if (!currentWorkshop.value) {
    return []
  }
  return sortByLastName(currentWorkshop.value.workshopChildrens);
});

defineEmits(["updateWorkshops"]);
</script>
