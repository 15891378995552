<template>
  <ListPreview
    :list="filteredRatingLists"
    title="Оценочные листы"
    :itemTitleKey="itemTitleKey"
    :link="link"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { computed, onMounted, ref, watch } from "vue";

import { useJournalData } from "@/composables/journalData";
import { usePopupStore } from "@/store/popup";
import { useRoute } from "vue-router";

import ListPreview from "./ListPreview.vue";

const route = useRoute();
const { showError } = usePopupStore();

const { removeEmptyArrays, getAllData } = useJournalData();

const { journal, isForeignJournal, journalDaysWithIds } = storeToRefs(
  useSubjectsJournalStore()
);

const link = computed(() => {
  const name = isForeignJournal.value
    ? "foreign-rating-sheets"
    : "rating-sheets";

  return {
    name: name,
    query: {
      journalId: route.query.journalId,
    },
    params: {
      dayId: journalDaysWithIds.value.length
        ? journalDaysWithIds.value[0].teacherLogDayId
        : null,
    },
  };
});

const itemTitleKey = computed(() =>
  isForeignJournal.value ? "listDescription" : "skillListDescription"
);

const ratingLists = ref([]);

const filteredRatingLists = computed(() => {
  if (!ratingLists.value.length) {
    return [];
  }
  return removeEmptyArrays(ratingLists.value);
});

onMounted(getData);

watch(() => route.query, getData);

async function getData() {
  if (journal.value) {
    try {
      ratingLists.value = await getAllRatingLists();
    } catch (e) {
      showError(e);
    }
  }
}

async function getAllRatingLists() {
  if (isForeignJournal.value) {
    return getAllData(`foreign_checklist/by_teacher_log_day_id`, journal.value);
  } else {
    return getAllData(
      `teacher/log/skill_list/by_teacher_log_day`,
      journal.value
    );
  }
}
</script>
