<template>
  <LoaderRing v-if="isLoading" />
  <router-view v-else @update="update"></router-view>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { useNotesStore } from "@/store/notes";
import { onMounted, onUnmounted, ref, watch } from "vue";

import { useRoute } from "vue-router";
import { usePopupStore } from "@/store/popup";

import LoaderRing from "@/elements/LoaderRing.vue";

const { setNotes, clearNotes } = useNotesStore();
const { showError } = usePopupStore();

const route = useRoute();
const isLoading = ref(false);

onMounted(update);
onUnmounted(clearNotes);

watch(
  () => route.query,
  (c, p) => {
    if (c.filterBySearch !== p.filterBySearch) {
      return;
    }

    update();
  }
);

async function update() {
  if (route.query.filterByGroupId) {
    getGroupNotes();
  }
}

async function getGroupNotes() {
  isLoading.value = true;
  try {
    const groupNotes = await getGroupNotesHandler();
    setNotes(groupNotes.data);
  } catch (e) {
    showError(e);
  } finally {
    isLoading.value = false;
  }
}

async function getGroupNotesHandler() {
  return customAxios.get(
    `/tutor/note/class/by_group_id/` + route.query.filterByGroupId
  );
}
</script>
