<template>
  <v-select
    v-if="studiosFilter.length"
    :items="studiosFilter"
    v-model="studio"
    variant="outlined"
    density="compact"
    hide-details
    placeholder="Я-студия"
    item-title="iStudioName"
    item-value="id"
    bg-color="white"
  ></v-select>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { customAxios } from "@/service/customAxios";
import { useRoute } from "vue-router";
import { removeFilterFromURL, pushFilterToURL } from "@/service/router";

const route = useRoute();
const studio = ref(null);
const studiosFilter = ref([]);

onMounted(async () => {
  await getStudiosForFilter();
  if (route.query.filterByStudio) {
    studio.value = Number(route.query.filterByStudio);
  }
});

watch(studio, (id) =>
  id
    ? pushFilterToURL({ name: "filterByStudio", value: id })
    : removeFilterFromURL("filterByStudio")
);

async function getStudiosForFilter() {
  try {
    const r = await getStudiosForFilterHandler();

    setStudios(r.data);
  } catch (e) {
    console.log(e);
  }
}

function setStudios(studios) {
  studiosFilter.value = studios;
}

async function getStudiosForFilterHandler() {
  return customAxios.get("/i_studio/for_report");
}
</script>
