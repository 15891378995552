<template>
  <v-select
    v-if="childrenGroupsSelector.length"
    v-model="selectedChildrenGroupId"
    :items="childrenGroupsSelector"
    variant="outlined"
    density="compact"
    hide-details
    placeholder="Класс не выбран"
    clearable
    persistent-clear
    bg-color="white"
  ></v-select>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { useChildrenGroupsData } from "@/composables/childrenGroups";
import { onMounted, watch } from "vue";
import { pushFilterToURL, removeFilterFromURL } from "@/service/router";

const route = useRoute();

const {
  getActiveChildGroups,
  selectedChildrenGroupId,
  childrenGroupsSelector,
} = useChildrenGroupsData();

watch(selectedChildrenGroupId, (id) => {
  id
    ? pushFilterToURL({ name: "filterByGroupId", value: id })
    : removeFilterFromURL("filterByGroupId");
});

watch(() => route.query.filterByGroupId, setCurrentFilter);

onMounted(async () => {
  await getActiveChildGroups();

  setCurrentFilter();
});

function setCurrentFilter() {
  if (!route.query.filterByGroupId || !childrenGroupsSelector.value.length) {
    return;
  }

  const isExisted =
    childrenGroupsSelector.value.findIndex(
      (v) => v.value === Number(route.query.filterByGroupId)
    ) >= 0;

  selectedChildrenGroupId.value = isExisted
    ? Number(route.query.filterByGroupId)
    : childrenGroupsSelector.value[0].value;
}
</script>
