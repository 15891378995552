<template>
  <tr class="tr-actions">
    <slot></slot>
    <td>
      <slot name="btns"></slot>
      <AppButton @click="$emit('editItem', item)" class="bordered-item tr-actions__edit">
        Редактировать
      </AppButton>
      <AppButton
        @click="$emit('removeItem', id, item)"
        class="bordered-item tr-actions__delete"
      >
        Удалить
      </AppButton>
    </td>
  </tr>
</template>

<script>
import AppButton from "@/elements/AppButton.vue";

export default {
  emits: ["editItem", "removeItem"],
  components: { AppButton },
  props: {
    id: { type: Number, required: true },
    item: { type: Object, required: true },
  },
};
</script>

<style lang="scss">
.tr-actions {
  button.bordered-item {
    flex-grow: 1;
    margin: 4px;
    transition: all 0.3s;
    justify-content: center;
  }

  &__delete.bordered-item {
    border-color: red;
    color: red;

    &:hover {
      background-color: rgba(red, 0.2);
    }

    &:active {
      background-color: rgba(red, 0.3);
    }
  }

  &__edit.bordered-item {
    border-color: #BA68C8;
    color: #BA68C8;

    &:hover {
      background-color: rgba(#BA68C8, 0.2);
    }

    &:active {
      background-color: rgba(#BA68C8, 0.3);
    }
  }
}
</style>
