import { defineStore } from "pinia";
import { customAxios } from "@/service/customAxios";

export const useNewsStore = defineStore("news", {
  state: () => {
    return {
      news: [],
    };
  },
  getters: {
    getNewsById(state) {
      return (id) =>
        state.news.length ? state.news.find((v) => v.id === Number(id)) : null;
    },
  },
  actions: {
    setNews(news) {
      this.news = news;
    },
    async getPublishedNews() {
      return customAxios.get(`/news/published`);
    },
    async getAllNews() {
      return customAxios.get("/news/all");
    },
  },
});
