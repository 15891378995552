<template>
  <div>
    <div class="page-wrapper">
      <div class="d-flex justify-space-between flex-wrap mb-3">
        <h3>{{ journal.teacherLogName }}</h3>

        <router-link
          :to="{
            name: 'subject-journal-edit',
            query: { journalId: $route.query.journalId },
          }"
          class="bordered-item"
          >Редактировать</router-link
        >
      </div>

      <p v-if="journal.teacherLogDescription" class="mb-3">
        {{ journal.teacherLogDescription }}
      </p>

      <ul class="d-flex flex-wrap">
        <li class="gradient-chip">
          {{ journal.personGroup.personGroupName }}
        </li>
        <li class="gradient-chip">
          {{ journal.scheduleClass.scheduleClassName }}
        </li>
        <li class="gradient-chip">занятий: {{ journal.days.length }}</li>
        <li class="gradient-chip">
          {{ getDateFormatWithoutTime(journal.beginDateTime) }} -
          {{ getDateFormatWithoutTime(journal.endDateTime) }}
        </li>
      </ul>
    </div>

    <RatingSheetsPreview class="mt-3"/>
    <DictationsListsPreview class="mt-3" />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { useDatesChanger } from "@/composables/datesChanger";

import RatingSheetsPreview from "./RatingSheetsPreview.vue";
import DictationsListsPreview from "./DictationsListsPreview.vue";

const { journal } = storeToRefs(useSubjectsJournalStore());

const { getDateFormatWithoutTime } = useDatesChanger();
</script>
