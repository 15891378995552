<template>
  <v-card class="ma-2">
    <v-dialog v-model="showLoader"><LoaderRing ></LoaderRing></v-dialog>
    <v-card-title class="card_ligth_header">
      <h3 class="ma-2">Помещения</h3>
    </v-card-title>
    <v-card-title class="card_ligth_header" style="min-height: 5vh">
      <v-row>
        <v-col cols="12">
          <v-text-field
              v-model="locationSearchBody"
              label="Найти"
              variant="outlined"
              clearable
              hint="Введите ключевое слово для поиска"
              rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
              @click="showLocationDialog = true"
              variant="outlined"
              color="primary"
              class="mb-4"
          >
            Добавить
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <div class="d-sm-none d-flex align-center flex-column">
        <v-card
            class="ma-5 mx-5 w-100"
            v-for="item in locationPaginatedData"
            :key="item"
        >
          <v-card-title class="card_ligth_header">
            <h5>
              Помещение №
              {{ locationPaginatedData.indexOf(item) + 1 }}
            </h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list-item lines="two">
              <v-list-item-title>Название:</v-list-item-title>
              <v-list-item-subtitle>{{
                  item.locationName
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Описание:</v-list-item-title>
              <v-list-item-subtitle>{{
                  item.locationDescription
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Добавлено:</v-list-item-title>
              <v-list-item-subtitle>{{
                  item.createDate.replace("T", " ")
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Ответственный:</v-list-item-title>
              <v-list-item-subtitle>
                <v-chip size="small"
                >{{
                    item.locationOwner.firstname.replace("T", " ")
                  }}
                  {{
                    item.locationOwner.lastname.replace("T", " ")
                  }}</v-chip
                >
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Параметры:</v-list-item-title>
              <v-list-item-subtitle>
                <v-chip-group active-class="primary--text" column>
                  <v-chip
                      size="small"
                      v-for="pg in item.locationOptions"
                      :key="pg"
                  >{{ pg.optionName }}</v-chip
                  >
                </v-chip-group>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item lines="two">
              <v-list-item-title>Активно:</v-list-item-title>
              <v-list-item-subtitle
              ><v-chip size="small" v-if="item.isActive">Да</v-chip
              ><v-chip v-else size="small"
              >Нет</v-chip
              ></v-list-item-subtitle
              >
            </v-list-item>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-btn
                @click="editLocation(item)"
                class="ma-1"
                color="primary"
                variant="outlined"
                rounded
            >
              Редактировать
            </v-btn>
            <v-btn
                @click="deleteLocation(item)"
                class="ma-1"
                color="warning"
                variant="outlined"
                rounded
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div class="d-none d-sm-block">
        <v-table fixed-header class="h-100">
          <thead>
          <tr>
            <th class="text-left">
              <b>№</b>
            </th>
            <th class="text-left">
              <b>Название</b>
            </th>
            <th class="text-left">
              <b>Описание</b>
            </th>
            <th class="text-left">
              <b>Добавлено</b>
            </th>
            <th class="text-left">
              <b>Ответственный</b>
            </th>
            <th class="text-left">
              <b>Параметры</b>
            </th>
            <th class="text-left">
              <b>Активно</b>
            </th>
            <th class="text-left">
              <b>Действие</b>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in locationPaginatedData" :key="item">
            <td>
              <p>{{ locationPaginatedData.indexOf(item) + 1 }}</p>
            </td>
            <td>
              <p>{{ item.locationName }}</p>
            </td>
            <td>
              <p>{{ item.locationDescription }}</p>
            </td>
            <td>
              <p>{{ item.createDate.replace("T", " ") }}</p>
            </td>
            <td>
              <p>
                {{ item.locationOwner.firstname }}
                {{ item.locationOwner.lastname }}
              </p>
            </td>
            <td>
              <v-chip-group active-class="primary--text" column>
                <v-chip
                    size="small"
                    v-for="pg in item.locationOptions"
                    :key="pg"
                >{{ pg.optionName }}</v-chip
                >
              </v-chip-group>
            </td>
            <td>
              <v-chip size="small" v-if="item.isActive">Да</v-chip
              ><v-chip v-else size="small">Нет</v-chip>
            </td>
            <td>
              <p>
                <v-btn
                    @click="editLocation(item)"
                    class="ma-1"
                    color="primary"
                    variant="outlined"
                    rounded
                >
                  Редактировать
                </v-btn>
                <v-btn
                    @click="deleteLocation(item)"
                    class="ma-1"
                    color="warning"
                    variant="outlined"
                    rounded
                >
                  Удалить
                </v-btn>
              </p>
            </td>
          </tr>
          </tbody>
        </v-table>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions
        class="card_ligth_actions"
        style="min-height: 5vh"
    >
      <v-container class="text-center">
        <v-pagination
            v-model="locationPage"
            :length="locationPageCount"
            :total-visible="3"
            size="small"
        ></v-pagination>
      </v-container>
    </v-card-actions>
    <v-dialog v-model="showLocationDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Помещение</h5>
        </v-card-title>
        <v-card-text>
          <v-form ref="locationForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="Название*"
                      v-model="locationName"
                      :rules="locationNameRules"
                      hint="Укажите название"
                      :counter="30"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      label="Описание*"
                      v-model="locationDescription"
                      :rules="locationDescriptionRules"
                      :counter="255"
                      hint="Укажите описание"
                      required
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="locationOwner"
                      :items="scheduleEventOrganizers"
                      outlined
                      dense
                      chips
                      small-chips
                      closable-chips
                      item-title="firstname"
                      item-value="id"
                      :rules="locationOwnerRules"
                      label="Ответственный"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.firstname + ' ' + item.raw.lastname"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.firstname + ' ' + item.raw.lastname"
                          :subtitle="item.raw.username"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="locationOptionsList"
                      :items="activeLocationOptions"
                      outlined
                      dense
                      chips
                      small-chips
                      closable-chips
                      multiple
                      :rules="locationOptionsListRules"
                      label="Параметры помещения"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.optionName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.optionName"
                          :subtitle="item.raw.optionDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-row class="px-6" fluid>
                    <v-switch
                        v-model="isActive"
                        :label="'Активно'"
                        color="success"
                    >
                    </v-switch>
                    <v-chip v-if="isActive">Да</v-chip
                    ><v-chip v-else>Нет</v-chip>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn rounded variant="outlined" @click="closeLocationDialog">
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveLocation">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import LoaderRing from "@/elements/LoaderRing.vue";
import _ from "lodash";
import {customAxios} from "@/service/customAxios";

export default {
  name: "ScheduleLocationLIst",
  props: [],
  components: {LoaderRing },
  data: () => ({
    noteCount:10,
    isActive:false,
    locationSearchBody:'',
    showLoader: false,
    showLocationDialog: false,
    locations:[],
    locationOptions:[],


    locationPage: 1,
    locationName: "",
    locationDescription: "",
    locationOwner: null,
    locationNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    locationOptionsListRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) => (v && v.length > 0) || "Значение до должно превышать 30 символов!",
    ],
    locationDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 255) || "Значение до должно превышать 255 символов!",
    ],
    locationOwnerRules: [
      (v) => (!!v && true) || "Поле обязательно для заполнения!",
      //,
      //v => v===undefined || 'Поле обязательно для заполнения!'
    ],

    locationId: undefined,
    locationOwnerId: undefined,
    locationOptionsList: [],

    users:[],


  }),
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
    activeLocationOptions() {
      let sourceLocationOptions = this.locationOptions;
      return sourceLocationOptions.filter((item) => item.isActive === true);
    },
    scheduleEventOrganizers(){
      let  source = this.users;

      let result = [];
      source.forEach(item =>{
        if(item.roles.includes("ROLE_TEACHER") ||
            item.roles.includes("ROLE_TUTOR") ||
            item.roles.includes("ROLE_MASTER") ){
          result.push(item);
        }
      })
      return result.sort(
          dynamicSort(
              "lastname",
              1
          )
      );

    },

    filteredLocations() {
      const ss = this.locationSearchBody.toLowerCase();

      let tempLocations = this.locations;

      return tempLocations.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );
    },
    locationPageCount() {
      let l = this.filteredLocations.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },
    locationPaginatedData() {
      const start = (this.currentLocationPage - 1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.filteredLocations.slice(start, end);
    },
    currentLocationPage() {
      if (this.locationPageCount < this.locationPage) {
        return 1;
      } else {
        return this.locationPage;
      }
    },





  },
  methods: {
    editLocation(item) {
      this.locationName = item.locationName;
      this.locationDescription = item.locationDescription;
      this.isActive = item.isActive;
      this.locationOwnerId = item.locationOwnerId;
      this.locationOwner = item.locationOwner;
      this.showLocationDialog = true;
      this.locationId = item.id;
      this.locationOptionsList = item.locationOptions;
    },
    deleteLocation(item) {

      customAxios
          .delete("/schedule/location/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              this.locations.splice(this.locations.indexOf(item), 1);
            } else {
              alert("Что-то пошло не так");
              console.log(response);
            }
          })
       
    },
    closeLocationDialog() {
      this.locationName = "";
      this.locationDescription = "";
      this.isActive = false;
      this.showLocationDialog = false;
      this.locationOwner = null;
      this.locationOwnerId = undefined;
      this.locationId = undefined;
      this.locationOptionsList = [];
    },
    saveLocation() {
      this.$refs.locationForm.validate().then((result) => {
        if (result.valid) {
          if (this.locationOwner instanceof Object) {
            this.locationOwner = this.locationOwner.id;
          }

          let location = {
            locationName: capitalizeFirstLetter(this.locationName),
            locationDescription: capitalizeFirstLetter(
                this.locationDescription
            ),
            locationOwnerId: this.locationOwner,
            isActive: this.isActive,
            locationOptions: this.locationOptionsList,
          };

          if (this.locationId) {
            customAxios
                .put(
                    "/schedule/location/" + this.locationId,
                    location
                )
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.locations, response.data.id);
                    this.locations.splice(index, 1, response.data);
                    this.locationName = "";
                    this.locationDescription = "";
                    this.isActive = false;
                    this.showLocationDialog = false;
                    this.locationOwner = null;
                    this.locationOwnerId = null;
                    this.locationId = null;
                    this.locationOptionsList = null;
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

          } else {
            customAxios
                .post("/schedule/location", location)
                .then((response) => {
                  if (response.status === 201) {
                    this.locations.push(response.data);
                    this.locationName = "";
                    this.locationDescription = "";
                    this.isActive = false;
                    this.showLocationDialog = false;
                    this.locationOwner = null;
                    this.locationOwnerId = null;
                    this.locationOptionsList = null;
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

          }
        }
      });
    },

  },
  created: function () {
    this.showLoader = true;
    function getLocations(customAxios) {
      return customAxios.get("/schedule/location");
    }
    function getLocationOption(customAxios) {
      return customAxios.get("/schedule/location/option");
    }

    function getUsers(customAxios) {
      return customAxios.get("/user/minimal");
    }


    Promise.all([
      getLocations(customAxios),
      getLocationOption(customAxios),
      getUsers(customAxios),
    ]).then((results) => {

      if (results[0].status === 200) {
        this.locations = results[0].data;
      }
      if (results[1].status === 200) {
        this.locationOptions = results[1].data;
      }
      if (results[2].status === 200) {
        this.users = results[2].data;
      }
      this.showLoader = false;
    })
        .catch(() => {
          this.showLoader = false;
          alert("Ошибка загрузки данных, обратитесь к администратору системы!");
        });

  },


  setup() {
    return {};
  },
};
function dynamicSort(property, sortOrder) {
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result;
    if (property === "eventName") {
      result =
          a.scheduleClass.scheduleClassName < b.scheduleClass.scheduleClassName
              ? -1
              : a.scheduleClass.scheduleClassName >
              b.scheduleClass.scheduleClassName
                  ? 1
                  : 0;
      return result * sortOrder;
    } else if (property === "eventOrganizer") {
      result =
          a.eventOrganizer.firstname < b.eventOrganizer.firstname
              ? -1
              : a.eventOrganizer.firstname > b.eventOrganizer.firstname
                  ? 1
                  : 0;
      return result * sortOrder;
    } else {
      result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    }
  };
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

</script>

<style lang="scss">
.form-element {
  box-shadow: $shadow;
  display: grid;
  grid-template-columns: 40% 1fr;
  overflow: hidden;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: $gradient-1;
  }

  &__card.v-card {
    box-shadow: none;
    padding: 30px;
  }

  &__title.v-card-title {
    color: $main;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
  }

  .v-text-field__input {
    input::placeholder {
      font-size: 18px;
      line-height: 22px;
    }

    label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #6e6d94;
    }

    .v-field__outline {
      color: rgba(#6e6d94, 0.5);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;

    &__img {
      display: none;
    }
  }
}
</style>
