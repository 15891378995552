import UserNews from "@/pages/UserNews.vue";
import ArticlePage from "@/pages/ArticlePage.vue";

import MyCognationEditor from "@/cognations/MyCognationEditor.vue";
import CognationsLayout from "@/cognations/CognationsLayout.vue";
import CognationsList from "@/cognations/CognationsList.vue";
import CognationPage from "@/cognations/CognationPage";

import ChildSchedule from "@/usersPages/ChildSchedule.vue";
import ChildMetricsBySybject from "@/usersPages/ChildMetricsBySybject.vue";
import ChildDiary from "@/usersPages/ChildDiary.vue";
import LevelsChart from "@/usersPages/LevelsChart.vue";
import CognationsForParent from "../cognations/CognationsForParent.vue";

import UserNotesLayout from "@/components/notes/UserNotesLayout.vue";
import ChildNotesList from "@/components/notes/ChildNotesList.vue";
import ChildNotePage from "@/components/notes/ChildNotePage.vue";
import GroupNotePage from "@/components/notes/GroupNotePage.vue";

import EReportByChild from "@/reports/english/EReportByChild.vue";
import ChildWorkshops from "@/usersPages/ChildWorkshops.vue";

const childWorkshops = {
  name: "child-workshops",
  path: "/child-workshops",
  props: (route) => ({
    filterByChildId: route.query.filterByChildId,
  }),
  component: ChildWorkshops,
};

const news = [
  { name: "news", path: "/news", component: UserNews },
  {
    name: "news-article",
    path: "/news/article/:articleId",
    component: ArticlePage,
  },
];

const cognations = {
  name: "cognations",
  path: "/my-cognations",
  component: CognationsLayout,
  props: (route) => ({
    filter: route.query.filter,
  }),
  children: [
    {
      name: "cognation-page",
      path: "cognation/:cognationId",
      component: CognationPage,
    },
    {
      name: "cognation-create",
      path: "create",
      component: MyCognationEditor,
    },
    {
      name: "cognation-edit",
      path: "edit/:cognationId",
      component: MyCognationEditor,
    },
    {
      name: "cognations-list",
      path: "list",
      component: CognationsList,
    },
    {
      name: "cognations-list-mine",
      path: "list/mine",
      component: CognationsList,
    },
  ],
};

const cognationsForParent = {
  name: "my-children-cognations",
  path: "/my-children-cognations",
  component: CognationsForParent,
  props: (route) => ({
    filter: route.query.filter,
  }),
  children: [
    {
      name: "my-child-cognation-page",
      path: "cognation/:cognationId",
      component: CognationPage,
    },
    {
      name: "my-children-cognations-list",
      path: "list",
      component: CognationsList,
    },
  ],
};

const diary = { name: "diary", path: "/diary", component: ChildDiary };

const schedule = {
  name: "schedule",
  path: "/schedule",
  component: ChildSchedule,
};

const metrics = [
  { name: "metrics", path: "/metrics", component: LevelsChart },
  {
    name: "metrics_by_subject",
    path: "/metrics_by_subject",
    component: ChildMetricsBySybject,
  },
];

const notes = [
  {
    path: "/notes",
    component: UserNotesLayout,
    props: (route) => ({
      filterByChildId: route.query.filterByChildId,
      noteId: route.query.noteId,
    }),
    children: [
      {
        name: "user-notes-list",
        path: "list",
        component: ChildNotesList,
      },
      {
        name: "child-note-page",
        path: "note",
        component: ChildNotePage,
        props: (route) => ({
          noteId: route.query.noteId,
          groupId: route.query.groupId,
        }),
      },
      {
        name: "users-group-note-page",
        path: "group-note",
        component: GroupNotePage,
      },
    ],
  },
];

const englishReport = {
  component: EReportByChild,
  name: "users-english-report",
  path: "/english-report",
};

const common = [
  ...news,
  ...metrics,
  ...notes,
  englishReport,
  childWorkshops,
  schedule,
  diary,
];

export const parent = [cognationsForParent];

export const child = [cognations];

export const users = [...parent, ...child, ...common];
