<template>
  <div>
    <PageTitle
      :title="`SWOT ${
        currentSwot !== null
          ? `для ${currentSwot.child.lastname} ${currentSwot.child.firstname}`
          : ''
      }`"
    />

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <div class="my-3">
          <label>Создание анализа:</label>
          <ol class="pl-5">
            <li>
              Если у ребёнка уже <strong>есть</strong> SWOT анализ - создастся
              <strong>дополнительный</strong> на основе существующего
            </li>
            <li>
              Если у ребёнка уже еще <strong>нет</strong> SWOT анализа -
              создастся
              <strong>новый</strong>
            </li>
          </ol>

          <p class="mt-2">
            Список всех созданных копий анализа можно посмотреть
            <router-link
              :to="{
                name: 'child-swots',
                params: { childId: $route.params.childId },
              }"
              class="bordered-item"
              >по этой ссылке</router-link
            >
          </p>
        </div>
        <div class="my-3">
          <label>Редактирование анализа:</label>
          <ol class="pl-5">
            <li>
              <strong>Описание анализа</strong> - сохраняется автоматически
              после редактирования
            </li>
            <li>
              <strong>Пункты разделов</strong> (Сильные стороны/Слабые
              стороны...) - сохраняются по нажатию на кнопку Сохранить в
              модальном окне
            </li>
          </ol>
        </div>
        <div class="my-3">
          <label>Удаление анализа:</label>
          <ol class="pl-5">
            <li>
              Кнопка <strong>Удалить анализ</strong> - внизу страницы (под
              анализом)
            </li>
          </ol>
        </div>
      </template>
    </TogglerOfWrapper>

    <div class="d-flex justify-end mb-2">
      <router-link
        :to="{
          name: 'child-swots',
          params: { childId: $route.params.childId },
        }"
        class="bordered-item"
        >К списку анализов</router-link
      >
    </div>

    <div class="s-report">
      <div v-if="tagsTitles.length" class="s-report__tags my-3">
        <STag
          v-for="t in tagsTitles"
          :key="t.id"
          :tag="t"
          @show-points="getPointsByTagId"
          :note="currentNote"
        />
      </div>

      <div class="page-wrapper my-3">
        <h4>Управление активностью анализа</h4>
        <v-switch
          v-model="isActive"
          :label="`${isActive ? `Активен` : `Не активен`}`"
          color="success"
          hide-details
        />
      </div>

      <div class="page-wrapper my-3">
        <h4>Описание анализа</h4>

        <v-textarea
          v-model="noteBody"
          maxlength="255"
          variant="outlined"
          counter="255"
          hide-details
        ></v-textarea>
      </div>

      <div class="page-wrapper my-3">
        <div v-if="currentNote">
          <h4>Сейчас открыта копия анализа</h4>

          <SNotePreview :item="currentNote" class="my-3" />
        </div>

        <h4>Информация об анализе</h4>
        <SPreview :item="swot" />
      </div>

      <div class="d-flex justify-center flex-wrap mt-5">
        <AppButton @click="create" class="bordered-item ma-1"
          >Создать новый анализ</AppButton
        >

        <AppButton @click="createCopy" class="bordered-item ma-1"
          >Создать копию анализа</AppButton
        >
        <AppButton
          @click="removeSwot"
          class="bordered-item bordered-item--danger ma-1"
          >Удалить анализ</AppButton
        >
      </div>
    </div>

    <v-dialog v-model="visible" persistent scrollable>
      <SPoints
        @close="visible = false"
        @save="editNotePoints"
        :tagId="currentTagPopup"
        :note="currentNote"
      />
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, onMounted } from "vue";
import { useSwotStore } from "@/store/swot";

import { storeToRefs } from "pinia";
import { usePopupStore } from "@/store/popup";
import { customAxios } from "@/service/customAxios";

import { debounce } from "@/util/common";
import { useRoute, useRouter } from "vue-router";
import { useDatesChanger } from "@/composables/datesChanger";

import AppButton from "@/elements/AppButton.vue";
import STag from "./STag.vue";
import SPoints from "./SPoints.vue";

import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import SPreview from "./SPreview.vue";
import PageTitle from "@/elements/PageTitle.vue";

import SNotePreview from "./SNotePreview.vue";

const emits = defineEmits(["update"]);

const { tagsTitles, swot, currentSwot, currentNote } = storeToRefs(
  useSwotStore()
);
const { setDateTimeNoSeconds } = useDatesChanger();

const { showConfirmPopup, closePopup, showError, showToastPopup } =
  usePopupStore();
const router = useRouter();
const route = useRoute();
const { setPoints, setTagsTitles } = useSwotStore();

const noteBody = ref("Описание анализа");
const isActive = ref(currentSwot.value.isActive);

const visible = ref(false);
const currentTagPopup = ref(null);
// const loadingStatus = ref("loading");

const debouncedEditNoteText = debounce(editNoteBody, 1000);

watch(isActive, editActiveStatus);
watch(noteBody, debouncedEditNoteText);
watch(() => route.query.noteId, setCurrentData);
watch(currentNote, setCurrentData);

onMounted(async () => {
  setCurrentData();
  await getTags();
});

async function getTags() {
  const r = await getTagsHandler();
  setTagsTitles(r.data);
}

async function getTagsHandler() {
  return customAxios.get("/tutor/swot/tag/type");
}

function setCurrentData() {
  if (!currentNote.value) {
    return;
  }

  noteBody.value = currentNote.value.noteBody;
}

async function removeSwot() {
  try {
    const res = await customConfirm();

    if (res === "no") {
      closePopup();
    } else {
      closePopup();
      await removeSwotHandler();
      router.push({
        name: "child-swots",
        params: { childId: route.params.childId },
      });
      showToastPopup("Запись удалена");
    }
  } catch (error) {
    showError(error);
  }
}

async function customConfirm() {
  return showConfirmPopup("Удалить SWOT?");
}

async function getPointsByTagId(id) {
  currentTagPopup.value = id;

  try {
    const r = await getPointsByTagIdHandler(id);
    setPoints(r.data);
    visible.value = true;
  } catch (error) {
    showError(error);
  }
}

async function editActiveStatus() {
  if (
    currentSwot.value.isActive === isActive.value ||
    noteBody.value.trim().length === 0
  ) {
    return;
  }

  try {
    await editNoteHandler({
      isActive: isActive.value,
    });
    showToastPopup("Статус активности изменён");
  } catch (error) {
    showError(error);
  }
}

async function editNoteBody() {
  if (
    !noteBody.value.trim().length ||
    noteBody.value === currentNote.value.noteBody
  ) {
    return;
  }

  try {
    await editNoteHandler();
  } catch (error) {
    showError(error);
  }
}

async function editNotePoints(points) {
  if (!noteBody.value.trim().length) {
    return;
  }

  try {
    await editNoteHandler({
      tagList: points,
    });
    visible.value = false;
    currentTagPopup.value = null;
    emits("update");
  } catch (error) {
    showError(error);
  }
}

async function createCopy() {
  try {
    await createCopyHandler();
    emits("update");
  } catch (error) {
    console.log(error);
  }
}

async function create() {
  try {
    await createHandler();
    // getActiveSwotData();
  } catch (error) {
    showError(error);
  }
}

async function getPointsByTagIdHandler(id) {
  return customAxios.get("/tutor/swot/tag/" + id);
}

async function createCopyHandler() {
  return customAxios.post("/tutor/swot/note", {
    isLastVersion: false,
    noteBody: noteBody.value,
    // tagList: points !== undefined && points,
    tutorSwotAnalysisId: currentSwot.value.id,
  });
}

async function editNoteHandler(options) {
  let data = {
    tutorSwotAnalysisId: currentSwot.value.id,
    noteBody: noteBody.value,
  };

  if (options !== undefined) {
    data = { ...data, ...options };
  }
  return customAxios.put("/tutor/swot/note/" + currentNote.value.id, data);
}

async function createHandler() {
  return customAxios.post("/tutor/swot", {
    analysisDateTime: setDateTimeNoSeconds(new Date()),
    childId: Number(route.params.childId),
    isActive: true,
    tagList: [],
  });
}

async function removeSwotHandler() {
  return customAxios.delete("/tutor/swot/" + currentSwot.value.id);
}
</script>

<style lang="scss">
.s-report {
  &__tags {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}
</style>
