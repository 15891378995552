<template>
  <div>
    <PageTitle title="Дневник" />

    <div class="page-wrapper">
      <div class="g-col g-col--2-1fr">
        <FilterByChild v-if="isParent" />
        <WeekRangeFilter v-model="range" :clearable="false" />
      </div>
    </div>

    <StatusWrapper
      :loadingStatus="loadingStatus"
      :isDataExisted="diaryDays.length > 0"
    >
      <AppButton
        class="accent-button my-4"
        @click="downloadExcel(title, 'diary-table')"
        >Скачать расписание</AppButton
      >

      <table class="hidden" id="diary-table">
        <tbody v-for="(day, i) in diaryDays" :key="i">
          <tr>
            <td>
              {{ getDateFormatWithoutTime(day.dayDate) }}
            </td>
          </tr>
          <tr v-for="(l, i) in day.lessons" :key="i">
            <td>{{ getTimeFromDate(l.dayDate) }}</td>
            <td>
              <p>{{ l.subjectName }}</p>
            </td>
          </tr>
          <tr v-if="day.lessons.length === 0">
            В этот день нет уроков
          </tr>
        </tbody>
      </table>
      <div class="mt-2">
        <DiaryDay v-for="d in diaryDays" :key="d.id" :day="d" />
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { watch, ref, computed, onMounted } from "vue";
import { useAuthStore } from "@/store/auth";

import { useDatesChanger } from "@/composables/datesChanger";
import { useUserData } from "@/composables/userData";
import { usePopupStore } from "@/store/popup";

import { useDiaryData } from "@/composables/diary";

import PageTitle from "@/elements/PageTitle.vue";
import AppButton from "@/elements/AppButton.vue";
import WeekRangeFilter from "@/components/WeekRangeFilter.vue";

// eslint-disable-next-line no-unused-vars
import DiaryDay from "./components/DiaryDay.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";
import FilterByChild from "@/components/filtersElements/FilterByChild.vue";
import { useRoute } from "vue-router";
import { pushFilterToURL } from "@/service/router";

// eslint-disable-next-line no-unused-vars
const { setDateTime, getDateFormatWithoutTime, getTimeFromDate } =
  useDatesChanger();
const { downloadExcel } = useUserData();
const { getCurrentWeek } = useDatesChanger();

const { updateDiaryHandler, getSortedDiaryDays } = useDiaryData();
const { showError } = usePopupStore();
const { isParent, userId, isChild } = useAuthStore();

const route = useRoute();

const loadingStatus = ref("nothing");
const diary = ref(null);
const range = ref(getCurrentWeek());

const title = computed(
  () =>
    "расписание" +
    getDateFormatWithoutTime(range.value[0]) +
    "-" +
    getDateFormatWithoutTime(range.value[1])
);

const diaryDays = computed(() => {
  if (diary.value === null) {
    return [];
  }

  return getSortedDiaryDays(diary.value);
});

onMounted(async () => {
  if (isChild) {
    pushFilterToURL({ name: "filterByChildId", value: userId });
  }

  getDiaryByChildId();
});

watch(range, getDiaryByChildId);
watch(() => route.query.filterByChildId, getDiaryByChildId);

async function getDiaryByChildId() {
  if (!range.value.length || !route.query.filterByChildId) {
    return;
  }
  loadingStatus.value = "loading";

  try {
    const r = await updateDiaryHandler({
      beginPeriodDateTime: setDateTime(range.value[0]),
      endPeriodDateTime: setDateTime(range.value[1]),
      childId: Number(route.query.filterByChildId),
    });
    diary.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}
</script>
