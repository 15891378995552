import { useRoute } from "vue-router";
import { sortByDate } from "@/util/common";
import { ref } from "vue";
import { customAxios } from "@/service/customAxios";

export function useNotes() {
  const route = useRoute();
  const paginatedList = ref([]);

  async function getChildrenNotesHandler(childId) {
    return customAxios.get(
      `/tutor/note/child/for_parent/${childId}`
    );
  }

  function getFilteredSortedItems(notes) {
    if (!notes.length) {
      return [];
    }

    const filtered = notes.filter((n) => filterNote(n));

    return sortNotes(filtered);
  }

  function filterNote(n) {
    if (route.query.filterByGroupId && !findExistedNotesByGroupId(n)) {
      return false;
    }

    if (route.query.filterByChildId && !findExistedNotesByChildId(n)) {
      return false;
    }

    if (route.query.filterByAuthorId && !findExistedNotesByAuthorId(n)) {
      return false;
    }

    if (route.query.filterBySearch && !findExistedNotesBySearch(n)) {
      return false;
    }

    if (route.query.filterByRecepient !== "all" && !getRecepient(n)) {
      return false;
    }

    if (route.query.filterByEmployee  && !findExistedNotesByEmployee(n)) {
      return false;
    }

    return true;
  }

  function findExistedNotesByEmployee(n) {
    return n.childNote.creatorId === Number(route.query.filterByEmployee)
  }

  // вынести
  function getRecepient(n) {
    if (
      route.query.filterByRecepient === "individual" &&
      n.isGroupComment === true
    ) {
      return false;
    }

    if (
      route.query.filterByRecepient === "common" &&
      n.isGroupComment === false
    ) {
      return false;
    }

    return true;
  }

  function findExistedNotesByGroupId(n) {
    if (!n.childGroup && !n.child) {
      return true;
    }

    if (n.childGroup) {
      return n.childGroupId === Number(route.query.filterByGroupId);
    }

    if (n.child && n.child.personGroups) {
      return n.child.personGroups.find(
        (g) => g.id === Number(route.query.filterByGroupId)
      );
    }
  }

  function findExistedNotesByChildId(n) {
    if (!n.childId) {
      return true;
    }
    return n.childId === Number(route.query.filterByChildId);
  }

  function findExistedNotesByAuthorId(n) {
    return n.creator.id === Number(route.query.filterByAuthorId);
  }

  function findExistedNotesBySearch(n) {
    let body = "";

    if (n.noteBody) {
      body = n.noteBody.toLowerCase();
    }

    if (n.childNote && n.childNote.noteBody) {
      body = n.childNote.noteBody.toLowerCase();
    }

    if (n.classNote && n.classNote.noteBody) {
      body = n.classNote.noteBody.toLowerCase();
    }

    return body.includes(route.query.filterBySearch.toLowerCase());
  }

  function sortNotes(list) {
    if (!list.length) {
      return [];
    }
    return sortByDate(list, route.query.sortByDate);
  }

  return {
    paginatedList,
    getFilteredSortedItems,
    getChildrenNotesHandler,
  };
}
