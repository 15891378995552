import { useRoute } from "vue-router";

export function useWorkshopFilters() {
  const route = useRoute();

  function filterByCommonFilters(workshop) {
    if (route.query.filterByInput && hasNotTextFromSearch(workshop)) {
      return false;
    }

    if (route.query.filterByDay && hasNotSelectedDay(workshop)) {
      return false;
    }

    if (route.query.filterByPeriod && outOfPeriod(workshop)) {
      return false;
    }

    if (route.query.filterByType && hasNotSelectedType(workshop)) {
      return false;
    }

    return true;
  }

  function hasNotSelectedDay(workshop) {
    if (!workshop.workshopDays) {
      return;
    }

    return (
      workshop.workshopDays.find((d) => {
        const day = new Date(d.eventDateTimeBegin);

        const mondayWednesdayFriday = [1, 3, 5];
        const tuesdayThursday = [2, 4];

        if (Number(route.query.filterByDay) === 1) {
          return mondayWednesdayFriday.includes(day.getDay());
        }

        return tuesdayThursday.includes(day.getDay());
      }) === undefined
    );
  }

  function outOfPeriod(workshop) {
    const startDay = new Date(workshop.beginDateTime);
    const today = new Date();
    const finishDay = new Date(workshop.endDateTime);

    if (finishDay < today) {
      return true;
    }

    if (route.query.filterByPeriod === "current" && startDay > today) {
      return true;
    }

    if (route.query.filterByPeriod === "future" && startDay < today) {
      return true;
    }

    return false;
  }

  function hasNotSelectedType(workshop) {
    return workshop.workshopType !== Number(route.query.filterByType);
  }

  function hasNotTextFromSearch(workshop) {
    const input = route.query.filterByInput.toLowerCase().trim();
    const isWorkshopName = workshop.workshopName.toLowerCase();

    const isWorkshopMaster =
      `${workshop.master.firstname} ${workshop.master.lastname} ${workshop.master.secname}`.toLowerCase();

    return !isWorkshopName.includes(input) && !isWorkshopMaster.includes(input);
  }

  return { filterByCommonFilters };
}
