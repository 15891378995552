<template>
  <div class="widget w-cognations">
    <div>
      <h4 class="mb-5">Моё достижение</h4>

      <StatusWrapper
        :isDataExisted="cognation !== null"
        :loadingStatus="loadingStatus"
      >
        <div class="w-cognations__item pa-2">
          <p>{{ cognation.cognationName }}</p>
          <p>{{ cognation.cognationDescription }}</p>
          <p>{{ getDateFormatWithoutTime(cognation.cognationCreateDate) }}</p>
        </div>
      </StatusWrapper>
    </div>
    <WLink
      :link="{ name: 'cognations-list' }"
      title="К остальным достижениям"
      class="mt-2"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useCognations } from "@/composables/cognations";
import { useDatesChanger } from "@/composables/datesChanger";

import { usePopupStore } from "@/store/popup";

import StatusWrapper from "@/components/StatusWrapper.vue";
import WLink from "./WLink.vue";
import { getNewestItem } from "@/util/common";

const { getMyCognationsHandler } = useCognations();
const { getDateFormatWithoutTime } = useDatesChanger();
const { showError } = usePopupStore();

const list = ref([]);
const loadingStatus = ref("loading");

onMounted(getMyCognations);

async function getMyCognations() {
  try {
    const r = await getMyCognationsHandler();
    list.value = r.data;
  } catch (error) {
    showError(error);
  } finally {
    loadingStatus.value = "loaded";
  }
}

const cognation = computed(() => {
  if (!list.value.length) {
    return null;
  }

  let newest;

  list.value.forEach((v) => {
    if (!newest) {
      newest = v;
    } else {
      newest = getNewestItem(v, newest);
    }
  });

  return newest;
});
</script>

<style lang="scss">
.w-cognations {
  &__item {
    outline: 3px solid #fff;
    border-radius: 8px;
    display: grid;
    grid-template-rows: 1fr max-content max-content;
    grid-gap: 16px;

    p {
      font-weight: 600;

      &:nth-child(2) {
        font-style: italic;
      }

      &:last-child {
        font-size: 14px;
        line-height: 20px;
        text-align: right;
      }
    }
  }
}
</style>
