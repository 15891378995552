import { customAxios } from "@/service/customAxios";
import { computed, ref } from "vue";

export function useSchoolSubjectsData() {
  const selectedSchoolSubjectId = ref(null);
  const schoolSubjects = ref([]);

  function createSubjectsSelector(objects) {
    return objects.map((v) => {
      return {
        title: v.scheduleClassName,
        value: v.id,
      };
    });
  }

  const subjectsSelector = computed(() => {
    if (!schoolSubjects.value.length) {
      return [];
    }
    return createSubjectsSelector(schoolSubjects.value);
  });

  async function getSchoolSubjects() {
    const response = await customAxios.get("/schedule/class/active/for_log");
    schoolSubjects.value = response.data;
  }

  function setSubjectTitle(id) {
    if (!subjectsSelector.value.length) {
      return "";
    }

    const item = subjectsSelector.value.find((v) => v.id === id);

    return item ? item.title : "Предмет без названия";
  }

  return {
    setSubjectTitle,
    schoolSubjects,
    subjectsSelector,
    selectedSchoolSubjectId,
    getSchoolSubjects,
  };
}
