<template>
  <div>
    <PhotoTip />

    <CustomForm title="моего достижения" @create="create" @remove="remove">
      <div
        :class="{
          error: v$.name.$errors.length,
          success: !v$.name.$errors.length && v$.name.$model && v$.name.$dirty,
        }"
        class="form__item"
      >
        <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
          >Заголовок*</v-label
        >
        <v-text-field
          v-model="v$.name.$model"
          type="text"
          @focus="v$.name.$touch"
          placeholder="Введи текст заголовка"
          maxlength="100"
          variant="outlined"
          hide-details
        />

        <div
          class="input-errors"
          v-for="error of v$.name.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div
        :class="{
          error: v$.description.$errors.length,
          success:
            !v$.description.$errors.length &&
            v$.description.$model &&
            v$.description.$dirty,
        }"
        class="form__item"
      >
        <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
          >Описание</v-label
        >

        <v-textarea
          v-model="v$.description.$model"
          type="text"
          @focus="v$.description.$touch"
          placeholder="Введи oписание достижения"
          maxlength="255"
          variant="outlined"
          counter="255"
          hide-details
        ></v-textarea>

        <div
          class="input-errors"
          v-for="error of v$.description.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div
        :class="{
          error: v$.category.$errors.length,
          success:
            !v$.category.$errors.length &&
            v$.category.$model &&
            v$.category.$dirty,
        }"
        class="form__item"
      >
        <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
          >Категория*</v-label
        >
        <v-autocomplete
          v-model="category"
          :items="categories"
          variant="outlined"
          placeholder="Категория"
          item-value="id"
        >
          <template v-slot:selection="{ item }">
            {{ item.raw.name }}
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="item.raw.name"
              :subtitle="item.raw.description"
            ></v-list-item>
          </template>
        </v-autocomplete>

        <div
          class="input-errors"
          v-for="error of v$.category.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <!-- <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
        >Ссылка на сторонний ресурс</v-label
      >
      <v-text-field
        v-model="externalLink"
        type="text"
        placeholder="Введи её сюда"
        variant="outlined"
        hide-details
        class="mb-5"
      /> -->

      <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2">
        * Обязательно заполни эти поля
      </v-label>
    </CustomForm>

    <FileUploader @update="emits('update')" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch, defineEmits } from "vue";
import { required, maxLength, helpers } from "@vuelidate/validators";
import { usePopupStore } from "@/store/popup";

import { useCognationsStore } from "@/store/cognations";
import { customAxios } from "@/service/customAxios";
import { capitalizeFirstLetter } from "@/util/common";

import { useRoute, useRouter } from "vue-router";
import { useCognations } from "@/composables/cognations";

// import FileUploader from "@/components/FileUploader.vue";
import useVuelidate from "@vuelidate/core";
import CustomForm from "@/components/CustomForm.vue";

import PhotoTip from "@/elements/PhotoTip.vue";
import FileUploader from "./FileUploader.vue";

const rules = {
  name: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
  description: {
    max: helpers.withMessage("Не более 255 символов", maxLength(255)),
  },
  category: {
    required: helpers.withMessage("Обязательное поле", required),
  },
};
const emits = defineEmits(["update"]);

const {
  showError,
  showToastPopup,
  showConfirmPopup,
  closePopup,
  showInfoPopup,
} = usePopupStore();

const { getCategoriesHandler } = useCognations();
const { getCognationById } = useCognationsStore();
const cognation = computed(() => getCognationById(route.params.cognationId));

const route = useRoute();
const router = useRouter();
const externalLink = ref("");
const name = ref("");
const description = ref("");
const category = ref(null);
const categories = ref([]);

onMounted(async () => {
  await getCategories();
  setCurrentData();
});

watch(cognation, setCurrentData);

function setCurrentData() {
  if (!cognation.value) {
    return
  }
  name.value = cognation.value.cognationName;
  description.value = cognation.value.cognationDescription;
  category.value = cognation.value.categoryId;
}

const v$ = useVuelidate(rules, {
  name: name,
  description: description,
  category: category,
});

const data = computed(() => ({
  cognationName: capitalizeFirstLetter(name.value),
  cognationDescription: capitalizeFirstLetter(description.value),
  categoryId: category.value,
  externalLink: externalLink.value,
}));

async function create() {
  try {
    await createHandler();
    showToastPopup("Достижение создано");
    router.push({ name: "cognations-list-mine" });
  } catch (error) {
    showError(error);
  }
}

async function createHandler() {
  return customAxios.post("/cognation/my", data.value);
}

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();

      const r = await removeCognation();

      if (r.status === 207) {
        showInfoPopup(r.data.message);
      } else {
        showToastPopup("Достижение удалено");
        router.push({ name: "cognations-list-mine" });
      }
    } else {
      closePopup();
    }
  } catch (e) {
    showError(e);
  }
}

async function customConfirm() {
  return showConfirmPopup("Удалить достижение?");
}

function removeCognation() {
  return customAxios.delete("/cognation/my/" + route.params.cognationId);
}

async function getCategories() {
  try {
    const r = await getCategoriesHandler();
    categories.value = r.data;
  } catch (error) {
    showError(error);
  }
}
</script>
