
<template>
  <v-card class="ma-2">
    <v-dialog v-model="showLoader"><LoaderRing ></LoaderRing></v-dialog>
    <h3 class="ma-2">Заявки на бронирование кабинетов</h3>
  
    <v-card-title class="card_ligth_header" style="min-height: 5vh">
      <v-row>
        <v-col cols="12">
          <v-text-field
              v-model="ticketSearchBody"
              label="Найти"
              variant="outlined"
              clearable
              hint="Введите ключевое слово для поиска"
              rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
              @click="showTicketDialog = true"
              variant="outlined"
              color="primary"
              class="mb-4"
          >
            Добавить
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <div class="d-sm-none d-flex align-center flex-column">
        <v-card
            class="ma-5 mx-5 w-100"
            v-for="item in ticketPaginatedData"
            :key="item"
        >
          <v-card-title class="card_ligth_header">
            <h5>
              Заявка № {{ ticketPaginatedData.indexOf(item) + 1 }}
            </h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list-item lines="two">
              <v-list-item-title>Заявитель:</v-list-item-title>
              <v-list-item-subtitle
              >{{ item.creator.firstname }}
                {{ item.creator.lastname }}</v-list-item-subtitle
              >
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Заявка:</v-list-item-title>
              <v-list-item-subtitle>{{
                  item.scheduleTicketDescription
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Кабинет:</v-list-item-title>
              <v-list-item-subtitle>{{
                  item.scheduleLocation.locationName
                }}</v-list-item-subtitle>
            </v-list-item>

            <v-list-item lines="two">
              <v-list-item-title>Добавлено:</v-list-item-title>
              <v-list-item-subtitle>{{
                  item.createDate.replace("T", " ")
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title
              >Время бронирования:</v-list-item-title
              >
              <v-list-item-subtitle
              >{{ item.beginDateTime.replace("T", " ") }} -
                {{
                  item.endDateTime.replace("T", " ")
                }}</v-list-item-subtitle
              >
            </v-list-item>

            <v-list-item lines="two">
              <v-list-item-title>Статус:</v-list-item-title>
              <v-list-item-subtitle
              ><v-chip
                  size="small"
                  v-if="item.scheduleTicketStatus === 1"
              >Создана</v-chip
              ></v-list-item-subtitle
              >
              <v-list-item-subtitle
              ><v-chip
                  size="small"
                  v-if="item.scheduleTicketStatus === 2"
              >Одобрена</v-chip
              ></v-list-item-subtitle
              >
              <v-list-item-subtitle
              ><v-chip
                  size="small"
                  v-if="item.scheduleTicketStatus === 3"
              >Отвергнута</v-chip
              ></v-list-item-subtitle
              >
            </v-list-item>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-btn
                @click="editTicket(item)"
                class="ma-1"
                color="primary"
                variant="outlined"
                rounded
            >
              Редактировать
            </v-btn>
            <v-btn
                @click="deleteTicket(item)"
                class="ma-1"
                color="warning"
                variant="outlined"
                rounded
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div class="d-none d-sm-block">
        <v-table fixed-header class="h-100">
          <thead>
          <tr>
            <th class="text-left">
              <b>№</b>
              <font-awesome-icon
                  v-on:click="byTicketId"
                  icon="fa-solid fa-sort"
              />
            </th>
            <th class="text-left">
              <b>Заявитель </b>
              <font-awesome-icon
                  v-on:click="byTicketCreator"
                  icon="fa-solid fa-sort"
              />
            </th>
            <th class="text-left">
              <b>Заявка </b>
              <font-awesome-icon
                  v-on:click="byTicketDescription"
                  icon="fa-solid fa-sort"
              />
            </th>
            <th class="text-left">
              <b>Кабинет </b>
              <font-awesome-icon
                  v-on:click="byTicketLocation"
                  icon="fa-solid fa-sort"
              />
            </th>
            <th class="text-left">
              <b>Добавлено </b>
              <font-awesome-icon
                  v-on:click="byTicketCreateDate"
                  icon="fa-solid fa-sort"
              />
            </th>
            <th class="text-left">
              <b>Время бронирования </b>
              <font-awesome-icon
                  v-on:click="byTicketBeginDateTime"
                  icon="fa-solid fa-sort"
              />
            </th>
            <th class="text-left">
              <b>Статус </b>
              <font-awesome-icon
                  v-on:click="byScheduleTicketStatus"
                  icon="fa-solid fa-sort"
              />
            </th>
            <th class="text-left">
              <b>Действие</b>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in ticketPaginatedData" :key="item">
            <td>
              <p>{{ ticketPaginatedData.indexOf(item) + 1 }}</p>
            </td>
            <td>
              <p>
                {{ item.creator.firstname }}
                {{ item.creator.lastname }}
              </p>
            </td>
            <td>
              <p>{{ item.scheduleTicketDescription }}</p>
            </td>
            <td>
              <p>{{ item.scheduleLocation.locationName }}</p>
            </td>
            <td>
              <p>{{ item.createDate.replace("T", " ") }}</p>
            </td>
            <td>
              <p>
                {{ item.beginDateTime.replace("T", " ") }} -
                {{ item.endDateTime.replace("T", " ") }}
              </p>
            </td>
            <td>
              <v-chip
                  size="small"
                  v-if="item.scheduleTicketStatus === 1"
              >Создана</v-chip
              >
              <v-chip
                  size="small"
                  v-if="item.scheduleTicketStatus === 2"
              >Одобрена</v-chip
              >
              <v-chip
                  size="small"
                  v-if="item.scheduleTicketStatus === 3"
              >Отвергнута</v-chip
              >
            </td>
            <td>
              <v-btn
                  @click="editTicket(item)"
                  class="ma-1"
                  color="primary"
                  variant="outlined"
                  rounded
              >
                Редактировать
              </v-btn>
              <v-btn
                  @click="deleteTicket(item)"
                  class="ma-1"
                  color="warning"
                  variant="outlined"
                  rounded
              >
                Удалить
              </v-btn>
            </td>
          </tr>
          </tbody>
        </v-table>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions
        class="card_ligth_actions"
        style="min-height: 5vh"
    >
      <v-container class="text-center">
        <v-pagination
            v-model="ticketPage"
            :length="ticketPageCount"
            :total-visible="3"
            size="small"
        ></v-pagination>
      </v-container>
    </v-card-actions>
    <v-dialog v-model="showTicketDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Заявка на бронирование</h5>
        </v-card-title>
        <v-card-text>
          <v-form ref="scheduleTicketForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="creator"
                      :items="scheduleEventOrganizers"
                      :rules="ticketCreatorRules"
                      outlined
                      dense
                      chips
                      no-filter
                      small-chips
                      closable-chips
                      item-title="firstname"
                      item-value="id"
                      label="Заявитель"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.firstname + ' ' + item.raw.lastname"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.firstname + ' ' + item.raw.lastname"
                          :subtitle="item.raw.username"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="scheduleLocation"
                      :items="activeLocations"
                      :rules="ticketLocationRules"
                      outlined
                      chips
                      small-chips
                      closable-chips
                      item-title="locationName"
                      item-value="id"
                      label="Помещение*"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.locationName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.locationName"
                          :subtitle="item.raw.locationDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="ticketClass"
                      :items="activeClasses"
                      :rules="ticketClassRules"
                      outlined
                      chips
                      small-chips
                      closable-chips
                      item-title="scheduleClassName"
                      item-value="id"
                      label="Занятие*"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.scheduleClassName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.scheduleClassName"
                          :subtitle="item.raw.scheduleClassDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      label="Описание*"
                      v-model="scheduleTicketDescription"
                      :rules="scheduleTicketDescriptionRules"
                      :counter="255"
                      hint="Укажите описание заявки"
                      required
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-label>Дата начала*</v-label>
                  <DatePicker
                      v-model="beginDateTime"
                      autoApply
                      textInput
                      locale="ru"
                      v-on:change="generateEndDate"
                  />
                  <v-alert
                      v-if="ticketBeginNoDateAlert === true"
                      border="top"
                      color="red lighten-2"
                      dark
                  >
                    Укажите дату!
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-label>Дата окончания*</v-label>
                  <DatePicker
                      v-model="endDateTime"
                      autoApply
                      textInput
                      locale="ru"
                      required
                  />
                  <v-alert
                      v-if="ticketDateAlert === true"
                      border="top"
                      color="red lighten-2"
                      dark
                  >
                    Окончание раньше начала!
                  </v-alert>
                  <v-alert
                      v-if="ticketEndNoDateAlert === true"
                      border="top"
                      color="red lighten-2"
                      dark
                  >
                    Укажите дату!
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="scheduleTicketStatus"
                      :items="ticketStatusList"
                      :rules="ticketStatusRules"
                      aria-required="true"
                      filled
                      chips
                      closable-chips
                      color="blue-grey-lighten-2"
                      label="Статус заявки"
                      item-title="scheduleTicketStatusName"
                      item-value="scheduleTicketStatus"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.scheduleTicketStatusName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.scheduleTicketStatusName"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn variant="outlined" rounded @click="closeTicket">
            Отмена
          </v-btn>
          <v-btn variant="outlined" rounded @click="saveTicket">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-card>
</template>

<script>
import LoaderRing from "@/elements/LoaderRing.vue";
import _ from "lodash";
import {customAxios} from "@/service/customAxios";

export default {
  name: "ScheduleLocationTicketList",
  props: [],
  components: {LoaderRing },
  data: () => ({
    noteCount:10,
    isActive:false,
    showLoader:false,
    users:[],
    locations:[],
    scheduleClasses:[],
    classTypePage:1,


    scheduleTickets: [],
    ticketSearchBody: "",
    showTicketDialog: false,
    ticketSortKey: "id",
    ticketSortOrder: 1,
    ticketPage: 1,
    lastTicketSortKey: "id",

    creator: null,
    ticketCreatorRules: [
      (v) => (!!v && true) || "Поле обязательно для заполнения!",
      //,
      //v => v===undefined || 'Поле обязательно для заполнения!'
    ],
    scheduleTicketDescriptionRules: [
      (v) => (!!v && true) || "Поле обязательно для заполнения!",
      //,
      // v => (v && v.length <= 255) || 'Значение до должно превышать 255 символов!',
    ],
    scheduleTicketDescription: "",
    beginDateTime: undefined,
    endDateTime: undefined,
    scheduleTicketStatus: null,
    scheduleLocation: null,
    scheduleTicketId: undefined,
    ticketStatusList: [
      { scheduleTicketStatus: 1, scheduleTicketStatusName: "Создан" },
      { scheduleTicketStatus: 2, scheduleTicketStatusName: "Одобрена" },
      { scheduleTicketStatus: 3, scheduleTicketStatusName: "Отвергнута" },
    ],
    ticketLocationRules: [
      (v) => (!!v && true) || "Поле обязательно для заполнения!",
      //,
      //v => v===undefined || 'Поле обязательно для заполнения!'
    ],
    ticketStatusRules: [
      (v) => (!!v && true) || "Поле обязательно для заполнения!",
      //,
      //v => v===undefined || 'Поле обязательно для заполнения!'
    ],
    ticketEndNoDateAlert: false,
    ticketDateAlert: false,
    ticketBeginNoDateAlert: false,

    ticketClass: null,
    ticketClassRules: [
      (v) => (!!v && true) || "Поле обязательно для заполнения!",
    ],


  }),
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
    scheduleEventOrganizers(){
      let  source = this.users;

      let result = [];
      source.forEach(item =>{
        if(item.roles.includes("ROLE_TEACHER") ||
            item.roles.includes("ROLE_TUTOR") ||
            item.roles.includes("ROLE_MASTER") ){
          result.push(item);
        }
      })
      return result.sort(
          dynamicSort(
              "lastname",
              1
          )
      );

    },
    activeLocations() {
      let sourceLocations = this.locations;
      return sourceLocations.filter((item) => item.isActive === true);
    },
    activeClasses() {
      let classes = this.scheduleClasses;
      return classes.filter((item) => item.isActive === true);
    },
    filteredTickets() {
      const ss = this.ticketSearchBody.toLowerCase();

      let tempTickets = this.scheduleTickets;

      return tempTickets.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );
    },
    sortedTickets() {
      let tempTickets = this.filteredTickets;
      return tempTickets.sort(
          dynamicSort(this.ticketSortKey, this.ticketSortOrder)
      );
    },
    ticketPageCount() {
      let l = this.filteredTickets.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },
    ticketPaginatedData() {
      const start = (this.currentTicketPage - 1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.sortedTickets.slice(start, end);
    },
    currentTicketPage() {
      if (this.ticketPageCount < this.ticketPage) {
        return 1;
      } else {
        return this.ticketPage;
      }
    },



  },
  methods: {
    byTicketCreator() {
      if (this.lastTicketSortKey.includes("creatorId")) {
        this.ticketSortOrder = this.ticketSortOrder * -1;
      }
      this.ticketSortKey = "creatorId";
      this.lastTicketSortKey = this.ticketSortKey;
    },
    byTicketDescription() {
      if (this.lastTicketSortKey.includes("scheduleTicketDescription")) {
        this.ticketSortOrder = this.ticketSortOrder * -1;
      }
      this.ticketSortKey = "scheduleTicketDescription";
      this.lastTicketSortKey = this.ticketSortKey;
    },
    byTicketLocation() {
      if (this.lastTicketSortKey.includes("scheduleLocationId")) {
        this.ticketSortOrder = this.ticketSortOrder * -1;
      }
      this.ticketSortKey = "scheduleLocationId";
      this.lastTicketSortKey = this.ticketSortKey;
    },
    byTicketCreateDate() {
      if (this.lastTicketSortKey.includes("createDate")) {
        this.ticketSortOrder = this.ticketSortOrder * -1;
      }
      this.ticketSortKey = "createDate";
      this.lastTicketSortKey = this.ticketSortKey;
    },
    byTicketBeginDateTime() {
      if (this.lastTicketSortKey.includes("beginDateTime")) {
        this.ticketSortOrder = this.ticketSortOrder * -1;
      }
      this.ticketSortKey = "beginDateTime";
      this.lastTicketSortKey = this.ticketSortKey;
    },
    byScheduleTicketStatus() {
      if (this.lastTicketSortKey.includes("scheduleTicketStatus")) {
        this.ticketSortOrder = this.ticketSortOrder * -1;
      }
      this.ticketSortKey = "scheduleTicketStatus";
      this.lastTicketSortKey = this.ticketSortKey;
    },
    byTicketId() {
      if (this.lastTicketSortKey.includes("id")) {
        this.ticketSortOrder = this.ticketSortOrder * -1;
      }
      this.ticketSortKey = "id";
      this.lastTicketSortKey = this.ticketSortKey;
    },


    generateEndDate() {
      let beginDateInMs = Date.parse(this.eventBeginDateTime);
      if (this.scheduleClass !== null) {
        if (this.scheduleClass.scheduleClassDuration > 0) {
          let durationInMs = this.scheduleClass.scheduleClassDuration * 60000;
          let endDateInMs = beginDateInMs + durationInMs;
          this.eventEndDateTime = new Date(endDateInMs);
        } else {
          let durationInMs = 40 * 60000;
          let endDateInMs = beginDateInMs + durationInMs;
          this.eventEndDateTime = new Date(endDateInMs);
        }
      } else {
        let durationInMs = 40 * 60000;
        let endDateInMs = beginDateInMs + durationInMs;
        this.eventEndDateTime = new Date(endDateInMs);
      }
    },
    editTicket(item) {
      this.scheduleClassName = item.scheduleClassName;
      this.creator = item.creator;
      this.scheduleTicketDescription = item.scheduleTicketDescription;
      this.scheduleLocation = item.scheduleLocation;
      this.ticketClass = item.scheduleClass;
      this.createDate = item.createDate;
      this.beginDateTime = item.beginDateTime;
      this.endDateTime = item.endDateTime;
      this.scheduleTicketStatus = item.scheduleTicketStatus;
      this.scheduleTicketId = item.id;
      this.showTicketDialog = true;
    },
    deleteTicket(item) {
      if (confirm("Вы точно хотите удалить тип занятия?")) {
        customAxios.delete( "/schedule/ticket/" + item.id)
            .then((response) => {
              if (response.status === 202) {
                this.scheduleTickets.splice(
                    this.scheduleTickets.indexOf(item),
                    1
                );
              } else {
                console.log(response);
              }
            })
      }
    },
    saveTicket() {
      this.$refs.scheduleTicketForm.resetValidation();

      this.ticketBeginNoDateAlert =
          this.beginDateTime === null || this.beginDateTime === undefined;

      this.ticketEndNoDateAlert =
          this.endDateTime === null || this.endDateTime === undefined;

      if (!this.ticketEndNoDateAlert && !this.ticketBeginNoDateAlert) {
        this.ticketDateAlert =
            Date.parse(this.beginDateTime) >= Date.parse(this.endDateTime);
      }

      this.$refs.scheduleTicketForm.validate().then((result) => {
        console.log("result: " + result);
        if (result.valid) {
          if (
              !this.ticketEndNoDateAlert &&
              !this.ticketBeginNoDateAlert &&
              !this.ticketDateAlert
          ) {
            if (this.creator instanceof Object) {
              this.creator = this.creator.id;
            }
            if (this.scheduleLocation instanceof Object) {
              this.scheduleLocation = this.scheduleLocation.id;
            }
            if (this.ticketClass instanceof Object) {
              this.ticketClass = this.ticketClass.id;
            }
            let ticket = {
              scheduleTicketDescription: capitalizeFirstLetter(
                  this.scheduleTicketDescription
              ),
              creatorId: this.creatorId,
              scheduleLocationId: this.scheduleLocation,
              scheduleClassId: this.ticketClass,
              beginDateTime: this.beginDateTime,
              endDateTime: this.endDateTime,
              scheduleTicketStatus: this.scheduleTicketStatus,
            };

            if (this.scheduleTicketId) {
              customAxios.put("/schedule/ticket/" + this.scheduleTicketId, ticket)
                  .then((response) => {
                    if (response.status === 202) {
                      let index = getIndex(
                          this.scheduleTickets,
                          response.data.id
                      );
                      this.scheduleTickets.splice(index, 1, response.data);
                      this.showTicketDialog = false;
                      this.scheduleTicketDescription = "";
                      this.creatorId = null;
                      this.scheduleLocationId = null;
                      this.beginDateTime = null;
                      this.ticketClass = null;
                      this.endDateTime = null;
                      this.creator = null;
                      this.scheduleLocation = null;
                      this.scheduleTicketStatus = null;
                      this.scheduleTicketId = null;
                    } else {
                      alert("Что-то пошло не так");
                      console.log(response);
                    }
                  })

            } else {
              customAxios.post( "/schedule/ticket", ticket)
                  .then((response) => {
                    if (response.status === 201) {
                      this.scheduleTickets.push(response.data);
                      this.showTicketDialog = false;
                      this.scheduleTicketDescription = "";
                      this.creatorId = null;
                      this.scheduleLocationId = null;
                      this.beginDateTime = null;
                      this.ticketClass = null;
                      this.endDateTime = null;
                      this.creator = null;
                      this.scheduleLocation = null;
                      this.scheduleTicketStatus = null;
                    } else {
                      alert("Что-то пошло не так");
                      console.log(response);
                    }
                  })

            }
          }
        }
      });
    },
    closeTicket() {
      this.showTicketDialog = false;
      this.scheduleTicketDescription = "";
      this.creatorId = null;
      this.scheduleLocationId = null;
      this.beginDateTime = null;
      this.ticketClass = null;
      this.endDateTime = null;
      this.creator = null;
      this.scheduleLocation = null;
      this.scheduleTicketStatus = null;
      this.scheduleTicketId = null;
    },




  },
  created: function () {
    this.showLoader = true;

    function getUsers(customAxios) {
      return customAxios.get( "/user/minimal");
    }

    function getScheduleClasses(customAxios) {
      return customAxios.get( "/schedule/class");
    }

    function getLocations(customAxios) {
      return customAxios.get( "/schedule/location");
    }
    function getScheduleTickets(customAxios) {
      return customAxios.get( "/schedule/ticket");
    }




    Promise.all([
      getUsers(customAxios),
      getScheduleClasses(customAxios),
      getLocations(customAxios),
      getScheduleTickets(customAxios),
    ]).then((results) => {

      if (results[0].status === 200) {
        this.users = results[0].data;
      }
      if (results[1].status === 200) {
        this.scheduleClasses = results[1].data;
      }
      if (results[2].status === 200) {
        this.locations = results[2].data;
      }
      if (results[3].status === 200) {
        this.scheduleTickets = results[3].data;
      }

      this.showLoader = false;
    })
        .catch(() => {
          this.showLoader = false;
          alert("Ошибка загрузки данных, обратитесь к администратору системы!");
        });

  },


  setup() {
    return {};
  },
};
function dynamicSort(property, sortOrder) {
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result;
    if (property === "eventName") {
      result =
          a.scheduleClass.scheduleClassName < b.scheduleClass.scheduleClassName
              ? -1
              : a.scheduleClass.scheduleClassName >
              b.scheduleClass.scheduleClassName
                  ? 1
                  : 0;
      return result * sortOrder;
    } else if (property === "eventOrganizer") {
      result =
          a.eventOrganizer.firstname < b.eventOrganizer.firstname
              ? -1
              : a.eventOrganizer.firstname > b.eventOrganizer.firstname
                  ? 1
                  : 0;
      return result * sortOrder;
    } else {
      result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    }
  };
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

</script>

<style lang="scss">
.form-element {
  box-shadow: $shadow;
  display: grid;
  grid-template-columns: 40% 1fr;
  overflow: hidden;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: $gradient-1;
  }

  &__card.v-card {
    box-shadow: none;
    padding: 30px;
  }

  &__title.v-card-title {
    color: $main;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
  }

  .v-text-field__input {
    input::placeholder {
      font-size: 18px;
      line-height: 22px;
    }

    label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #6e6d94;
    }

    .v-field__outline {
      color: rgba(#6e6d94, 0.5);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;

    &__img {
      display: none;
    }
  }
}
</style>
