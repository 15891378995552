import { customAxios } from "@/service/customAxios";
import { useIamStudioStore } from "@/store/iamstudio";
import { usePopupStore } from "@/store/popup";
import { ref } from "vue";

export function useIstudio() {
  const { setListOfStudios } = useIamStudioStore();
  const { showError } = usePopupStore();

  const loading = ref(true);

  async function updateStudios() {
    loading.value = true;

    try {
      const r = await updateStudiosHandler();
      setListOfStudios(r.data);
    } catch (e) {
      showError(e);
    } finally {
      loading.value = false;
    }
  }

  async function updateStudiosHandler() {
    return customAxios.get("/i_studio/");
  }

  return { updateStudios, loading };
}
