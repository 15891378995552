<template>
  <TableRowActions
    v-if="mode === 'showing' && subjectsSelector.length > 1"
    :id="item.id"
    :item="item"
    @removeItem="$emit('removeObject', $event)"
    @editItem="$emit('editItem', $event)"
  >
    <td>{{ idx }}</td>
    <td>{{ setSubjectTitle(item.classId) }}</td>
    <td>{{ item.realLevel }}</td>
    <td>{{ item.planLevel }}</td>
  </TableRowActions>

  <tr v-if="mode !== 'showing' && subjectsSelector.length > 1">
    <td>
      <span v-if="idx">{{ idx }}</span>
    </td>

    <td class="w-100" v-if="mode === 'creating'">
      <v-select
        :items="subjectsSelector"
        v-model="selectedSchoolSubjectId"
        label="Предмет"
        variant="outlined"
      ></v-select>
    </td>
    <td v-else>{{ setSubjectTitle(item.classId) }}</td>
    <td>
      <AppSelector
        :options="ratingSelector"
        v-model="realLevel"
        class="w-100"
      />
    </td>
    <td>
      <AppSelector
        :options="ratingSelector"
        v-model="planLevel"
        class="w-100"
      />
    </td>
    <td>
      <AppButton v-if="mode === 'editing'" @click="editObject(item.id)">
        <CIcon icon="check-icon" />
      </AppButton>

      <AppButton
        v-if="mode === 'creating'"
        @click="
          $emit('saveObject', {
            planLevel: planLevel,
            realLevel: realLevel,
            classId: selectedSchoolSubjectId,
          })
        "
      >
        <CIcon icon="check-icon" />
      </AppButton>
    </td>
    <td>
      <AppButton @click="cancelEditing">
        <CIcon icon="cross-icon" />
      </AppButton>
    </td>
  </tr>
</template>

<script>
import { ref, onMounted } from "vue";

import AppSelector from "@/elements/AppSelector.vue";
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";
import TableRowActions from "@/components/TableRowActions.vue";
import { useSchoolSubjectsData } from "@/composables/schoolSubjects";

const ratingSelector = [
  {
    id: 1,
    value: 50,
    title: "50",
  },
  {
    id: 2,
    value: 55,
    title: "55",
  },
  {
    id: 3,
    value: 60,
    title: "60",
  },
  {
    id: 4,
    value: 65,
    title: "65",
  },
  {
    id: 5,
    value: 70,
    title: "70",
  },
  {
    id: 6,
    value: 75,
    title: "75",
  },
  {
    id: 7,
    value: 80,
    title: "80",
  },
  {
    id: 8,
    value: 85,
    title: "85",
  },
  {
    id: 9,
    value: 90,
    title: "90",
  },
  {
    id: 10,
    value: 95,
    title: "95",
  },
  {
    id: 11,
    value: 100,
    title: "100",
  },
];

export default {
  props: {
    item: {
      type: Object,
      required: false,
    },
    idx: {
      type: Number,
      required: false,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  emits: [
    "editItem",
    "removeObject",
    "saveObject",
    "close",
    "editObject",
    "cancelEditing",
  ],
  components: {
    AppSelector,
    TableRowActions,

    AppButton,
    CIcon,
  },
  setup(props, ctx) {
    const { setSubjectTitle, subjectsSelector, selectedSchoolSubjectId, getSchoolSubjects } =
      useSchoolSubjectsData();

    onMounted(getSchoolSubjects);

    const planLevel = ref(100);
    const realLevel = ref(100);

    function cancelEditing() {
      planLevel.value = props.item.planLevel;
      realLevel.value = props.item.realLevel;

      ctx.emit("cancelEditing");
    }

    function editObject(id) {
      console.log(id);
      ctx.emit("editObject", {
        id: id,
        options: {
          planLevel: planLevel.value,
          realLevel: realLevel.value,
          classId: selectedSchoolSubjectId.value,
        },
      });
    }

    onMounted(() => {
      if (props.item) {
        planLevel.value = props.item.planLevel;
        realLevel.value = props.item.realLevel;
        selectedSchoolSubjectId.value = props.item.classId;
      }
    });

    return {
      editObject,
      cancelEditing,
      setSubjectTitle,
      realLevel,
      planLevel,
      subjectsSelector,
      selectedSchoolSubjectId,
      ratingSelector,
    };
  },
};
</script>
