<template>
  <div>
    <PageTitle
      :title="`Заметка о ребенке ${
        note && note.childNote.child
          ? `${note.childNote.child.lastname} ${note.childNote.child.firstname}`
          : ``
      }`"
    />

    <div v-if="note">
      <div class="page-wrapper">
        <div v-html="note.childNote.noteBody"></div>
      </div>

      <div class="page-wrapper mt-3">
        <h3>Файлы</h3>

        <FilesPreview
          :files="note.childNote.fileList"
          :links="{
            downloadUrl: '/tutor/note/child/file/',
            deleteUrl: '/tutor/note/child/files/delete/',
          }"
        />
      </div>
      <div v-if="note.childNote.creator" class="page-wrapper my-3">
        <div class="d-flex flex-wrap">
          <div class="my-2 mr-4">
            <p class="text-grey">Автор</p>
            <span>
              {{ note.childNote.creator.firstname }}
              {{ note.childNote.creator.secname }}
              {{ note.childNote.creator.lastname }}</span
            >
          </div>

          <div class="my-2">
            <p class="text-grey">Дата</p>
            <span> {{ getDateFormatWithoutTime(note.noteDateTime) }}</span>
          </div>
        </div>
      </div>
    </div>
    <LoaderRing v-else />
  </div>
</template>

<script setup>
import { useNotesStore } from "@/store/notes";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

import { computed } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

import LoaderRing from "@/elements/LoaderRing.vue";
import PageTitle from "@/elements/PageTitle.vue";
import FilesPreview from "../FilesPreview.vue";

const { getDateFormatWithoutTime } = useDatesChanger();
const { notes } = storeToRefs(useNotesStore());

const route = useRoute();

const note = computed(() => {
  if (!notes.value.length) {
    return null;
  }

  return notes.value.find((v) => v.childNote.id === Number(route.query.noteId));
});
</script>
