<template>
  <CustomForm
    title="оценочного листа"
    @create="create"
    @remove="remove"
    :saveIsDisable="saveIsDisable"
  >
    <div v-if="dateTitle" class="form__item">
      <div class="form__label">Дата</div>
      <v-chip class="pa-2">{{ dateTitle }}</v-chip>
    </div>

    <div v-if="currentList && typeOfWorkTitle" class="form__item">
      <p class="form__label">Тип работы:</p>
      <p>
        {{ typeOfWorkTitle }}
      </p>
    </div>

    <div v-if="!currentList" class="form__item">
      <v-select
        v-model="sheetType"
        :items="typesOfWork"
        variant="outlined"
        density="compact"
        hide-details
        placeholder="Выберите тип работы"
      ></v-select>
    </div>

    <div
      :class="{
        error: v$.name.$errors.length,
        success: !v$.name.$errors.length && v$.name.$model && v$.name.$dirty,
      }"
      class="form__item"
    >
      <p class="form__label">Название:</p>
      <input
        v-model="v$.name.$model"
        type="text"
        @focus="v$.name.$touch"
        maxlength="100"
        class="form__input"
      />

      <div
        :class="{ 'showing-tip': v$.name.$dirty && v$.name.$model.length }"
        class="form__tip-wrapper"
      >
        <div class="form__tip form__tip--counter showing">
          <span>Любые символы </span>
          <span> {{ v$.name.$model.length }} / 100 </span>
        </div>
      </div>

      <div
        class="input-errors"
        v-for="error of v$.name.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <v-switch
      v-model="showResultForParent"
      color="success"
      :label="
        showResultForParent ? 'показывать родителям' : 'не показывать родителям'
      "
    />
  </CustomForm>
</template>

<script setup>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";
import { ref, computed, defineEmits, watch, onMounted } from "vue";

import { storeToRefs } from "pinia";
import { useSubjectsJournalStore } from "@/store/subjects-journal";

import { usePopupStore } from "@/store/popup";
import { useRouter, useRoute } from "vue-router";
import { customAxios } from "@/service/customAxios";

import CustomForm from "@/components/CustomForm.vue";
import { debounce } from "@/util/common";

const typesOfWork = [
  { title: "Проверочная работа", value: 0 },
  { title: "Самостоятельная работа", value: 1 },
  { title: "Диагностическая работа", value: 2 },
];

const rules = {
  name: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
};

const emits = defineEmits(["updateLists"]);

const router = useRouter();
const route = useRoute();

const { closePopup, showError, showToastPopup, showConfirmPopup } =
  usePopupStore();

const { journal, dateFromJournal } = storeToRefs(useSubjectsJournalStore());
const { listById } = useSubjectsJournalStore();

const currentList = computed(() => listById(route.query.listId));

const typeOfWorkTitle = computed(() => {
  const type = typesOfWork.find((v) => v.value === currentList.value.listType);
  return type ? type.title : "";
});

const dateTitle = computed(() => dateFromJournal.value(route.query.dayId));

const sheetType = ref(currentList.value ? currentList.value.listType : null);

const name = ref("");
const showResultForParent = ref(false);

onMounted(setCurrentData);

function setCurrentData() {
  if (currentList.value) {
    name.value = currentList.value.skillListDescription;
    showResultForParent.value = currentList.value.showResultForParent;
  }
}

const v$ = useVuelidate(rules, {
  name: name,
});

const saveIsDisable = computed(() => {
  return (
    !name.value || name.value.length === 0 || v$.value.name.$errors.length > 0
  );
});

const data = computed(() => ({
  showResultForParent: showResultForParent.value,
  skillListDescription: name.value,
  listType: sheetType.value,
  teacherLogId: journal.value.id,
  teacherLogDayId: Number(route.query.dayId),
}));

const debounceUpdate = debounce(editSheet, 1000);

watch(name, async (n) => {
  if (currentList.value && n !== currentList.value.skillListDescription) {
    debounceUpdate();
  }
});
watch(showResultForParent, async (n) => {
  if (currentList.value && n !== currentList.value.showResultForParent) {
    editSheet();
  }
});

async function editSheet() {
  if (route.query.listId) {
    try {
      await editSheetHandler();
      showToastPopup("Изменения сохранены");
    } catch (e) {
      showError(e);
    }
  }
}

async function editSheetHandler() {
  return customAxios.put(`/teacher/log/skill_list/${route.query.listId}`, {
    showResultForParent: showResultForParent.value,
    skillListDescription: name.value,
  });
}

async function create() {
  try {
    await createHandler();
    showToastPopup("Оценочный лист создан");
    emits("updateLists");
    router.push({
      name: "rating-sheets",
      query: { journalId: route.query.journalId, dayId: route.query.dayId },
    });
  } catch (e) {
    showError(e);
  }
}

async function createHandler() {
  return customAxios.post(`/teacher/log/skill_list`, data.value);
}

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();

      await removeList();
      showToastPopup("Оценочный лист удален");
      emits("updateLists");
      router.back();
    } else {
      closePopup();
    }
  } catch (e) {
    showError(e);
  }
}

async function customConfirm() {
  return showConfirmPopup("Удалить Оценочный лист?");
}

async function removeList() {
  return customAxios.delete(`/teacher/log/skill_list/${route.query.listId}`);
}
</script>
