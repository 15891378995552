<template>
  <div class="main-layout" :class="{ 'without-user': !user }">
    <div class="main-layout__content">
      <SideMenu v-if="user" />

      <div
        class="main-layout__page js-scrolling-wrapper"
        :class="{ 'main-layout__wrapper': $route.path !== '/' }"
      >
        <PageHeader />

        <div class="main-layout__view">
          <slot></slot>
        </div>
      </div>
    </div>

    <CustomPopup />
  </div>
</template>

<script setup>
import SideMenu from "@/sidemenu/SideMenu.vue";
import CustomPopup from "@/components/popups/CustomPopup.vue";
import PageHeader from "./PageHeader.vue";

import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";

const { user } = storeToRefs(useAuthStore());
</script>

<style lang="scss">
.main-layout {
  height: 100%;

  &__content {
    display: flex;
    height: 100%;
  }

  &__page {
    width: 100%;
    max-height: 100%;
    overflow-y: scroll;
  }

  &.without-user {
    .main-layout__page {
      max-height: none;
      height: auto;
    }

    &__content {
      display: block;
      height: auto;
    }
  }

  &__wrapper {
    max-width: 1440px;
    margin: 0 auto;
  }

  &__view {
    padding: 8px;
    min-height: 100%;
  }
}
</style>
