<template>
  <v-card>
    <StatusWrapper
      :loadingStatus="loadingStatus"
      :isDataExisted="activeLines.length > 0"
    >
      <v-card-actions class="d-flex justify-end">
        <v-btn
          class="ma-1"
          rounded
          variant="outlined"
          color="primary-dark"
          @click="$emit(`editChildsCompetence`, currentCompetence)"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
      <v-card-title> Критерии ФГОС, ФООП: </v-card-title>
      <v-card-text>
        <v-list v-if="activeLines && activeLines.length">
          <v-list-group v-for="line in activeLines" :key="line.id">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" :title="line.competenceLineName">
              </v-list-item>
            </template>

            <v-list-group
              v-for="chapter in line.competenceChapters"
              :key="chapter.id"
            >
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  :title="chapter.competenceChapterName"
                >
                </v-list-item>
              </template>

              <v-list-item
                v-for="competence in chapter.competences"
                :key="competence.id"
              >
                <label>
                  <input
                    type="checkbox"
                    @click="toggleSkills(competence)"
                    :checked="setCheckedStatus(competence)"
                  />
                  {{ competence.competenceName }}
                </label>
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </v-list>
        <p v-else>Нет привязанных критериев</p>
      </v-card-text>
    </StatusWrapper>
  </v-card>
</template>

<script setup>
import { ref, onMounted, computed, defineProps } from "vue";
import { storeToRefs } from "pinia";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { customAxios } from "@/service/customAxios";

import StatusWrapper from "./StatusWrapper.vue";

const props = defineProps({
  inboxCompetence: {
    type: Array,
    required: false,
  },
});

const { journal } = storeToRefs(useSubjectsJournalStore());

const competenceLines = ref([]);
const currentCompetence = ref([]);

const activeLines = computed(() =>
  competenceLines.value.length
    ? competenceLines.value.filter((v) => v.active === true)
    : []
);
const loadingStatus = ref("loading");

function setCheckedStatus(item) {
  if (!currentCompetence.value.length) {
    return false;
  }

  return currentCompetence.value.findIndex((v) => v.id === item.id) !== -1;
}

function toggleSkills(item) {
  let arr = currentCompetence.value.slice();

  if (arr.find((v) => v.id === item.id)) {
    arr = arr.filter((v) => v.id !== item.id);
  } else {
    arr.push(item);
  }

  currentCompetence.value = arr;
}

onMounted(async () => {
  if (props.inboxCompetence && props.inboxCompetence.length) {
    competenceLines.value.push(props.inboxCompetence);
    loadingStatus.value = "loaded";
  } else {
    getAllCompetenceLines();
  }
});

async function getAllCompetenceLines() {
  try {
    const r = await getAllCompetenceLinesHandler();
    competenceLines.value = r.data;
  } catch (e) {
    console.log(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}

async function getAllCompetenceLinesHandler() {
  return customAxios.get(
    `/schedule/competence/line/by_class/${journal.value.scheduleClassId}`
  );
}
</script>
