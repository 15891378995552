<template>
  <div class="tutor-child-card">
    <PageTitle title="Карточка ученика и представителей" />

    <PersonCard v-if="currentChild" :person="currentChild" />

    <div v-if="parents.length > 0" class="mt-4">
      <PersonCard v-for="p in parents" :key="p.id" :person="p" />
    </div>
  </div>

</template>
<script>
import { useTutorStore } from "@/store/tutor";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import PageTitle from "@/elements/PageTitle.vue";
import PersonCard from "../PersonCard.vue";
import { customAxios } from "@/service/customAxios";
import { onMounted, ref } from "vue";
// const sexOptions = [
//   { id: 1, title: "Не указано", value: 1 },
//   { id: 2, title: "Женский", value: 2 },
//   { id: 3, title: "Мужской", value: 3 },
// ];

export default {
  // emits: ["close"],
  setup() {
    const { setCurrentChild } = useTutorStore();
    const { currentChild } = storeToRefs(useTutorStore());

    const route = useRoute();
    // const childPassword = ref("");

    // const textRules = [
    //   (v) => !!v || "Поле обязательно для заполнения!",
    //   (v) =>
    //     (v && v.length <= 100) || "Значение до должно превышать 100 символов!",
    // ];

    // const user = JSON.parse(localStorage.getItem("user"));

  


    // const password = ref("");
    // const userDialog = ref(false);
    // const userForm = ref(null);

    // function clearUserForm() {
    //   userDialog.value = false;
    //   id.value = "";
    //   lastname.value = "";
    //   firstname.value = "";
    //   secname.value = "";
    //   username.value = "";
    //   personEmail.value = "";
    //   personMobilePhone.value = "";
    //   birthday.value = undefined;
    //   password.value = "";
    // }

    // function editUser(user) {
    //   id.value = user.id;
    //   lastname.value = user.lastname;
    //   firstname.value = user.firstname;
    //   secname.value = user.secname;
    //   username.value = user.username;
    //   personEmail.value = user.personEmail;
    //   personMobilePhone.value = user.personMobilePhone;
    //   birthday.value = user.birthday;
    //   userDialog.value = true;
    // }

    const parents = ref([]);

    onMounted(async () => {
      await updateCurrentChild();
      await getParents();
    });

    async function updateCurrentChild() {
      try {
        const r = await updateCurrentChildHandler();
        setCurrentChild(r.data);
      } catch (e) {
        console.log(e);
      }
    }

    async function updateCurrentChildHandler() {
      return customAxios.get(`/user/${route.params.id}`);
    }

    async function getParents() {
      try {
        const r = await getParentsHandler();
        parents.value = r.data;
      } catch (e) {
        console.log(e);
      }
    }

    async function getParentsHandler() {
      return customAxios.get(`/user/parent/by_child_id/${route.params.id}`);
    }

    // function capitalizeFirstLetter(string) {
    //   return string.charAt(0).toUpperCase() + string.slice(1);
    // }


    // async function saveUser() {
    //   if (userForm.value.validate()) {
    //     navigator.clipboard
    //       .writeText(password.value)
    //       .then(async () => {
    //         try {
    //           const user = {
    //             // password: childPassword.value,
    //             // lastname: capitalizeFirstLetter(lastname.value),
    //             // secname: capitalizeFirstLetter(secname.value),
    //             // firstname: capitalizeFirstLetter(firstname.value),
    //             // username: username.value,
    //             // personEmail: personEmail.value,
    //             // personMobilePhone: personMobilePhone.value,
    //             // birthday: birthday.value,
    //           };
    //           const r = await customAxios.put(
    //             `/user/for_tutor/${id.value}`,
    //             user
    //           );
    //           console.log(r.data);
    //           if (r.status === 202) {
    //             if (password.value.length > 1) {
    //               alert("Пароль успешно сохранен и скопирован!");
    //             }
    //             await updateCurrentChild();
    //             await getParents();
    //           }
    //         } catch (e) {
    //           console.log(e);
    //         }
    //       })
    //       .catch((err) => {
    //         console.log("Something went wrong", err);
    //       });
    //   }
    // }

    // async function updateAvatar(person) {
    //   id.value = person.id;
    //   showCropper.value = true;
    // }



    return {
      currentChild,
      parents,
      // isEditMode,
      // sexOptions,
      // parents,
      // updateAvatar,

      // showCropper,
      // headers,
      // labels,
     
      // childPassword,
      // generatePassword,
      // childParents,
      // formatDateTime,

      // personGroups,
      // password,

      // clearUserForm,
      // userDialog,
      // editUser,
      // saveUser,
      // textRules,
      // userForm,
      // getLoginLink,
      // getDateFormatWithTime,
    };
  },
  components: { PageTitle, PersonCard },
};
</script>
