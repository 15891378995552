<template>
  <td>
    <CommentCell v-model="comment" />
  </td>
</template>

<script setup>
import CommentCell from "@/components/CommentCell.vue";
import { ref, defineEmits, watch, defineProps } from "vue";
import { debounce } from "@/util/common";

const emits = defineEmits(["send"]);
const props = defineProps({
  comment: {
    type: String,
    required: false,
  },
})

const comment = ref(props.comment);

const debouncedEditChild = debounce(send, 1000);

watch(comment, () => {
  debouncedEditChild();
});

function send() {
  emits("send", comment.value);
}
</script>
