<template>
  <div
    class="comment-cell bg-white"
    :class="{
      commentShowen: show,
      hasComment: props.modelValue && props.modelValue.length,
    }"
  >
    <AppButton @click="show = !show">
      <CIcon v-if="show" icon="cross-icon" />
      <CIcon v-else icon="file-icon" />
    </AppButton>

    <div v-if="show">
      <textarea
        :value="props.modelValue"
        placeholder="Введите текст..."
        maxlength="250"
        rows="10"
        @input="emits('update:modelValue', $event.target.value)"
      ></textarea>

      <div v-if="props.modelValue">{{ props.modelValue.length }} / 250</div>
    </div>
  </div>
</template>

<script setup>
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps(["modelValue"]);
const emits = defineEmits(["update:modelValue"]);

const show = ref(false);
</script>

<style lang="scss">
.comment-cell {
  width: 100%;
  min-width: 120px;
  min-height: 30px;
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(#000, 0.4);
  border-radius: 4px;

  textarea {
    width: 100%;
    padding: 8px;
    position: relative;
  }

  button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.1s;

    svg {
      stroke: rgba(#000, 0.2);
    }
  }

  &.hasComment {
    button {
      background-color: rgba(76, 175, 80, 0.7);
    }

    svg {
      stroke: #fff;
    }
  }

  &.commentShowen {
    min-width: 400px;
    width: 400px;
    
    button {
      position: static;
      transform: translateY(0);
    }
  }
}
</style>
