<template>
  <div class="info-popup">
    <div class="info-popup__icon">
      <CIcon icon="user-icon" />
    </div>

    <div class="info-popup__content">
      <p>{{ text }}</p>
      <div class="info-popup__buttons">
        <AppButton
          @click="closePopup"
          class="info-popup__button info-popup__button--ok"
        >
          Понятно
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";

import { usePopupStore } from "@/store/popup";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const { text } = storeToRefs(usePopupStore());
    const { closePopup } = usePopupStore();

    return { text, closePopup };
  },
  components: { AppButton, CIcon },
};
</script>

<style lang="scss">
.info-popup {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;

  p {
    font-weight: 500;
    text-align: center;
    margin: 30px 0;
  }

  &__icon {
    background-color: $success;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;

    svg {
      stroke: #fff;
    }

    .app-icon {
      height: 60px;
      width: auto;
    }
  }

  &__content {
    padding: 16px;
  }

  &__button {
    color: #fff;
    border-radius: 16px;
    padding: 4px 16px;
    transition: 0.2s;
    margin: 0 auto;

    &--ok {
      background-color: $success;
    }

    &--cancel {
      background-color: $main-1;
    }

    & + & {
      margin-left: 4px;
    }

    &:hover {
      opacity: 0.8;
      box-shadow: $shadow;
    }

    &:active {
      opacity: 0.9;
      box-shadow: none;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }
}
</style>
