<template>
  <LoaderRing v-if="isLoading" />
  <router-view v-else @update="update"></router-view>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { useNotesStore } from "@/store/notes";
import { onMounted, onUnmounted, ref, watch } from "vue";

import { useRoute } from "vue-router";
import { usePopupStore } from "@/store/popup";

import LoaderRing from "@/elements/LoaderRing.vue";

const { setNotes, clearNotes } = useNotesStore();
const { showError } = usePopupStore();

const route = useRoute();
const isLoading = ref(false);

onMounted(() => {
  console.log(3);
  update();
});

onUnmounted(clearNotes);

watch(
  () => route.query.filterByGroupId,
  (c, p) => {
    if (c !== p) {
      update();
    }
  }
);

watch(
  () => route.path,
  (_, p) => {
    if (p.includes("edit")) {
      console.log(2);
      update();
    }
  }
);

async function update() {
  getNotes();
}

async function getNotes() {
  isLoading.value = true;
  try {
    const childrenNotes = await getChildrenNotesHandler();
    setNotes(childrenNotes.data);
  } catch (e) {
    showError(e);
  } finally {
    isLoading.value = false;
  }
}

async function getChildrenNotesHandler() {
  return customAxios.get(`/tutor/note/child/all`);
}
</script>
