<template>
  <div class="section-promo">
    <h1 class="gradient-logo-title">Эврика</h1>

    <Swiper
      :slides-per-view="1"
      :modules="modules"
      :autoplay="true"
      :speed="500"
      delay="1000"
      effect="slide"
    >
      <SwiperSlide class="swiper-slide" v-for="(s, i) in slides" :key="i">
        <img :src="s" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/autoplay";

const slides = [
  "../slider/1.jpg",
  "../slider/2.jpg",
  "../slider/4.jpg",
  "../slider/5.jpg",
  "../slider/6.jpg",
  "../slider/7.jpg",
  "../slider/8.jpg",
  "../slider/9.jpg",
  "../slider/10.jpg",
];

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return { slides, modules: [Autoplay] };
  },
};
</script>

<style lang="scss">
.section-promo {
  height: 400px;
  position: relative;
  background: $light-gradient;
  border-radius: 8px;
  overflow: hidden;

  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-size: 60px;
    line-height: 70px;
    text-shadow: 4px 4px #fff;
    font-weight: 700;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  &::after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($main-2, 0.3);
    z-index: 1;
    display: block;
    position: absolute;
  }

  .swiper-slide {
    height: 400px;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
