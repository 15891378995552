<template>
  <div class="page-wrapper pa-4 text-center">
    <p>Пытаемся войти...</p>
  </div>
</template>

<script setup>
import { useUserData } from "@/composables/userData";
import { useRoute, useRouter } from "vue-router";
import { usePopupStore } from "@/store/popup";

import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

import { store as vuexStore } from "@/store";

const { showError, showWarningPopup } = usePopupStore();
const { loginByUuid, updateUserInfo } = useUserData();

const { userId } = storeToRefs(useAuthStore());
const { logout, setUser } = useAuthStore();

const router = useRouter();
const route = useRoute();

function checkUuid(uuid) {
  const regExpUuid =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
  return uuid.match(regExpUuid);
}

onMounted(login);

async function login() {
  if (!route.params.uuid || !checkUuid(route.params.uuid)) {
    return;
  }

  if (userId.value) {
    logout();
  }
  try {
    const r = await loginByUuid(route.params.uuid);

    if (r.status === 207) {
      showWarningPopup(r.data.message);
    } else {
      setUser(r.data);
      await updateUserInfo();
      vuexStore.commit("authentication/loginSuccess", r.data);
    }

    router.push("/");
  } catch (e) {
    showError(e);
  }
}
</script>
