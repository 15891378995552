<template>
  <div class="error-popup">
    <div class="error-popup__icon">
      <CIcon icon="error-icon" />
    </div>

    <div class="error-popup__content">
      <h4>Ошибка</h4>
      <p>{{ text }}</p>
      <AppButton @click="closePopup" class="error-popup__button">Понятно</AppButton>
    </div>
  </div>
</template>

<script>
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";

import { usePopupStore } from "@/store/popup";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const { closePopup } = usePopupStore();

    const { text } = storeToRefs(usePopupStore());
    return {
      text,
      closePopup,
    };
  },

  components: { AppButton, CIcon },
};
</script>

<style lang="scss">
.error-popup {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;

  &__icon {
    background-color: $error;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;

    svg {
      stroke: #fff;
    }

    .app-icon {
      height: 60px;
      width: auto;
    }
  }

  &__content {
    text-align: center;
    padding: 16px;

    h4 {
      color: $error;
      margin-bottom: 16px;
    }
  }

  &__button {
    color: #fff;
    border-radius: 16px;
    padding: 4px 16px;
    transition: 0.2s;
    background-color: $error;
    margin: 0 auto;
    margin-top: 16px;
  }
}
</style>
