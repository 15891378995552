<template>
  <quill-editor
    style="height: 400px"
    :value="props.value"
    @change="onEditorChange"
    :options="{
      placeholder: 'Начните добавлять текст сюда',
      modules: {
        toolbar: toolbarOptions,
        imageDrop: true,
        imageResize: true,
        htmlEditButton: {
          debug: true,
          syntax: false,
          buttonTitle: 'Показать HTML код',
          msg: 'Отредактируйте HTML  в этом окне,  для сохранения нажмите - сохранить',
          okText: 'Сохранить',
          cancelText: 'Отмена',
          buttonHTML: 'КОД',
          prependSelector: 'div#myelement',
          editorModules: {},
        },
        imageCompress: {
          quality: 0.7, // default
          maxWidth: 1000, // default
          imageType: 'image/jpeg', // default
          debug: true, // default
          suppressErrorLogging: false, // default
          insertIntoEditor: undefined, // default
        },
      },
    }"
  />
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

import { quillEditor, Quill } from "vue3-quill";
Quill.debug("error");
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "@taoqf/quill-image-resize-module";
import ImageCompress from "quill-image-compress";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/htmlEditButton", htmlEditButton);
Quill.register("modules/imageCompress", ImageCompress);
import htmlEditButton from "quill-html-edit-button";

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],
  // ['link', 'image', 'video', 'formula'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
  // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  // [{ 'direction': 'rtl' }],                         // text direction

  // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  // [{ 'font': [] }],
  // [{ 'align': [] }],

  // ['clean']                                         // remove formatting button
];


const emit = defineEmits(["update:value"]);
const props = defineProps(["value"]);

const onEditorChange = ({ html }) => {
  // console.log("editor change!", quill, html, text);
  emit("update:value", html);
};
</script>
