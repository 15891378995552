<template>
<div ref="wrapRef">
  <slot ref="elRef"></slot>
</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
function findElement(slot) {
  if (slot.el) {
    return slot.el;
  }

  let found = false;

  while (!found) {
    if (!slot.children || slot.children.length === 0) {
      throw new Error("Can not find element to observe");
    }

    return findElement(slot.children[0]);
  }
}

export default {
  emits: ["show", "hide"],
  props: {
    threshold: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      observer: null,
    };
  },
  methods: {
    initObserver() {
      this.observer = new IntersectionObserver((entries) => {

        entries.forEach((entry) => {

          if (entry.isIntersecting) {
            this.$emit("show", entry);
          } else {
            this.$emit("hide", entry);
          }
        });
      }, { threshold: this.threshold || 0});

      // this.observer.observe(findElement(this.$slots.default()[0]));
      this.observer.observe(this.$refs.wrapRef);
    },
  },
  mounted() {
    this.initObserver();
  },
  unmounted() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>
