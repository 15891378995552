<template>
  <div class="not-found pa-2">
    <div class="not-found__text">
      <p>
        Упс...<br />
        Страница не найдена
      </p>
      <router-link to="/">Вернуться на главную</router-link>
    </div>
  </div>
</template>

<style lang="scss">
.not-found {
  height: 100%;
  background: $gradient-1;
  border-radius: 32px;
  color: #fff;
  padding: 16px;
  padding-bottom: 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 30px;
    line-height: 50px;
    font-weight: 500;
  }

  a {
    border-radius: 8px;
    margin-top: 30px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    background-color: #fff;
    color: $main-2;
    padding: 16px;
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.2s;

    &:hover {
      transform: scale(1.01);
      background-color: rgba(#fff, 0.9);
    }

    &:hover {
      transform: scale(1);
      background-color: rgba(#fff, 0.8);
    }
  }

  &__text {
    z-index: 1;
    position: relative;
  }

  @media (max-width: 480px) {
    p {
      font-size: 20px;
      line-height: 30px;
    }

    a {
      font-size: 14px;
      line-height: 20px;
      padding: 8px;
    }
  }
}
</style>
