<template>
  <div @click="$emit('closeChip')" class="closable-chip bordered-item ma-1">
    <div class="closable-chip__content">
      <span>{{ data }}</span>
      <CIcon icon="cross-icon" />
    </div>
  </div>
</template>

<script>
import CIcon from "@/elements/CIcon.vue";

export default {
  emits: ["closeChip"],
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  setup() {},
  components: { CIcon },
};
</script>

<style lang="scss">
.closable-chip {
  display: inline-block;

  svg {
    width: 16px;
    height: 16px;
    margin: 0;
    margin-left: 4px;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  span {
    white-space:wrap;
  }
}
</style>
