<template>
  <div>
    <PageTitle title="Отчёт: Английский Контрольные (по работе)" />

    <div class="g-col g-col--1fr-max">
      <FilterByYearAndGroup />
      <FilterByEChecklists />
    </div>

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <p class="mb-3">Здесь отобразится отчёт.</p>
        <v-label> Выставьте фильтры</v-label>
        <ol class="pl-5">
          <li><strong>год</strong></li>
          <li><strong>класс</strong></li>
        </ol>
        <p class="mt-2">
          После выбора класса и года появится выпадающее меню с
          <strong>оценочными листами</strong>
        </p>
        <p>Выберите нужный <strong>оценочный лист</strong></p>
      </template>
    </TogglerOfWrapper>

    <StatusWrapper
      :loadingStatus="loading"
      :isDataExisted="report !== null && report.dataRows.length > 0"
    >
      <div class="d-flex justify-end my-2">
        <AppButton
          @click="
            downloadExcel(
              getReportTitle('Отчёт_англ_контрольные_'),
              `english-report-by-work`
            )
          "
          class="accent-button"
          >Скачать отчёт</AppButton
        >
      </div>

      <div class="page-wrapper my-3">
        <div class="table-wrapper">
          <table id="english-report-by-work" class="table-custom">
            <thead>
              <tr>
                <th :colspan="report.headers.length" class="pb-5">
                  <span v-if="group">{{ group }}</span>
                  <span v-if="year">({{ year }})</span>
                </th>
              </tr>
              <tr class="table-custom__headers">
                <th v-for="(h, i) in report.headers" :key="i">{{ h }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(r, i) in sortedReport.dataRows" :key="i">
                <td>
                  <p v-if="r.child">
                    {{ r.child.lastname }} {{ r.child.firstname }}
                  </p>
                  <p v-else>Среднее значение класса</p>
                </td>

                <td v-for="(v, i) in r.dataValue" :key="i">
                  {{ v }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { usePopupStore } from "@/store/popup";
import { computed, ref, watch } from "vue";

import { useRoute } from "vue-router";
import { customAxios } from "@/service/customAxios";

import FilterByYearAndGroup from "@/components/filtersElements/FilterByYearAndGroup.vue";
import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import FilterByEChecklists from "@/components/filtersElements/FilterByEChecklists.vue";

import StatusWrapper from "@/components/StatusWrapper.vue";
import AppButton from "@/elements/AppButton.vue";

import { useUserData } from "@/composables/userData";
import { storeToRefs } from "pinia";
import { useFiltersStore } from "@/store/filters";
import { sortByChildLastname } from "@/util/common";
import { useReportsData } from "@/store/reports";
import PageTitle from "@/elements/PageTitle.vue";
const { getReportTitle } = useReportsData();

const { group, year } = storeToRefs(useFiltersStore());

const { showError } = usePopupStore();
const route = useRoute();
const { downloadExcel } = useUserData();

const loading = ref("nothing");
const report = ref(null);

const sortedReport = computed(() => {
  if (!report.value || !report.value.dataRows.length) {
    return [];
  }

  const filteredDataRows = report.value.dataRows.filter(
    (d) => d.child !== null
  );
  const middleValue = report.value.dataRows.find((v) => v.child === null);

  return {
    headers: report.value.headers,
    dataRows: [...sortByChildLastname(filteredDataRows), middleValue],
  };
});

watch(() => route.query.filterByChecklist, getReport);

async function getReport() {
  if (!route.query.filterByChecklist) {
    return;
  }
  loading.value = "loading";

  try {
    const r = await getReportHandler();
    report.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getReportHandler() {
  return customAxios.post("/foreign_checklist/report/by_checklist", {
    checklistId: Number(route.query.filterByChecklist),
  });
}
</script>

<style lang="scss"></style>
