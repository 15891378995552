<template>
  <div class="wrapper user-news">
    <PageTitle title="Эвриканские новости" />

    <StatusWrapper
      :is-data-existed="news.length > 0"
      :loading-status="loadingStatus"
    >
      <div class="page-wrapper mb-3">
        <div class="g-col g-col--2-1fr">
          <DatePicker
            v-model="period"
            range
            autoApply
            locale="ru"
            placeholder="Фильтр по периоду"
            :enable-time-picker="false"
            :format="getRangeUIFormat"
            clearable
          />
        </div>
      </div>

      <NewsCard
        v-if="freshNewsId"
        :item="news[freshNewsId]"
        class="user-news__main-news"
        bigCard
      />
      <ComponentWithPagination
        :list="filteredNews"
        @changeList="paginatedData = $event"
      >
        <div v-if="paginatedData.length" class="user-news__cards">
          <NewsCard v-for="(n, i) in paginatedData" :key="i" :item="n" />
        </div>
      </ComponentWithPagination>
    </StatusWrapper>
  </div>
</template>

<script setup>
import NewsCard from "@/components/NewsCard.vue";
import PageTitle from "@/elements/PageTitle.vue";
import ComponentWithPagination from "@/components/ComponentWithPagination.vue";

import StatusWrapper from "@/components/StatusWrapper.vue";

import { useNewsStore } from "@/store/news";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";

import { getNewestDate } from "@/util/common";
import { usePopupStore } from "@/store/popup";
import { useDatesChanger } from "@/composables/datesChanger";

const { getRangeUIFormat } = useDatesChanger();
const { news } = storeToRefs(useNewsStore());

const { getPublishedNews, setNews } = useNewsStore();
const { showError } = usePopupStore();

const loadingStatus = ref("loading");
const period = ref([]);

const filteredNews = computed(() => {
  if (!news.value.length) {
    return [];
  } else if (!period.value || !period.value.length) {
    return news.value;
  } else {
    return news.value.filter((v) => {
      const start = new Date(period.value[0]);
      const end = new Date(period.value[1]);
      const dateOfNews = new Date(v.createDateTime);

      if (start > dateOfNews) {
        return false;
      }

      if (end < dateOfNews) {
        return false;
      }

      return true;
    });
  }
});

onMounted(getNews);

async function getNews() {
  loadingStatus.value = "loading";
  try {
    const r = await getPublishedNews();
    setNews(r.data);
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}

const paginatedData = ref([]);

const freshNewsId = computed(() => {
  const fresh = {
    id: null,
    date: null,
  };

  if (!news.value.length) {
    return null;
  }

  news.value.forEach((v) => {
    if (fresh.id === null) {
      fresh.id = v.id;
      fresh.date = v.createDateTime;

      return;
    } else {
      const newestDate = getNewestDate(fresh, v.createDateTime);

      if (fresh.date !== newestDate) {
        fresh.id = v.id;
        fresh.date = v.createDateTime;
      }
    }
  });

  return news.value.findIndex((v) => v.id === fresh.id);
});
</script>

<style lang="scss">
.user-news {
  &__cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }

  &__main-news {
    margin-bottom: 32px;
  }
}
</style>
