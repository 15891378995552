import { customAxios } from "@/service/customAxios";
import { computed, ref } from "vue";
import { sortByLastName } from "@/util/common";
import { usePopupStore } from "@/store/popup";

export function useEmployeeData() {
  const { showError } = usePopupStore();

  const selectedEmployee = ref(null);
  const employees = ref([]);

  
  const employeesOptions = computed(() => {
    if (!employees.value.length) {
      return [];
    }

    const sorted = sortByLastName(employees.value);

    return sorted.map((v) => ({
      value: v.id,
      title: `${v.lastname} ${v.firstname} ${v.secname}`,
    }));
  });

  const masters = ref([]);
  const mastersOptions = computed(() => {
    if (!masters.value.length) {
      return [];
    }

    const sorted = sortByLastName(masters.value);

    return sorted.map((v) => ({
      value: v.id,
      title: `${v.lastname} ${v.firstname} ${v.secname}`,
    }));
  });

  async function getMasters() {
    try {
      const r = await getMastersHandler();
      masters.value = r.data;
    } catch (e) {
      showError(e);
    }
  }

  async function getMastersHandler() {
    return await customAxios.get(`/user/minimal/masters`);
  }

  async function getEmployees() {
    try {
      const r = await getEmployeesHandler();
      employees.value = r.data;
    } catch (e) {
      console.log(e);
    }
  }
  async function getEmployeesHandler() {
    return customAxios.get("/user/employees");
  }

  const employeesSelector = computed(() => {
    if (!employees.value.length) {
      return [];
    }

    return employees.value.map((v) => {
      return {
        title: `${v.firstname} ${v.lastname}`,
        value: v.id,
        id: v.id,
      };
    });
  });

  return {
    employeesSelector,
    selectedEmployee,
    employees,
    getEmployees,
    getMasters,
    mastersOptions,
    masters,
    employeesOptions
  };
}
