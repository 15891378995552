<template>
  <div>
    <PageTitle title="Отчёт: Английский Диктанты (по ребёнку)" />
    <FiltersForChildsReport>
      <FilterByYear />
    </FiltersForChildsReport>

    <TipForChildFilters />

    <StatusWrapper
      :loadingStatus="loading"
      :isDataExisted="report !== null && report.dataRows.length > 0"
    >
      <div class="page-wrapper my-3">
        <Line :data="chart" :options="chartOptions" />
      </div>

      <div class="d-flex justify-end my-2">
        <AppButton
          @click="
            downloadExcel(
              getReportTitle('Отчёт_англ_диктанты_'),
              `e-report-dict-by-child`
            )
          "
          class="accent-button"
          >Скачать отчёт</AppButton
        >
      </div>

      <div class="page-wrapper my-3">
        <div class="table-wrapper">
          <table id="e-report-dict-by-child" class="table-custom">
            <thead>
              <tr v-if="!isChild">
                <th :colspan="report.headers.length">
                  <p v-if="child">Отчёт по ребенку: {{ child }}</p>
                </th>
              </tr>
              <tr>
                <th :colspan="report.headers.length" class="pb-5">
                  <span v-if="group">{{ group }}</span>
                  <span v-if="year">({{ year }})</span>
                </th>
              </tr>

              <tr class="table-custom__headers">
                <th v-for="(h, i) in report.headers" :key="i">{{ h }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(r, i) in report.dataRows" :key="i">
                <td>
                  {{ r.dataName }}
                </td>

                <td v-for="(v, i) in r.dataValue" :key="i">
                  {{ v }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { computed, onMounted, ref, watch } from "vue";

import { useRoute } from "vue-router";
import { pushFilterToURL } from "@/service/router";
import { useAuthStore } from "@/store/auth";

import { storeToRefs } from "pinia";
import { useFiltersStore } from "@/store/filters";
import { useReportsData } from "@/store/reports";

import TipForChildFilters from "@/components/tips/TipForChildFilters.vue";
import StatusWrapper from "@/components/StatusWrapper.vue";
import AppButton from "@/elements/AppButton.vue";

import { Line } from "vue-chartjs";
import { useUserData } from "@/composables/userData";
import PageTitle from "@/elements/PageTitle.vue";
import FiltersForChildsReport from "../FiltersForChildsReport.vue";
import FilterByYear from "@/components/filtersElements/FilterByYear.vue";

const { group, year, child } = storeToRefs(useFiltersStore());
const { downloadExcel } = useUserData();
const { getReportTitle } = useReportsData();

const { showError } = usePopupStore();
const { isChild, userId } = useAuthStore();

const route = useRoute();
const loading = ref("nothing");
const report = ref(null);

const chart = computed(() => {
  if (report.value === null) {
    return;
  }
  return {
    labels: report.value.dataRows.map((v) => v.dataName),
    datasets: [
      {
        label: "Result",
        data: report.value.dataRows.map((v) => v.dataValue[0]),
        backgroundColor: "rgba(121, 134, 203, 1)",
        borderColor: "rgba(121, 134, 203, 1)",
      },
    ],
  };
});

const chartOptions = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

watch(() => route.query.filterByYear, getReport);
watch(() => route.query.filterByChildId, getReport);

onMounted(async () => {
  if (isChild) {
    pushFilterToURL({ name: "filterByChildId", value: userId });
  }
  getReport();
});

async function getReport() {
  if (!route.query.filterByYear || !route.query.filterByChildId) {
    return;
  }
  loading.value = "loading";

  try {
    const r = await getReportHandler();
    report.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getReportHandler() {
  return customAxios.post("/foreign_checklist/report/dictation/by_child", {
    studyYearId: Number(route.query.filterByYear),
    personId: Number(route.query.filterByChildId),
  });
}
</script>
