<template>
  <v-card v-if="popup" class="page-wrapper">
    <div class="d-flex justify-end mb-2">
      <AppButton @click="clearPopup" class="bordered-item">Закрыть</AppButton>
    </div>
    <h4 class="d-flex flex-wrap">
      <span class="mr-2">
        {{ popup.attendanceChild.child.lastname }}
        {{ popup.attendanceChild.child.firstname }}
        {{ popup.attendanceChild.child.secname }}
      </span>
      <span>
        {{ popup.attendanceDay.attendanceDayDate }}
      </span>
    </h4>

    <div v-if="subjects.length">
      <SubjectAttandanceFromJournal
        v-for="s in subjects"
        :key="s.id"
        :item="s"
        class="my-3"
        @update="emits('update')"
      />
    </div>
    <v-label v-else class="text-deep-purple-darken-1 font-weight-bold mb-2"
      >Грузим предметы из журналов...</v-label
    >
  </v-card>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { useAttendanceStore } from "@/store/attendance-journal";
import { storeToRefs } from "pinia";

import { onMounted, ref,defineEmits } from "vue";
import { usePopupStore } from "@/store/popup";

import AppButton from "@/elements/AppButton.vue";
import SubjectAttandanceFromJournal from "./SubjectAttandanceFromJournal.vue";

const { showError } = usePopupStore();
const { popup } = storeToRefs(useAttendanceStore());
const { clearPopup } = useAttendanceStore();

const subjects = ref([]);
const emits = defineEmits(["update"]);

onMounted(getAttendanceDataFromJournals);

async function getAttendanceDataFromJournals() {
  try {
    const r = await getAttendanceDataFromJournalsHandler();
    subjects.value = r.data;
  } catch (error) {
    showError(error);
  }
}

async function getAttendanceDataFromJournalsHandler() {
  const reqData = {
    childId: popup.value.attendanceChild.child.id,
    date: popup.value.attendanceDay.attendanceDayDate,
  };
  return customAxios.post("/attendance/from_teacher_log/", reqData);
}
</script>
