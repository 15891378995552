<template>
  <v-container class="wrapper">
    <TableElement>
      <template v-slot:tabs>
        <v-tabs bg-color="primary-dark" v-model="part">
          <v-tab value="1">Опросы</v-tab>
          <v-tab disabled value="2">Вопросы</v-tab>
          <v-tab disabled value="3">Ответы</v-tab>
        </v-tabs>
      </template>
      <template v-slot:window>
        <v-card-text class="align-self-stretch ma-0 pa-0">
          <v-window v-model="part" class="align-self-stretch h-75">
            <v-window-item value="1" class="align-self-stretch">
              <v-card-title class="card_ligth_header">
                <h3 class="ma-2">Опросы</h3>
              </v-card-title>
              <v-card-title class="card_ligth_header" style="min-height: 5vh">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="checklistSearchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="checklistDialog = true"
                      variant="outlined"
                      color="primary"
                      rounded
                      class="mb-4"
                    >
                      Добавить
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="min-height: 80vh">
                <div class="d-sm-none d-flex align-center flex-column">
                  <v-card
                    class="ma-5 mx-5 w-100"
                    v-for="item in checklistPaginatedData"
                    :key="item.id"
                  >
                    <v-card-title class="card_ligth_header">
                      <h6 class="text-white">
                        Опрос № {{ sortedChecklists.indexOf(item) + 1 }}
                      </h6>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list-item lines="two">
                        <v-list-item-title>Название:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.checklistName
                        }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Описание:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.checklistDescription
                        }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Вопросов:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.questionCount
                        }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Создан:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.createDate.replace("T", " ")
                        }}</v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item lines="two">
                        <v-list-item-title>Активен:</v-list-item-title>
                        <v-list-item-subtitle
                          ><v-chip size="small" v-if="item.isModerated"
                            >Да</v-chip
                          ><v-chip v-else size="small"
                            >Нет</v-chip
                          ></v-list-item-subtitle
                        >
                      </v-list-item>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="card_ligth_actions d-flex flex-wrap">
                      <v-btn
                        @click="checklistQuestions(item)"
                        class="ma-1"
                        color="primary-dark"
                        variant="outlined"
                        rounded
                        >Вопросы</v-btn
                      >
                      <v-btn
                        @click="editChecklist(item)"
                        class="ma-1"
                        color="primary"
                        variant="outlined"
                        rounded
                      >
                        Редактировать
                      </v-btn>
                      <v-btn
                        @click="deleteChecklist(item)"
                        class="ma-1"
                        color="warning"
                        variant="outlined"
                        rounded
                      >
                        Удалить
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
                <div class="d-none d-sm-block">
                  <v-table fixed-header class="h-100">
                    <thead>
                      <tr>
                        <th class="text-left">
                          <b># </b>
                          <font-awesome-icon
                            v-on:click="byChecklistId"
                            icon="fa-solid fa-sort"
                          />
                        </th>
                        <th class="text-left">
                          <b>Название </b>
                          <font-awesome-icon
                            v-on:click="byСhecklistName;"
                            icon="fa-solid fa-sort"
                          />
                        </th>
                        <th class="text-left">
                          <b>Описание </b>
                          <font-awesome-icon
                            v-on:click="byChecklistDescription"
                            icon="fa-solid fa-sort"
                          />
                        </th>
                        <th class="text-left">
                          <b>Добавлено </b>
                          <font-awesome-icon
                            v-on:click="byCreateDate"
                            icon="fa-solid fa-sort"
                          />
                        </th>
                        <th class="text-left">
                          <b>Вопросов </b>
                          <font-awesome-icon
                            v-on:click="byQuestionCount"
                            icon="fa-solid fa-sort"
                          />
                        </th>

                        <th class="text-left">
                          <b>Активно </b>
                          <font-awesome-icon
                            v-on:click="byIsActive"
                            icon="fa-solid fa-sort"
                          />
                        </th>
                        <th class="text-left">
                          <b>Действие</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in checklistPaginatedData" :key="item.id">
                        <td>
                          <p>{{ sortedChecklists.indexOf(item) + 1 }}</p>
                        </td>
                        <td>
                          <p>{{ item.checklistName }}</p>
                        </td>
                        <td>
                          <p>{{ item.checklistDescription }}</p>
                        </td>
                        <td>
                          <p>{{ item.createDate.replace("T", " ") }}</p>
                        </td>
                        <td>
                          <p>{{ item.questionCount }}</p>
                        </td>
                        <td>
                          <v-chip size="small" v-if="item.isActive">Да</v-chip
                          ><v-chip v-else size="small">Нет</v-chip>
                        </td>
                        <td>
                          <p>
                            <v-btn
                              @click="checklistQuestions(item)"
                              class="ma-1"
                              color="primary-dark"
                              variant="outlined"
                              rounded
                              >Вопросы</v-btn
                            >
                            <v-btn
                              @click="editChecklist(item)"
                              class="ma-1"
                              color="primary"
                              variant="outlined"
                              rounded
                            >
                              Редактировать
                            </v-btn>
                            <v-btn
                              @click="deleteChecklist(item)"
                              class="ma-1"
                              color="warning"
                              variant="outlined"
                              rounded
                            >
                              Удалить
                            </v-btn>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </v-table>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="card_ligth_header" style="min-height: 5vh">
                <v-container class="text-center">
                  <v-pagination
                    v-model="checklistPage"
                    :length="checklistPageCount"
                    :total-visible="3"
                    size="small"
                  ></v-pagination>
                </v-container>
              </v-card-actions>
            </v-window-item>
            <v-window-item :value="2" class="align-self-stretch">
              <v-card-title class="card_ligth_header">
                <h3 class="ma-2">Вопросы опроса</h3>
              </v-card-title>
              <v-card-title class="card_ligth_header" style="min-height: 5vh">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="questionsSearchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded
                      append-icon="mdi-plus-circle-outline"
                      @click:append="questionDialog = true"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="min-height: 80vh">
                <div class="d-sm-none d-flex align-center flex-column">
                  <v-card
                    class="ma-5 mx-5 w-100"
                    v-for="item in questionPaginatedData"
                    :key="item.id"
                  >
                    <v-card-title class="card_ligth_header">
                      <h6 class="text-white">
                        Вопрос № {{ sortedQuestions.indexOf(item) + 1 }}
                      </h6>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list-item lines="two">
                        <v-list-item-title>Вопрос:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.questionBody
                        }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Ответов:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.answerCount
                        }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Порядковый №:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.questionOrder
                        }}</v-list-item-subtitle>
                      </v-list-item>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="card_ligth_actions">
                      <v-btn
                        @click="questionAnswers(item)"
                        class="ma-1"
                        color="primary-dark"
                        variant="outlined"
                        rounded
                        >Ответы</v-btn
                      >
                      <v-btn
                        @click="editQuestion(item)"
                        class="ma-1"
                        color="primary"
                        variant="outlined"
                        rounded
                      >
                        Редактировать
                      </v-btn>
                      <v-btn
                        @click="deleteQuestion(item)"
                        class="ma-1"
                        color="warning"
                        variant="outlined"
                        rounded
                      >
                        Удалить
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
                <div class="d-none d-sm-block">
                  <v-table fixed-header class="h-100">
                    <thead>
                      <tr>
                        <th class="text-left">
                          <b># </b>
                          <font-awesome-icon
                            v-on:click="byQuestionId"
                            icon="fa-solid fa-sort"
                          />
                        </th>
                        <th class="text-left">
                          <b>Вопрос </b>
                          <font-awesome-icon
                            v-on:click="byQuestionBody"
                            icon="fa-solid fa-sort"
                          />
                        </th>
                        <th class="text-left">
                          <b>Порядковый № </b>
                          <font-awesome-icon
                            v-on:click="byQuestionOrder"
                            icon="fa-solid fa-sort"
                          />
                        </th>
                        <th class="text-left">
                          <b>Ответов</b>
                          <font-awesome-icon
                            v-on:click="byAnswerCount"
                            icon="fa-solid fa-sort"
                          />
                        </th>
                        <th class="text-left">
                          <b>Действие</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in questionPaginatedData" :key="item.id">
                        <td>
                          <p>{{ sortedQuestions.indexOf(item) + 1 }}</p>
                        </td>
                        <td>
                          <p>{{ item.questionBody }}</p>
                        </td>
                        <td>
                          <p>{{ item.questionOrder }}</p>
                        </td>
                        <td>
                          <p>{{ item.answerCount }}</p>
                        </td>
                        <td>
                          <p>
                            <v-btn
                              @click="questionAnswers(item)"
                              class="ma-1"
                              color="primary-dark"
                              variant="outlined"
                              rounded
                              >Ответы</v-btn
                            >
                            <v-btn
                              @click="editQuestion(item)"
                              class="ma-1"
                              color="primary"
                              variant="outlined"
                              rounded
                            >
                              Редактировать
                            </v-btn>
                            <v-btn
                              @click="deleteQuestion(item)"
                              class="ma-1"
                              color="warning"
                              variant="outlined"
                              rounded
                            >
                              Удалить
                            </v-btn>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </v-table>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="card_ligth_header" style="min-height: 5vh">
                <v-container class="text-center">
                  <v-pagination
                    v-model="questionPage"
                    :length="questionPageCount"
                    :total-visible="3"
                    size="small"
                  ></v-pagination>
                </v-container>
              </v-card-actions>
            </v-window-item>
            <v-window-item :value="3" class="align-self-stretch">
              <v-card-title class="card_ligth_header">
                <v-row>
                  <v-col cols="1">
                    <v-btn class="pa-2" icon @click="backPart">
                      <font-awesome-icon icon="fa-solid fa-arrow-left" />
                    </v-btn>
                  </v-col>
                  <v-col cols="11">
                    <h3 class="ma-2">Ответы на вопрос</h3>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-card-title class="card_ligth_header" style="min-height: 5vh">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="answerSearchBody"
                      label="Найти"
                      variant="outlined"
                      clearable
                      hint="Введите ключевое слово для поиска"
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="answerDialog = true"
                      variant="outlined"
                      color="primary"
                      rounded
                      class="mb-4"
                    >
                      Добавить
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="min-height: 80vh">
                <div class="d-sm-none d-flex align-center flex-column">
                  <v-card
                    class="ma-5 mx-5 w-100"
                    v-for="item in answerPaginatedData"
                    :key="item.id"
                  >
                    <v-card-title class="card_ligth_header">
                      <h6 class="text-white">
                        Ответ № {{ sortedAnswers.indexOf(item) + 1 }}
                      </h6>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list-item lines="two">
                        <v-list-item-title>Ответ:</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.answerBody
                        }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Верный:</v-list-item-title>
                        <v-list-item-subtitle
                          ><v-chip v-if="item.isTrue">Да</v-chip
                          ><v-chip v-else>Нет</v-chip></v-list-item-subtitle
                        >
                      </v-list-item>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="card_ligth_actions">
                      <v-btn
                        @click="editAnswer(item)"
                        class="ma-1"
                        color="primary"
                        variant="outlined"
                        rounded
                      >
                        Редактировать
                      </v-btn>
                      <v-btn
                        @click="deleteAnswer(item)"
                        class="ma-1"
                        color="warning"
                        variant="outlined"
                        rounded
                      >
                        Удалить
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
                <div class="d-none d-sm-block">
                  <v-table fixed-header class="h-100">
                    <thead>
                      <tr>
                        <th class="text-left">
                          <b># </b>
                          <font-awesome-icon
                            v-on:click="byAnswerId"
                            icon="fa-solid fa-sort"
                          />
                        </th>
                        <th class="text-left">
                          <b>Ответ </b>
                          <font-awesome-icon
                            v-on:click="byAnswerBody"
                            icon="fa-solid fa-sort"
                          />
                        </th>
                        <th class="text-left">
                          <b>Верный </b>
                          <font-awesome-icon
                            v-on:click="byAnswerIsTrue"
                            icon="fa-solid fa-sort"
                          />
                        </th>

                        <th class="text-left">
                          <b>Действие</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in answerPaginatedData" :key="item.id">
                        <td>
                          <p>{{ sortedAnswers.indexOf(item) + 1 }}</p>
                        </td>
                        <td>
                          <p>{{ item.answerBody }}</p>
                        </td>
                        <td>
                          <v-chip v-if="item.isTrue">Да</v-chip
                          ><v-chip v-else>Нет</v-chip>
                        </td>
                        <td>
                          <p>
                            <v-btn
                              @click="editAnswer(item)"
                              class="ma-1"
                              color="primary"
                              variant="outlined"
                              rounded
                            >
                              Редактировать
                            </v-btn>
                            <v-btn
                              @click="deleteAnswer(item)"
                              class="ma-1"
                              color="warning"
                              variant="outlined"
                              rounded
                            >
                              Удалить
                            </v-btn>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </v-table>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions
                class="card_ligth_actions"
                style="min-height: 5vh"
              >
                <v-container class="text-center">
                  <v-pagination
                    v-model="answerPage"
                    :length="answerPageCount"
                    :total-visible="3"
                    size="small"
                  ></v-pagination>
                </v-container>
              </v-card-actions>
            </v-window-item>
          </v-window>
        </v-card-text>
      </template>
    </TableElement>

    <v-row justify="center">
      <v-dialog v-model="checklistDialog" scrollable persistent>
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Опрос</h5>
          </v-card-title>
          <v-card-text>
            <v-form ref="checklistForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Название*"
                      v-model="checklistName"
                      :rules="checklistNameRules"
                      variant="outlined"
                      hint="Укажите название достижения"
                      :counter="30"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Описание*"
                      v-model="checklistDescription"
                      :rules="checklistDescriptionRules"
                      variant="outlined"
                      :counter="255"
                      hint="Укажите описание"
                      required
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12">
                    <v-autocomplete
                      v-model="questionCount"
                      :items="questionsCounter"
                      dense
                      filled
                      label="Вопросов"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-row class="px-0" fluid>
                      <v-switch
                        v-model="isActive"
                        :label="`Модерация`"
                        color="success"
                      >
                      </v-switch>
                      <v-chip v-if="isActive"> Готово к использованию</v-chip
                      ><v-chip v-else> Не готово к использованию</v-chip>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="personGroups"
                      :items="userGroups"
                      outlined
                      dense
                      chips
                      small-chips
                      closable-chips
                      multiple
                      label="Группы просмотра"
                    >
                      <template v-slot:chip="{ props, item }">
                        <v-chip
                          v-bind="props"
                          :text="item.raw.personGroupName"
                        ></v-chip>
                      </template>
                      <template v-slot:item="{ props, item }">
                        <v-list-item
                          v-bind="props"
                          :title="item.raw.personGroupName"
                        ></v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn rounded variant="outlined" @click="closeChecklist">
              Отмена
            </v-btn>
            <v-btn rounded variant="outlined" @click="saveChecklist">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="questionDialog" scrollable persistent>
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Категория</h5>
          </v-card-title>
          <v-card-text>
            <v-form ref="questionForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      label="Вопрос*"
                      v-model="questionBody"
                      :rules="questionBodyRules"
                      variant="outlined"
                      :counter="255"
                      hint="Укажите описание"
                      required
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="answerCount"
                      :items="answersCounter"
                      dense
                      filled
                      label="Кол-во ответов"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="questionOrder"
                      :items="questionsCounter"
                      dense
                      filled
                      label="Порядковый №"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn rounded variant="outlined" @click="closeQuestion">
              Отмена
            </v-btn>
            <v-btn rounded variant="outlined" @click="saveQuestion">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="answerDialog" scrollable persistent>
        <v-card>
          <v-card-title class="card_ligth_header"> Ответ </v-card-title>
          <v-card-text>
            <v-form ref="answerForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      label="Описание*"
                      v-model="answerBody"
                      :rules="answerBodyRules"
                      variant="outlined"
                      :counter="255"
                      hint="Укажите описание"
                      required
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="px-0" fluid>
                      <v-switch v-model="isTrue" :label="`Ответ верен:`">
                      </v-switch>
                      <v-chip v-if="isTrue"> да</v-chip
                      ><v-chip v-else> нет</v-chip>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn rounded variant="outlined" @click="closeAnswer">
              Отмена
            </v-btn>
            <v-btn rounded variant="outlined" @click="saveAnswer">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showLoader" persistent
        ><v-alert
          ><span class="text-h5 text-primary"
            >Загрузка данных
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular></span
        ></v-alert>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { apiUrl } from "@/service/user.service";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}
import _ from "lodash";
import { router } from "@/service/router";
import TableElement from "../elements/TableElement.vue";

export default {
  name: "AdminChecklist",
  components: { TableElement },
  props: [],
  data: () => ({
    showLoader: false,
    userGroups: [],
    personGroups: null,
    part: 1,
    checklistDialog: false,
    checklistName: "",
    checklistNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 30) || "Значение до должно превышать 30 символов!",
    ],
    checklistDescription: "",
    checklistDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 255) || "Значение до должно превышать 30 символов!",
    ],
    isActive: false,

    questionCount: null,

    questionDialog: false,

    questionBody: "",
    questionBodyRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 255) || "Значение до должно превышать 30 символов!",
    ],

    answerCount: null,

    questionOrder: null,
    answerDialog: false,

    answerBody: "",
    answerBodyRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
        (v && v.length <= 255) || "Значение до должно превышать 30 символов!",
    ],

    isTrue: false,
    checklistId: null,
    answerId: null,
    questionId: null,
    checklists: [],
    selectedChecklist: null,
    questions: [],

    selectedQuestion: null,
    checklistSearchBody: "",
    checklistPage: 1,
    checklistSortKey: "createDate",
    LastChecklistSortKey: "createDate",
    checklistSortOrder: 1,

    noteCount: 20,

    questionsSearchBody: "",
    questionPage: 1,
    questionSortKey: "id",
    lastQuestionSortKey: "id",
    questionSortOrder: 1,

    answers: [],
    answerSearchBody: "",
    answerPage: 1,
    answerSortKey: "id",
    lastAnswerSortKey: "id",
    answerSortOrder: 1,
    selectedAnswer: null,
  }),
  //watch: {
  //  part(newPart) {
  //    localStorage.cognationAdminTab = newPart;
  //  }
  //},
  //mounted() {
  //  if (localStorage.cognationAdminTab) {
  //    this.part = localStorage.cognationAdminTab;
  //  }
  //},
  methods: {
    backPart() {
      this.part = this.part - 1;
    },
    closeChecklist() {
      this.checklistName = "";
      this.checklistDescription = "";
      this.questionCount = null;
      this.isActive = false;
      this.personGroups = null;
      this.checklistDialog = false;
    },
    editChecklist(item) {
      this.checklistId = item.id;
      this.checklistName = item.checklistName;
      this.checklistDescription = item.checklistDescription;
      this.questionCount = item.questionCount;
      this.isActive = item.isActive;
      this.personGroups = item.personGroups;
      this.checklistDialog = true;
    },

    saveChecklist() {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };

      if (this.$refs.checklistForm.validate()) {
        if (this.subject instanceof Object) this.subject = this.subject.id;

        let checklist = {
          checklistName: capitalizeFirstLetter(this.checklistName),
          checklistDescription: capitalizeFirstLetter(
            this.checklistDescription
          ),
          questionCount: this.questionCount,
          isActive: this.isActive,
          personGroups: this.personGroups,
        };
        if (this.checklistId) {
          this.axios
            .put(
              apiUrl + "/checklist/" + this.checklistId,
              checklist,
              jwtTokenConfig
            )
            .then((response) => {
              if (response.status === 202) {
                let index = getIndex(this.checklists, response.data.id);
                this.checklists.splice(index, 1, response.data);
                this.checklistId = null;
                this.checklistName = "";
                this.checklistDescription = "";
                this.questionCount = null;
                this.isActive = false;
                this.personGroups = null;
                this.checklistDialog = false;
              } else {
                alert("Что-то пошло не так");
                console.log(response);
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                this.$store.dispatch("authentication/logout");
                this.$store.dispatch("alert/clear");
                localStorage.clear();
                router.push("/").then((r) => {
                  console.log("error: " + r);
                });
              }
            });
        } else {
          this.axios
            .post(apiUrl + "/checklist", checklist, jwtTokenConfig)
            .then((response) => {
              if (response.status === 201) {
                this.checklists.push(response.data);
                this.checklistId = null;
                this.checklistName = "";
                this.checklistDescription = "";
                this.questionCount = null;
                this.isActive = false;
                this.personGroups = null;
                this.checklistDialog = false;
              } else {
                alert("Что-то пошло не так");
                console.log(response);
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                this.$store.dispatch("authentication/logout");
                this.$store.dispatch("alert/clear");
                localStorage.clear();
                router.push("/").then((r) => {
                  console.log("error: " + r);
                });
              }
            });
        }
      }
    },

    deleteChecklist(item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      if (confirm("Вы точно хотите удалить запись?")) {
        this.axios
          .delete(apiUrl + "/checklist/" + item.id, jwtTokenConfig)
          .then((response) => {
            if (response.status === 202) {
              this.checklists.splice(this.checklists.indexOf(item), 1);
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$store.dispatch("authentication/logout");
              this.$store.dispatch("alert/clear");
              localStorage.clear();
              router.push("/").then((r) => {
                console.log("error: " + r);
              });
            }
          });
      }
    },

    closeQuestion() {
      this.questionBody = "";
      this.answerCount = null;
      this.questionOrder = null;
      this.questionDialog = false;
    },

    editQuestion(item) {
      this.questionId = item.id;
      this.questionBody = item.questionBody;
      this.answerCount = item.answerCount;
      this.questionOrder = item.questionOrder;
      this.questionDialog = true;
    },

    saveQuestion() {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      if (this.$refs.questionForm.validate()) {
        if (this.subject instanceof Object) this.subject = this.subject.id;

        let question = {
          checklistId: this.selectedChecklist.id,
          questionBody: capitalizeFirstLetter(this.questionBody),
          answerCount: this.answerCount,
          questionOrder: this.questionOrder,
          isActive: this.isActive,
        };
        if (this.questionId) {
          this.axios
            .put(
              apiUrl + "/checklist/question/" + this.questionId,
              question,
              jwtTokenConfig
            )
            .then((response) => {
              if (response.status === 202) {
                let index = getIndex(this.questions, response.data.id);
                this.questions.splice(index, 1, response.data);
                this.questionId = null;
                this.questionBody = "";
                this.answerCount = null;
                this.questionOrder = null;
                this.questionDialog = false;
              } else {
                alert("Что-то пошло не так");
                console.log(response);
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                this.$store.dispatch("authentication/logout");
                this.$store.dispatch("alert/clear");
                localStorage.clear();
                router.push("/").then((r) => {
                  console.log("error: " + r);
                });
              }
            });
        } else {
          this.axios
            .post(apiUrl + "/checklist/question", question, jwtTokenConfig)
            .then((response) => {
              if (response.status === 201) {
                this.questions.push(response.data);
                this.questionId = null;
                this.questionBody = "";
                this.answerCount = null;
                this.questionOrder = null;
                this.questionDialog = false;
              } else {
                alert("Что-то пошло не так");
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                this.$store.dispatch("authentication/logout");
                this.$store.dispatch("alert/clear");
                localStorage.clear();
                router.push("/").then((r) => {
                  console.log("error: " + r);
                });
              }
            });
        }
      }
    },

    deleteQuestion(item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      if (confirm("Вы точно хотите удалить запись?")) {
        this.axios
          .delete(apiUrl + "/checklist/question/" + item.id, jwtTokenConfig)
          .then((response) => {
            if (response.status === 202) {
              this.questions.splice(this.questions.indexOf(item), 1);
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$store.dispatch("authentication/logout");
              this.$store.dispatch("alert/clear");
              localStorage.clear();
              router.push("/").then((r) => {
                console.log("error: " + r);
              });
            }
          });
      }
    },

    closeAnswer() {
      this.answerBody = "";
      this.isTrue = false;
      this.answerDialog = false;
    },

    editAnswer(item) {
      this.answerId = item.id;
      this.answerBody = item.answerBody;
      this.isTrue = item.isTrue;
      this.answerDialog = true;
    },

    saveAnswer() {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.$refs.answerForm.validate().then((res) => {
        if (res.valid) {
          let answer = {
            questionId: this.selectedQuestion.id,
            answerBody: capitalizeFirstLetter(this.answerBody),
            isTrue: this.isTrue,
          };
          if (this.answerId) {
            this.axios
              .put(
                apiUrl + "/checklist/question/answer/" + this.answerId,
                answer,
                jwtTokenConfig
              )
              .then((response) => {
                if (response.status === 202) {
                  let index = getIndex(this.answers, response.data.id);
                  this.answers.splice(index, 1, response.data);
                  this.answerId = null;
                  this.answerBody = "";
                  this.isTrue = false;
                  this.answerDialog = false;
                } else {
                  alert("Что-то пошло не так");
                  console.log(response);
                }
              })
              .catch((error) => {
                if (error.response.status === 401) {
                  this.$store.dispatch("authentication/logout");
                  this.$store.dispatch("alert/clear");
                  localStorage.clear();
                  router.push("/").then((r) => {
                    console.log("error: " + r);
                  });
                }
              });
          } else {
            this.axios
              .post(
                apiUrl + "/checklist/question/answer",
                answer,
                jwtTokenConfig
              )
              .then((response) => {
                if (response.status === 201) {
                  this.answers.push(response.data);
                  this.answerBody = "";
                  this.isTrue = false;
                  this.answerDialog = false;
                } else {
                  alert("Что-то пошло не так");
                }
              })
              .catch((error) => {
                if (error.response.status === 401) {
                  this.$store.dispatch("authentication/logout");
                  this.$store.dispatch("alert/clear");
                  localStorage.clear();
                  router.push("/").then((r) => {
                    console.log("error: " + r);
                  });
                }
              });
          }
        }
      });
    },

    deleteAnswer(item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      if (confirm("Вы точно хотите удалить запись?")) {
        this.axios
          .delete(
            apiUrl + "/checklist/question/answer/" + item.id,
            jwtTokenConfig
          )
          .then((response) => {
            if (response.status === 202) {
              this.answers.splice(this.answers.indexOf(item), 1);
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$store.dispatch("authentication/logout");
              this.$store.dispatch("alert/clear");
              localStorage.clear();
              router.push("/").then((r) => {
                console.log("error: " + r);
              });
            }
          });
      }
    },

    checklistQuestions(item) {
      this.selectedChecklist = item;
      this.showLoader = true;
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };

      this.axios
        .get(apiUrl + "/checklist/question/" + item.id, jwtTokenConfig)
        .then((response) => {
          if (response.status === 200) {
            this.questions = response.data;
            this.showLoader = false;
          } else {
            this.showLoader = false;
            alert("Что-то пошло не так");
            console.log(response);
          }
        })
        .then((this.part = 2))
        .catch((error) => {
          this.showLoader = false;
          alert("Что-то пошло не так");
          console.log(error);
          if (error.response.status === 401) {
            this.$store.dispatch("authentication/logout");
            this.$store.dispatch("alert/clear");
            localStorage.clear();
            router.push("/").then((r) => {
              console.log("error: " + r);
            });
          }
        });
      this.part = 2;
    },
    questionAnswers(item) {
      this.selectedQuestion = item;

      this.showLoader = true;
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };

      this.axios
        .get(apiUrl + "/checklist/question/answer/" + item.id, jwtTokenConfig)
        .then((response) => {
          if (response.status === 200) {
            this.answers = response.data;
            this.part = 3;
            this.showLoader = false;
          } else {
            alert("Что-то пошло не так");
            console.log(response);
            this.showLoader = false;
          }
        })
        .catch((error) => {
          this.showLoader = false;
          alert("Что-то пошло не так");
          console.log(error);
          if (error.response.status === 401) {
            this.$store.dispatch("authentication/logout");
            this.$store.dispatch("alert/clear");
            localStorage.clear();
            router.push("/").then((r) => {
              console.log("error: " + r);
            });
          }
        });
    },

    byChecklistId() {
      if (this.lastChecklistSortKey.includes("id")) {
        this.checklistSortOrder = this.checklistSortOrder * -1;
      }
      this.checklistSortKey = "id";
      this.lastChecklistSortKey = this.checklistSortKey;
    },

    byСhecklistName() {
      if (this.lastChecklistSortKey.includes("checklistName")) {
        this.checklistSortOrder = this.checklistSortOrder * -1;
      }
      this.checklistSortKey = "checklistName";
      this.lastChecklistSortKey = this.checklistSortKey;
    },

    byChecklistDescription() {
      if (this.lastChecklistSortKey.includes("checklistDescription")) {
        this.checklistSortOrder = this.checklistSortOrder * -1;
      }
      this.checklistSortKey = "checklistDescription";
      this.lastChecklistSortKey = this.checklistSortKey;
    },

    byCreateDate() {
      if (this.lastChecklistSortKey.includes("createDate")) {
        this.checklistSortOrder = this.checklistSortOrder * -1;
      }
      this.checklistSortKey = "createDate";
      this.lastChecklistSortKey = this.checklistSortKey;
    },

    byQuestionCount() {
      if (this.lastChecklistSortKey.includes("questionCount")) {
        this.checklistSortOrder = this.checklistSortOrder * -1;
      }
      this.checklistSortKey = "questionCount";
      this.lastChecklistSortKey = this.checklistSortKey;
    },

    byIsActive() {
      if (this.lastChecklistSortKey.includes("isActive")) {
        this.checklistSortOrder = this.checklistSortOrder * -1;
      }
      this.checklistSortKey = "isActive";
      this.lastChecklistSortKey = this.checklistSortKey;
    },

    byQuestionId() {
      if (this.lastQuestionSortKey.includes("id")) {
        this.questionSortOrder = this.questionSortOrder * -1;
      }
      this.questionSortKey = "id";
      this.lastQuestionSortKey = this.questionSortKey;
    },

    byQuestionBody() {
      if (this.lastQuestionSortKey.includes("questionBody")) {
        this.questionSortOrder = this.questionSortOrder * -1;
      }
      this.questionSortKey = "questionBody";
      this.lastQuestionSortKey = this.questionSortKey;
    },

    byQuestionOrder() {
      if (this.lastQuestionSortKey.includes("questionOrder")) {
        this.questionSortOrder = this.questionSortOrder * -1;
      }
      this.questionSortKey = "questionOrder";
      this.lastQuestionSortKey = this.questionSortKey;
    },

    byAnswerCount() {
      if (this.lastQuestionSortKey.includes("answerCount")) {
        this.questionSortOrder = this.questionSortOrder * -1;
      }
      this.questionSortKey = "answerCount";
      this.lastQuestionSortKey = this.questionSortKey;
    },

    byAnswerId() {
      if (this.lastAnswerSortKey.includes("id")) {
        this.answerSortOrder = this.answerSortOrder * -1;
      }
      this.answerSortKey = "id";
      this.lastAnswerSortKey = this.answerSortKey;
    },

    byAnswerBody() {
      if (this.lastAnswerSortKey.includes("answerBody")) {
        this.answerSortOrder = this.answerSortOrder * -1;
      }
      this.answerSortKey = "answerBody";
      this.lastAnswerSortKey = this.answerSortKey;
    },

    byAnswerIsTrue() {
      if (this.lastAnswerSortKey.includes("isTrue")) {
        this.answerSortOrder = this.answerSortOrder * -1;
      }
      this.answerSortKey = "isTrue";
      this.lastAnswerSortKey = this.answerSortKey;
    },
  },

  computed: {
    questionsCounter() {
      let questions = [];
      for (let i = 0; i < 30; i++) {
        questions[i] = i + 1;
      }
      return questions;
    },

    answersCounter() {
      let answers = [];
      for (let i = 0; i < 30; i++) {
        answers[i] = i + 1;
      }
      return answers;
    },

    filteredAnswers() {
      const ss = this.answerSearchBody.toLowerCase();

      let tempAnswers = this.answers;

      return tempAnswers.filter((item) =>
        _.some(item, (v) => {
          if (!(v instanceof Object)) {
            return _.toLower(v).indexOf(ss) > -1;
          }
        })
      );
    },

    sortedAnswers() {
      let tempAnswers = this.filteredAnswers;
      return tempAnswers.sort(
        dynamicSort(this.answerSortKey, this.answerSortOrder)
      );
    },

    answerPageCount() {
      let l = this.sortedAnswers.length,
        s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },

    answerPaginatedData() {
      const start = (this.currentAnswerPage - 1) * parseInt(this.noteCount),
        end = start + parseInt(this.noteCount);

      return this.sortedAnswers.slice(start, end);
    },

    currentAnswerPage() {
      if (this.answerPageCount < this.answerPage) {
        return 1;
      } else {
        return this.answerPage;
      }
    },

    filteredQuestions() {
      const ss = this.questionsSearchBody.toLowerCase();

      let tempQuestions = this.questions;

      return tempQuestions.filter((item) =>
        _.some(item, (v) => {
          if (!(v instanceof Object)) {
            return _.toLower(v).indexOf(ss) > -1;
          }
        })
      );
    },

    sortedQuestions() {
      let tempQuestions = this.filteredQuestions;
      return tempQuestions.sort(
        dynamicSort(this.questionSortKey, this.questionSortOrder)
      );
    },

    questionPageCount() {
      let l = this.sortedQuestions.length,
        s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },

    questionPaginatedData() {
      const start = (this.currentQuestionPage - 1) * parseInt(this.noteCount),
        end = start + parseInt(this.noteCount);

      return this.sortedQuestions.slice(start, end);
    },

    currentQuestionPage() {
      if (this.questionPageCount < this.questionPage) {
        return 1;
      } else {
        return this.questionPage;
      }
    },

    filteredChecklists() {
      const ss = this.checklistSearchBody.toLowerCase();

      let tempChecklists = this.checklists;

      return tempChecklists.filter((item) =>
        _.some(item, (v) => {
          if (!(v instanceof Object)) {
            return _.toLower(v).indexOf(ss) > -1;
          }
        })
      );
    },

    sortedChecklists() {
      let tempChecklists = this.filteredChecklists;
      return tempChecklists.sort(
        dynamicSort(this.checklistSortKey, this.checklistSortOrder)
      );
    },

    checklistPageCount() {
      let l = this.sortedChecklists.length,
        s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },

    checklistPaginatedData() {
      const start = (this.currentChecklistPage - 1) * parseInt(this.noteCount),
        end = start + parseInt(this.noteCount);

      return this.sortedChecklists.slice(start, end);
    },

    currentChecklistPage() {
      if (this.checklistPageCount < this.checklistPage) {
        return 1;
      } else {
        return this.checklistPage;
      }
    },

    loggedIn() {
      return this.$store.state.authentication.status.loggedIn;
    },

    user() {
      return this.$store.state.authentication.user;
    },

    authentication() {
      return this.$store.state.authentication;
    },
  },
  created: function () {
    this.showLoader = true;
    let jwtTokenConfig = {
      headers: {
        Authorization: "Bearer " + this.user.token,
      },
    };

    function getChecklists(jwtTokenConfig, axios) {
      return axios.get(apiUrl + "/checklist", jwtTokenConfig);
    }
    function getUserGroups(jwtTokenConfig, axios) {
      return axios.get(apiUrl + "/user_group", jwtTokenConfig);
    }

    Promise.all([
      getChecklists(jwtTokenConfig, this.axios),
      getUserGroups(jwtTokenConfig, this.axios),
    ])
      .then((results) => {
        if (results[0].status === 200) {
          this.checklists = results[0].data;
        }

        if (results[1].status === 200) {
          this.userGroups = results[1].data;
        }

        this.showLoader = false;
      })
      .catch((error) => {
        this.showLoader = false;
        alert("Ошибка при загрузке данных, обратитесь к администратору!");
        if (error.response.status === 401) {
          this.$store.dispatch("authentication/logout");
          this.$store.dispatch("alert/clear");
          localStorage.clear();
          router.push("/").then((r) => {
            console.log("error: " + r);
          });
        }
      });
  },
};
function dynamicSort(property, sortOrder) {
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
</script>
