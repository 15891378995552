<template>
  <div class="s-preview">
    <div class="s-preview__top">
      <div>
        <v-label class="mr-2">ID анализа:</v-label>
        <span> {{ item.id }}</span>
      </div>
      <div>
        <v-label class="mr-2">Дата создания анализа:</v-label>
        <span> {{ getDateFormatWithTime(item.createDateTime) }}</span>
      </div>
      <div>
        <v-label class="mr-2">ID создателя:</v-label>
        <span> {{ item.creatorId }}</span>
      </div>
      <div>
        <v-label class="mr-2">Активность:</v-label>
        <span> {{ item.isActive ? "Активный" : "Неактивный" }}</span>
      </div>
    </div>
    <Transition>
      <SSwotNotes v-if="visible" :notes="item.noteList" />
    </Transition>
    <AppButton
      v-if="item.noteList.length > 0"
      @click="visible = !visible"
      class="s-preview__toggler"
      :class="{ isOpened: visible }"
    >
      <p>{{ visible ? "Скрыть" : "Показать" }} копии анализа</p>
      <CIcon icon="chevron-down-icon" />
    </AppButton>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

import AppButton from "@/elements/AppButton.vue";
import SSwotNotes from "./SSwotNotes.vue";
import CIcon from "@/elements/CIcon.vue";

defineProps({ item: { type: Object, required: true } });
const { getDateFormatWithTime } = useDatesChanger();
const visible = ref(false);
</script>

<style lang="scss">
.s-preview {
  background-color: #fff;
  box-shadow: $shadow;
  border-radius: 8px;
  cursor: pointer;

  a {
    text-decoration: none;
    color: $text;
  }

  span {
    color: $main-2;
  }

  &__toggler {
    background-color: $main-2;
    color: #fff;
    display: flex;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 4px;
    align-items: center;

    svg {
      stroke: #fff;
      margin-left: 8px;
    }

    &.isOpened {
      svg {
        transform: rotate(-180deg);
      }
    }

    &:hover {
      background-color: rgba($main-2, 0.8);
    }
    &:active {
      background-color: rgba($main-2, 0.7);
    }
  }

  &__top {
    padding: 8px;
  }
}
</style>
