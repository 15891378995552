<template>
  <div>
    <PageTitle title="Отчёт: Английский Диктанты (по классу)" />

    <FilterByYearAndGroup />

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <p class="mb-3">Здесь отобразится отчёт.</p>

        <v-label> Выставьте фильтры</v-label>
        <ol class="pl-5">
          <li><strong>год</strong></li>
          <li><strong>класс</strong></li>
        </ol>
      </template>
    </TogglerOfWrapper>

    <StatusWrapper :loadingStatus="loading" :isDataExisted="isDataExisted">
      <div class="d-flex justify-end my-2">
        <AppButton
          @click="
            downloadExcel(
              `Отчёт_англ_диктанты_${group}_${year}`,
              'e-report-dict-by-group'
            )
          "
          class="accent-button"
          >Скачать отчёт</AppButton
        >
      </div>

      <div class="page-wrapper">
        <div class="table-wrapper">
          <table id="e-report-dict-by-group" class="table-custom">
            <thead>
              <tr>
                <th :colspan="report.headers.length">
                  <p>Отчёт Диктанты</p>
                </th>
              </tr>
              <tr>
                <th :colspan="report.headers.length" class="pb-5">
                  <span v-if="group">{{ group }}</span>
                  <span v-if="year">({{ year }})</span>
                </th>
              </tr>
              <tr class="table-custom__headers">
                <th v-for="(h, i) in sortedReport.headers" :key="i">
                  {{ h }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(r, i) in sortedReport.dataRows" :key="i">
                <td>
                  <p v-if="r.child">
                    {{ r.child.lastname }} {{ r.child.firstname }}
                  </p>
                </td>

                <td v-for="(v, i) in r.dataValue" :key="i">
                  {{ v }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { customAxios } from "@/service/customAxios";
import { useRoute } from "vue-router";

import { usePopupStore } from "@/store/popup";
import { useUserData } from "@/composables/userData";
import { sortByChildLastname } from "@/util/common";

import { useFiltersStore } from "@/store/filters";
import { storeToRefs } from "pinia";

import StatusWrapper from "@/components/StatusWrapper.vue";
import AppButton from "@/elements/AppButton.vue";
import FilterByYearAndGroup from "@/components/filtersElements/FilterByYearAndGroup.vue";

import TogglerOfWrapper from "@/components/elements/TogglerOfWrapper.vue";
import PageTitle from "@/elements/PageTitle.vue";

const { group, year } = storeToRefs(useFiltersStore());
const { downloadExcel } = useUserData();
const { showError } = usePopupStore();
const route = useRoute();

const loading = ref("nothing");
const report = ref(null);

const isDataExisted = computed(
  () =>
    report.value !== null &&
    report.value.dataRows.length > 0 &&
    sortedReport.value.headers.length > 1
);

const sortedReport = computed(() => {
  if (report.value === null) {
    return null;
  }

  return {
    headers: report.value.headers,
    dataRows: sortByChildLastname(report.value.dataRows),
  };
});

onMounted(getReport);

watch(() => route.query.filterByGroupId, getReport);
watch(() => route.query.filterByYear, getReport);

async function getReport() {
  if (!route.query.filterByGroupId || !route.query.filterByYear) {
    return;
  }

  loading.value = "loading";
  try {
    const r = await getReportHandler();
    report.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loading.value = "loaded";
  }
}

async function getReportHandler() {
  return customAxios.post("/foreign_checklist/report/dictation/by_group", {
    personGroupId: Number(route.query.filterByGroupId),
    studyYearId: Number(route.query.filterByYear),
  });
}
</script>
