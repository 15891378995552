import "vuetify/lib/styles/main.css";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { router } from "@/service/router";
import { createVuetify } from "vuetify";
import { createApp } from "vue";
import VueScrollTo from "vue-scrollto";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { store } from "./store/index";
import App from "./App";
import axios from "axios";
import VueAxios from "vue-axios";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import lodash from "lodash";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { quillEditor } from "vue3-quill";

import { createPinia } from "pinia";
const pinia = createPinia();

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import "@fontsource/montserrat";

import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

//import colors from 'vuetify/lib/util/colors'
//import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";

import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css

import {
  faChartColumn,
  faClipboardUser,
  faPaperPlane,
  faCheckToSlot,
  faComment,
  faUsersGear,
  faComments,
  faCalendar,
  faClose,
  faBackward,
  faImage,
  faArrowLeft,
  faCheck,
  faCircleQuestion,
  faUndo,
  faBriefcase,
  faBroom,
  faFilter,
  faClone,
  faSort,
  faFileImport,
  faUserGraduate,
  faChalkboardTeacher,
  faPlay,
  faVideo,
  faScrewdriverWrench,
  faAngleRight,
  faAngleLeft,
  faServer,
  faPenToSquare,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faDownload,
  faSave,
  faUndoAlt,
  faTools,
  faPlusCircle,
  faTrashAlt,
  faXmark,
  faFloppyDisk,
  faIdCard,
  faFolderOpen,
  faChildren,
  faMessage,
  faEllipsisVertical,
  faAtom,
} from "@fortawesome/free-solid-svg-icons";
library.add(faClipboardUser);
library.add(faChartColumn);
library.add(faPaperPlane);
library.add(faCheckToSlot);
library.add(faComment);
library.add(faUsersGear);
library.add(faCalendar);
library.add(faClose);
library.add(faBackward);
library.add(faImage);
library.add(faArrowLeft);
library.add(faCheck);
library.add(faCircleQuestion);
library.add(faBriefcase);
library.add(faBroom);
library.add(faUndo);
library.add(faComments);
library.add(faClone);
library.add(faIdCard);
library.add(faFolderOpen);
library.add(faChildren);
library.add(faMessage);
library.add(faEllipsisVertical);
library.add(faAtom);
library.add(faFloppyDisk);
library.add(faXmark);
library.add(faTrashAlt);
library.add(faTools);
library.add(faPlusCircle);
library.add(faSave);
library.add(faUndoAlt);
library.add(faDownload);
library.add(faArrowRightToBracket);
library.add(faArrowRightFromBracket);
library.add(faPenToSquare);
library.add(faServer);
library.add(faAngleRight);
library.add(faAngleLeft);
library.add(faScrewdriverWrench);
library.add(faVideo);
library.add(faPlay);
library.add(faChalkboardTeacher);
library.add(faUserGraduate);
library.add(faFileImport);
library.add(faSort);
library.add(faFilter);
const app = createApp(App);

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    primary: "#FA9DB7",
    "primary-darken-1": "#3700B3",
    secondary: "#03DAC6",
    "secondary-darken-1": "#018786",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    "primary-dark": "#9C7CA9",
    "chat-bg": "#6E6D94",
    main: "#fff",
  },
};


const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
      myCustomLightTheme,
    },
  },
});

//app.use(VueLodash, { lodash: lodash })
app.component('EasyDataTable', Vue3EasyDataTable);
app.component("DatePicker", Datepicker);
app.use(quillEditor);
app.use(router);
app.use(vuetify);
app.use(lodash);
app.use(VueAxios, axios);
app.config.globalProperties.$axios = axios;
app.use(store);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(pinia);
app.use(VueScrollTo);
app.use(vue3videoPlay);
app.component("QuillEditor", QuillEditor);
app.component('Vue3VideoPlayer', vue3videoPlay);
app.mount("#app");
