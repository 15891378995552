import { defineStore } from "pinia";

export const useCognationsStore = defineStore("cognations", {
  state: () => ({
    cognations: [],
  }),
  getters: {
    getCognationById(store) {
      return (id) => store.cognations.find(v => v.id === Number(id))
    }
  },

  actions: {
    setCognations(list) {
      this.cognations = list;
    },
    clearCognations() {
      this.cognations = null;
    },
  },
});
