<template>
  <div class="w-workshops widget">
    <div>
      <h4 class="mb-5">
        Мастерские на текущую неделю <br />
        {{ title }}
      </h4>

      <StatusWrapper
        :isDataExisted="timetable.length > 0"
        :loadingStatus="loadingStatus"
      >
        <ul class="list w-workshops__days">
          <li v-for="t in timetable" :key="t.id" class="my-3">
            <p class="text-right">
              {{ getDateWithWeekday(t.dayDate) }}
              {{ isItToday(t.dayDate) }}
            </p>

            <ul class="list w-workshops__events">
              <li
                v-for="ev in t.dayWorkshopsEvents"
                :key="ev.id"
                class="d-flex flex-wrap"
              >
                <p
                  v-if="ev.eventDateTimeBegin && ev.eventDateTimeEnd"
                  class="mr-2"
                >
                  {{ getTimeFromDate(ev.eventDateTimeBegin) }} -
                  {{ getTimeFromDate(ev.eventDateTimeEnd) }}
                </p>
                <p v-if="ev.workshop.workshopName">
                  {{ ev.workshop.workshopName }}
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </StatusWrapper>
    </div>

    <WLink
      :link="{ name: 'workshops-timetable' }"
      title="К расписанию"
      class="mt-2"
    />
  </div>
</template>

<script setup>
import { useWorkshopsData } from "@/composables/workshops";
import { computed, onMounted, ref } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

import StatusWrapper from "@/components/StatusWrapper.vue";
import WLink from "./WLink.vue";

import { isItToday } from "@/util/common";
import { usePopupStore } from "@/store/popup";
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";

const { updateTimetableHandler } = useWorkshopsData();
const {
  getCurrentWeek,
  getTimeFromDate,
  getDateFormatWithoutTime,
  getDateWithWeekday,
} = useDatesChanger();

const { showError } = usePopupStore();
const { userId } = storeToRefs(useAuthStore());

const timetable = ref([]);
const loadingStatus = ref("loading");

const currentWeek = getCurrentWeek();

const title = computed(() => {
  if (!currentWeek.length) {
    return "";
  }

  return `${getDateFormatWithoutTime(
    currentWeek[0]
  )} - ${getDateFormatWithoutTime(currentWeek[1])}`;
});

onMounted(update);

async function update() {
  try {
    const r = await updateTimetableHandler({
      beginDate: currentWeek[0],
      endDate: currentWeek[1],
      childId: userId.value,
    });
    timetable.value = r.data;
  } catch (error) {
    showError(error);
  } finally {
    loadingStatus.value = "loaded";
  }
}
</script>

<style lang="scss">
.w-workshops {
  color: #fff;

  h3 {
    color: inherit;
  }

  &__events {
    outline: 3px solid #fff;
    border-radius: 8px;

    p {
      text-align: left;
    }

    p:not(:first-child) {
      font-weight: 600;
    }

    p:first-child {
      font-style: italic;
    }

    li {
      padding: 4px;
    }

    li + li {
      border-top: 1px solid rgba(#fff, 0.5);
      margin-top: 8px;
    }
  }
}
</style>
