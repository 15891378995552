import { customAxios } from "@/service/customAxios";
import { computed, ref } from "vue";
import { useDatesChanger } from "./datesChanger";
const { getTimeFromWeek } = useDatesChanger();

export function useScheduleData() {
  const scheduleEvents = ref([]);

  const daysHeaders = computed(() => scheduleEvents.value.headers);

  const weekSchedule = computed(() => {
    if (!scheduleEvents.value.weekEvents) {
      return [];
    }

    return scheduleEvents.value.weekEvents.map((v, idx) => {
      const id = idx + 1;
      const time = getTimeFromWeek(v.hourEventsByDay);

      const events = v.hourEventsByDay.map((lessons) => {
        return lessons.map((l) => {
          const backgroundColor =
            l.scheduleClass.scheduleClassBackgroundColor || "#fff";

          const textColor = l.scheduleClass.scheduleClassTextColor || "#000";

          return {
            title: l.scheduleClass.scheduleClassName,
            backgroundColor: backgroundColor,
            textColor: textColor,
            groups: l.eventParticipantGroups,
            subgroup: l.subgroup
          };
        });
      });

      return {
        id,
        time,
        events,
      };
    });
  });

  async function getCurrentWeekEvents(options) {
    try {
      const r = await getCurrentWeekEventsHandler(options);
      scheduleEvents.value = r.data;
    } catch (e) {
      console.log(e);
    }
  }

  async function getCurrentWeekEventsHandler({
    childGroups = [],
    classTypes = [],
    employees = [],
  }) {
    const options = {
      childGroups: childGroups,
      classTypes: classTypes,
      employees: employees,
    };
    return customAxios.post("/schedule/week_events/table", options);
  }

  async function getEventsByRange(options) {
    try {
      const r = await getEventsByRangeHandler(options);
      scheduleEvents.value = r.data;
    } catch (e) {
      console.log(e);
    }
  }

  async function getEventsByRangeHandler({
    childGroups = [],
    classTypes = [],
    employees = [],
    beginDateTime,
    endDateTime,
  }) {
    const options = {
      childGroups: childGroups,
      classTypes: classTypes,
      employees: employees,
      beginDateTime: beginDateTime,
      endDateTime: endDateTime,
    };
    return customAxios.post("/schedule/events_by_range/table", options);
  }

  return {
    getCurrentWeekEvents,
    weekSchedule,
    daysHeaders,
    getEventsByRange,
  };
}
