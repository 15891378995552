<template>
  <div v-if="profile" class="wrapper">
    <div class="w-child">
      <div class="w-child__controls" ref="wrapperRef">
        <div class="w-child__avatar" ref="centerCircleRef">
          <router-link to="/profile">
            <img
              v-if="user.avatar"
              :src="
                'https://school.eurekacenter.ru/avatar/' + profile.personAvatar
              "
            />
            <CIcon v-else icon="user-icon" />
          </router-link>
        </div>

        <div
          v-for="c in controls"
          :key="c.id"
          ref="controlsRef"
          class="w-child__control"
          :class="{ active: widget === c.link }"
        >
          <AppButton @click="widget = c.link">
            <CIcon :icon="c.icon" />
          </AppButton>
        </div>
      </div>
      <div class="w-child__content">
        <WDiary v-if="widget === 'diary'" />
        <WWorkshops v-if="widget === 'workshops'" />
        <WCognations v-if="widget === 'cognation'" />
        <WBooking v-if="widget === 'bookings'" />
        <WNotes v-if="widget === 'notes'" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useAuthStore } from "@/store/auth";

import WDiary from "./WDiary.vue";
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";

import WWorkshops from "./WWorkshops.vue";
import WCognations from "./WCognations.vue";
import WBooking from "./WBooking.vue";

import WNotes from "./WNotes.vue";
import { storeToRefs } from "pinia";

const controls = [
  { id: 1, icon: "book-icon", link: "diary" },
  { id: 2, icon: "message-icon", link: "notes" },
  { id: 3, icon: "flask", link: "workshops" },
  { id: 4, icon: "calendar-icon", link: "bookings" },
  { id: 5, icon: "trophy", link: "cognation" },
];

const centerCircleRef = ref();
const controlsRef = ref([]);
const wrapperRef = ref();

const widget = ref("diary");
const { user, profile } = storeToRefs(useAuthStore());

onMounted(() => {
  setControls();
  window.addEventListener("resize", setControls);
});

function setControls() {
  if (window.innerWidth <= 768 || !wrapperRef.value || !centerCircleRef.value) {
    return;
  }

  const angleStep = 360 / controls.length;
  const wrapperRadius = wrapperRef.value.offsetWidth / 2;
  const centerCircleRadius = centerCircleRef.value.offsetWidth / 2;

  centerCircleRef.value.style.top = wrapperRadius - centerCircleRadius + "px";
  centerCircleRef.value.style.left = wrapperRadius - centerCircleRadius + "px";

  controlsRef.value.forEach((c, i) => {
    const angle = angleStep * i - 90;
    const circleRadius = c.offsetWidth / 2;

    const x =
      wrapperRadius +
      (wrapperRadius - centerCircleRadius / 2.5) *
        Math.cos(angle * (Math.PI / 180)) -
      circleRadius;

    const y =
      wrapperRadius +
      (wrapperRadius - centerCircleRadius / 2.5) *
        Math.sin(angle * (Math.PI / 180)) -
      circleRadius;

    c.style.left = `${x}px`;
    c.style.top = `${y}px`;
  });
}
</script>

<style lang="scss">
@import "@/styles/widget.scss";

.w-child {
  border-radius: 30px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  box-shadow: rgba(#7986cb, 0.4) 0px 5px, rgba(#7986cb, 0.3) 0px 10px,
    rgba(#7986cb, 0.2) 0px 15px, rgba(#7986cb, 0.1) 0px 20px,
    rgba(#7986cb, 0.05) 0px 25px;
  padding: 16px;
  font-weight: 600;
  color: #fff;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 16px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #c78dc9, #7986cb);
  }

  &__avatar {
    border-radius: 50%;
    outline: 5px solid #fff;
    position: absolute;
    width: 50%;
    aspect-ratio: 1/1;
    box-shadow: $shadow;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    svg {
      stroke: #fff;
      height: 60%;
      width: 60%;
    }
  }

  &__controls {
    position: relative;
    border-radius: 50%;
    width: 320px;
    height: 320px;
    z-index: 1;
  }

  &__control {
    outline: 5px solid #fff;
    background-size: cover;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    position: absolute;
    width: 15%;
    box-shadow: $shadow;
    aspect-ratio: 1/1;
    transition: all 0.2s;

    svg {
      stroke: #fff;
      height: 60%;
      width: 60%;
      position: relative;
      z-index: 1;
    }

    button {
      width: 100%;
      height: 100%;
    }

    &.active {
      background-color: #fff;

      svg {
        stroke: $main-2;
      }
    }
    &:hover {
      background-color: rgba(#fff, 0.1);

      &.active {
        background-color: rgba(#fff, 0.3);
      }

      svg {
        stroke: #fff;
      }
    }
  }

  &__content {
    z-index: 1;
    position: relative;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 16px;

    &__controls {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 4px;
    }

    &__avatar,
    &__control {
      width: auto;
      position: relative;
      margin: 8px;
      outline: 3px solid #fff;
    }

    &__content {
      display: block;
    }
  }
}
</style>
