<template>
  <TogglerOfWrapper>
    <template v-slot:name>Подсказка</template>
    <template v-slot:content>
      <p class="mb-3">Здесь отобразится отчёт.</p>
      <v-label> Выставьте фильтры</v-label>
      <ol class="pl-5">
        <li v-if="userGlobalRole === 'employee'"><strong>класс</strong></li>
        <li v-if="isParent || userGlobalRole === 'employee'">
          <strong>ребёнок</strong>
        </li>
        <li><strong>год (период)</strong></li>
      </ol>

      <slot></slot>
    </template>
  </TogglerOfWrapper>
</template>

<script setup>
import { useAuthStore } from "@/store/auth";

import TogglerOfWrapper from "../elements/TogglerOfWrapper.vue";

const { isParent, userGlobalRole } = useAuthStore();
</script>
