<template>
  <div class="wrapper">
    <v-card v-if="selectedData">
      <v-card-title class="card_ligth_header">
        <v-row cols="6" class="pa-2">
          <v-col cols="12 ma-4">
            <h3>Ожидаемые и текущие уровни освоения предметов:</h3>
            <h3 v-if="child">{{ child.lastname }} {{ child.firstname }} {{ child.secname }}</h3>
          </v-col>
          <v-col cols="12">
            <v-select v-model="metricView" :items="metricViewData" label="Вид графика" item-title="name"
              item-value="value" variant="outlined" class="w-100"></v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-0 pa-10">
        <v-row v-if="chartDatas !== null">
          <Bar v-if="metricView === 'bar'" :data="chartDatas"></Bar>

          <Radar v-else :data="chartDatas"></Radar>

        </v-row>
        <v-row v-else>
          <v-banner
            text="Нет данных для отображения. Необходимо выбрать ребенка, вид графика и нажать на кнопку открыть."
            :stacked="false">
          </v-banner>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-table>
          <thead>
            <tr>
              <th class="text-left">
                Предмет
              </th>
              <th class="text-left">
                Ожидаемый уровень
              </th>
              <th class="text-left">
                Текущий уровень
              </th>
              <th class="text-left">
                Действие
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in selectedData" :key="item.name">
              <td>{{ item.scheduleClass.scheduleClassName }}</td>
              <td>{{ item.myLevel }}</td>
              <td>{{ item.teacherLevel }}</td>
              <td> <v-btn @click="editValue(item)" color="primary" class="ma-2" size="small" variant="outlined" rounded>
                  Редактировать ожидаемый уровень
                </v-btn></td>
            </tr>
          </tbody>
        </v-table>


      </v-card-text>
    </v-card>
    <v-dialog v-model="showEditForm" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Редактирование ожидаемого уровня</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="teacherLogForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Предмет*" v-model="className" variant="outlined" :counter="100"
                    disabled></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-select v-model="myLevel" label="Ожидаемый уровень" variant="outlined" :items="myLevels"
                    item-value="value" item-title="value">
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn rounded variant="outlined" @click="showEditForm = false">
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveLevel">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import { Bar, Radar } from "vue-chartjs";
import { apiUrl } from "@/service/user.service";


import {
  BarElement,
  CategoryScale,
  Chart as ChartJS, Filler,
  Legend,
  LinearScale, LineElement, PointElement,
  RadialLinearScale,
  Title,
  Tooltip
} from "chart.js";
import { useRoute } from "vue-router";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
);

export default {
  name: "ChildMetrics",
  setup() {
    const route = useRoute();
    return {
      route
    };
  },


  props: [],

  components: {
    Bar,
    Radar
  },
  data: () => ({


    metricViewData: [
      { name: "Паутинка", value: "radar" },
      { name: "Столбики", value: "bar" },
    ],
    showEditForm: false,
    myLevel: 50,
    id: '',

    className: '',
    metricView: "radar",

    showLoader: false,
    chartData: [],
    myProfile: {},
    selectedChildId: "",
    selectedData: null,
    showMetrics: false,
    lastDate: null,
    chartDatas: null,
    child: {},


  }),

  methods: {
    editValue(item) {
      this.id = item.id;
      this.myLevel = item.myLevel;
      this.className = item.scheduleClass.scheduleClassName;
      this.showEditForm = true;

    },
    saveLevel: function () {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };

      let childLevel = {
        myLevel: this.myLevel,
      }

      this.axios
        .put(apiUrl + "/child/metric/metric/" + this.id, childLevel, jwtTokenConfig)
        .then((response) => {
          if (response.status === 202) {
            let index = getIndex(this.selectedData, response.data.id);
            this.selectedData.splice(index, 1, response.data);

            this.metricsData(this.selectedData);
            this.showEditForm = false;
          } else {
            this.showLoader = false;
            this.showEditForm = false;
            console.log(response);
          }
        })
        .catch((error) => {
          this.showEditForm = false;
          this.showLoader = false;
          console.log(error);
        });

    },
    getChildMetrics: function () {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };
      this.axios
        .get(apiUrl + "/child/metric/by_child/" + this.route.params.id, jwtTokenConfig)
        .then((response) => {
          if (response.status === 200) {
            this.selectedData = response.data;
            this.metricsData(this.selectedData)
            if (this.selectedData.length > 0) {
              this.child = this.selectedData[0].child;
            }
          } else if (response.status === 207) {
            console.log(response)
            alert(response.data.message);
          } else {
            this.showLoader = false;
            console.log(response);
          }
        })
        .catch((error) => {
          this.showLoader = false;
          console.log(error);
        });

    },

    metricsData(chartData) {
      this.selectedChart = chartData;
      let labelSet = [];
      let childDataSet = [];
      let teacherDataSet = [];
      let lastDate = "";

      chartData.forEach((element) => {
        labelSet.push(element.scheduleClass.scheduleClassName);
        childDataSet.push(element.myLevel);
        teacherDataSet.push(element.teacherLevel);
        lastDate = element.lastUpdateDateTime;
      });


      this.chartDatas = {
        labels: labelSet,
        datasets: [
          {
            label: "Планируемый уровень на" + " (" + lastDate.replace("T", " ") + ")",
            backgroundColor: "rgba(255,99,132,0.2)",
            borderColor: "rgba(255,99,132,1)",
            pointBackgroundColor: "rgba(255,99,132,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(255,99,132,1)",
            data: childDataSet,
          },
          {
            label: "Фактический уровень на" + " (" + lastDate.replace("T", " ") + ")",
            backgroundColor: "rgba(104,99,255,0.2)",
            borderColor: "rgb(115,99,255)",
            pointBackgroundColor: "rgb(99,208,255)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(99,107,255)",
            data: teacherDataSet,
          },
        ],
      };
      this.chartValues = {
        responsive: true,
      };

      this.showMetrics = true;

    },
  },
  computed: {
    myLevels() {
      let levels = [];
      for (let i = 10.0; i < 101.0; i = i + 10.0) {
        let level = { value: parseFloat(i).toFixed(1) };
        levels.push(level);

      }
      return levels;
    },

    loggedIn() {
      return this.$store.state.authentication.status.loggedIn;
    },
    user() {
      return this.$store.state.authentication.user;
    },
    authentication() {
      return this.$store.state.authentication;
    },
  },
  created: function () {
    this.showLoader = true;
    this.getChildMetrics();
    this.showLoader = false;
  },


};

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}
</script>