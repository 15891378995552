<template>
  <button type="button" class="app-button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.app-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
