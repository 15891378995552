<template>
  <div class="user-profile">
    <LoaderRing v-if="profile === null" />
    <AvatarImage v-else :link="profile.personAvatar" />

    <div class="user-profile__info">
      <div class="user-profile__name">
        <p>
          {{ user.username }}
        </p>
        <p>{{ user.fullName }}</p>
      </div>

      <div class="user-profile__role">
        <p v-if="user.roles.length > 2">суперюзер</p>
        <p v-else>{{ getRoleTitle(user.roles) }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import AvatarImage from "@/elements/AvatarImage.vue";
import LoaderRing from "@/elements/LoaderRing.vue";

import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";

const { user, profile } = storeToRefs(useAuthStore());

const rolesDict = {
  ROLE_TUTOR: "Тьютор",
  ROLE_CHILD: "Ученик",
  ROLE_PARENT: "Родитель",
  ROLE_TEACHER: "Учитель",
  ROLE_MASTER: "Мастер",
};

function getRoleTitle(roles) {
  let result = "Пользователь";

  for (let i = 0; i < roles.length; i++) {
    const title = rolesDict[roles[i]];

    if (title) {
      result = title;
    }
  }

  return result;
}
</script>

<style lang="scss">
.user-profile {
  padding-top: 16px;
  padding-bottom: 16px;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  border-bottom: 1px solid rgba(#000, 0.1);
  transition: 0.2s;

  &__name {
    padding: 8px 0;

    p:first-child {
      word-break: break-all;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 8px;
      text-transform: capitalize;
    }

    p:last-child {
      word-break: break-all;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: grey;
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__role {
    color: #fff;
    border-radius: 8px;
    padding: 4px;

    font-weight: 600;
    background-image: $light-gradient;

    p {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 12px;
      margin: 0;
    }
  }
}
</style>
