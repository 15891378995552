import axios from "axios";
import { apiUrl } from "@/service/user.service";
import { useAuthStore } from "@/store/auth";
import { router } from "./router";

const apiInstance = axios.create();

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url.endsWith("/auth/refresh_token")
    ) {
      originalRequest._retry = true;

      const { data } = await customAxios.refreshAccessToken();
      console.log("новая пара токенов")

      customAxios.setNewTokens(data.accessToken, data.refreshToken);
      useAuthStore().setNewTokens(data.accessToken, data.refreshToken)
      originalRequest.headers.Authorization = "Bearer " + data.accessToken;

      return apiInstance(originalRequest);
    }

    if (
        (originalRequest.url.endsWith("/auth/refresh_token") && error.response.status === 403 ) ||
        (originalRequest.url.endsWith("/auth/refresh_token") && error.response.status === 400 )

    ) {
      console.log("Токен не действителен")
      useAuthStore().logout()
      router.push('/login')
    }

    return Promise.reject(error);
  }
);

class HTTPService {
  #user;
  constructor(http, baseUrl) {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.http = http;
    this.baseUrl = baseUrl;
  }
  get authorization() {
    const token = this.user ? this.user.token : "";

    return !this.user ? {} : { Authorization: "Bearer " + token };
  }
  setUser(user) {
    this.user = user;
  }
  get(path, options = {}) {
    return this.http.get(this.baseUrl + path, {
      ...options,
      headers: { ...this.authorization },
    });
  }
  post(path, data, options = {}) {
    return this.http.post(this.baseUrl + path, data, {
      ...options,
      headers: { ...this.authorization },
    });
  }
  put(path, data) {
    return this.http.put(this.baseUrl + path, data, {
      headers: { ...this.authorization },
    });
  }
  delete(path) {
    return this.http.delete(this.baseUrl + path, {
      headers: { ...this.authorization },
    });
  }
  refreshAccessToken() {
    return this.post("/auth/refresh_token", {
      refreshToken: this.user.refreshToken,
    });
  }
  setNewTokens(newAccessToken, newRefreshToken) {
    this.user.token = newAccessToken;
    this.user.refreshToken = newRefreshToken;
    localStorage.setItem("user", JSON.stringify(this.user));
  }
}

export const customAxios = new HTTPService(apiInstance, apiUrl);
