<template>
  <div>
    <div class="checkbox-container">
      <label>
        <input type="checkbox" v-model="isChecked" />

        <span class="checkmark"></span>
      </label>
    </div>
    <CommentCell class="mt-1" v-model="comment" />
  </div>
</template>

<script setup>
import { watch, ref, defineProps, defineEmits, computed } from "vue";

import CommentCell from "./CommentCell.vue";
import { debounce } from "@/util/common";

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  comment: {
    type: String,
    required: false,
  },
  isChecked: {
    type: Boolean,
    required: false,
  },
});

const emits = defineEmits(["editComment", "editStatus"]);

const comment = ref(props.comment);
const isChecked = ref(props.isChecked);

const debouncedEditChild = debounce(editComment, 1000);

watch(comment, (v) => {
  if (v !== props.comment) {
    debouncedEditChild();
  }
});

watch(isChecked, (v) => {
  if (v !== props.isChecked) {
    emits("editStatus", data.value);
  }
});

const data = computed(() => ({
  comment: comment.value,
  isChecked: isChecked.value,
  id: props.id,
}));

function editComment() {
  emits("editComment", data.value);
}
</script>
