import CCognations from "@/cognations/common/CCognations.vue";
import CCognationsLayout from "@/cognations/common/CCognationsLayout.vue";

// Мастерские
import WorkshopsLayoutVue from "@/workshops/WorkshopsLayout.vue";
import MasterWorkshopsListVue from "@/workshops/MasterWorkshopsList.vue";
import WorkshopPage from "@/workshops/WorkshopPage.vue";
import WorkshopEditor from "@/workshops/WorkshopEditor.vue";
import WorkshopsJournalVue from "@/workshops/WorkshopsJournal.vue";
import WorkshopBookingPage from "@/workshops/WorkshopBookingPage.vue";
import ThemeEditor from "@/workshops/ThemeEditor.vue";
import ChildWorkshops from "@/usersPages/ChildWorkshops.vue";
import WorkshopsBooking from "@/workshops/WorkshopsBooking.vue";

// Gallery
import GalleryLayout from "@/gallery/GalleryLayout.vue";
import AlbumbsList from "@/gallery/AlbumbsList.vue";
import PhotoGallery from "@/gallery/PhotoGallery.vue";

// reports
import WorkshopChildReport from "@/reports/workshops/WorkshopChildReport.vue";

// notes
import JournalsCommentsLayout from "@/components/notes/JournalsCommentsLayout.vue";
import JournalsCommentsList from "@/components/notes/JournalsCommentsList.vue";
import WorkshopsTimetable from "@/workshops/WorkshopsTimetable.vue";
import RReport from "@/reports/russian-literature/RReport.vue";

import CLayout from "@/consultations/CLayout.vue";
import CognationPage from "@/cognations/CognationPage.vue";

const consultations = {
  path: "/consultations",
  name: "consultations",
  component: CLayout,
};

const cognations = {
  path: "/cognations",
  name: "cognations",
  component: CCognationsLayout,

  children: [
    {
      path: "list",
      name: "common-cognations",
      component: CCognations,
    },
    {
      path: "cognation/:cognationId",
      name: "common-cognations-page",
      component: CognationPage,
    },
  ],
};

const workshopsTimetable = {
  name: "workshops-timetable",
  path: "/workshops-timetable",
  component: WorkshopsTimetable,
};

const workshop = {
  path: "/workshop/:workshopId",
  component: WorkshopBookingPage,
  name: "workshop-page",
};

const workshops = {
  path: "/workshops",
  component: WorkshopsLayoutVue,
  props: (route) => ({
    filterByYear: route.query.filterByYear,
  }),

  children: [
    {
      name: "workshops-booking",
      path: "published/booking",
      component: WorkshopsBooking,
    },
    {
      name: "published-list",
      path: "published",
      component: ChildWorkshops,
    },
    {
      path: "list",
      component: MasterWorkshopsListVue,
      name: "master-workshops",
    },
    {
      path: "edit/:workshopId",
      component: WorkshopPage,
      name: "workshop-edit",
    },
    {
      path: "edit/:workshopId/theme/create",
      component: ThemeEditor,
      name: "theme-create",
    },
    {
      path: "edit/:workshopId/theme/edit/:themeId",
      component: ThemeEditor,
      name: "theme-edit",
    },

    {
      path: "create",
      component: WorkshopEditor,
      name: "workshop-create",
    },
    {
      name: "workshop-journal",
      path: "workshop-journal/:id",
      component: WorkshopsJournalVue,
    },
  ],
};

const gallery = {
  path: "/gallery",
  name: "gallery",
  component: GalleryLayout,

  children: [
    { path: "albums", name: "gallery-albums", component: AlbumbsList },
    {
      path: "album/:albumId/photos",
      name: "album-photos",
      component: PhotoGallery,
    },
  ],
};

const reports = [
  {
    name: "russian-literature-report",
    path: "/report/:subjectId",
    component: RReport,
  },
  {
    name: "workshop-child-report",
    path: "/workshop-child-report",
    component: WorkshopChildReport,
  },
];

const journalsComments = {
  name: "journals-coments",
  path: "/journals-comments",
  component: JournalsCommentsLayout,
  children: [
    {
      name: "child-journals-comments",
      path: "list",
      component: JournalsCommentsList,
      props: (route) => ({
        filterByGroupId: route.query.filterByGroupId,
        filterByChildId: route.query.filterByChildId,
        filterByAuthorId: route.query.filterByAuthorId,
        sortByDate: route.query.sortByDate,
        filterBySearch: route.query.filterBySearch,
      }),
    },
  ],
};

export const common = [
  consultations,
  workshops,
  gallery,
  journalsComments,
  cognations,
  workshopsTimetable,
  ...reports,
  workshop,
];
