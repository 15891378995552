<template>
  <div class="d-flex flex-wrap align-center">
    <div
      v-for="(s, i) in statuses"
      :key="i"
      class="status ma-2 d-flex align-center"
    >
      <div
        class="status__color mr-2"
        :style="{ 'background-color': s.color }"
      ></div>
      <span>{{ s.title }}</span>
    </div>
  </div>
</template>

<script setup>
const statuses = [
  { color: "red", title: "Не усвоено" },
  { color: "yellow", title: "Частично усвоено" },
  { color: "green", title: "Усвоено" },
  { color: "lightgrey", title: "Нет данных" },
];
</script>
<style lang="scss">
.status__color {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  box-shadow: $shadow;
}
</style>
