<template>
  <ListWithDayIdSelectorLayout>
    <template v-slot:title>
      <BackToTable>Оценочные листы</BackToTable>
    </template>

    <template v-slot:content>
      <router-link
        :to="{
          name: 'foreign-rating-sheet-create',
          query: {
            journalId: $route.query.journalId,
            dayId: $route.query.dayId,
          },
        }"
        class="gradient-button"
        >Добавить лист</router-link
      >

      <ul v-if="lists.length > 0">
        <li v-for="l in lists" :key="l.id" class="gradient-list-item mt-4">
          <router-link
            class="gradient-list-item__content"
            :to="{
              name: 'foreign-rating-sheet-edit',
              query: {
                journalId: $route.query.journalId,
                dayId: $route.query.dayId,
                listId: l.id,
              },
            }"
          >
            <h4>{{ l.listDescription }}</h4>
          </router-link>
        </li>
      </ul>
      <v-label
        v-else
        class="d-flex text-deep-purple-darken-1 font-weight-bold mt-5"
        >Список пуст</v-label
      >
    </template>
  </ListWithDayIdSelectorLayout>
</template>

<script setup>
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { storeToRefs } from "pinia";

import ListWithDayIdSelectorLayout from "@/subjectJournal/components/ListWithDayIdSelectorLayout.vue";
import BackToTable from "@/subjectJournal/components/BackToTable.vue";

const { lists } = storeToRefs(useSubjectsJournalStore());
</script>
