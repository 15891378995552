<template>
  <section id="contact" class="section-contact-us">
    <div class="wrapper">
      <FormElement
        v-intersect="onIntersect"
        class="section-contact-us__form"
        :class="{ animated: isIntersecting }"
      >
        <template v-slot:img>
          <svg
            class="contact-us__icon"
            fill="#ffffff"
            viewBox="0 0 512 512"
            enable-background="new 0 0 512 512"
            id="Letter"
            version="1.1"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <g>
                  <g>
                    <path
                      d="M371.518,232.8H38.647v50.149h10v-35.408L188.73,348.063c4.892,3.51,10.622,5.265,16.354,5.265 c5.73,0,11.463-1.755,16.354-5.265l57.081-40.961l-5.83-8.125l-57.081,40.962c-6.296,4.517-14.754,4.517-21.048-0.001 L59.191,242.8h291.782l-47.104,33.802l5.83,8.125l51.818-37.185v175.462l-108.436-84.558l-6.148,7.886l105.051,81.918H58.187 l105.044-81.918l-6.15-7.886L48.647,423.007V302.238h-10V438.25h332.87V232.8z"
                    ></path>
                    <rect
                      height="9.999"
                      transform="matrix(0.8124 -0.5831 0.5831 0.8124 -115.389 224.6395)"
                      width="13.326"
                      x="284.791"
                      y="286.665"
                    ></rect>
                  </g>
                  <g>
                    <rect
                      height="5"
                      width="26.478"
                      x="261.095"
                      y="137.745"
                    ></rect>
                    <rect
                      height="5"
                      width="144.608"
                      x="287.572"
                      y="177.808"
                    ></rect>
                    <rect
                      height="5"
                      width="171.086"
                      x="261.095"
                      y="205.304"
                    ></rect>
                    <rect
                      height="5"
                      width="42.512"
                      x="389.669"
                      y="232.8"
                    ></rect>
                    <rect
                      height="5"
                      width="42.636"
                      x="304.002"
                      y="137.745"
                    ></rect>
                    <rect
                      height="5"
                      width="42.512"
                      x="389.669"
                      y="356.078"
                    ></rect>
                    <rect
                      height="5"
                      width="42.512"
                      x="389.669"
                      y="379.412"
                    ></rect>
                    <path
                      d="M205.443,118.265h-17.819v-16.7c0-9.826,7.994-17.819,17.819-17.819s17.819,7.993,17.819,17.819v113.181h10V101.564 c0-6.775-2.437-12.989-6.477-17.819h213.747c12.583,0,22.819,11.597,22.819,25.851v318.66h-73.684v9.999h83.684V109.596 c0-19.769-14.723-35.851-32.819-35.851h-235.09c-15.34,0-27.819,12.479-27.819,27.819v26.7h27.819V118.265z"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </template>
        <template v-slot:card-title> Связаться с нами </template>

        <template v-slot:form>
          <v-text-field
            flat
            label="Имя"
            solo
            variant="underlined"
            class="v-text-field__input"
          ></v-text-field>
          <v-text-field
            flat
            label="Email"
            solo
            variant="underlined"
            class="v-text-field__input"
          ></v-text-field>
          <v-text-field
            flat
            label="Тема"
            solo
            variant="underlined"
            class="v-text-field__input"
          ></v-text-field>
          <v-textarea
            flat
            label="Ваше сообщение"
            solo
            variant="underlined"
            class="v-text-field__input"
          ></v-textarea>

          <v-switch
            solo
            v-model="pdAgree"
            label="Даю согласие на обработку ПД"
            color="green"
            hide-details
            class="section-contact-us__switch"
          ></v-switch>

          <v-btn
            class="my-2 w-100"
            variant="outlined"
            rounded="pill"
            color="#FA9DB7"
          >
            Отправить
          </v-btn>
        </template>
      </FormElement>
    </div>
  </section>
</template>

<script>
import FormElement from "../elements/FormElement.vue";
export default {
  components: { FormElement },
  data() {
    return {
      pdAgree: false,
      isIntersecting: false,
    };
  },
  methods: {
    onIntersect(isIntersecting) {
      if (isIntersecting) {
        this.isIntersecting = isIntersecting;
      }
    },
  },
};
</script>

<style lang="scss">
.section-contact-us {
  padding: 0 16px;
  margin-top: 50px;
  margin-bottom: 50px;

  &__switch {
    color: #c7c7c7 !important;

    .v-label {
      color: #c7c7c7;
    }

    .text-green + .v-label {
      color: green;
    }
  }

  &__form {
    opacity: 0;
    -webkit-transform: translate3d(20%, 0, 0);
    transform: translate3d(20%, 0, 0);

    &.animated {
      -webkit-animation: slideFade 0.4s cubic-bezier(0.12, 0, 0.39, 0) 0s 1
        forwards;
      animation: slideFade 0.4s cubic-bezier(0.12, 0, 0.39, 0) 0s 1 forwards;
    }
  }
}
</style>
