<template>
  <v-autocomplete
    v-if="eventsTypesSelector.length"
    v-model="selectedEventType"
    :items="eventsTypesSelector"
    variant="outlined"
    placeholder="Тип занятия"
    item-value="value"
    item-title="title"
    density="compact"
    hide-details
    clearable
  ></v-autocomplete>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { onMounted, watch } from "vue";
import { useEventsTypesData } from "@/composables/eventsTypesData";
import { pushFilterToURL, removeFilterFromURL } from "@/service/router";
import { useRoute } from "vue-router";

const { getEventsTypes, eventsTypesSelector, selectedEventType } =
  useEventsTypesData();
const route = useRoute();

onMounted(async () => {
  await getEventsTypes();

  setEventType();
});

watch(() => route.query.filterByEventType, setEventType);

function setEventType() {
  if (!eventsTypesSelector.value.length) {
    return;
  } else if (!route.query.filterByEventType) {
    selectedEventType.value = eventsTypesSelector.value[0].value;
  }

  selectedEventType.value =
    eventsTypesSelector.value.findIndex(
      (v) => v.value === Number(route.query.filterByEventType)
    ) >= 0
      ? Number(route.query.filterByEventType)
      : null;
}

watch(selectedEventType, (id) =>
  id
    ? pushFilterToURL({ name: "filterByEventType", value: id })
    : removeFilterFromURL("filterByEventType")
);
</script>
