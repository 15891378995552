<template>
  <CObserver @show="addAnimatedClass">
    <slot></slot>
  </CObserver>
</template>

<script>
import CObserver from "./CObserver.vue";

export default {
  components: {
    CObserver,
  },
  methods: {
    addAnimatedClass(el) {
      el.target.children[0].classList.add("animated");
    },
  },
};
</script>
