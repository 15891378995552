<template>
  <div class="page-wrapper">
    <h2 class="text-center mb-4">Пользовательское cоглашение</h2>

    <div>
      <h4 class="text-center pa-4">1. Общие положения</h4>

      <p class="text-justify">
        Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует
        отношения между ОАНО «Образовательный центр «Эврика» (Администрация) с
        одной стороны и пользователем сайта
        <a href="https://school.eurekacenter.ru/" class="card-link"
          >https://school.eurekacenter.ru/</a
        >
        с другой (далее - сайт).
      </p>
      <p class="text-justify">
        Сайт не является средством массовой информации.
      </p>
      <p class="text-justify">
        Используя сайт , Вы соглашаетесь с условиями данного соглашения. Если Вы
        не согласны с условиями данного соглашения, не используйте сайт.
      </p>

      <h4 class="text-center pa-4">2. Предмет соглашения</h4>

      <p class="text-justify">
        Администрация предоставляет пользователю право на размещение на сайте
        текстовой информации, аудио, видео и фото материалов, результатов
        интеллектуальной деятельности, ссылок на материалы, размещенные на
        других сайтах.
      </p>

      <h4 class="text-center pa-4">3. Права и обязанности сторон</h4>

      <p class="text-justify">
        Пользователь имеет право осуществлять поиск и получение информации на
        сайте, создавать информацию для сайта, информацию сайта в личных
        некоммерческих целях для осуществления своих должностных функций или
        получения образовательных услуг.
      </p>

      <p class="card-text">
        Администрация имеет право по своему усмотрению и необходимости
        создавать, изменять, отменять правила, ограничивать доступ к любой
        информации на сайте. Создавать, изменять, удалять информацию. Удалять
        учетные записи. Отказывать в регистрации без объяснения причин.
      </p>

      <p class="card-text">
        Пользователь обязуется обеспечить достоверность предоставляемой
        информации. Обеспечивать сохранность личных данных от доступа третьих
        лиц. Обновлять Персональные данные, предоставленные при регистрации, в
        случае их изменения. Не распространять информацию, которая направлена на
        пропаганду войны, разжигание национальной, расовой или религиозной
        ненависти и вражды, а также иной информации, за распространение которой
        предусмотрена уголовная или административная ответственность. Не
        нарушать работоспособность сайта. Не создавать несколько учётных записей
        на Сайте, если фактически они принадлежат одному и тому же лицу. Не
        передавать в пользование свою учетную запись и/или логин и пароль своей
        учетной записи третьим лицам. Не регистрировать учетную запись от имени
        или вместо другого лица за исключением случаев, предусмотренных
        законодательством РФ. Не размещать материалы рекламного, эротического,
        порнографического или оскорбительного характера, а также иную
        информацию, размещение которой запрещено или противоречит нормам
        действующего законодательства РФ. Не использовать скрипты (программы)
        для автоматизированного сбора информации и/или взаимодействия с Сайтом и
        его Сервисами.
      </p>

      <p class="text-justify">
        Администрация обязуется поддерживать работоспособность сайта за
        исключением случаев, когда это невозможно по независящим от
        Администрации причинам.
      </p>

      <h4 class="text-center pa-4">4. Ответственность сторон</h4>

      <p class="card-text">
        Пользователь лично несет полную ответственность за распространяемую им
        информацию.
      </p>
      <p class="card-text">
        Администрация не несет никакой ответственности за услуги,
        предоставляемые третьими лицами.
      </p>
      <p class="card-text">
        В случае возникновения форс-мажорной ситуации (боевые действия,
        чрезвычайное положение, стихийное бедствие и т. д.) Администрация не
        гарантирует сохранность информации, размещённой Пользователем, а также
        бесперебойную работу информационного ресурса.
      </p>

      <h4 class="text-center pa-4">5. Условия действия Соглашения</h4>
      <p class="card-text">
        Данное Соглашение вступает в силу при любом использовании данного сайта.
      </p>
      <p class="card-text">
        Соглашение перестает действовать при появлении его новой версии.
      </p>
      <p class="card-text">
        Администрация оставляет за собой право в одностороннем порядке изменять
        данное соглашение по своему усмотрению.
      </p>
      <p class="card-text">
        Администрация не оповещает пользователей об изменении в Соглашении.
      </p>
    </div>
  </div>
</template>
