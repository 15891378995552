<template>
  <div>
    <ul class="list">
      <li
        v-for="r in currentList"
        :key="r.id"
        class="pa-2 bg-deep-purple-lighten-5 rounded my-1"
      >
        <p class="text-bold">{{ r[itemTitleKey] }}</p>
      </li>
    </ul>

    <AppButton v-if="list.length > SHOWED_ITEMS" @click="showAll = !showAll">
      <span v-if="showAll">Скрыть</span>
      <span v-else>Показать ещё {{ list.length - SHOWED_ITEMS }}</span>

      <CIcon v-if="showAll" icon="chevron-top-icon" />
      <CIcon v-else icon="chevron-down-icon" />
    </AppButton>
  </div>
</template>

<script setup>
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";

import { ref, computed, defineProps } from "vue";

const SHOWED_ITEMS = 5;

const props = defineProps({
  list: {
    type: Array,
    required: true,
  },
  itemTitleKey: {
    type: String,
    required: true,
  },
});

const showAll = ref(false);

const currentList = computed(() => {
  return showAll.value ? props.list : props.list.slice(0, SHOWED_ITEMS);
});
</script>
