<template>
  <div class="page-wrapper">
    <div class="table-simple">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Слова</th>
            <th>Ошибки</th>
            <th>Итог</th>
            <th>Компетенции</th>
          </tr>
        </thead>
        <tbody>
          <DictationRow
            v-for="(c, i) in sortedChildren"
            :key="c.id"
            :child="c"
            :idx="i"
            @updateLists="emits('updateLists')"
            @click="lastEditRowId = i"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { useSubjectsJournalStore } from "@/store/subjects-journal";

import { computed, defineEmits, ref } from "vue";
import { sortByChildLastname } from "@/util/common";

import DictationRow from "./DictationRow.vue";

const emits = defineEmits(["updateLists"]);

const route = useRoute();
const { listById } = useSubjectsJournalStore();

const currentList = computed(() => listById(route.query.listId));
const lastEditRowId = ref(null);

const sortedChildren = computed(() => {
  if (!currentList.value || !currentList.value.childList.length) {
    return [];
  }

  return sortByChildLastname(currentList.value.childList);
});
</script>
