<template>
  <div>
    <TableElement>
      <template v-slot:tabs>
        <v-tabs bg-color="primary-dark" v-model="tab">
          <v-tab value="1">Группы пользователей</v-tab>
          <v-tab :value="2" :disabled="true" >Подгруппы</v-tab>
        </v-tabs>
      </template>
      <template v-slot:window>
        <v-card-text class="align-self-stretch h-75 ma-0 pa-0">
          <v-window v-model="tab" class="align-self-stretch" :touch="{left: null, right: null}">
            <v-window-item value="1" class="align-self-stretch">
              <v-card-title class="card_ligth_header">
                <h3>Группы пользователей</h3>
              </v-card-title>
              <v-card-title style="min-height: 5vh" class="card_ligth_header">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="groupSearchBody"
                        label="Найти"
                        @click:clear="clearSearch"
                        variant="outlined"
                        clearable
                        hint="Введите ключевое слово для поиска"
                        rounded
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                        @click="groupDialog = true"
                        variant="outlined"
                        color="primary"
                        rounded
                    >
                      Добавить
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="ma-0 pa-0">
                <div class="d-sm-none d-flex align-center flex-column" v-if="!!groupsPaginatedData">
                  <v-card
                      class="ma-4 mx-4 w-100"
                      v-for="item in groupsPaginatedData"
                      :key="item.id"
                  >
                    <v-card-title class="text-pink-lighten-2 bg-pink-lighten-5 ">
                      <h5>
                        Группа пользователей №
                        {{ filteredUserGroups.indexOf(item) + 1 }}
                      </h5>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-list-item lines="two">
                        <v-list-item-title>Название: </v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.personGroupName
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Описание:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.personGroupDescription
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Добавлена:</v-list-item-title>
                        <v-list-item-subtitle>{{
                            item.personGroupCreateDate
                          }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item lines="two">
                        <v-list-item-title>Активна:</v-list-item-title>
                        <v-list-item-subtitle v-if="item.isLocked"
                        >Нет</v-list-item-subtitle
                        >
                        <v-list-item-subtitle v-else>Да</v-list-item-subtitle>
                      </v-list-item>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="text-pink-lighten-2 bg-pink-lighten-5 d-flex flex-wrap">
                      <v-btn
                          v-if="item.isChilds"
                          @click="getSubgroups(item)"
                          color="primary"
                          class="ma-2"
                          variant="outlined"
                          rounded
                      >
                        Подгруппы
                      </v-btn>
                      <v-btn
                          @click="editUserGroup(item)"
                          color="primary"
                          class="ma-2"
                          size="small"
                          variant="outlined"
                          rounded
                      >
                        Редактировать
                      </v-btn>

                      <v-btn
                          @click="deleteUserGroup(item)"
                          class="ma-2"
                          variant="outlined"
                          size="small"
                          color="warning"
                          rounded
                      >
                        Удалить
                      </v-btn>
                      <v-btn
                          v-if="item.isChilds"
                          @click="groupMetricClasses(item)"
                          class="ma-2"
                          variant="outlined"
                          color="warning"
                          size="small"
                          rounded
                      >
                        Предметы
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
                <div class="d-none d-sm-block" v-if="!!userGroups">
                  <Vue3EasyDataTable
                      :headers="userGroupHeaders"
                      :items="userGroups"
                      :search-value="groupSearchBody"
                      multi-sort
                      alternating
                      show-index
                  >
                    <template
                        #item-personGroupCreateDate="{ personGroupCreateDate }"
                    >
                      <p>{{ personGroupCreateDate }}</p>
                    </template>
                    <template #item-isLocked="{ isLocked }">
                      <v-chip size="small" v-if="!!isLocked">Не активна</v-chip>
                      <v-chip size="small" v-else>Активна</v-chip>
                    </template>

                    <template #item-actions="item">
                      <v-btn
                          v-if="item.isChilds"
                          @click="getSubgroups(item)"
                          color="primary"
                          class="ma-2"
                          variant="outlined"
                          rounded
                      >
                        Подгруппы
                      </v-btn>
                      <v-btn
                          v-if="item.isChilds"
                          @click="groupMetricClasses(item)"
                          class="ma-2"
                          variant="outlined"
                          color="warning"
                          rounded
                      >
                        Предметы
                      </v-btn>
                      <v-btn
                          @click="editUserGroup(item)"
                          color="primary"
                          class="ma-2"
                          variant="outlined"
                          rounded
                      >
                        Редактировать
                      </v-btn>

                      <v-btn
                          @click="deleteUserGroup(item)"
                          class="ma-2"
                          variant="outlined"
                          color="warning"
                          rounded
                      >
                        Удалить
                      </v-btn>
                    </template>
                  </Vue3EasyDataTable>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <div class="d-sm-none d-flex align-center flex-column" >
                <v-card-actions
                    style="min-height: 5vh"
                    class="card_ligth_actions d-sm-block"
                >
                  <v-container class="text-center">
                    <v-pagination
                        v-model="groupPage"
                        :length="groupPageCount"
                        :total-visible="3"
                        size="small"
                    ></v-pagination>
                  </v-container>
                </v-card-actions>
              </div>
            </v-window-item>
            <v-window-item :value="2" class="align-self-stretch">
              <div v-if="!!selectedGroup">
                <v-card-title class="card_ligth_header">
                  <h3> Подгруппы: {{selectedGroup.personGroupName}}</h3>
                </v-card-title>
                <v-card-title style="min-height: 5vh" class="card_ligth_header">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="subgroupSearchBody"
                          label="Найти"
                          variant="outlined"
                          clearable
                          hint="Введите ключевое слово для поиска"
                          rounded
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                          @click="addSubgroup"
                          variant="outlined"
                          color="primary"
                          class="mb-4"
                          rounded
                      >
                        Добавить
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text class="ma-0 pa-0">
                  <Vue3EasyDataTable
                      :headers="subgroupHeaders"
                      :items="subgroups"
                      :search-value="subgroupSearchBody"
                      multi-sort
                      alternating
                      show-index
                  >

                    <template #item-members="{ members}">
                      <v-chip-group>
                        <v-chip v-for="m in members" :key="m.id" size="small">{{ m.firstname }} {{m.lastname}}</v-chip>
                      </v-chip-group>
                    </template>

                    <template #item-actions="item">
                      <v-btn
                          @click="editSubgroup(item)"
                          color="primary"
                          class="ma-2"
                          variant="outlined"
                          rounded
                      >
                        Редактировать
                      </v-btn>

                      <v-btn
                          @click="deleteSubgroup(item)"
                          class="ma-2"
                          variant="outlined"
                          color="warning"
                          rounded
                      >
                        Удалить
                      </v-btn>
                    </template>
                  </Vue3EasyDataTable>
                </v-card-text>
              </div>
            </v-window-item>
          </v-window>
        </v-card-text>
      </template>
    </TableElement>
  </div>

  <v-dialog v-model="groupDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h3>Группа пользователей</h3>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-4">
        <v-form ref="userGroupForm">
          <v-row class="w-100">
            <v-col cols="12">
              <v-text-field
                  label="Название*"
                  v-model="personGroupName"
                  :rules="personGroupNameRules"
                  hint="Укажите название группы"
                  :counter="30"
                  required
                  variant="outlined"
              ></v-text-field>
              <v-alert density="compact" type="error" v-show="existGroupError"
              >Группа уже существует!
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-textarea
                  label="Описание*"
                  v-model="personGroupDescription"
                  :rules="personGroupDescriptionRules"
                  :counter="255"
                  hint="Укажите описание"
                  variant="outlined"
                  required
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-container class="px-0" fluid>
                <v-switch
                    v-model="isLocked"
                    :label="isLocked ? 'Заблокирована' : 'Активна'"
                ></v-switch>
              </v-container>
            </v-col>
            <v-col cols="12">
              <v-container class="px-0" fluid>
                <v-switch
                    v-model="isChilds"
                    :label="isChilds ? 'Обучающиеся' : 'Не обучающиеся'"
                ></v-switch>
              </v-container>
            </v-col>

            <v-col cols="12" v-if="isChilds">
              <v-autocomplete
                  v-model="studyYearId"
                  label="Учебный год"
                  variant="outlined"
                  item-title="studyYearName"
                  item-value="id"
                  :items="studyYears"
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item
                      v-bind="props"
                      :title="item.raw.studyYearName"
                  ></v-list-item>
                </template>
              </v-autocomplete>
            </v-col>


          </v-row>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn rounded variant="outlined" @click="closeUserGroupForm">
          Отмена
        </v-btn>
        <v-btn rounded variant="outlined" @click="saveGroup"> Сохранить </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="showGroupClassForm" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h3>Предметы для <span v-if="selectedGroup!==null">"{{selectedGroup.personGroupName}}"</span></h3>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="userGroupForm">


          <v-col cols="12">
            <v-autocomplete
                v-model="classes"
                label="Предметы для раздела текущие и ожидаемые показатели"
                variant="outlined"
                :custom-filter="scheduleClassFilter"
                multiple
                chips
                closable-chips
                clearable
                :items="scheduleClasses"
            >
              <template v-slot:chip="{ props, item }">
                <v-chip v-bind="props" :text="item.raw.scheduleClassName"></v-chip>
              </template>
              <template v-slot:item="{ props, item }">
                <v-list-item
                    v-bind="props"
                    :title="item.raw.scheduleClassName"
                ></v-list-item>
              </template>

            </v-autocomplete>
          </v-col>

        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn rounded variant="outlined" @click="closeClassesForm">
          Отмена
        </v-btn>
        <v-btn rounded variant="outlined" @click="saveClasses"> Сохранить </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="showLoader" persistent
  ><v-alert><span class="text-h5 text-primary">Загрузка данных
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular></span
  ></v-alert>
  </v-dialog>
  <v-dialog v-model="subgroupDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h3>Подгруппа для: {{selectedGroup.personGroupName}}</h3>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-4">
        <v-form ref="subgroupForm">
          <v-row class="w-100">
            <v-col cols="12">
              <v-text-field
                  label="Название*"
                  v-model="subgroupName"
                  :rules="subgroupNameRules"
                  hint="Укажите название "
                  :counter="30"
                  required
                  variant="outlined"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                  label="Описание*"
                  v-model="subgroupDescription"
                  :rules="subgroupDescriptionRules"
                  :counter="250"
                  hint="Укажите описание"
                  variant="outlined"
                  required
              ></v-textarea>
            </v-col>
            <v-col v-if="!!selectedGroup.groupPersons && selectedGroup.groupPersons.length>0">
              <v-autocomplete
                  v-model="members"
                  multiple
                  clearable
                  v-on:click:clear="members=[]"
                  label="Члены"
                  variant="outlined"
                  item-title="firstname"
                  return-object
                  item-value="id"
                  :items="selectedGroup.groupPersons.sort(function (a, b) {
              if (a.lastname > b.lastname) {
                  return 1;
              }
              if (a.lastname < b.lastname) {
                  return -1;
              }
              // a должно быть равным b
              return 0;})"
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item
                      v-bind="props"
                      :title="item.raw.lastname + ' ' + item.raw.firstname"
                  ></v-list-item>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" v-else>
              <p>Группа не содержит пользователей</p>
            </v-col>


          </v-row>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn rounded variant="outlined" @click="closeSubgroupForm">
          Отмена
        </v-btn>
        <v-btn rounded variant="outlined" @click="saveSubgroup"> Сохранить </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import Vue3EasyDataTable from "vue3-easy-data-table";
import TableElement from "../elements/TableElement.vue";
import {customAxios} from "@/service/customAxios";


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: "AdminUserGroupPage",
  components: {
    Vue3EasyDataTable,
    TableElement,
  },

  props: [],
  data: () => ({
    subgroupName:'',
    subgroupNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    subgroupDescription:'',

    subgroupDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 250) || "Значение до должно превышать 250 символов!",
    ],
    personGroupId:'',
    members: [],
    subgroups:[],
    subgroupSearchBody:'',


    subgroupDialog:false,


    studyYears:[],
    studyYearId:undefined,

    userGroupHeaders: [
      { text: "Группа", value: "personGroupName", sortable: true},
      { text: "Описание", value: "personGroupDescription", sortable: true},
      { text: "Добавлена", value: "personGroupCreateDate"},
      { text: "Состояние", value: "isLocked"},
      { text: "Действие", value: "actions", sortable: true},
    ],
    subgroupHeaders: [
      { text: "Имя", value: "subgroupName", sortable: true},
      { text: "Описание", value: "subgroupDescription", sortable: true},
      { text: "Участники", value: "members"},
      { text: "Действие", value: "actions", sortable: true},
    ],
    showLoader: false,

    page: 1,
    noteCount: 20,

    personGroups: [],
    roles: [],
    //personGroupsSource:[],
    valid: true,
    dialog: false,
    searchBody: "",
    showInput: false,
    loading: false,

    isChilds:false,

    groupSearchBody: "",
    groupDialog: false,
    personGroupName: "",
    personGroupDescription: "",
    isLocked: false,
    existGroupError: false,
    personGroupNameForCheck: "",
    groupPage: 1,
    userGroups: [],
    tab: 0,
    personGroupNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 30) || "Значение до должно превышать 30 символов!",
    ],
    personGroupDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 255) || "Значение до должно превышать 255 символов!",
    ],


    userGroupSortKey: "id",
    userGroupLastSortKey: "id",
    userGroupSortOrder: 1,

    scheduleClasses: [],
    classes:[],
    childGroupClasses:[],
    userGroupsForSelect:[],
    groupClasses:{},
    showGroupClassForm: false,
    selectedGroup:null,
    active:false,

    id:null,
    personGroupsForFilter: "",

  }),
  methods: {

    addSubgroup(){
      this.subgroupDialog = true;
    },

    closeSubgroupForm(){
      this.subgroupName = '';
      this.subgroupDescription = '';
      this.personGroupId = '';
      this.members = [];
      this.subgroupDialog = false;
    },
    saveSubgroup(){

      this.$refs.subgroupForm.validate().then((responce) => {
        if (responce.valid) {
          let subgroup = {
            subgroupName: capitalizeFirstLetter(this.subgroupName),
            subgroupDescription: capitalizeFirstLetter(this.subgroupDescription),
            members: this.members,
            personGroupId: this.selectedGroup.id
          };

          if (this.id) {
            customAxios.put("/user_group/subgroup/" + this.id, subgroup)
                .then((response) => {
                  if (response.status === 202) {
                    this.closeSubgroupForm()
                    let index = getIndex(this.subgroups, response.data.id);
                    this.subgroups.splice(index, 1, response.data);

                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

          }
          else {
            customAxios.post( "/user_group/subgroup", subgroup)
                .then((response) => {
                  if (response.status === 201) {
                    this.closeSubgroupForm();
                    this.subgroups.push(response.data);
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
          }
        }
      });
    },
    editSubgroup(item){
      this.subgroupName = item.subgroupName;
      this.subgroupDescription = item.subgroupDescription;
      this.members = item.members;
      this.id = item.id;
      this.subgroupDialog = true;
    },
    deleteSubgroup(item){
      customAxios.delete( "/user_group/subgroup/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              this.subgroups.splice(this.subgroups.indexOf(item), 1);
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error);
          });

    },
    getSubgroups(item){
      this.selectedGroup = item;

      customAxios.get( "/user_group/subgroup/" + item.id)
          .then((response) => {
            if (response.status === 200) {
              this.subgroups = response.data;
              this.selectedGroup = item;
              this.showLoader = false;
              this.tab =2;

            } else {
              this.showLoader = false;
              console.log(response);
            }
          })
          .catch((error) => {
            this.showLoader = false;
            console.log(error);
          });
    },


    scheduleClassFilter(item, queryText, itemText) {
      return (
          itemText.raw.scheduleClassName
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },

    formatDate(dateString){
      let formatter = new Intl.DateTimeFormat("ru", {
        // weekday: "long",
        year: "numeric",
        month: "numeric",
        day: "numeric",

      });

      return capitalizeFirstLetter(formatter.format(Date.parse(dateString)));

    },

    editUserGroup: function (event) {
      this.id = event.id;
      this.studyYearId = event.studyYearId;
      this.isChilds=event.isChilds;
      this.personGroupName = event.personGroupName;
      this.personGroupDescription = event.personGroupDescription;
      this.isLocked = event.isLocked;
      this.groupDialog = true;
    },
    groupMetricClasses: function (item) {
      console.log(item.id);
      this.selectedGroup = item;
      customAxios.get( "/child/metric/classes/" + item.id)
          .then((response) => {
            if (response.status === 200) {
              this.active=response.data.active;
              this.classes=response.data.classes;
              this.groupClasses = response.data;
              this.showLoader = false;
              this.showGroupClassForm = true;
            } else {
              this.showLoader = false;
              console.log(response);
            }
          })
          .catch((error) => {
            this.showLoader = false;
            console.log(error);
          });
    },
    closeClassesForm: function (){
      this.showGroupClassForm = false;
      // this.selectedGroup = null;
      this.classes = [];


    },
    saveClasses: function (){

      let groupClasses = {
        classes: this.classes,
      };
      customAxios.put( "/child/metric/classes/" + this.groupClasses.id, groupClasses)
          .then((response) => {
            if (response.status === 202) {
              alert("Данные успешно сохранены!")
              this.showGroupClassForm = false;
              // this.selectedGroup = null;
              this.classes = [];

            } else {
              alert("Ошибка при сохранении данных!")
              console.log(response);
            }
          })
          .catch((error) => {
            alert("Ошибка при сохранении данных!")
            console.log(error);
          });

    },


    deleteUserGroup: function (item) {
      customAxios.delete( "/user_group/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              this.userGroups.splice(this.userGroups.indexOf(item), 1);
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    closeUserGroupForm() {
      this.groupDialog = false;
      this.personGroupName = "";
      this.isChilds=false;
      this.personGroupDescription = "";
      this.isLocked = false;
    },
    saveGroup() {

      this.$refs.userGroupForm.validate().then((responce) => {
        if (responce.valid) {
          customAxios.get("/user_group/exist/" + capitalizeFirstLetter(this.personGroupName))
              .then((res) => {
                this.personGroupNameForCheck = res.data;
                console.log("personGroupNameForCheck: ", res.data);
                let userGroup = {
                  id: this.id,
                  isChilds: this.isChilds,
                  personGroupName: capitalizeFirstLetter(this.personGroupName),
                  personGroupDescription: capitalizeFirstLetter(this.personGroupDescription),
                  isLocked: this.isLocked,
                  studyYearId: this.studyYearId
                };

                if (this.personGroupNameForCheck.groupExist) {
                  console.log("group exist");
                  if (
                      this.id &&
                      this.personGroupNameForCheck.ownerId === this.id &&
                      this.personGroupNameForCheck.name === this.personGroupName
                  ) {
                    customAxios
                        .put(
                             "/user_group/" + this.id,
                            userGroup
                        )
                        .then((response) => {
                          if (response.status === 202) {
                            let index = getIndex(this.userGroups, response.data.id);
                            this.userGroups.splice(index, 1, response.data);
                            this.groupDialog = false;
                            this.id = null;
                            this.isChilds=false;
                            this.personGroupName = "";
                            this.personGroupDescription = "";
                            this.isLocked = false;
                            this.existGroupError = false;
                          } else {
                            alert("Что-то пошло не так");
                            console.log(response);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                  } else {
                    console.log("Группа с таким именем уже существует!");
                    this.existGroupError = true;
                  }
                } else {
                  if (this.id) {
                    customAxios
                        .put(
                             "/user_group/" + this.id,
                            userGroup
                        )
                        .then((response) => {
                          if (response.status === 202) {
                            let index = getIndex(this.userGroups, response.data.id);
                            this.userGroups.splice(index, 1, response.data);
                            this.groupDialog = false;
                            this.id = null;
                            this.isChilds=false;
                            this.personGroupName = "";
                            this.personGroupDescription = "";
                            this.isLocked = false;
                            this.existGroupError = false;

                          } else {
                            alert("Что-то пошло не так");
                            console.log(response);
                          }
                        })

                  } else {
                    customAxios
                        .post( "/user_group", userGroup)
                        .then((response) => {
                          if (response.status === 201) {
                            this.userGroups.push(response.data);
                            this.groupDialog = false;
                            this.personGroupName = "";
                            this.isChilds=false;
                            this.personGroupDescription = "";
                            this.isLocked = false;
                            this.existGroupError = false;
                          } else {
                            alert("Что-то пошло не так");
                            console.log(response);
                          }
                        })
                  }
                }
              })
              .catch((error) => console.log(error));
        }
      });
    },


    clearSearch() {
      this.groupSearchBody = "";
    },


    userGroupFilter(itemText, queryText,item) {
      console.log("filter item: " + item);
      console.log("filter itemText: " + itemText);
      const textPersonGroupName = item.raw.personGroupName.toLowerCase();
      const textPersonGroupDescription = item.raw.personGroupDescription.toLowerCase();

      const searchText = queryText.toLowerCase();

      return (
          textPersonGroupName.indexOf(searchText) > -1 ||
          textPersonGroupDescription.indexOf(searchText) > -1
      );
    },
    userGroupNameFilter(item, queryText) {
      console.log("filter item: " + item);
      const textPersonGroupName = item.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
          textPersonGroupName.indexOf(searchText) > -1
      );
    },
  },
  computed: {

    personGroupsSource() {
      let tempGroups = this.userGroups;
      return tempGroups.filter((item) => item.isLocked === false);
    },

    filteredUserGroups() {
      const ss = this.groupSearchBody.toLowerCase();

      let tempGroups = this.userGroups;

      return tempGroups.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );
    },
    sortedUserGroups() {
      //const k = this.sortKey;
      let tempGroups = this.filteredUserGroups;
      return tempGroups.sort(
          dynamicSort(this.userGroupSortKey, this.userGroupSortOrder)
      );
    },
    groupPageCount() {
      let l = this.filteredUserGroups.length,
          s = parseInt(this.noteCount);
      console.log("Page count: " + Math.ceil(l / s));

      return Math.ceil(l / s);
    },
    groupsPaginatedData() {
      const start = (this.currentGroupPage - 1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.sortedUserGroups.slice(start, end);
    },
    currentGroupPage() {
      if (this.groupPageCount < this.groupPage) {
        return 1;
      } else {
        return this.groupPage;
      }
    },

    currentPage() {
      if (this.pageCount < this.page) {
        return 1;
      } else {
        return this.page;
      }
    },

    loggedIn() {
      return this.$store.state.authentication.status.loggedIn;
    },
    user() {
      return this.$store.state.authentication.user;
    },
    authentication() {
      return this.$store.state.authentication;
    },
  },
  created: function () {
    this.showLoader = true;


    function getScheduleClasses(customAxios) {
      return customAxios.get( "/schedule/class/for_metrics/active");
    }

    function getUserGroups(customAxios) {
      return customAxios.get( "/user_group");
    }


    function getStudyYears(customAxios) {
      return customAxios.get( "/schedule/year");
    }

    Promise.all([
      getUserGroups( customAxios),
      getScheduleClasses( customAxios),
      getStudyYears( customAxios),

    ])
        .then((results) => {
          if (results[0].status === 200) {
            this.userGroups = results[0].data;
          }
          if (results[1].status === 200) {
            this.scheduleClasses = results[1].data;
          }

          if (results[2].status === 200) {
            this.studyYears = results[2].data;
            if(this.studyYears){
              this.studyYearId = this.studyYears[0].id;
            }
          }
          this.showLoader = false;
        })
        .catch(() => {
          this.showLoader = false;
          alert("Ошибка загрузки данных, обратитесь к администратору системы!");
        });
  },
};

function dynamicSort(property, sortOrder) {
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
</script>
