<template>
  <div class="page-wrapper">
    <div class="d-flex justify-space-between">
      <h3 class="mb-2">Задания</h3>
      <router-link
        :to="{
          name: 'rating-sheet-task-create',
          query: {
            journalId: $route.query.id,
            dayId: $route.query.dayId,
            listId: $route.query.listId,
          },
        }"
        class="gradient-button"
        >Добавить задание
      </router-link>
    </div>

    <ul v-if="currentList.skillListSkills.length" class="mt-4">
      <li
        v-for="s in currentList.skillListSkills"
        :key="s.id"
        class="gradient-list-item"
      >
        <router-link
          class="gradient-list-item__content"
          :to="{
            name: 'rating-sheet-task-edit',
            query: {
              journalId: $route.query.id,
              dayId: $route.query.dayId,
              taskId: s.id,
              listId: $route.query.listId,
            },
          }"
          ><h4>{{ s.skillDescription }}</h4>
        </router-link>
      </li>
    </ul>
    <v-label
      v-else
      class="d-flex text-deep-purple-darken-1 font-weight-bold mt-5"
      >Список пуст</v-label
    >
  </div>
</template>

<script setup>
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const { listById } = useSubjectsJournalStore();
const currentList = computed(() => listById(route.query.listId));
</script>
