<template>
  <div class="table-simple">
    <table>
      <thead>
        <th></th>
        <th v-for="d in props.journal.attendanceDays" :key="d.id">
          {{ d.attendanceDayDate.split("").slice(-2).join("") }}
        </th>
      </thead>
      <tbody>
        <tr v-for="c in props.journal.attendanceChilds" :key="c.id">
          <td>{{ c.child.lastname }} {{ c.child.firstname }}</td>

          <AttendanceStatusCell
            v-for="d in c.dayRecords"
            :key="d.id"
            :record="d"
            @update="emits('update')"
            @openPopup="emits('openPopup', $event)"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

import AttendanceStatusCell from "./AttendanceStatusCell.vue";

const props = defineProps({ journal: { type: Object } });
const emits = defineEmits(["update", "openPopup"]);
</script>
