<template>
  <div class="workshop-preview">
    <div class="workshop-preview__top">
      {{ getDateWithWeekday(item.dayDate) }}
    </div>

    <ul v-if="item.dayWorkshopsEvents.length" class="list">
      <li v-for="(w, i) in item.dayWorkshopsEvents" :key="i" class="my-1">
        <router-link
          :to="{
            name: 'workshop-page',
            params: { workshopId: w.workshop.id },
          }"
          class="link"
        >
          <p v-if="w.workshop.workshopName">
            <span>{{ getTimeFromDate(w.eventDateTimeBegin) }}</span>
            <span>{{ w.workshop.workshopName }}</span>
          </p>

          <p v-if="isAdmin">(ID: {{ w.workshop.id }})</p>
        </router-link>
      </li>
    </ul>
    <p v-else class="text-grey">В этот день нет мастерских</p>
  </div>
</template>

<script setup>
import { useDatesChanger } from "@/composables/datesChanger";
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
import { defineProps } from "vue";
defineProps({ item: { type: Object, required: true } });
const { getDateWithWeekday, getTimeFromDate } = useDatesChanger();
const { isAdmin } = storeToRefs(useAuthStore());

</script>

<style lang="scss">
.workshop-preview {
  border-radius: 16px;
  box-shadow: $light-shadow;
  background-color: #fff;
  transition: 0.2s all;

  ul {
    padding: 8px;
  }

  p {
    color: $secondary;
    transition: 0.2s all;

    span:first-child {
      font-weight: 600;
      margin-right: 8px;
    }

    &:hover {
      font-weight: 600;

      span:last-child {
        text-decoration: underline;
      }
    }
  }

  &__top {
    background-color: $secondary;
    color: #fff;
    padding: 8px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  &:hover {
    background-color: $secondary;
    color: #fff;

    p {
      color: #fff;
    }
  }
}
</style>
