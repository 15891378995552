<template>
  <div>
    <div v-if="uploadPercentage === 0">
      <ul v-if="files.length" class="list">
        <li
          v-for="(file, i) in files"
          :key="file.id"
          class="my-3 d-flex align-center justify-space-between flex-wrap"
        >
          <p class="mr-2 mb-2">
            <span v-if="file.fileDescrption">{{ file.fileDescrption }}</span>
            <span v-if="file.fileName"> ({{ file.fileName }})</span>
            <span v-else>Файл {{ i + 1 }}</span>
          </p>
          <div class="d-flex">
            <AppButton
              v-if="links.downloadUrl"
              @click="downloadFile(file)"
              class="mr-2 bordered-item d-flex align-center justify-space-between pa-2"
            >
              <v-icon icon="mdi-download"></v-icon>Скачать
            </AppButton>

            <AppButton
              v-if="$route.path.includes('edit')"
              @click="removeFile(file)"
              class="bordered-item d-flex align-center justify-space-between pa-2"
            >
              <v-icon icon="mdi-delete"></v-icon>Удалить
            </AppButton>
          </div>
        </li>
      </ul>

      <div v-else class="mt-3 text-grey">Нет файлов</div>
    </div>

    <div v-else class="mt-5">
      <v-label class="text-pink font-weight-bold mb-2"
        >Грузим файл. Подождите, пожалуйста</v-label
      >

      <v-progress-linear color="pink" v-model="uploadPercentage" height="25">
        <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
      </v-progress-linear>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from "vue";
import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";

import { useRoute } from "vue-router";

import download from "js-file-download";
import AppButton from "@/elements/AppButton.vue";

const route = useRoute();
const isCognationPage = computed(() => route.path.includes("cognations"));

const { showInfoPopup, showErrorWithCustomText, showToastPopup } =
  usePopupStore();

const props = defineProps({
  files: { type: Array, required: false },
  links: { type: Object, required: true },
  path: { type: String, required: false, default: "id" },
});
const emits = defineEmits(["update"]);

const uploadPercentage = ref(0);

async function downloadFile(item) {
  try {
    if (isCognationPage.value) {
      const r = await downloadFileHandler(
        `/${item.randomFileName ? item.randomFileName : item.id}`
      );

      download(r.data, item.fileName);
    } else {
      const r = await downloadFileHandler(item.id);
      download(r.data, item.fileName);
    }

    uploadPercentage.value = 0;
  } catch (e) {
    showErrorWithCustomText(e);
  }
}

async function downloadFileHandler(id) {
  return customAxios.get(`${props.links.downloadUrl}${id}`, {
    responseType: "blob",
    onDownloadProgress: (progressEvent) => {
      uploadPercentage.value = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
  });
}

async function removeFile(item) {
  try {
    const r = await removeFileHandler(item.id);

    if (r.status === 202) {
      showToastPopup("Файл удален");
      emits("update");
    }

    if (r.status === 207) {
      showInfoPopup(r.data.message);
    }
  } catch (e) {
    showErrorWithCustomText(e);
  }
}

async function removeFileHandler(id) {
  if (isCognationPage.value) {
    return props.links.deleteUrl.includes("video")
      ? customAxios.delete(`${props.links.deleteUrl}${id}`)
      : customAxios.put(`${props.links.deleteUrl}${route.params.cognationId}`, {
          fileId: id,
        });
  }
  return customAxios.put(`${props.links.deleteUrl}${id}`);
}
</script>
