<template>
  <div class="only-on-desktop my-3">
    <div class="only-on-desktop__content">
      <slot></slot>
    </div>
    <div class="only-on-desktop__tip pa-4">
      <p class="text-deep-purple-darken-1 font-weight-bold text-center"
        >Подсказка: для просмотра графиков данных, пожалуйста, откройте ПК версию сайта.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.only-on-desktop {
  &__content {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  &__tip {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
}
</style>
