<template>
  <aside class="side-menu" :class="{ half: halfSize, visible: visible }">
    <div class="side-menu__overlay"></div>
    <div class="side-menu__menu">
      <div>
        <div class="side-menu__top">
          <div class="side-menu__logo" @click="visible = false">
            <router-link to="/" class="gradient-logo-title">Эврика</router-link>
            <AppButton><CIcon icon="cross-icon" /></AppButton>
          </div>

          <AppButton
            class="side-menu__menu-resizer"
            @click="halfSize = !halfSize"
          >
            <CIcon v-if="halfSize" icon="chevron-right-icon" />
            <CIcon v-else icon="chevron-left-icon" />
          </AppButton>

          <AppButton class="side-menu__menu-closer" @click="hide">
            <CIcon icon="cross-icon" />
          </AppButton>
        </div>

        <router-link
          to="/profile"
          class="side-menu__profile link"
          @click="visible = false"
        >
          <UserProfile />
        </router-link>

        <RoleChanger />

        <SideMenuList v-if="isChild" :items="child" @close="visible = false" />

        <SideMenuList
          v-if="isParent"
          :items="parent"
          @close="visible = false"
        />

        <SideMenuList
          v-if="userGlobalRole === 'employee'"
          :items="employes"
          @close="visible = false"
        />

        <SideMenuList v-if="isAdmin" :items="admin" @close="visible = false" />
      </div>

      <AppButton @click="logoutUser" class="side-menu__logout">
        <CIcon icon="logout" v-if="halfSize" />
        <span v-else>Выйти</span>
      </AppButton>
    </div>
  </aside>
</template>

<script setup>
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { admin, parent, child, employes } from "./links-list";
import { ref } from "vue";
import { useSideMenuStore } from "@/store/side-menu";

import { store as vuexStore } from "@/store";

import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";
import UserProfile from "./UserProfile";
import SideMenuList from "./SideMenuList.vue";
import RoleChanger from "./RoleChanger.vue";

const router = useRouter();

const { logout } = useAuthStore();

const { isAdmin, isParent, isChild, userGlobalRole } =
  storeToRefs(useAuthStore());


const { hide } = useSideMenuStore();
const { visible } = storeToRefs(useSideMenuStore());

const halfSize = ref(false);

function logoutUser() {
  router.push("/");
  vuexStore.commit("authentication/logout");
  logout();
}
</script>

<style lang="scss">
.side-menu {
  height: 100%;

  a {
    text-decoration: none;
  }

  &__menu-closer {
    display: none;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
  }

  &__menu-resizer {
    color: $main-2;

    svg {
      stroke: $main-2;
    }
  }

  &__profile {
    text-decoration: none;

    .user-profile:hover {
      padding-left: 4px;
      padding-right: 4px;
      border: 1px solid rgba(#000, 0.1);
      border-radius: 8px;
    }

    &:active {
      color: inherit;
    }
  }

  &__logo {
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      text-decoration: none;
    }

    button {
      display: none;
    }
  }

  &__menu {
    padding-right: 16px;
    padding-left: 16px;
    height: 100%;
    overflow-y: scroll;
    width: 300px;
    position: relative;
    z-index: 100;
    background-color: #fff;
    display: grid;
    grid-template-rows: 1fr max-content;
    grid-gap: 32px;
    padding-bottom: 8px;
  }

  &__logout {
    color: $main-1;
    border: 1px solid rgba($main-1, 0.4);
    border-radius: 8px;
    padding: 4px;
    width: 100%;
    transition: 0.2s;

    svg {
      stroke-width: 1px;
      stroke: rgba($main-1, 0.4);
    }

    &:hover {
      background-color: rgba($main-1, 0.1);
    }

    &:active {
      background-color: rgba($main-1, 0.2);
    }
  }

  &.half {
    .side-menu-link,
    .side-menu__top {
      justify-content: center;
    }

    .user-profile__avatar,
    .side-menu-link svg {
      margin-right: 0;
    }

    .side-menu__menu {
      width: auto;
      box-shadow: $shadow;
    }

    .side-menu__logout span,
    .side-menu-list p {
      display: none;
    }

    .side-menu-link {
      span,
      svg:last-child {
        display: none;
      }
    }

    .user-profile__info,
    .side-menu__logo {
      display: none;
    }

    .side-menu__logout {
      border: none;
    }
  }

  @media (max-width: 1023px) {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;

    &__menu {
      box-shadow: $shadow;
      position: fixed;
      top: 0;
      left: -100%;
      width: 100%;
    }

    &.visible {
      display: block;

      .side-menu__menu {
        animation-name: menu;
        animation-duration: 0.2s;
        animation-delay: 0s;
        animation-fill-mode: forwards;
      }

      .side-menu__overlay {
        display: block;
        animation-name: overlay;
        animation-duration: 0.2s;
        animation-delay: 0.01s;
        animation-fill-mode: forwards;
      }
    }

    &__menu-closer {
      display: flex;
      svg {
        stroke: $main-2;
      }
    }

    &__menu-resizer {
      display: none;
    }
  }
}

@keyframes overlay {
  100% {
    opacity: 1;
  }
}
@keyframes menu {
  100% {
    left: 0;
  }
}
</style>
