<template>
  <div class="list-days-id">
    <div class="page-wrapper">
      <h3><slot name="title"></slot></h3>
      <div class="list-days-id__top">
        <ul>
          <li v-for="d in journalDaysWithIds" :key="d.id">
            <AppButton
              :class="{
                active: Number(dateInput) === d.teacherLogDayId,
              }"
              @click="dateInput = d.teacherLogDayId"
            >
              {{ getDateFormatWithTime(d.teacherLogDayDate) }}
            </AppButton>
          </li>
        </ul>
      </div>
    </div>

    <div class="page-wrapper mt-2">
      <div v-if="dateInput">
        <slot name="content"></slot>
      </div>
      <p v-else>Для отображения данных выберите день</p>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import { removeFilterFromURL, pushFilterToURL } from "@/service/router";

import { storeToRefs } from "pinia";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { useRoute } from "vue-router";

import AppButton from "@/elements/AppButton.vue";

const route = useRoute();

const { getDateFormatWithTime } = useDatesChanger();
const { journalDaysWithIds } = storeToRefs(useSubjectsJournalStore());

const dateInput = ref(route.query.dayId ? route.query.dayId : null);

watch(dateInput, (day) => {
  day
    ? pushFilterToURL({ name: "dayId", value: day })
    : removeFilterFromURL("dayId");
});
</script>

<style lang="scss">
.list-days-id {
  &__top {
    margin-top: 16px;

    p {
      margin-bottom: 8px;
      text-transform: uppercase;
      font-size: 14px;
    }

    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
    }

    li {
      margin: 8px;
    }

    button {
      padding: 2px 4px;
      font-size: 14px;
      line-height: 16px;
      transition: all 0.2s;
      border-radius: 4px;
      outline: 1px solid $main-2;

      &.active {
        outline: none;
        color: #fff;
        background-image: $main-gradient-to-right;
      }

      &:hover {
        outline: none;
        background-color: rgba($main-2, 0.3);
      }
    }
  }
}
</style>
