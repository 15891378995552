<template>
  <v-select
    v-model="month"
    :items="monthDictWithoutSummer"
    variant="outlined"
    density="compact"
    hide-details
    item-title="title"
    item-value="value"
    placeholder="Месяц"
    bg-color="white"
  ></v-select>
</template>

<script setup>
import { pushFilterToURL, removeFilterFromURL } from "@/service/router";
import { monthDictWithoutSummer } from "@/util/common";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const month = ref(null);

onMounted(setMonth);

function setMonth() {
  if (route.query.filterByMonth) {
    month.value = Number(route.query.filterByMonth);
    return;
  }
  const prev = new Date();
  prev.setMonth(prev.getMonth() - 1);
  const m = prev.getMonth();

  if (m === 5 || m === 6 || m === 7) {
    return 4;
  }

  month.value = m;
}

watch(month, (id) =>
  id
    ? pushFilterToURL({ name: "filterByMonth", value: id })
    : removeFilterFromURL("filterByMonth")
);
</script>
