<template>
  <div>
    <div class="mb-2">
      <v-label class="mb-2">Поиск</v-label>
      <v-text-field
        v-model="searchInput"
        variant="outlined"
        placeholder="Введите часть названия или имя мастера"
        hide-details
        density="compact"
        clearable
        persistent-clear
        @update:model-value="$emit('search', searchInput)"
        @click:clear="$emit('clear')"
      />
    </div>

    <div>
      <v-label class="mb-1">Фильтры</v-label>
      <div class="g-col g-col--3-1fr">
        <FilterByYear />
        <v-select
          v-model="typeFilter"
          :items="typeFilterItems"
          variant="outlined"
          density="compact"
          hide-details
          placeholder="Тип"
          :clearable="true"
        ></v-select>

        <v-select
          v-model="periodFilter"
          :items="periodFilterItems"
          variant="outlined"
          density="compact"
          hide-details
          placeholder="Период"
          :clearable="true"
        ></v-select>

        <v-select
          v-model="dayFilter"
          :items="days"
          variant="outlined"
          density="compact"
          hide-details
          placeholder="Дни"
          :clearable="true"
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { removeFilterFromURL, pushFilterToURL } from "@/service/router";
import { useRoute } from "vue-router";

import FilterByYear from "@/components/filtersElements/FilterByYear.vue";

const days = [
  { title: "понедельник/среда/пятница", value: 1 },
  { title: "вторник/четверг", value: 2 },
];

const periodFilterItems = [
  { value: "current", title: "Текущие" },
  { value: "future", title: "Предстоящие" },
];

const typeFilterItems = [
  { value: 2, title: "клубок" }, // 17:00
  { value: 1, title: "мастерская" }, // 15:30
];

const route = useRoute();

const searchInput = ref("");

watch(searchInput, (input) =>
  input
    ? pushFilterToURL({ name: "filterByInput", value: input })
    : removeFilterFromURL("filterByInput")
);

const dayFilter = ref(null);
watch(dayFilter, (day) =>
  day
    ? pushFilterToURL({ name: "filterByDay", value: day })
    : removeFilterFromURL("filterByDay")
);

const periodFilter = ref(null);
watch(periodFilter, (period) =>
  period
    ? pushFilterToURL({ name: "filterByPeriod", value: period })
    : removeFilterFromURL("filterByPeriod")
);

const typeFilter = ref(null);
watch(typeFilter, (type) =>
  type
    ? pushFilterToURL({ name: "filterByType", value: type })
    : removeFilterFromURL("filterByType")
);

onMounted(setCurrentFilters);

function setCurrentFilters() {
  if (route.query.filterByInput) {
    searchInput.value = route.query.filterByInput;
  }

  if (route.query.filterByType) {
    typeFilter.value = Number(route.query.filterByType);
  }

  if (route.query.filterByDay) {
    dayFilter.value = Number(route.query.filterByDay);
  }

  if (route.query.filterByPeriod) {
    periodFilter.value = route.query.filterByPeriod;
  }
}
</script>
