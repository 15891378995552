<template>
  <div>
    <PageTitle title="Журналы посещения" />
    <div class="page-wrapper">
      <div v-if="childrenGroupsSelector.length" class="filters-inputs">
        <v-select
          v-model="selectedChildrenGroupId"
          :items="childrenGroupsSelector"
          variant="outlined"
          density="compact"
          hide-details
          placeholder="Выберите класс"
          @update:model-value="updateVersion"
          clearable
          persistent-clear
        ></v-select>
      </div>

      <StatusWrapper
        :loadingStatus="loadingStatus"
        :isDataExisted="journals.length > 0"
        :key="version"
      >
        <ComponentWithPagination
          :list="filteredJournals"
          @changeList="paginatedData = $event"
        >
          <ul class="mt-4">
            <li
              v-for="j in paginatedData"
              :key="j.id"
              class="gradient-list-item"
            >
              <router-link
                class="gradient-list-item__link-with-period gradient-list-item__content"
                :to="{ name: 'attendance-journal', params: { id: j.id } }"
              >
                <h4>{{ j.name }} ({{ j.group.personGroupName }})</h4>
                <div class="gradient-list-item__period">
                  <CIcon icon="calendar-icon" />
                  <span
                    >{{ getDateFormatWithoutTime(j.beginDateTime) }} -
                    {{ getDateFormatWithoutTime(j.endDateTime) }}</span
                  >
                </div>
              </router-link>
            </li>
          </ul>
        </ComponentWithPagination>
      </StatusWrapper>
    </div>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { onMounted, ref, computed, watch } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";

import { usePopupStore } from "@/store/popup";
import { useChildrenGroupsData } from "@/composables/childrenGroups";
import StatusWrapper from "@/components/StatusWrapper.vue";

import CIcon from "@/elements/CIcon.vue";
import ComponentWithPagination from "@/components/ComponentWithPagination.vue";
import PageTitle from "@/elements/PageTitle.vue";

const { selectedChildrenGroupId, childrenGroupsSelector, getChildrenGroups } =
  useChildrenGroupsData();

onMounted(getChildrenGroups);

const version = ref(1);

const { getDateFormatWithoutTime } = useDatesChanger();
const { showError } = usePopupStore();

const loadingStatus = ref("loading");
const journals = ref([]);

function updateVersion() {
  version.value = version.value + 1;
  // console.log("version:" + version.value);
}

onMounted(getAllJournals);

async function getAllJournals() {
  loadingStatus.value = "loading";

  try {
    const r = await getAtendanceJournalsData();
    journals.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}

async function getAtendanceJournalsData() {
  return customAxios.get(`/attendance`);
}

const filteredJournals = computed(() => {
  if (!journals.value.length) {
    return [];
  }

  return journals.value.filter((j) => {
    if (
      selectedChildrenGroupId.value &&
      j.userGroupId != Number(selectedChildrenGroupId.value)
    ) {
      return false;
    }

    return true;
  });
});

const paginatedData = ref([]);

watch(selectedChildrenGroupId, (v) => {
  setGroupIdToLocalStore(v);
});

function setGroupIdToLocalStore(value) {
  if (!value) {
    localStorage.removeItem("selectedAttendanceJournalChildrenGroupId");
    return;
  }

  localStorage.setItem(
    "selectedAttendanceJournalChildrenGroupId",
    JSON.stringify(value)
  );
}

onMounted(parseLocalStore);

function parseLocalStore() {
  const localGroupId = localStorage.getItem(
    "selectedAttendanceJournalChildrenGroupId"
  );

  if (localGroupId) {
    selectedChildrenGroupId.value = Number(JSON.parse(localGroupId));
  }
}
</script>
