<template>
  <DatePicker
    week-picker
    auto-apply
    :disabled-week-days="[6, 0]"
    :format="getRangeUIFormat"
    :modelValue="modelValue"
    @update:modelValue="emitUpdate"
    placeholder="Неделя"
    :clearable="false"
  />
</template>

<script setup>
import { useDatesChanger } from "@/composables/datesChanger";
import { defineEmits, defineProps } from "vue";

defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);

const { getRangeUIFormat } = useDatesChanger();

function emitUpdate(v) {
  emit("update:modelValue", removeWeekends(v));
}

function removeWeekends(period) {
  if (!period) {
    return [];
  }
  const monday = period[0];
  const friday = new Date(period[1]);
  friday.setDate(period[1].getDate() - 2);

  return [monday, friday];
}
</script>
