<template>
  <div class="card-colored-top">
    <div class="card-colored-top__top">
      <slot name="top"></slot>
    </div>
    <div class="card-colored-top__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss">
.card-colored-top {
  background-color: #fff;
  box-shadow: $light-shadow;
  border-radius: 8px;

  &__top {
    background-color: $secondary;
    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-weight: 600;
    padding: 8px;
  }

  &__content {
    padding: 8px;
  }
}
</style>
