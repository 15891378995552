import { defineStore } from "pinia";

export const useIamStudioStore = defineStore("iamstudio", {
  state() {
    return {
      studios: [],
      events: [],
    };
  },
  getters: {
    getStudioById(state) {
      return (id) => state.studios.find((v) => v.id === Number(id));
    },
    studiosOptions(state) {
      return state.studios.map((v) => ({ title: v.iStudioName, value: v.id }));
    },
    getEventsByStudioId(state) {
      return (id) => state.events.filter(v => v.studioId === Number(id))
    },
    getEventsByMonthId(state) {
      return (id) => state.events.filter(v => v.monthIndex === Number(id))
    }
  },
  actions: {
    setListOfStudios(list) {
      this.studios = list;
    },
    clearListOfStudios() {
      this.studios = [];
    },
    setEvents(list) {
      this.events = list;
    },
  },
});
