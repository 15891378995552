<template>
  <div class="w-notes widget">
    <div>
      <h4 class="mb-5">Заметка обо мне</h4>

      <StatusWrapper
        :isDataExisted="note !== null"
        :loadingStatus="loadingStatus"
      >
        <div class="w-notes__item pa-2">
          <p class="d-flex justify-space-between mb-4">
            <span>
              {{ note.childNote.creator.firstname }}
              {{ note.childNote.creator.secname }}
              {{ note.childNote.creator.lastname }}
            </span>
            <span>{{ getDateFormatWithoutTime(note.noteDateTime) }}</span>
          </p>

          <div v-html="note.childNote.noteBody"></div>
        </div>
      </StatusWrapper>
    </div>

    <WLink
      :link="{ name: 'user-notes-list' }"
      title="К заметкам"
      class="mt-2"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useNotes } from "@/composables/notes";
import { usePopupStore } from "@/store/popup";

import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/auth";
import { getNewestItem } from "@/util/common";

import { useDatesChanger } from "@/composables/datesChanger";

import StatusWrapper from "@/components/StatusWrapper.vue";
import WLink from "./WLink.vue";

const { showError } = usePopupStore();
const { getChildrenNotesHandler } = useNotes();
const { getDateFormatWithoutTime } = useDatesChanger();
const { userId } = storeToRefs(useAuthStore());

const list = ref([]);
const loadingStatus = ref("loading");

const note = computed(() => {
  if (!list.value.length) {
    return null;
  }

  let newest;

  list.value.forEach((v) => {
    if (!newest) {
      newest = v;
    } else {
      newest = getNewestItem(v, newest);
    }
  });

  return newest;
});

onMounted(getNotes);

async function getNotes() {
  loadingStatus.value = "loading";

  try {
    const r = await getChildrenNotesHandler(userId.value);
    list.value = r.data;
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}
</script>

<style lang="scss">
.w-notes {
  &__item {
    outline: 3px solid #fff;
    border-radius: 8px;

    p:first-child {
      font-style: italic;
    }
  }
}
</style>
