<template>
  <div>
    <div class="d-flex justify-space-between align-center py-2 flex-wrap">
      <v-label class="text-deep-purple-darken-1 font-weight-bold mr-3 ma-1"
        >Текст заметки*</v-label
      >

      <p
        class="ma-1"
        :class="{
          'text-grey': noteBody.length > 0 && noteBody.length <= 15000,
          'text-red': noteBody.length === 0 || noteBody.length > 15000,
        }"
      >
        {{ noteBody.length }} / 15000 символов
      </p>
    </div>

    <TextEditor class="mb-3" :value="noteBody" @update:value="onNoteBodyUpdate" />
  </div>
</template>

<script setup>
import TextEditor from "@/components/TextEditor.vue";
import { onUnmounted } from "vue";
import { useNotesStore } from "@/store/notes";
import { storeToRefs } from "pinia";

const { clearNoteContent, changeNoteBody } = useNotesStore();
const { noteBody } = storeToRefs(useNotesStore());

function onNoteBodyUpdate(e) {
  changeNoteBody(e)
}

onUnmounted(clearNoteContent)
</script>
