<template>
  <v-card class="ma-2">

    <v-card-title class="card_ligth_header">
        <h3 class="ma-2">Расписание</h3>
      </v-card-title>
      <v-card-title class="card_ligth_header" style="min-height: 5vh">
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="scheduleSearchBody"
                label="Найти"
                variant="outlined"
                clearable
                hint="Введите ключевое слово для поиска"
                rounded
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn
                @click="showScheduleDialog = true"
                variant="outlined"
                color="primary"
                class="mb-4"
                rounded
            >
              Добавить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="min-height: 80vh">
        <div class="d-sm-none d-flex align-center flex-column">
          <v-card
              class="ma-4 mx-4 w-100"
              v-for="item in schedulePaginatedData"
              :key="item"
          >
            <v-card-title class="text-pink-lighten-2 bg-pink-lighten-5 ">
              <h3>
                Расписание №
                {{ schedulePaginatedData.indexOf(item) + 1 }}
              </h3>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-list-item lines="two">
                <v-list-item-title>Название:</v-list-item-title>
                <v-list-item-subtitle>{{
                    item.scheduleName
                  }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item lines="two">
                <v-list-item-title>Описание:</v-list-item-title>
                <v-list-item-subtitle>{{
                    item.scheduleDescription
                  }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item lines="two">
                <v-list-item-title>Добавлено:</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip size="small">{{
                      item.createDate.replace("T", " ")
                    }}</v-chip>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item lines="two">
                <v-list-item-title>Период действия:</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip size="small"
                  >{{ item.scheduleStudyYear.studyYearName }} ({{
                      item.scheduleStudyYear.beginDate.replace("T", " ")
                    }}
                    -
                    {{
                      item.scheduleStudyYear.endDate.replace("T", " ")
                    }})</v-chip
                  >
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item lines="two">
                <v-list-item-title>Активно:</v-list-item-title>
                <v-list-item-subtitle
                ><v-chip size="small" v-if="item.isActive">Да</v-chip
                ><v-chip v-else size="small"
                >Нет</v-chip
                ></v-list-item-subtitle
                >
              </v-list-item>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-pink-lighten-2 bg-pink-lighten-5 ">
              <v-btn
                  @click="editSchedule(item)"
                  class="ma-1"
                  color="primary"
                  variant="outlined"
                  rounded
              >
                Редактировать
              </v-btn>
              <v-btn
                  @click="deleteSchedule(item)"
                  class="ma-1"
                  color="warning"
                  variant="outlined"
                  rounded
              >
                Удалить
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div class="d-none d-sm-block">
          <v-table fixed-header class="h-100">
            <thead>
            <tr>
              <th class="text-left">
                <b>№</b>
              </th>
              <th class="text-left">
                <b>Название</b>
              </th>
              <th class="text-left">
                <b>Описание</b>
              </th>
              <th class="text-left">
                <b>Добавлено</b>
              </th>
              <th class="text-left">
                <b>Период</b>
              </th>
              <th class="text-left">
                <b>Активно</b>
              </th>
              <th class="text-left">
                <b>Действие</b>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in schedulePaginatedData" :key="item">
              <td>
                <p>{{ schedulePaginatedData.indexOf(item) + 1 }}</p>
              </td>
              <td>
                <p>{{ item.scheduleName }}</p>
              </td>
              <td>
                <p>{{ item.scheduleDescription }}</p>
              </td>
              <td>
                <p>
                  {{ item.scheduleStudyYear.studyYearName }} ({{
                    item.scheduleStudyYear.beginDate.replace("T", " ")
                  }}
                  -
                  {{
                    item.scheduleStudyYear.endDate.replace("T", " ")
                  }})
                </p>
              </td>
              <td>
                <p>{{ item.createDate.replace("T", " ") }}</p>
              </td>
              <td>
                <v-chip size="small" v-if="item.isActive">Да</v-chip
                ><v-chip v-else size="small">Нет</v-chip>
              </td>
              <td>
                <p>
                  <v-btn
                      @click="editSchedule(item)"
                      class="ma-1"
                      color="primary"
                      variant="outlined"
                      rounded
                  >
                    Редактировать
                  </v-btn>
                  <v-btn
                      @click="deleteSchedule(item)"
                      class="ma-1"
                      color="warning"
                      variant="outlined"
                      rounded
                  >
                    Удалить
                  </v-btn>
                </p>
              </td>
            </tr>
            </tbody>
          </v-table>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions
          class="card_ligth_actions"
          style="min-height: 5vh"
      >
        <v-container class="text-center">
          <v-pagination
              v-model="schedulePage"
              :length="schedulePageCount"
              :total-visible="3"
              size="small"
          ></v-pagination>
        </v-container>
      </v-card-actions>

      <v-dialog v-model="showScheduleDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Расписание</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="scheduleForm">

            <v-col cols="12">
              <v-text-field
                  label="Название*"
                  v-model="scheduleName"
                  :rules="scheduleNameRules"
                  hint="Укажите название"
                  :counter="30"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                  label="Описание*"
                  v-model="scheduleDescription"
                  :rules="scheduleDescriptionRules"
                  :counter="255"
                  hint="Укажите описание"
                  required
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                  v-model="scheduleStudyYear"
                  :items="activeYears"
                  outlined
                  dense
                  chips
                  small-chips
                  closable-chips
                  :rules="scheduleStudyYearRules"
                  item-title="studyYearName"
                  item-value="id"
                  label="Период действия"
              >
                <template v-slot:chip="{ props, item }">
                  <v-chip
                      v-bind="props"
                      :text="item.raw.studyYearName"
                  ></v-chip>
                </template>
                <template v-slot:item="{ props, item }">
                  <v-list-item
                      v-bind="props"
                      :title="item.raw.studyYearName"
                      :subtitle="item.raw.studyYearDescription"
                  ></v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-row class="px-6" fluid>
                <v-switch
                    v-model="isActive"
                    :label="'Активно'"
                    color="success"
                >
                </v-switch>
                <v-chip v-if="isActive">Да</v-chip
                ><v-chip v-else>Нет</v-chip>
              </v-row>
            </v-col>

            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn rounded variant="outlined" @click="closeScheduleDialog">
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveSchedule">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <v-dialog v-model="showLoader"><LoaderRing ></LoaderRing></v-dialog>

  </v-card>
</template>

<script>
import LoaderRing from "@/elements/LoaderRing.vue";
import _ from "lodash";
import {customAxios} from "@/service/customAxios";

export default {
  name: "ScheduleLIst",
  props: [],
  components: {LoaderRing },
  data: () => ({
    showLoader: false,
    scheduleSearchBody: "",
    showScheduleDialog: false,

    scheduleName:'',
    scheduleDescription:'',
    isActive:false,
    scheduleId:1,
    schedulePage:1,
    scheduleStudyYear:1,
    schedules:[],
    studyYears:[],
    noteCount:10,
    id:"",

    scheduleNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    scheduleDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 255) || "Значение до должно превышать 255 символов!",
    ],
    scheduleStudyYearRules: [
      (v) => (!!v && true) || "Поле обязательно для заполнения!",
    ],

  }),
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
    activeYears() {
      let sourceYears = this.studyYears;
      return sourceYears.filter((item) => item.isActive === true);
    },
    filteredSchedule() {
      const ss = this.scheduleSearchBody.toLowerCase();

      let tempSchedules = this.schedules;

      return tempSchedules.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );
    },
    schedulePageCount() {
      let l = this.filteredSchedule.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },
    schedulePaginatedData() {
      const start = (this.currentSchedulePage - 1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.filteredSchedule.slice(start, end);
    },
    currentSchedulePage() {
      if (this.schedulePageCount < this.schedulePage) {
        return 1;
      } else {
        return this.schedulePage;
      }
    },


  },
  methods: {
    editSchedule(item) {
      this.scheduleName = item.scheduleName;
      this.scheduleDescription = item.scheduleDescription;
      this.isActive = item.isActive;
      this.id = item.id;
      this.scheduleStudyYear = item.scheduleStudyYear;
      this.showScheduleDialog = true;
    },
    deleteSchedule(item) {

      if (confirm("Вы точно хотите удалить расписание?")) {
        customAxios
            .delete("/schedule/" + item.id)
            .then((response) => {
              if (response.status === 202) {
                this.schedules.splice(this.schedules.indexOf(item), 1);
              } else {
                console.log(response);
              }
            })
      }
    },
    closeScheduleDialog() {
      this.scheduleName = "";
      this.scheduleDescription = "";
      this.isActive = false;
      this.showScheduleDialog = false;
      this.scheduleId = null;
      this.scheduleStudyYear = null;
      this.id=null;
    },
    saveSchedule() {
      this.$refs.scheduleForm.validate().then((result) => {
        if (result.valid) {

          if (this.scheduleStudyYear instanceof Object) {
            this.scheduleStudyYear = this.scheduleStudyYear.id;
          }

          let schedule = {
            scheduleName: capitalizeFirstLetter(this.scheduleName),
            scheduleDescription: capitalizeFirstLetter(
                this.scheduleDescription
            ),
            isActive: this.isActive,
            studyYearId: this.scheduleStudyYear,
          };
          console.log("Schedule: " + schedule);

          if (this.id) {
            customAxios
                .put(
                    "/schedule/" + this.id,
                    schedule
                )
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.schedules, response.data.id);
                    this.schedules.splice(index, 1, response.data);
                    this.closeScheduleDialog();
                  } else {
                    alert("Что-то пошло не так");
                  }
                })

          } else {
            customAxios.post("/schedule", schedule)
                .then((response) => {
                  if (response.status === 201) {
                    this.schedules.push(response.data);
                    this.closeScheduleDialog();
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })

          }
        }
      });
    },

  },
  created: function () {
    this.showLoader = true;
 
    function getStudyYears(customAxios) {
      return customAxios.get("/schedule/year");
    }

    function getSchedule(customAxios) {
      return customAxios.get("/schedule");
    }

    Promise.all([
      getStudyYears(customAxios),
      getSchedule(customAxios),
    ]).then((results) => {
      if (results[0].status === 200) {
        this.studyYears = results[0].data;
      }
      if (results[1].status === 200) {
        this.schedules = results[1].data;
      }
      this.showLoader = false;
    })
  },


  setup() {
    return {};
  },
};
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

</script>

<style lang="scss">
.form-element {
  box-shadow: $shadow;
  display: grid;
  grid-template-columns: 40% 1fr;
  overflow: hidden;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: $gradient-1;
  }

  &__card.v-card {
    box-shadow: none;
    padding: 30px;
  }

  &__title.v-card-title {
    color: $main;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
  }

  .v-text-field__input {
    input::placeholder {
      font-size: 18px;
      line-height: 22px;
    }

    label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #6e6d94;
    }

    .v-field__outline {
      color: rgba(#6e6d94, 0.5);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;

    &__img {
      display: none;
    }
  }
}
</style>
