import { defineStore } from "pinia";
export const useGalleryStore = defineStore("gallery", {
  state: () => ({
    albums: [],
    photos: [],
  }),

  actions: {
    setAlbumbs(albums) {
      this.albums = albums;
    },
    clearAlbumbs() {
      this.albums = null;
    },
    setPhotos(photos) {
      this.photos = photos;
    },
  },
});
