<template>
  <div class="w-diary widget">
    <div>
      <h4 class="mb-5">Расписание уроков <br />{{ title }}</h4>

      <StatusWrapper
        :isDataExisted="diaryDays.length > 0"
        :loadingStatus="loadingStatus"
      >
        <ul class="list w-diary__days">
          <li v-for="(d, i) in diaryDays" :key="i">
            <p class="text-right mb-1">
              {{ isItToday(d.dayDate) }} {{ getDateWithWeekday(d.dayDate) }}
            </p>

            <ul v-if="d.lessons.length > 0" class="list w-diary__lessons">
              <li v-for="(l, i) in d.lessons" :key="i">
                <p>{{ getTimeFromDate(l.dayDate) }}</p>
                <p>{{ l.subjectName }}</p>
              </li>
            </ul>

            <p v-else class="pa-2 w-diary__day">{{ TITLE_NO_LESSONS }}</p>
          </li>
        </ul>
      </StatusWrapper>
    </div>
    <WLink :link="{ name: 'diary' }" title="В дневник" class="mt-2" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import { useDiaryData } from "@/composables/diary";

import { useAuthStore } from "@/store/auth";
import { usePopupStore } from "@/store/popup";
import { storeToRefs } from "pinia";

import { TITLE_NO_LESSONS } from "@/util/common";
import { isItToday } from "@/util/common";

import StatusWrapper from "@/components/StatusWrapper.vue";
import WLink from "./WLink.vue";

const { updateDiaryHandler, getSortedDiaryDays } = useDiaryData();
const {
  setDateTime,
  getTimeFromDate,
  getDateFormatWithoutTime,
  getDateWithWeekday,
} = useDatesChanger();

const { userId } = storeToRefs(useAuthStore());
const { showError } = usePopupStore();

const loadingStatus = ref("loading");

const days = computed(() => {
  const today = new Date();
  const FRIDAY = 5;
  const aftertomorrow = new Date(today);

  if (today.getDay() === FRIDAY) {
    aftertomorrow.setDate(today.getDate() + 4);
  } else {
    aftertomorrow.setDate(today.getDate() + 2);
  }

  return [today, aftertomorrow];
});

const title = computed(() => {
  if (!days.value.length) {
    return "";
  }

  return `${getDateFormatWithoutTime(
    days.value[0]
  )} - ${getDateFormatWithoutTime(days.value[1])}`;
});

const diary = ref(null);
const diaryDays = computed(() => {
  if (diary.value === null) {
    return [];
  }

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const days = [];

  getSortedDiaryDays(diary.value).forEach((v) => {
    const d1 = new Date(v.dayDate);

    if (d1.getDate() === today.getDate() || d1.getDate() === tomorrow.getDate()) {
      days.push(v);
    }
  });

  return days;
});

onMounted(update);

async function update() {
  loadingStatus.value = "loading";
  try {
    const r = await updateDiaryHandler({
      beginPeriodDateTime: setDateTime(days.value[0]),
      endPeriodDateTime: setDateTime(days.value[1]),
      childId: userId.value,
    });
    diary.value = r.data;
  } catch (error) {
    showError(error);
  } finally {
    loadingStatus.value = "loaded";
  }
}
</script>

<style lang="scss">
.w-diary {
  &__lessons {
    li {
      padding: 4px;
    }

    li + li {
      border-top: 1px solid rgba(#fff, 0.5);
      margin-top: 8px;
    }
  }

  &__day {
    outline: 3px solid #fff;
    border-radius: 8px;
  }

  &__days {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }

  &__lessons {
    outline: 3px solid #fff;
    border-radius: 8px;

    p {
      font-weight: 600;

      &:first-child {
        font-style: italic;
      }

      &:last-child {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  @media (max-width: 768px) {
    &__days {
      grid-template-columns: 1fr;
    }
  }
}
</style>
