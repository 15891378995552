<template>
  <tr class="foreign-child-result-editor">
    <td>{{ props.child.name }}</td>

    <td
      class="cell-input"
      :class="{
        error: v$.writingReadingCompetenceBall.$errors.length,
      }"
    >
      <input v-model="v$.writingReadingCompetenceBall.$model" type="number" />
    </td>

    <td
      class="cell-input"
      :class="{
        error: v$.listeningCompetenceBall.$errors.length,
      }"
    >
      <input v-model="v$.listeningCompetenceBall.$model" type="number" />
    </td>

    <td
      class="cell-input"
      :class="{
        error: v$.speakingCompetenceBall.$errors.length,
      }"
    >
      <input v-model="v$.speakingCompetenceBall.$model" type="number" />
    </td>

    <td class="foreign-child-result-editor__total">
      <p>{{ props.child.totalBall }}</p>
    </td>

    <td>
      <AppButton
        @click="$emit('startChildEditing', props.child)"
        class="foreign-child-result-editor__edit"
        :class="{ wasEdited: wasEdited }"
      >
        <CIcon icon="pen-icon" />
      </AppButton>
    </td>
  </tr>
</template>

<script setup>
import { computed, ref, watch, defineProps, defineEmits, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { maxValue, minValue } from "@vuelidate/validators";

import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";

const rules = {
  writingReadingCompetenceBall: {
    minValueValue: minValue(0),
    maxValueValue: maxValue(100),
  },
  speakingCompetenceBall: {
    minValueValue: minValue(0),
    maxValueValue: maxValue(100),
  },
  listeningCompetenceBall: {
    minValueValue: minValue(0),
    maxValueValue: maxValue(100),
  },
};

const emits = defineEmits(["editChildsResult", "startChildEditing"]);

const props = defineProps({
  child: {
    type: Object,
    required: true,
  },
});

const writingReadingCompetenceBall = ref(0);
const speakingCompetenceBall = ref(0);
const listeningCompetenceBall = ref(0);

const v$ = useVuelidate(rules, {
  writingReadingCompetenceBall: writingReadingCompetenceBall,
  speakingCompetenceBall: speakingCompetenceBall,
  listeningCompetenceBall: listeningCompetenceBall,
});

const result = computed(() => ({
  speakingCompetenceBall: speakingCompetenceBall.value,
  listeningCompetenceBall: listeningCompetenceBall.value,
  writingReadingCompetenceBall: writingReadingCompetenceBall.value,
}));

onMounted(setCurrentData)

function setCurrentData() {
  if (!props.child) {
    return;
  }
  speakingCompetenceBall.value = props.child.speakingCompetenceBall;
  writingReadingCompetenceBall.value = props.child.writingReadingCompetenceBall;
  listeningCompetenceBall.value = props.child.listeningCompetenceBall;
}

function editChildsResult() {
  emits("editChildsResult", {
    id: props.child.id,
    options: result.value,
  });
}

const wasEdited = computed(() => {
  if (!props.child) {
    return false;
  }

  const statuses = [
    ...props.child.listeningCompetenceStatuses,
    ...props.child.speakingCompetenceStatuses,
    ...props.child.writingReadingCompetenceStatuses,
  ];

  const edited = statuses.filter((v) => {
    return v.achieved !== v.workingTowards;
  });

  return edited.length > 0;
});

const isValid = (v) => v >= 0 && v <= 100;

watch(writingReadingCompetenceBall, (v) => {
  if (isValid() && v !== props.child.writingReadingCompetenceStatuses) {
    editChildsResult();
  }
});

watch(speakingCompetenceBall, (v) => {
  if (isValid() && v !== props.child.speakingCompetenceStatuses) {
    editChildsResult();
  }
});

watch(listeningCompetenceBall, (v) => {
  if (isValid() && v !== props.child.listeningCompetenceStatuses) {
    editChildsResult();
  }
});
</script>

<style lang="scss">
.foreign-child-result-editor {
  td.error {
    border-color: $error;
  }
  &__edit,
  &__total {
    border: 1px solid $main-2;
    padding: 4px;
    color: $main-2;
    border-radius: 4px;
    text-align: center;

    p {
      font-weight: 700;
    }
  }

  &__edit {
    svg {
      font-size: 30px;
      line-height: 35px;
      stroke: $main-2;
    }

    &:hover {
      background-color: rgba($main-2, 0.1);
    }

    &:active {
      background-color: rgba($main-2, 0.2);
    }

    &.wasEdited {
      border-color: $success;

      svg {
        stroke: $success;
      }

      &:hover {
        background-color: rgba($success, 0.1);
      }

      &:active {
        background-color: rgba($success, 0.2);
      }
    }
  }
}
</style>
