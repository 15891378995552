<template>
  <div>
    <PageTitle title="Мастерские для мастера" />

    <div class="d-flex justify-center my-3">
      <router-link :to="{ name: 'workshop-create' }" class="accent-button"
        >Создать мастерскую</router-link
      >
    </div>

    <div class="page-wrapper my-3">
      <WorkshopFilters />
      <FilterByMine />
    </div>

    <ComponentWithPagination
      :list="filteredWorkshops"
      @changeList="paginatedData = $event"
    >
      <ul v-if="paginatedData.length">
        <li v-for="w in paginatedData" :key="w.id" class="gradient-list-item">
          <router-link
            :to="{
              name: 'workshop-page',
              params: { workshopId: w.id },
              query: { filterByYear: $route.query.filterByYear },
            }"
            class="gradient-list-item__link-with-period gradient-list-item__content"
          >
            <h4 v-if="w.workshopName">
              {{ w.workshopName }} 
              <span v-if="isAdmin">(ID: {{ w.id }})</span>
            </h4>

            <div class="gradient-list-item__period">
              <CIcon icon="calendar-icon" />
              {{ getDateFormatWithoutTime(w.beginDateTime) }} -
              {{ getDateFormatWithoutTime(w.endDateTime) }}
            </div>
          </router-link>
        </li>
      </ul>
    </ComponentWithPagination>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import { useWorkshopStore } from "@/store/workshop";

import { useRoute } from "vue-router";
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";

import { useWorkshopFilters } from "@/composables/workshopFilters";

import ComponentWithPagination from "@/components/ComponentWithPagination.vue";
import CIcon from "@/elements/CIcon.vue";

import PageTitle from "@/elements/PageTitle.vue";
import WorkshopFilters from "./WorkshopFilters.vue";
import FilterByMine from "@/components/filtersElements/FilterByMine.vue";

const { filterByCommonFilters } = useWorkshopFilters();
const { userId, isAdmin } = storeToRefs(useAuthStore());
const { getDateFormatWithoutTime } = useDatesChanger();
const { workshops } = storeToRefs(useWorkshopStore());

const route = useRoute();

const paginatedData = ref([]);

const filteredWorkshops = computed(() => {
  if (!workshops.value.length) {
    return [];
  }
  return workshops.value
    .filter((w) => {
      if (route.query.filterByOwner && isNotAuthor(w)) {
        return false;
      }

      return filterByCommonFilters(w);
    })
    .sort((a, b) => {
      if (a.endDateTime > b.endDateTime) {
        return -1;
      }
      return 1;
    });
});

function isNotAuthor(workshop) {
  if (!userId.value) {
    return false;
  }
  return workshop.creatorId !== userId.value;
}
</script>
