<template>
  <div>
    <PageTitle title="Расписание сотрудника" />
    <div class="page-wrapper">
      <div class="g-col g-col--2-1fr">
        <FilterByEmployee />
        <WeekRangeFilter v-model="range" />
      </div>
    </div>

    <TogglerOfWrapper>
      <template v-slot:name>Подсказка</template>
      <template v-slot:content>
        <p class="mb-3">Здесь отобразится расписание.</p>
        <p>Выставьте: <strong>сотрудника</strong> и <strong>неделю</strong>.</p>
      </template>
    </TogglerOfWrapper>

    <StatusWrapper
      :loadingStatus="loadingStatus"
      :isDataExisted="weekSchedule.length > 0"
    >
      <div class="page-wrapper mt-4">
        <TimeTable :headers="daysHeaders" :weekSchedule="weekSchedule" />
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup>
import { watch, ref, onMounted } from "vue";
import { useScheduleData } from "@/composables/scheduleData";

import { usePopupStore } from "@/store/popup";
import { useRoute } from "vue-router";
import { useDatesChanger } from "@/composables/datesChanger";

import StatusWrapper from "@/components/StatusWrapper.vue";
import TimeTable from "@/components/TimeTable.vue";
import WeekRangeFilter from "@/components/WeekRangeFilter.vue";
import FilterByEmployee from "../filtersElements/FilterByEmployee.vue";

import PageTitle from "@/elements/PageTitle.vue";
import TogglerOfWrapper from "../elements/TogglerOfWrapper.vue";

const { getCurrentWeek } = useDatesChanger();
const { showError } = usePopupStore();
const { getEventsByRange, getCurrentWeekEvents, weekSchedule, daysHeaders } =
  useScheduleData();

const loadingStatus = ref("nothing");
const range = ref(getCurrentWeek());
const route = useRoute();

onMounted(updateTable);
watch(range, updateTable);
watch(() => route.query.filterByEmployee, updateTable);

async function updateTable() {
  if (!route.query.filterByEmployee) {
    return;
  }

  const data = {
    employees: [Number(route.query.filterByEmployee)],
  };

  loadingStatus.value = "loading";

  try {
    if (range.value.length) {
      await getEventsByRange({
        ...data,
        beginDateTime: range.value[0],
        endDateTime: range.value[1],
      });
    } else {
      await getCurrentWeekEvents(data);
    }
  } catch (e) {
    showError(e);
  } finally {
    loadingStatus.value = "loaded";
  }
}
</script>
