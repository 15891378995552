<template>
  <CustomForm
    title="оценочного листа"
    @create="create"
    @remove="remove"
    :saveIsDisable="saveIsDisable"
  >
    <div
      :class="{
        error: v$.name.$errors.length,
        success: !v$.name.$errors.length && v$.name.$model && v$.name.$dirty,
      }"
      class="form__item"
    >
      <input
        v-model="v$.name.$model"
        type="text"
        @focus="v$.name.$touch"
        placeholder="Название"
        maxlength="100"
        class="form__input"
      />

      <div
        :class="{ 'showing-tip': v$.name.$dirty && v$.name.$model.length }"
        class="form__tip-wrapper"
      >
        <div class="form__tip form__tip--counter showing">
          <span>Любые символы </span>
          <span> {{ v$.name.$model.length }} / 100 </span>
        </div>
      </div>

      <div
        class="input-errors"
        v-for="error of v$.name.$errors"
        :key="error.$uid"
      >
        <div class="error-msg form__tip form__tip--error showing">
          {{ error.$message }}
        </div>
      </div>
    </div>

    <v-switch
      v-model="showResultForParent"
      color="success"
      :label="
        showResultForParent ? 'показывать родителям' : 'не показывать родителям'
      "
    />
  </CustomForm>
</template>

<script setup>
import { ref, computed, watch, defineEmits, onMounted } from "vue";
import { customAxios } from "@/service/customAxios";
import { useSubjectsJournalStore } from "@/store/subjects-journal";

import { storeToRefs } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";

import { usePopupStore } from "@/store/popup";
import { useRoute, useRouter } from "vue-router";

import CustomForm from "@/components/CustomForm.vue";
import { debounce } from "@/util/common";

const rules = {
  name: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
};

const emits = defineEmits(["updateLists"]);

const route = useRoute();
const router = useRouter();

const { journal } = storeToRefs(useSubjectsJournalStore());

const { closePopup, showError, showToastPopup, showConfirmPopup } =
  usePopupStore();
const { listById } = useSubjectsJournalStore();

const currentList = computed(() => listById(route.query.listId));

const name = ref("");
const showResultForParent = ref(false);

onMounted(setCurrentData);

function setCurrentData() {
  if (currentList.value) {
    name.value = currentList.value.listDescription;
    showResultForParent.value = currentList.value.showResultForParent;
  }
}

const v$ = useVuelidate(rules, {
  name: name,
});

const saveIsDisable = computed(() => {
  return (
    !name.value || name.value.length === 0 || v$.value.name.$errors.length > 0
  );
});

async function create() {
  try {
    await createHandler();
    showToastPopup("Оценочный лист создан");
    update();
  } catch (e) {
    showError(e);
  }
}

const debounceUpdate = debounce(edit, 1000);


watch(name, async (n) => {
  if (currentList.value && n !== currentList.value.listDescription) {
    debounceUpdate();
  }
});

watch(showResultForParent, async (n) => {
  if (currentList.value && n !== currentList.value.showResultForParent) {
    edit();
  }
});

async function edit() {
  try {
    await editHandler();
    showToastPopup("Изменения сохранены");
  } catch (e) {
    showError(e);
  }
}

async function editHandler() {
  return customAxios.put("/foreign_checklist/" + route.query.listId, {
    listDescription: name.value,
    showResultForParent: showResultForParent.value,
  });
}

async function createHandler() {
  return customAxios.post("/foreign_checklist/", {
    listDescription: name.value,
    teacherLogId: journal.value.id,
    teacherLogDayId: Number(route.query.dayId),
    showResultForParent: showResultForParent.value,
  });
}

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();

      await removeList();
      showToastPopup("Оценочный лист удален");
      update();
    } else {
      closePopup();
    }
  } catch (e) {
    showError(e);
  }
}

async function customConfirm() {
  return showConfirmPopup("Удалить Оценочный лист?");
}

async function removeList() {
  return customAxios.delete(`/foreign_checklist/${route.query.listId}`);
}

function update() {
  router.push({
    name: "foreign-rating-sheets",
    query: { journalId: route.query.journalId, dayId: route.query.dayId },
  });
  emits("updateLists");
}
</script>
