<template>
  <div class="custom-confirm">
    <div class="custom-confirm__icon">
      <CIcon icon="error-icon" />
    </div>

    <div class="custom-confirm__content">
      <p>{{ text }}</p>
      <div class="custom-confirm__buttons">
        <AppButton
          @click="() => setConfirmRemoving(true)"
          class="custom-confirm__button custom-confirm__button--ok"
        >
          Удалить
        </AppButton>
        <AppButton
          @click="() => setConfirmRemoving(false)"
          class="custom-confirm__button custom-confirm__button--cancel"
          >Отмена</AppButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/elements/AppButton.vue";
import CIcon from "@/elements/CIcon.vue";

import { usePopupStore } from "@/store/popup";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const { text } = storeToRefs(usePopupStore());
    const { setConfirmRemoving } = usePopupStore();

    return { text, setConfirmRemoving };
  },
  components: { AppButton, CIcon },
};
</script>

<style lang="scss">
.custom-confirm {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;

  p {
    font-weight: 500;
    text-align: center;
    margin: 30px 0;
  }

  &__icon {
    background-color: $error;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    
    svg {
      stroke: #fff;
    }

    .app-icon {
      height: 60px;
      width: auto;
    }
  }

  &__content {
    padding: 16px;
  }

  &__button {
    color: #fff;
    border-radius: 16px;
    padding: 4px 16px;
    transition: 0.2s;

    &--ok {
      background-color: $error;
    }

    &--cancel {
      background-color: $main-1;
    }

    & + & {
      margin-left: 4px;
    }

    &:hover {
      opacity: 0.8;
      box-shadow: $shadow;
    }

    &:active {
      opacity: 0.9;
      box-shadow: none;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }
}
</style>
