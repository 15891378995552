<template>
  <div>
    <router-link :to="{ name: 'studios' }" class="bordered-item mb-3"
      >К студиям</router-link
    >
    <CustomForm
      title="Я - Студии"
      @create="create"
      @remove="remove"
      :saveIsDisable="saveIsDisable"
    >
      <div
        :class="{
          error: v$.name.$errors.length,
          success: !v$.name.$errors.length && v$.name.$model && v$.name.$dirty,
        }"
        class="form__item"
      >
        <div class="form__input-with-icon">
          <input
            v-model="v$.name.$model"
            type="text"
            @focus="v$.name.$touch"
            placeholder="Название студии"
            maxlength="100"
            class="form__input"
          />
          <CIcon icon="users-icon" />
        </div>

        <div
          :class="{ 'showing-tip': v$.name.$dirty && v$.name.$model.length }"
          class="form__tip-wrapper"
        >
          <div class="form__tip form__tip--counter showing">
            <span>Любые символы </span>
            <span> {{ v$.name.$model.length }} / 100 </span>
          </div>
        </div>

        <div
          class="input-errors"
          v-for="error of v$.name.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div
        :class="{
          error: v$.period.$errors.length,
          success:
            !v$.period.$errors.length &&
            v$.period.$model.length &&
            v$.period.$dirty,
        }"
        class="form__item"
      >
        <DatePicker
          v-model="period"
          range
          autoApply
          locale="ru"
          placeholder="Период студии"
          :enable-time-picker="false"
          @focus="v$.period.$touch"
          :clearable="false"
          :format="getRangeUIFormat"
        />

        <div
          class="input-errors"
          v-for="error of v$.period.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>
    </CustomForm>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";

import { useIamStudioStore } from "@/store/iamstudio";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";

import { usePopupStore } from "@/store/popup";
import { customAxios } from "@/service/customAxios";
import { useDatesChanger } from "@/composables/datesChanger";

import CIcon from "@/elements/CIcon.vue";
import CustomForm from "@/components/CustomForm.vue";

import { useIstudio } from "@/composables/istudio";
import { debounce } from "@/util/common";

const rules = {
  period: {
    required: helpers.withMessage("Обязательное поле", required),
  },
  name: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
};

const { getRangeUIFormat } = useDatesChanger();
const { updateStudios } = useIstudio();

const { showError, showToastPopup, showConfirmPopup, closePopup } =
  usePopupStore();

const { getStudioById } = storeToRefs(useIamStudioStore());

const route = useRoute();
const router = useRouter();

const studio = computed(() => getStudioById.value(route.params.id));

const period = ref([]);
const name = ref("");

const studioData = computed(() => ({
  iStudioName: name.value,
  startDateTime: period.value[0],
  endDateTime: period.value[1],
}));

const debouncedEditStudio = debounce(editStudio, 1000);

onMounted(setData);

function setData() {
  if (!studio.value) {
    return;
  }

  name.value = studio.value.iStudioName;
  period.value = [studio.value.startDateTime, studio.value.endDateTime];
}

watch(name, (v) => {
  if (studio.value && v.length && v !== studio.value.iStudioName) {
    debouncedEditStudio();
  }
});

watch(period, (v) => {
  if (
    studio.value &&
    v.length &&
    (studio.value.startDateTime !== v[0] || studio.value.endDateTime !== v[1])
  ) {
    debouncedEditStudio();
  }
});

const v$ = useVuelidate(rules, {
  name: name,
  period: period,
});

const saveIsDisable = computed(() => {
  return checkInvalidation();
});

function checkInvalidation() {
  v$.value.$touch();
  return v$.value.$invalid;
}

async function create() {
  try {
    await createNewStudio();
    showToastPopup("Новая студия создана");
    update();
  } catch (e) {
    showError(e);
  }
}

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "yes") {
      closePopup();
      await removeStudio();
      showToastPopup("Cтудия удалена");
      update();
    } else {
      closePopup();
    }
  } catch (e) {
    showError(e);
  }
}

async function customConfirm() {
  return showConfirmPopup(
    "Удалить Я-студию? Удалятся все зависимые события без возможности восстановления"
  );
}

async function editStudio() {
  if (checkInvalidation()) {
    return;
  }

  try {
    await editStudioHandler();
    showToastPopup("Сохранено");
    updateStudios();
  } catch (e) {
    showError(e);
  }
}

function removeStudio() {
  return customAxios.delete("/i_studio/" + studio.value.id);
}

async function editStudioHandler() {
  return customAxios.put("/i_studio/" + studio.value.id, studioData.value);
}

function createNewStudio() {
  return customAxios.post("/i_studio/", studioData.value);
}

async function update() {
  await updateStudios();
  router.push({ name: "studios" });
}
</script>
