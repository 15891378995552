<template>
  <div>
    <div class="d-flex justify-end my-3">
      <router-link :to="{ name: 'studio-event-create' }" class="accent-button"
        >Добавить Событие</router-link
      >
    </div>

    <div class="page-wrapper">
      <FilterByYearAndGroup />

      <ul v-if="list.length" class="mt-5">
        <li v-for="e in list" :key="e.id" class="gradient-list-item">
          <router-link
            :to="{
              name: 'studio-event-edit',
              params: { id: $route.params.id, eventId: e.id },
            }"
            class="gradient-list-item__link-with-period gradient-list-item__content"
          >
            <h4>{{ e.eventName }}</h4>

            <span class="i-am-studio-list__period" v-if="e.monthIndex >= 0">
              {{ getMonthTitle(e.monthIndex) }}
            </span>
          </router-link>
        </li>
      </ul>
      <div v-else class="py-4">Нет данных</div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useDatesData } from "@/composables/datesData";
import FilterByYearAndGroup from "@/components/filtersElements/FilterByYearAndGroup.vue";

const { getMonthTitle } = useDatesData();
defineProps({ list: { type: Array, required: false } });
</script>
