<template>
  <tr v-if="child" class="dictation-row">
    <td>{{ child.child.lastname }} {{ child.child.firstname }}</td>

    <td v-if="viewOnly === true">
      <b>{{ level }}</b>
    </td>
    <td v-else>
      <CompactSelect :options="options" v-model="level" />
    </td>

    <td
      :class="{ 'bordered-cell': fails.length > 0 }"
      class="dictation-row__competence h-100 w-100"
    >
      <v-list v-if="viewOnly === true">
        <v-list-item
          v-for="f in fails"
          :key="f"
          :subtitle="f.chapter.competenceChapterName"
        >
          <template v-slot:title>
            <p style="white-space: break-spaces !important">
              {{ f.competenceName }}
            </p>
          </template>
        </v-list-item>
      </v-list>

      <ChildCompetenceEditor v-else v-model="fails" />
    </td>
  </tr>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps, onMounted } from "vue";

import CompactSelect from "@/elements/CompactSelect.vue";
import ChildCompetenceEditor from "@/components/ChildCompetenceEditor.vue";
import { usePopupStore } from "@/store/popup";
import { customAxios } from "@/service/customAxios";

const options = [
  { title: "5", value: 5 },
  { title: "4", value: 4 },
  { title: "3", value: 3 },
  { title: "2", value: 2 },
  { title: "1", value: 1 },
];

const emits = defineEmits(["updateLists"]);

const props = defineProps({
  child: {
    type: Object,
    required: true,
  },
  viewOnly: {
    type: Boolean,
    required: false,
  },
});

const { showError, showToastPopup } = usePopupStore();

const level = ref(null);
const fails = ref([]);

onMounted(setCurrentData)

function setCurrentData() {
  if (!props.child) {
    return;
  }
  level.value = props.child.dictationLevel;
  fails.value = props.child.failCompetenceList;
}

async function editChildsResult() {
  try {
    await editChildsResultHandler();
    showToastPopup("Сохранено");
    emits("updateLists");
  } catch (e) {
    showError(e);
  }
}

async function editChildsResultHandler() {
  return customAxios.put(`/dictation/child/${props.child.id}`, {
    dictationLevel: level.value,
    childId: props.child.id,
    failCompetenceList: fails.value,
  });
}

watch(level, async (v) => {
  if (props.child && v !== props.child.dictationLevel) {
    editChildsResult();
  }
});

watch(
  fails,
  (v) => {
    if (props.child && v !== props.child.failCompetenceList) {
      editChildsResult();
    }
  },
  { deep: true }
);
</script>

<style>
.wrap-text {
  white-space: unset !important;
}
</style>
