<template>
  <v-autocomplete
    v-if="employeesSelector.length"
    v-model="selectedEmployee"
    :items="employeesSelector"
    variant="outlined"
    placeholder="Сотрудник"
    item-value="value"
    density="compact"
    hide-details
  ></v-autocomplete>
  <div v-else class="empty-select"></div>
</template>

<script setup>
import { useEmployeeData } from "@/composables/employeeData";
import { pushFilterToURL, removeFilterFromURL } from "@/service/router";
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";
const { getEmployees, employeesSelector, selectedEmployee } = useEmployeeData();

const route = useRoute();

onMounted(async () => {
  await getEmployees();

  setCurrentFilter();
});

watch(selectedEmployee, (id) => {
  id
    ? pushFilterToURL({ name: "filterByEmployee", value: id })
    : removeFilterFromURL("filterByEmployee");
});

function setCurrentFilter() {
  if (!route.query.filterByEmployee || !employeesSelector.value.length) {
    return;
  }

  const isExisted =
    employeesSelector.value.findIndex(
      (v) => v.value === Number(route.query.filterByEmployee)
    ) >= 0;

  selectedEmployee.value = isExisted
    ? Number(route.query.filterByEmployee)
    : null;
}
</script>

<style lang="scss"></style>
