<template>
  <Transition name="list">
    <div v-if="isVisible" class="custom-popup" :class="popupType">
      <CustomConfirm v-if="popupType === 'confirm'" />
      <ErrorPopup v-if="popupType === 'error'" />
      <ToastPopup v-if="popupType === 'toast'" />
      <WarningPopup v-if="popupType === 'warning'" />
      <InfoPopup v-if="popupType === 'info'" />
    </div>
  </Transition>
</template>

<script>
import { usePopupStore } from "@/store/popup";
import { storeToRefs } from "pinia";

import ToastPopup from "@/components/popups/ToastPopup.vue";
import ErrorPopup from "@/components/popups/ErrorPopup.vue";
import CustomConfirm from "@/components/popups/CustomConfirm.vue";
import WarningPopup from "@/components/popups/WarningPopup.vue";
import InfoPopup from "@/components/popups/InfoPopup.vue";

export default {
  components: {
    InfoPopup,
    ToastPopup,
    ErrorPopup,
    CustomConfirm,
    WarningPopup,
  },
  setup() {
    const { isVisible, popupType } = storeToRefs(usePopupStore());
    setTop();

    function setTop() {
      const toolbar = document.querySelector(".v-toolbar");

      if (toolbar) {
        top.value = toolbar.clientHeight + 16;
      }
    }

    window.addEventListener("resize", setTop);

    return { isVisible, popupType };
  },
};
</script>

<style lang="scss">
.custom-popup {
  position: fixed;
  z-index: 10000;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  left: 50%;
  transform: translateX(-50%);
  top: 5%;
}
</style>
