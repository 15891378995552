import { defineStore } from "pinia";

export const useSideMenuStore = defineStore("side-menu", {
  state: () => {
    return {
      visible: false,
    };
  },
  actions: {
    hide() {
      this.visible = false;
    },
    show() {
      this.visible = true;
    },
  },
});
