import { defineStore } from "pinia";

export const useNotesStore = defineStore("notes", {
  state: () => ({
    notes: [],
    loading: false,
    previewText: "",
    noteBody: "",
  }),
  getters: {
    getNoteById(state) {
      return (id) => {
        if (!state.notes.length) {
          return null;
        }
        return state.notes.find((v) => v.id === Number(id));
      };
    },
  },
  actions: {
    setNotes(l) {
      this.notes = l;
    },
    setLoadingStatus(v) {
      this.loading = v;
    },
    clearNotes() {
      this.notes = [];
    },
    changePreviewText(t) {
      this.previewText = t
    },
    changeNoteBody(t) {
      this.noteBody = t
    },
    clearNoteContent() {
      this.previewText = ''
      this.noteBody = ''
    }
  },
});
