import { useUserData } from "@/composables/userData";
import { customAxios } from "@/service/customAxios";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    profile: JSON.parse(localStorage.getItem("user-profile")),
    mainRole: JSON.parse(localStorage.getItem("user-role")),
  }),
  getters: {
    roles(state) {
      if (!state.user) {
        return [];
      }

      return state.user.roles.filter((v) => !v.toLowerCase().includes("user"));
    },
    userId(state) {
      if (!state.user) {
        return null;
      }
      return state.user.id;
    },
    isTutor() {
      if (!this.user) {
        return false;
      }

      return (
        this.user.roles.includes("ROLE_TUTOR") &&
        (this.mainRole === "ROLE_TUTOR" || this.mainRole === null)
      );
    },
    isAdmin() {
      if (!this.user) {
        return false;
      }

      return (
        this.user.roles.includes("ROLE_ADMIN") &&
        (this.mainRole === "ROLE_ADMIN" || this.mainRole === null)
      );
    },
    isParent() {
      if (!this.user) {
        return false;
      }

      return (
        this.user.roles.includes("ROLE_PARENT") &&
        (this.mainRole === "ROLE_PARENT" || this.mainRole === null)
      );
    },
    isMaster() {
      if (!this.user) {
        return false;
      }
      return (
        this.user.roles.includes("ROLE_MASTER") &&
        (this.mainRole === "ROLE_MASTER" || this.mainRole === null)
      );
    },
    isChild() {
      if (!this.user) {
        return false;
      }
      return this.user.roles.includes("ROLE_CHILD");
    },
    userGlobalRole(state) {
      if (!state.user) {
        return null;
      }

      const hasEmployeeRole = state.user.roles.find((v) =>
        ["ROLE_MASTER", "ROLE_TUTOR", "ROLE_TEACHER", "ROLE_ADMIN"].includes(v)
      );

      return hasEmployeeRole ? "employee" : "user";
    },
  },
  actions: {
    setMainRole(role) {
      this.mainRole = role;
      localStorage.setItem("user-role", JSON.stringify(this.mainRole));
    },
    logout() {
      const { resetUserLocalStorage } = useUserData();

      customAxios.setUser(null);
      this.reset();
      resetUserLocalStorage();
    },
    reset() {
      this.user = null;
      this.mainRole = null;
      this.profile = null;
    },
    setUser(data) {
      this.user = data;
      localStorage.setItem("user", JSON.stringify(this.user));
      customAxios.setUser(data);
    },
    setProfile(data) {
      this.profile = data;
      localStorage.setItem("user-profile", JSON.stringify(this.profile));
    },
    setNewTokens(newAccessToken, newRefreshToken) {
      this.user.token = newAccessToken
      this.user.refreshToken = newRefreshToken
    }
  },
});
