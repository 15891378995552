<template>
  <div class="d-flex justify-space-between flex-wrap">
    <h3>
      <slot></slot>
    </h3>
    <router-link
      :to="{
        name: 'subject-journal-main',
        query: { journalId: $route.query.journalId },
      }"
      class="bordered-item"
      >Назад к журналу</router-link
    >
  </div>
</template>
