<template>
  <div v-if="currentList">
    <DictationEditor @updateLists="$emit('updateLists')" />
    <DictationTable @updateLists="$emit('updateLists')" class="mt-3" />
  </div>
</template>

<script setup>
import { computed, defineEmits, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { useSubjectsJournalStore } from "@/store/subjects-journal";

import DictationTable from "@/subjectJournal/ratingSheets/DictationTable.vue";
import DictationEditor from "@/subjectJournal/ratingSheets/DictationEditor.vue";

const emits = defineEmits(["updateLists"]);
const { clearList, listById } = useSubjectsJournalStore();
const route = useRoute();

const currentList = computed(() => listById(route.query.listId));

onMounted(() => {
  emits("updateLists");
});

onUnmounted(clearList);
</script>
