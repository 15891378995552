<template>
  <div class="article-page wrapper">
    <PageTitle :title="news.newsHeader" />
    <div v-if="news.newsFiles.length" class="page-wrapper">
      <p class="d-flex align-center">
        <CIcon icon="file-icon" class="mr-2" /> Прикрепленные файлы:
      </p>

      <v-chip-group class="mt-3 mb-3">
        <v-chip
          v-for="(f, i) in news.newsFiles"
          :key="i"
          @click="downloadFile(f)"
        >
          {{ f.originalFileName }}
        </v-chip>
      </v-chip-group>

      <small>* Нажмите на название файла, чтобы скачать</small>
    </div>

    <div class="page-wrapper mt-4">
      <div v-html="news.newsBody" class="content-wrapper"></div>

      <div class="article-page__info">
        <div class="article-page__creator">
          <AvatarImage />
          <div>
            <p>
              {{ news.creator.firstname }}
              {{ news.creator.lastname }}
            </p>

            <p>{{ getDateFormatWithoutTime(news.createDateTime) }}</p>
          </div>
        </div>

        <div class="article-page__views">
          <CIcon icon="eye-icon" />
          <p><span>Количество просмотров:</span> {{ news.newsViews.length }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/elements/PageTitle.vue";
import AvatarImage from "@/elements/AvatarImage.vue";
import CIcon from "@/elements/CIcon.vue";

import { useNewsStore } from "@/store/news";
import { storeToRefs } from "pinia";
import { computed } from "vue";

import { useRoute } from "vue-router";
import { useDatesChanger } from "@/composables/datesChanger";
import { customAxios } from "@/service/customAxios";
import download from "js-file-download";

export default {
  components: { CIcon, PageTitle, AvatarImage },
  setup() {
    const { getDateFormatWithoutTime } = useDatesChanger();

    const route = useRoute();

    const { getNewsById } = storeToRefs(useNewsStore());
    const news = computed(() => getNewsById.value(route.params.articleId));

    async function downloadFile(item) {
      try {
        const r = await downloadFileHandler(item);
        download(r.data, item.originalFileName);
      } catch (e) {
        console.log(e);
      }
    }

    async function downloadFileHandler(item) {
      return customAxios.get(`/news/file/${item.id}`, {
        responseType: "blob",
      });
    }

    return { news, getDateFormatWithoutTime, downloadFile };
  },
};
</script>

<style lang="scss">
.article-page {
  img {
    border-radius: 16px;
    max-width: 100%;
  }

  &__info {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
  }

  &__creator {
    color: $light-text;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    margin-right: 16px;
    display: flex;
    align-items: center;
  }

  &__views {
    display: flex;
    color: $light-text;

    svg {
      stroke: $main-2;
      margin-right: 4px;
    }
  }

  @media (max-width: 480px) {
    &__views {
      span {
        display: none;
      }
    }
  }
}

//

.content-wrapper img {
  margin: auto;
  display: block;
}

.content-wrapper ul {
  padding: 20px !important;
  margin-left: 40px;
}

.content-wrapper .ql-align-center {
  align-content: center;
}

.ql-align-center {
  margin: 40px !important;
  align-content: center;
}

.content-wrapper p {
  margin: 10px !important;
  align-content: center;
}

.content-wrapper blockquote {
  padding: 15px;
  background: #eee;
  border-radius: 5px;
}

.content-wrapper blockquote p {
  padding: 15px;
  background: #eee;
  border-radius: 5px;
}

.content-wrapper blockquote::after {
  content: "\201C";
}

.content-wrapper blockquote::before {
  content: "\201D";
}

.content-wrapper pre {
  padding: 15px;
  background: rgba(104, 99, 255, 0.96);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
