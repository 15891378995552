<template>
  <div>
    <PageTitle
      :title="`Заметка о ребенке ${
        note && note.child
          ? `${note.child.lastname} ${note.child.firstname}`
          : ``
      }`"
    />

    <div v-if="note">
      <div
        v-if="isTutor"
        class="d-flex align-center justify-end my-2"
      >
        <router-link
          :to="{
            name: 'tutor-note-edit',
            query: {
              groupId: $route.query.groupId,
              noteId: $route.query.noteId,
            },
          }"
          class="accent-button"
          >Редактировать</router-link
        >
      </div>
      <div class="page-wrapper">
        <div v-html="note.noteBody"></div>
      </div>

      <div class="page-wrapper mt-3">
        <h3>Файлы</h3>

        <FilesPreview
          :files="note.fileList"
          :links="{
            downloadUrl: '/tutor/note/child/file/',
            deleteUrl: '/tutor/note/child/files/delete/',
          }"
        />
      </div>
      <div v-if="note.creator" class="page-wrapper my-3">
        <div class="d-flex flex-wrap">
          <div class="my-2 mr-4">
            <p class="text-grey">Автор</p>
            <span>
              {{ note.creator.firstname }} {{ note.creator.secname }}
              {{ note.creator.lastname }}</span
            >
          </div>

          <div class="my-2">
            <p class="text-grey">Дата</p>
            <span> {{ getDateFormatWithoutTime(note.noteDateTime) }}</span>
          </div>
        </div>
      </div>
    </div>
    <LoaderRing v-else />
  </div>
</template>

<script setup>
import { useNotesStore } from "@/store/notes";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

import { computed } from "vue";
import { useDatesChanger } from "@/composables/datesChanger";
import { useAuthStore } from "@/store/auth";

import LoaderRing from "@/elements/LoaderRing.vue";
import PageTitle from "@/elements/PageTitle.vue";
import FilesPreview from "../FilesPreview.vue";

const { getDateFormatWithoutTime } = useDatesChanger();
const { isTutor } = useAuthStore();
const { notes } = storeToRefs(useNotesStore());

const route = useRoute();

const note = computed(() => {
  if (!notes.value || (notes.value && !notes.value.length)) {
    return null;
  }

  return notes.value.find((v) => v.id === Number(route.query.noteId));
});
</script>
