import { customAxios } from "@/service/customAxios";
import { computed, ref } from "vue";

export function useSchoolData() {
  const schoolYears = ref([]);
  const selectedSchoolYear = ref(null);
  const schoolYearsOptions = computed(() => {
    return schoolYears.value.length
      ? schoolYears.value.map((v) => ({
          value: v.id,
          title: v.studyYearName,
        }))
      : [];
  });

  async function getSchoolYears() {
    try {
      const r = await getSchoolYearsHandler();
      setYears(r.data);
    } catch (e) {
      console.log(e);
    }
  }

  function setYears(data) {
    schoolYears.value = data;
    selectedSchoolYear.value = schoolYearsOptions.value.length
      ? schoolYearsOptions.value[schoolYearsOptions.value.length - 1].value
      : null;
  }

  function getSchoolYearsHandler() {
    return customAxios.get("/schedule/year");
  }
  return {
    getSchoolYears,
    selectedSchoolYear,
    schoolYearsOptions,
  };
}
