<template>
  <div class="avatar-with-cropper" @click="showCropper = true">
    <div class="avatar-with-cropper__content">
      <CIcon icon="camera-photo-icon" />
      <div class="avatar-with-cropper__tip">Добавить фото</div>
    </div>

    <img
      v-if="avatar"
      :src="`https://school.eurekacenter.ru/avatar/${avatar}`"
    />
    <CustomAvatarCropper
      v-model="showCropper"
      :upload-url="uploadUrl"
      @uploaded="handleUploaded"
      @error="handleError"
      :labels="labels"
      :request-options="headers"
    />
  </div>
</template>

<script setup>
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
import { ref, defineProps, defineEmits, computed } from "vue";

import { usePopupStore } from "@/store/popup";

import CIcon from "@/elements/CIcon.vue";
import CustomAvatarCropper from "@/components/CustomAvatarCropper.vue";

const props = defineProps({
  id: { type: Number, required: true },
  avatar: { type: String, required: false },
});

const emits = defineEmits(["update"]);

const { showToastPopup, showErrorWithCustomText } = usePopupStore();

const showCropper = ref(false);

const { user, userGlobalRole } = storeToRefs(useAuthStore());

const uploadUrl = computed(() => {
  if (!user.value) {
    return;
  }

  return userGlobalRole === "employee"
    ? `https://school-backend.eurekacenter.ru/api/upload/avatar/${props.id}`
    : `https://school-backend.eurekacenter.ru/api/upload/avatar`;
});

const labels = { submit: "Сохранить", cancel: "Отмена" };
const headers = {
  method: "POST",
  headers: { Authorization: "Bearer " + user.value.token },
  responseType: "blob",
};

function handleUploaded() {
  showToastPopup("Аватар загружен");
  emits("update");
}

function handleError() {
  showErrorWithCustomText("Произошла ошибка, попробуйте ещё раз");
}
</script>

<style lang="scss">
.avatar-with-cropper {
  width: 200px;
  height: 200px;
  background-size: 200% auto;
  background-image: linear-gradient(
    to right,
    #968ae7 0%,
    #b9acff 51%,
    #deceff 100%
  );
  transition: all 0.2s;
  position: relative;
  border-radius: 8px;
  max-width: 300px;
  box-shadow: $shadow;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;

  svg {
    stroke: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    height: auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    overflow: hidden;
    display: flex;
    border-radius: 8px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__tip {
    color: #fff;
    background-color: rgba($main-1, 0.6);
    width: 100%;
    position: absolute;
    bottom: -100%;
    padding: 8px;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
  }

  &:hover {
    background-position: right center;

    .avatar-with-cropper__tip {
      bottom: 0;
    }
  }
}
</style>
