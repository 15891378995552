<template>
  <div v-if="currentChild" class="page-wrapper">
    <PersonCard :person="currentChild" />
  </div>
  <LoaderRing v-else />
</template>

<script setup>
import { onMounted } from "vue";
import { useChildData } from "@/composables/childProfile";

import PersonCard from "@/components/PersonCard.vue";
import LoaderRing from "@/elements/LoaderRing.vue";

const { updateCurrentChild, currentChild } = useChildData();

onMounted(updateCurrentChild);
</script>
