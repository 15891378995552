<template>
  <div v-if="allCompetence.length">
    <h3>Критерии оценочного листа</h3>

    <div v-for="c in allCompetence" :key="c.id">
      <div class="page-wrapper mt-3">
        <ChildCompetenceEditor
          v-if="c.competenceLineName.toLowerCase().includes('speaking')"
          :inboxCompetence="c"
          v-model="speakingCompetence"
        />
        <ChildCompetenceEditor
          v-if="c.competenceLineName.toLowerCase().includes('listening')"
          :inboxCompetence="c"
          v-model="listeningCompetence"
        />
        <ChildCompetenceEditor
          v-if="c.competenceLineName.toLowerCase().includes('writing')"
          :inboxCompetence="c"
          v-model="writingReadingCompetence"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { customAxios } from "@/service/customAxios";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { storeToRefs } from "pinia";

import { computed, onMounted, ref, watch, defineEmits } from "vue";
import { useRoute } from "vue-router";
import { usePopupStore } from "@/store/popup";

import ChildCompetenceEditor from "@/components/ChildCompetenceEditor.vue";

const emits = defineEmits(["updateLists"]);
const { journal } = storeToRefs(useSubjectsJournalStore());
const { listById } = useSubjectsJournalStore();
const { showError, showToastPopup } = usePopupStore();

const route = useRoute();

const currentList = computed(() => listById(route.query.listId));

const allCompetence = ref([]);

const writingReadingCompetence = ref([]);
const speakingCompetence = ref([]);
const listeningCompetence = ref([]);

onMounted(load);

setCurrentData();

watch(journal, load);

function setCurrentData() {
  if (!currentList.value) {
    return;
  }
  writingReadingCompetence.value = currentList.value.writingReadingCompetences;
  speakingCompetence.value = currentList.value.speakingCompetences;
  listeningCompetence.value = currentList.value.listeningCompetences;
}

async function load() {
  if (journal.value) {
    try {
      const r = await getCompetenceHandler();
      allCompetence.value = r.data.filter((v) => v.active);
    } catch (e) {
      showError(e);
    }
  }
}

async function getCompetenceHandler() {
  return customAxios.get(
    `/schedule/competence/line/by_class/${journal.value.scheduleClassId}`
  );
}

const options = computed(() => ({
  speakingCompetences: speakingCompetence.value,
  writingReadingCompetences: writingReadingCompetence.value,
  listeningCompetences: listeningCompetence.value,
}));

watch(options, save);

async function save(data) {
  try {
    await saveList(data);
    showToastPopup("Сохранено");
    emits("updateLists");
  } catch (e) {
    showError(e);
  }
}

async function saveList(data) {
  return customAxios.put("/foreign_checklist/" + route.query.listId, {
    ...data,
    listDescription: currentList.value.listDescription,
  });
}
</script>
