<template>
  <div
    v-if="currentList && currentList.skillListSkills.length"
    class="page-wrapper"
  >
    <div class="table-simple">
      <table>
        <thead>
          <tr>
            <th></th>
            <th
              v-for="s in currentList.skillListSkills"
              :key="s.id"
              class="bordered-title-cell"
            >
              <p>{{ s.skillDescription }}</p>
              <p>Ур. {{ s.skillLevel }}</p>
            </th>
            <th class="bordered-title-cell">
              <p>1 ур.</p>
              <p>баллы</p>
            </th>
            <th class="bordered-title-cell">
              <p>1 ур.</p>
              <p>%</p>
            </th>

            <th class="bordered-title-cell">
              <p>2 ур.</p>
              <p>баллы</p>
            </th>
            <th class="bordered-title-cell">
              <p>2 ур.</p>
              <p>%</p>
            </th>

            <th class="bordered-title-cell">
              <p>3 ур.</p>
              <p>баллы</p>
            </th>
            <th class="bordered-title-cell">
              <p>3 ур.</p>
              <p>%</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(c, i) in sortedChildren"
            :key="c.id"
            @click="lastEditRowId = i"
            :class="{ 'table-simple__last-visited': lastEditRowId === i }"
          >
            <td>{{ c.child.lastname }} {{ c.child.firstname }}</td>
            <TableCell
              v-for="s in c.skills"
              :key="s.id"
              @setGrade="setGrade"
              :skill="s"
            />

            <!-- 1 level  -->
            <td class="bordered-cell">
              <span v-if="c.resultComputedTeacherGrade">
                {{ roundUp(c.resultComputedTeacherGrade) }}
              </span>
            </td>

            <td class="bordered-cell">
              <p v-if="c.resultComputedTeacherGradePercentage">
                {{ roundUp(c.resultComputedTeacherGradePercentage) }}
              </p>
            </td>

            <!-- 2 level  -->
            <td class="bordered-cell">
              <p v-if="c.resultComputedTeacherGradeLevel2">
                {{ roundUp(c.resultComputedTeacherGradeLevel2) }}
              </p>
            </td>
            <td class="bordered-cell">
              <p v-if="c.resultComputedTeacherGradePercentageLevel2">
                {{ roundUp(c.resultComputedTeacherGradePercentageLevel2) }}
              </p>
            </td>
            <!-- 3 level  -->
            <td class="bordered-cell">
              <p v-if="c.resultComputedTeacherGradeLevel3">
                {{ roundUp(c.resultComputedTeacherGradeLevel3) }}
              </p>
            </td>
            <td class="bordered-cell">
              <p v-if="c.resultComputedTeacherGradePercentageLevel3">
                {{ roundUp(c.resultComputedTeacherGradePercentageLevel3) }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { computed, ref, defineEmits } from "vue";
import { useRoute } from "vue-router";

import { usePopupStore } from "@/store/popup";
import { customAxios } from "@/service/customAxios";
import { sortByChildLastname } from "@/util/common";

import TableCell from "./TableCell.vue";

const emits = defineEmits(["updateLists"]);

const route = useRoute();
const lastEditRowId = ref(null);

const { listById } = useSubjectsJournalStore();
const currentList = computed(() => listById(route.query.listId));

const { showToastPopup, showError } = usePopupStore();

const sortedChildren = computed(() => {
  return sortByChildLastname(currentList.value.listChildrens);
});

async function setGrade(data) {
  try {
    await setGradeHandler(data);
    showToastPopup("Выставлено");
    emits("updateLists");
  } catch (e) {
    showError(e);
  }
}

async function setGradeHandler(data) {
  return customAxios.put(
    `/teacher/log/skill_list/child/skill/teacher/${data.id}`,
    data
  );
}

function roundUp(digit) {
  return Math.round(digit);
}
</script>
