<template>
  <div>
    <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2">{{
      item.className
    }}</v-label>
    <v-select
      v-model="status"
      :items="visitStatusList"
      placeholder="Статус"
      hide-details
      density="compact"
      variant="outlined"
    >
    </v-select>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, defineEmits } from "vue";

import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { visitStatusList } from "@/util/common";

const { showError, showToastPopup, showInfoPopup, showWarningPopup } =
  usePopupStore();

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});
const emits = defineEmits(["update"]);

const status = ref(props.item.logAttendanceStatus);

watch(status, saveLogAttendance);

async function saveLogAttendance() {
  try {
    const r = await saveLogAttendanceHandler();

    if (r.status === 202) {
      showToastPopup("Посещение сохранено");
    } else if (r.status === 207) {
      showInfoPopup(r.data.message);
    } else {
      showWarningPopup("Что-то пошло не так");
    }

    emits("update");
  } catch (error) {
    showError(error);
  }
}
async function saveLogAttendanceHandler() {
  return customAxios.put(
    "/attendance/from_teacher_log/" + props.item.logChildId,
    {
      logAttendanceStatus: status.value,
    }
  );
}
</script>
