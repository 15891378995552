<template>
  <div class="wrapper">
    <v-card>
      <v-card-title class="card_ligth_header">
        <v-row cols="6" class="pa-2">
          <v-col cols="12 ma-4">
            <h3>Уровни освоения предметов в динамике</h3>
            <h3 v-if="child">{{ child.lastname }} {{ child.firstname }} {{ child.secname }}</h3>
          </v-col>
          <v-col class="v-col-12 v-col-sm-12 v-col-lg-12 v-col-md-12 v-col-xl-12 v-col-xxl-12">
            <v-select v-model="metricView" :items="metricViewData" label="Вид графика" item-title="name"
              item-value="value" variant="outlined" class="w-100"></v-select>
          </v-col>
          <v-col class="v-col-12 v-col-sm-12 v-col-lg-12 v-col-md-12 v-col-xl-12 v-col-xxl-12">
            <v-select v-model="selectedSubjectId" v-on:update:model-value="getChildMetrics()" :items="subjects"
              label="Предмет" item-title="scheduleClassName" item-value="id" variant="outlined" class="w-100">
              <template v-slot:append>
                <v-icon icon="mdi-book-search-outline" @click="getChildMetrics()"></v-icon>

              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <LoaderRing v-if="showLoader" />
      <div v-else>
        <v-card class="ma-0 pa-10" v-if="selectedData && selectedData.length > 0">
          <div v-if="chartDatas">
            <Bar v-if="metricView === 'bar'" :data="chartDatas" :options="chartValues"></Bar>
            <Radar v-else :data="chartDatas" :options="chartValues"></Radar>
          </div>
          <div v-if="!!selectedData && selectedSubjectId !== 13">
            <v-row v-for="d in selectedData" :key="d"
              class="ma-10 v-col-12 v-col-sm-12 v-col-lg-12 v-col-md-12 v-col-xl-12 v-col-xxl-12">
              <v-col cols="12 ma-4">
                <h3>{{ d.valueDescription + " (" + this.formatDate(d.valueDate) + ")" }}</h3>
              </v-col>
              <v-table>
                <thead>
                  <tr>
                    <th class="text-left">
                      Задание
                    </th>
                    <th class="text-left">
                      Уровень
                    </th>
                    <th class="text-left">
                      Навыки
                    </th>
                    <th class="text-left">
                      Оценка учителя
                    </th>

                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in d.tableDataList" :key="item">
                    <td>{{ item.exerciseName }}</td>
                    <td>{{ item.skillLevel }}</td>
                    <td><span v-for="i in item.exerciseCompetence" :key="i.id"><a
                          v-on:click="getSkillDynamic(i)">{{ i.competenceName }}</a> <b v-if="i.chapter">(Раздел:
                          {{ i.chapter.competenceChapterName }})</b></span> </td>
                    <td>{{ item.exerciseTeacherBall }}</td>

                  </tr>
                </tbody>
              </v-table>
            </v-row>
          </div>
        </v-card>
        <v-card-text v-else>
          <v-row>
            <v-banner
              text="Нет данных для отображения. Необходимо выбрать ребенка, предмет, вид графика и нажать на кнопку открыть."
              :stacked="false"></v-banner>
          </v-row>
        </v-card-text>
      </div>
      <v-dialog v-model="showCompetenceDynamic" scrollable persistent>
        <v-card>
          <v-card-title class="card_ligth_header">
            <h5>Динамика по навыку</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <!--{{competenceDynamicData}}-->
            <Bar :data="selectedCompetenceChartDatas"></Bar>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <v-btn rounded variant="outlined" @click="showCompetenceDynamic = false">
              Закрыть
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

  </div>
</template>
<script>
import { Bar, Radar } from "vue-chartjs";
import { apiUrl } from "@/service/user.service";
import { useRoute } from "vue-router";


import {
  BarElement,
  CategoryScale,
  Chart as ChartJS, Filler,
  Legend,
  LinearScale, LineElement, PointElement,
  RadialLinearScale,
  Title,
  Tooltip
} from "chart.js";
import LoaderRing from "@/elements/LoaderRing.vue";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
);

export default {
  name: "ChildMetrics",
  setup() {
    const route = useRoute();
    return {
      route
    };
  },

  props: [],

  components: {
    LoaderRing,
    Bar,
    Radar
  },
  data: () => ({
    selectedCompetenceChartDatas: [],
    selectedCompetence: '',
    competenceDynamicData: [],
    showCompetenceDynamic: false,
    subjects: [],
    selectedSubjectId: '',
    chartValues: {},

    metricViewData: [
      { name: "Паутинка", value: "radar" },
      { name: "Столбики", value: "bar" },
    ],
    metricView: "bar",
    showLoader: false,
    chartData: [],
    myProfile: {},
    selectedData: null,
    showMetrics: false,
    lastDate: null,
    chartDatas: null,
    child: {},


  }),

  methods: {
    getSkillDynamic: function (item) {

      this.selectedCompetence = item;

      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token,
        },
      };


      let req = {
        childId: this.route.params.id,
        competenceId: item.id

      }

      this.axios
        .post(apiUrl + "/child/metric/by_competence", req, jwtTokenConfig)
        .then((response) => {
          if (response.status === 200) {
            this.competenceDynamicData = response.data;
            this.getSkillDynamicMetricsData(this.competenceDynamicData)


          } else if (response.status === 207) {
            console.log(response)
            alert(response.data.message);
          } else {
            this.showLoader = false;
            console.log(response);
          }
        })
        .catch((error) => {
          this.showLoader = false;
          console.log(error);
        });

    },

    getSkillDynamicMetricsData(chartData) {

      let labelSet = [];
      let dataSet = [];

      chartData.forEach((element) => {
        if (element.createDateTime) {
          labelSet.push(this.formatDate(element.createDateTime) + "(Уровень:" + element.skill.skillLevel + ")");
        } else {
          labelSet.push(" - ");
        }
        if (element.teacherGrade) {
          dataSet.push(element.teacherGrade.toFixed(2));
        } else {
          dataSet.push(0)
        }
      });

      this.selectedCompetenceChartDatas = {
        labels: labelSet,
        datasets: [
          {
            label: this.selectedCompetence.competenceName,
            backgroundColor: "rgba(180,255,99,0.2)",
            borderColor: "rgb(138,255,99)",
            pointBackgroundColor: "rgb(161,255,99)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(169,255,99)",
            data: dataSet,
          },
        ],
      };
      this.chartValues = {
        responsive: true,
      };

      this.showCompetenceDynamic = true;

    },

    getChildMetrics: function () {
      if (this.selectedSubjectId) {
        this.showLoader = true;
        let jwtTokenConfig = {
          headers: {
            Authorization: "Bearer " + this.user.token,
          },
        };

        let req = {
          childId: this.route.params.id,
          subjectId: this.selectedSubjectId

        }
        this.axios
          .post(apiUrl + "/child/metric/by_subject", req, jwtTokenConfig)
          .then((response) => {
            if (response.status === 200) {
              this.selectedData = response.data;
              if (this.selectedSubjectId !== 13) {
                this.metricsData(this.selectedData)
                this.showLoader = false;
              } else {
                this.foreignMetricsData(this.selectedData)
                this.showLoader = false;
              }

            } else if (response.status === 207) {
              console.log(response)
              this.showLoader = false;
              alert(response.data.message);
            } else {
              this.showLoader = false;
              console.log(response);
            }
          })
          .catch((error) => {
            this.showLoader = false;
            console.log(error);
          });
      } else {
        alert("Необходимо выбрать предмет!")
      }
    },
    metricsData(chartData) {
      this.selectedChart = chartData;
      let labelSet = [];
      let childDataSet = [];
      let childDataSetLevel2 = [];
      let childDataSetLevel3 = [];

      chartData.forEach((element) => {
        if (element.valueDate) {
          labelSet.push(element.valueDescription + " (" + this.formatDate(element.valueDate) + ")");
        }
        if (element.valueBall) {
          childDataSet.push(element.valueBall.toFixed(2));
        } else {
          childDataSet.push(0);
        }
        if (element.valueBallLevel2) {
          childDataSetLevel2.push(element.valueBallLevel2.toFixed(2));
        } else {
          childDataSetLevel2.push(0);
        }
        if (element.valueBallLevel3) {
          childDataSetLevel3.push(element.valueBallLevel3.toFixed(2));
        } else {
          childDataSetLevel3.push(0);
        }
      });

      this.chartDatas = {
        scales: {
          y: {
            max: 100,
            min: 0,
          },
          x: {
            max: 100,
            min: 0,
          },
        },
        labels: labelSet,
        datasets: [
          {
            label: "Динамика по предмету (уровень - 1, % )",
            backgroundColor: "rgba(180,255,99,0.2)",
            borderColor: "rgb(138,255,99)",
            pointBackgroundColor: "rgb(161,255,99)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(169,255,99)",
            data: childDataSet,
          },
          {
            label: "Динамика по предмету (уровень - 2, % )",
            backgroundColor: "rgba(255,193,99,0.2)",
            borderColor: "rgb(255,219,99)",
            pointBackgroundColor: "rgb(255,200,99)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(255,182,99)",
            data: childDataSetLevel2,
          },
          {
            label: "Динамика по предмету (уровень - 3, % )",
            backgroundColor: "rgba(255,99,132,0.2)",
            borderColor: "rgba(255,99,132,1)",
            pointBackgroundColor: "rgba(255,99,132,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(255,99,132,1)",
            data: childDataSetLevel3,
          },
        ],
      };
      this.chartValues = {
        responsive: true,
        indexAxis: 'x',
        elements: {
          bar: {
            borderWidth: 1,
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            font: {
              family: 'Arial',
              size: 16,
              style: 'normal',
              lineHeight: 1.2
            },
            display: true,
            text: getById(this.subjects, this.selectedSubjectId).scheduleClassName,
          }
        },
        scales: {
          y: {
            display: true,
            pointLabels: {
              display: false,
              centerPointLabels: true,
              font: {
                size: 6
              }
            }
          },
          x: {
            display: false,
            pointLabels: {
              display: false,
              centerPointLabels: true,
              font: {
                size: 6
              }
            }
          }
        }
      };

      this.showMetrics = true;
    },
    foreignMetricsData(chartData) {
      this.selectedChart = chartData;
      let labelSet = [];
      let childDataSet1 = [];
      let childDataSet2 = [];
      let childDataSet3 = [];
      let childDataSet4 = [];

      chartData.forEach((element) => {
        if (element.valueDate) {
          labelSet.push(element.valueDescription + " (" + this.formatDate(element.valueDate) + ")");
        }
        if (element.listeningCompetenceBall) {
          childDataSet1.push(element.listeningCompetenceBall);
        } else {
          childDataSet1.push(0)
        }
        if (element.writingReadingCompetenceBall) {
          childDataSet2.push(element.writingReadingCompetenceBall);
        } else {
          childDataSet2.push(0)
        }
        if (element.speakingCompetenceBall) {
          childDataSet3.push(element.speakingCompetenceBall);
        } else {
          childDataSet3.push(0)
        }
        if (element.totalBall) {
          childDataSet4.push(element.totalBall);
        } else {
          childDataSet4.push(0)
        }
      });

      this.chartDatas = {
        labels: labelSet,
        datasets: [
          {
            label: "Listening, %",
            backgroundColor: "rgba(180,255,99,0.2)",
            borderColor: "rgb(138,255,99)",
            pointBackgroundColor: "rgb(161,255,99)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(169,255,99)",
            data: childDataSet1,
          },
          {
            label: "Writing / Reading, %",
            backgroundColor: "rgba(255,193,99,0.2)",
            borderColor: "rgb(255,219,99)",
            pointBackgroundColor: "rgb(255,200,99)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(255,182,99)",
            data: childDataSet2,
          },
          {
            label: "Speaking, %",
            backgroundColor: "rgba(255,99,132,0.2)",
            borderColor: "rgba(255,99,132,1)",
            pointBackgroundColor: "rgba(255,99,132,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(255,99,132,1)",
            data: childDataSet3,
          },
          {
            label: "Total, %",
            backgroundColor: "rgb(250,190,157)",
            borderColor: "rgb(238,153,106)",
            pointBackgroundColor: "rgba(185,82,232,0.82)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(255,192,203)",
            data: childDataSet4,
          }

        ],
      };
      this.chartValues = {
        responsive: true,
        indexAxis: 'x',
        elements: {
          bar: {
            borderWidth: 1,
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            font: {
              family: 'Arial',
              size: 16,
              style: 'normal',
              lineHeight: 1.2
            },
            display: true,
            text: getById(this.subjects, this.selectedSubjectId).scheduleClassName,
          }
        },
        scales: {
          y: {
            display: true,
            pointLabels: {
              display: false,
              centerPointLabels: true,
              font: {
                size: 6
              }
            }
          },
          x: {
            display: false,
            pointLabels: {
              display: false,
              centerPointLabels: true,
              font: {
                size: 6
              }
            }
          }
        }
      };

      this.showMetrics = true;

    },
    formatDate(dateString) {
      let formatter = new Intl.DateTimeFormat("ru", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",

      });
      return capitalizeFirstLetter(formatter.format(Date.parse(dateString)));
    }
  },
  computed: {

    loggedIn() {
      return this.$store.state.authentication.status.loggedIn;
    },
    user() {
      return this.$store.state.authentication.user;
    },
    authentication() {
      return this.$store.state.authentication;
    },
  },
  created: function () {

    this.showLoader = true;

    let jwtTokenConfig = {
      headers: {
        Authorization: "Bearer " + this.user.token,
      },
    };
    function getChild(jwtTokenConfig, axios, route) {
      return axios.get(apiUrl + "/user/" + route.params.id, jwtTokenConfig);
    }

    function getSubjects(jwtTokenConfig, axios) {
      return axios.get(apiUrl + "/schedule/class/active/for_log", jwtTokenConfig);
    }

    Promise.all([
      getChild(jwtTokenConfig, this.axios, this.route),
      getSubjects(jwtTokenConfig, this.axios)
    ])
      .then((results) => {
        if (results[0].status === 200) {
          this.child = results[0].data;
          console.log("child:" + this.child)
        }

        if (results[1].status === 200) {
          this.subjects = results[1].data;
        }

        this.showLoader = false;
      })
      .catch((error) => {
        this.showLoader = false;
        alert("Ошибка загрузки данных, обратитесь к администратору системы!");
        if (error.response.status === 401) {
          this.$store.dispatch("authentication/logout");
          this.$store.dispatch("alert/clear");
          localStorage.clear();
          location.reload();
        }
      });
  },


};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getById(list, id) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return list[i];
    }
  }
  return -1;
}


</script>