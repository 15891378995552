<template>
  <div class="app-selector bordered-item">
    <div class="app-selector__content">
      <label v-if="modelValue === 0">
        <span>{{ emptyOption }}</span>
        <CIcon icon="arrow-icon" />
      </label>

      <label v-else>
        <span>{{ selectedOption.title }}</span>
        <CIcon icon="arrow-icon" />
      </label>

      <select @change="onChange">
        <option v-if="emptyOption" selected disabled>{{ emptyOption }}</option>
        <option
          v-for="opt in options"
          :key="opt.id"
          :value="opt.value"
          :selected="opt.value === modelValue"
        >
          {{ opt.title }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import CIcon from "@/elements/CIcon.vue";

export default {
  components: { CIcon },
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Number,
      required: true,
    },
    emptyOption: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    function onChange(e) {
      emit("update:modelValue", Number(e.target.value));
    }

    const selectedOption = computed(() => {
      if (!props.modelValue) {
        return null;
      }
      return props.options.find((v) => v.value === props.modelValue);
    });

    return {
      onChange,
      selectedOption,
    };
  },
};
</script>

<style lang="scss">
.app-selector {
  &__content {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
  }

  select {
    outline: none;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    color: inherit;
  }

  svg {
    stroke: $secondary;
    margin-left: 4px;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }
}
</style>
