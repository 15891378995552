
<template>
  <v-card class="ma-2">
    <v-dialog v-model="showLoader"><LoaderRing ></LoaderRing></v-dialog>
    <v-card-title class="card_ligth_header">
      <h3 class="ma-2">Занятия</h3>
    </v-card-title>
    <v-card-title class="card_ligth_header" style="min-height: 5vh">
      <v-row>
        <v-col cols="12">
          <v-text-field
              v-model="classSearchBody"
              label="Найти"
              variant="outlined"
              clearable
              hint="Введите ключевое слово для поиска"
              rounded
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
              @click="showClassDialog = true"
              variant="outlined"
              color="primary"
              class="mb-4"
          >
            Добавить
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :key="componentKey" >
      <div class="d-sm-none d-flex align-center flex-column">
        <v-card
   
            class="ma-5 mx-5 w-100"
            v-for="item in classPaginatedData"
            :key="item"
        >
          <v-card-title class="card_ligth_header">
            <h5>Параметр № {{ sortedClasses.indexOf(item) + 1 }}</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list-item lines="two">
              <v-list-item-title>Название:</v-list-item-title>
              <v-list-item-subtitle>{{
                  item.scheduleClassName
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Описание:</v-list-item-title>
              <v-list-item-subtitle>{{
                  item.scheduleClassDescription
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Тип:</v-list-item-title>
              <v-list-item-subtitle f-if="item.scheduleClassType" v-if="item.scheduleClassType">{{
                  item.scheduleClassType.scheduleClassTypeName
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title>Добавлено:</v-list-item-title>
              <v-list-item-subtitle>{{
                  item.createDate.replace("T", " ")
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item lines="two">
              <v-list-item-title
              >Продолжительность(мин):</v-list-item-title
              >
              <v-list-item-subtitle>{{
                  item.scheduleClassDuration
                }}</v-list-item-subtitle>
            </v-list-item>

            <v-list-item lines="two">
              <v-list-item-title>Активно:</v-list-item-title>
              <v-list-item-subtitle
              ><v-chip size="small" v-if="item.isActive">Да</v-chip
              ><v-chip v-else size="small"
              >Нет</v-chip
              ></v-list-item-subtitle
              >
            </v-list-item>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-btn
                @click="editClass(item)"
                class="ma-1"
                color="primary"
                size="small"
                variant="outlined"
                rounded
            >
              Редактировать
            </v-btn>
            <v-btn
                @click="deleteClass(item)"
                class="ma-1"
                color="warning"
                size="small"
                variant="outlined"
                rounded
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div class="d-none d-sm-block">
        <v-table fixed-header class="h-100">
          <thead>
          <tr>
            <th class="text-left">
              <b>№</b>
            </th>
            <th class="text-left"  v-on:click="byScheduleClassName">
              <b>Название </b>
              <font-awesome-icon icon="fa-solid fa-sort"/>
            </th>
            <th class="text-left"  v-on:click="byScheduleClassDescription">
              <b>Описание </b>
              <font-awesome-icon icon="fa-solid fa-sort"/>
            </th>
            <th class="text-left" v-on:click="byClassType">
              <b>Тип </b>
              <font-awesome-icon icon="fa-solid fa-sort"/>
            </th>
            <th class="text-left"  v-on:click="byClassDuration">
              <b>Продолжительность </b>
              <font-awesome-icon icon="fa-solid fa-sort"/>
            </th>
            <th class="text-left" v-on:click="byClassCreateDate">
              <b>Добавлено </b>
              <font-awesome-icon icon="fa-solid fa-sort"/>
            </th>
            <th class="text-left" v-on:click="byClassActive">
              <b>Активно </b>
              <font-awesome-icon icon="fa-solid fa-sort"/>
            </th>
            <th class="text-left">
              <b>Действие</b>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in sortedClasses" :key="item">
            <td>
              <p>{{ sortedClasses.indexOf(item) + 1 }}</p>
            </td>
            <td>
              <p>{{ item.scheduleClassName }}</p>
            </td>
            <td>
              <p>{{ item.scheduleClassDescription }}</p>
            </td>
            <td>
              <p v-if="item.scheduleClassType">
                {{ item.scheduleClassType.scheduleClassTypeName }}
              </p>
            </td>
            <td>
              <p>{{ item.scheduleClassDuration }}</p>
            </td>
            <td>
              <p>{{ item.createDate.replace("T", " ") }}</p>
            </td>
            <td>
              <v-chip size="small" v-if="item.isActive">Да</v-chip
              ><v-chip v-else size="small">Нет</v-chip>
            </td>
            <td>
              <v-btn
                  @click="editClass(item)"
                  class="ma-1"
                  color="primary"
                  size="small"
                  variant="outlined"
                  rounded
              >
                Редактировать
              </v-btn>
              <v-btn
                  @click="deleteClass(item)"
                  class="ma-1"
                  color="warning"
                  size="small"
                  variant="outlined"
                  rounded
              >
                Удалить
              </v-btn>
            </td>
          </tr>
          </tbody>
        </v-table>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions
        class="card_ligth_actions"
        style="min-height: 5vh"
    >
      <v-container class="text-center">
        <v-pagination
            v-model="classPage"
            :length="classPageCount"
            :total-visible="3"
            size="small"
        ></v-pagination>
      </v-container>
    </v-card-actions>
    <v-dialog v-model="showClassDialog" scrollable persistent>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5 v-if="!scheduleClassId">Новое занятие</h5>
          <h5 v-else>Редактирование занятия</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="scheduleClassForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="Название*"
                      v-model="scheduleClassName"
                      :rules="scheduleClassNameRules"
                      hint="Укажите название"
                      :counter="50"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      label="Описание*"
                      v-model="scheduleClassDescription"
                      :counter="255"
                      hint="Укажите описание"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="scheduleClassType"
                      :items="activeClassTypes"
                      outlined
                      dense
                      chips
                      small-chips
                      solo
                      label="Тип"
                      item-title="scheduleClassTypeName"
                      item-value="id"
                      :rules="scheduleClassTypeRules"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.scheduleClassTypeName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.scheduleClassTypeName"
                          :subtitle="item.raw.scheduleClassTypeDescription"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="scheduleClassDuration"
                      :items="minutesCounter"
                      dense
                      filled
                      label="Продолжительность (мин.)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="classTeachers"
                      :items="scheduleEventOrganizers"
                      variant="underlined"
                      clearable
                      chips
                      return-object
                      small-chips
                      multiple
                      label="Преподаватели"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.lastname +' '+ item.raw.firstname + ' ' + item.raw.secname"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.lastname +' '+ item.raw.firstname + ' ' + item.raw.secname"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="personGroups"
                      :items="userGroups"
                      variant="underlined"
                      clearable
                      chips
                      return-object
                      small-chips
                      multiple
                      label="Обучающиеся"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.personGroupName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.personGroupName"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="w-100">
                  <v-label>Цвет фона</v-label>
                  <v-color-picker v-model="this.scheduleClassBackgroundColor"></v-color-picker>
                </v-col>
                <v-col cols="12" class="w-100">
                  <v-label>Цвет текста</v-label>
                  <v-color-picker v-model="this.scheduleClassTextColor"></v-color-picker>
                </v-col>

                <v-col cols="12">
                  <v-row class="px-6" fluid>
                    <v-switch
                        v-model="showInLog"
                        :label="'Показывать в журнале'"
                        color="success"
                    >
                    </v-switch>
                    <v-chip v-if="showInLog">Да</v-chip
                    ><v-chip v-else>Нет</v-chip>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row class="px-6" fluid>
                    <v-switch
                        v-model="isActive"
                        :label="'Активно'"
                        color="success"
                    >
                    </v-switch>
                    <v-chip v-if="isActive">Да</v-chip
                    ><v-chip v-else>Нет</v-chip>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row class="px-6" fluid>
                    <v-switch
                        v-model="divideOnSubgroups"
                        :label="'Делить на подгруппы'"
                        color="success"
                    >
                    </v-switch>
                    <v-chip v-if="isActive">Да</v-chip
                    ><v-chip v-else>Нет</v-chip>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn rounded variant="outlined"  @click="closeClass">
            Отмена
          </v-btn>
          <v-btn rounded variant="outlined" @click="saveClass">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import LoaderRing from "@/elements/LoaderRing.vue";
import _ from "lodash";
import {customAxios} from "@/service/customAxios";


export default {
  name: "ScheduleClassList",
  props: [],
  components: {LoaderRing },
  data: () => ({
    componentKey:0,
    noteCount:10,
    isActive:false,
    showLoader:false,
    showClassDialog:false,

    classSearchBody: "",

    scheduleClassSortKey: "scheduleClassName",
    lastScheduleClassSortKey: "",
    scheduleClassSortOrder: 1,

    scheduleClassId: undefined,
    scheduleClassName: "",
    scheduleClassDescription: "",
    scheduleClassType: null,
    scheduleClassDuration: 40,
    classPage: 1,
    scheduleClassNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    scheduleClassDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 255) || "Значение до должно превышать 254 символов!",
    ],
    scheduleClassTypeRules: [
      (v) => (!!v && true) || "Поле обязательно для заполнения!",
    ],
    scheduleClassRules: [
      (v) => (!!v && true) || "Поле обязательно для заполнения!",
    ],
    scheduleClass: "",
    scheduleClassTextColor:"",
    scheduleClassBackgroundColor:"",
    showInLog:false,
    divideOnSubgroups:false,

    userGroups: [],
    personGroups:[],
    classTeachers:[],
    users:[],
    scheduleClassTypes:[],
    scheduleClasses:[],

  }),
  computed: {
    user() {
      return this.$store.state.authentication.user;
    },
    scheduleEventOrganizers(){
      let  source = this.users;

      let result = [];
      source.forEach(item =>{
        if(item.roles.includes("ROLE_TEACHER") ||
            item.roles.includes("ROLE_TUTOR") ||
            item.roles.includes("ROLE_MASTER") ){
          result.push(item);
        }
      })
      return result.sort(
          dynamicSort(
              "lastname",
              1
          )
      );

    },
    minutesCounter() {
      let minutes = [];
      for (let i = 0; i < 120; i++) {
        minutes[i] = i + 1;
      }
      return minutes;
    },

    activeClassTypes() {
      let classTypes = this.scheduleClassTypes;
      return classTypes.filter((item) => item.isActive === true);
    },


    filteredClasses() {
      const ss = this.classSearchBody.toLowerCase();

      let tempClasses = this.scheduleClasses;

      return tempClasses.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );
    },

    sortedClasses() {
      let tempClasses = this.filteredClasses;
      return tempClasses.sort(dynamicSort(this.scheduleClassSortKey, this.scheduleClassSortOrder));
    },
    classPageCount() {
      let l = this.sortedClasses.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l / s);
    },
    classPaginatedData() {
      const start = (this.currentClassPage - 1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.sortedClasses.slice(start, end);
    },
    currentClassPage() {
      if (this.classPageCount < this.classPage) {
        return 1;
      } else {
        return this.classPage;
      }
    },



  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    byScheduleClassName() {
      if (this.lastScheduleClassSortKey.includes("scheduleClassName")) {
        this.scheduleClassSortOrder = this.scheduleClassSortOrder * -1;
      }
      this.scheduleClassSortKey = "scheduleClassName";
      this.lastScheduleClassSortKey = this.scheduleClassSortKey;
      this.forceRerender();
    },
    byScheduleClassDescription() {
      if (this.lastScheduleClassSortKey.includes("scheduleClassDescription")) {
        this.scheduleClassSortOrder = this.scheduleClassSortOrder * -1;
      }
      this.scheduleClassSortKey = "scheduleClassDescription";
      this.lastScheduleClassSortKey = this.scheduleClassSortKey;
      this.forceRerender();
    },
    byClassCreateDate() {
      if (this.lastScheduleClassSortKey.includes("createDate")) {
        this.scheduleClassSortOrder = this.scheduleClassSortOrder * -1;
      }
      this.scheduleClassSortKey = "createDate";
      this.lastScheduleClassSortKey = this.scheduleClassSortKey;
      this.forceRerender();
    },
    byClassActive() {
      if (this.lastScheduleClassSortKey.includes("isActive")) {
        this.scheduleClassSortOrder = this.scheduleClassSortOrder * -1;
      }
      this.scheduleClassSortKey = "isActive";
      this.lastScheduleClassSortKey = this.scheduleClassSortKey;
      this.forceRerender();
    },
    byClassDuration() {
      if (this.lastScheduleClassSortKey.includes("scheduleClassDuration")) {
        this.scheduleClassSortOrder = this.scheduleClassSortOrder * -1;
      }
      this.scheduleClassSortKey = "scheduleClassDuration";
      this.lastScheduleClassSortKey = this.scheduleClassSortKey;
      this.forceRerender();
    },
    byClassType() {
      if (this.lastScheduleClassSortKey.includes("scheduleClassType")) {
        this.scheduleClassSortOrder = this.scheduleClassSortOrder * -1;
      }
      this.scheduleClassSortKey = "scheduleClassType";
      this.lastScheduleClassSortKey = this.scheduleClassSortKey;
      this.forceRerender();
    },

    editClass(item) {
      this.scheduleClassTextColor = item.scheduleClassTextColor;
      this.showInLog = item.showInLog;
      this.scheduleClassBackgroundColor = item.scheduleClassBackgroundColor;
      this.scheduleClassName = item.scheduleClassName;
      this.scheduleClassDescription = item.scheduleClassDescription;
      this.scheduleClassType = item.scheduleClassType;
      this.createDate = item.createDate;
      this.isActive = item.isActive;
      this.scheduleClassId = item.id;
      this.scheduleClassDuration = item.scheduleClassDuration;
      this.personGroups = item.personGroups;
      this.classTeachers = item.classTeachers;
      this.divideOnSubgroups = item.divideOnSubgroups;
      this.showClassDialog = true;
    },
    deleteClass(item) {
     
      if (confirm("Вы точно хотите тип занятия?")) {
        customAxios
            .delete("/schedule/class/" + item.id)
            .then((response) => {
              if (response.status === 202) {
                this.scheduleClasses.splice(
                    this.scheduleClasses.indexOf(item),
                    1
                );
              } else {
                console.log(response);
              }
            })
     
      }
    },
    saveClass() {


      this.$refs.scheduleClassForm.validate().then((result) => {
        if (result.valid) {
          if (this.scheduleClassType instanceof Object) {
            this.scheduleClassType = this.scheduleClassType.id;
          }
          let clearedTeachers = [];
          if(this.classTeachers && this.classTeachers.length>0){
            this.classTeachers.forEach(item =>{
              let teacher = {
                id: item.id
              }
              clearedTeachers.push(teacher)
            })
          }
          const ololo = this.personGroups.map(item =>item={"id":item.id} )

          let scheduleClass = {
            scheduleClassName: capitalizeFirstLetter(this.scheduleClassName),
            scheduleClassDescription: capitalizeFirstLetter(this.scheduleClassDescription),
            scheduleClassDuration: this.scheduleClassDuration,
            isActive: this.isActive,
            scheduleClassTypeId: this.scheduleClassType,
            scheduleClassTextColor: this.scheduleClassTextColor,
            scheduleClassBackgroundColor: this.scheduleClassBackgroundColor,
            personGroups: ololo,
            classTeachers: clearedTeachers,
            showInLog: this.showInLog,
            divideOnSubgroups:this.divideOnSubgroups
          };

          if (this.scheduleClassId) {
            customAxios
                .put(
                    "/schedule/class/" + this.scheduleClassId,
                    scheduleClass
                )
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.scheduleClasses, response.data.id);
                    this.scheduleClasses.splice(index, 1, response.data);
                    this.showClassDialog = false;
                    this.scheduleClassName = "";
                    this.scheduleClassDescription = "";
                    this.scheduleClassDuration = "";
                    this.scheduleClassTextColor = "";
                    this.scheduleClassBackgroundColor = "";
                    this.scheduleClassId = undefined;
                    this.scheduleClassType = undefined;
                    this.personGroups = [];
                    this.classTeachers = [];
                    this.isActive = false;
                    this.showInLog = false;
                    this.divideOnSubgroups = false;
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
               
          } else {
            customAxios
                .post("/schedule/class", scheduleClass)
                .then((response) => {
                  if (response.status === 201) {
                    this.scheduleClasses.push(response.data);
                    this.showClassDialog = false;
                    this.scheduleClassName = "";
                    this.scheduleClassDescription = "";
                    this.scheduleClassDuration = "";
                    this.scheduleClassTextColor = "";
                    this.scheduleClassBackgroundColor = "";
                    this.personGroups = [];
                    this.classTeachers = [];
                    this.scheduleClassId = undefined;
                    this.scheduleClassType = undefined;
                    this.isActive = false;
                    this.showInLog = false;
                    this.divideOnSubgroups = false;
                  } else {
                    alert("Что-то пошло не так");
                    console.log(response);
                  }
                })
               
          }
        }
      });
    },
    closeClass() {
      this.showClassDialog = false;
      this.scheduleClassName = "";
      this.scheduleClassDescription = null;
      this.scheduleClassDuration = "";
      this.scheduleClassId = undefined;
      this.scheduleClassType = null;
      this.personGroups = [];
      this.classTeachers = [];
      this.isActive = false;
      this.showInLog = false;
      this.divideOnSubgroups = false;
    },

  },
  created: function () {
    this.showLoader = true;
    
    function getUsers(customAxios) {
      return customAxios.get("/user/minimal");
    }
    function getUserGroups(customAxios) {
      return customAxios.get("/user_group");
    }
    function getScheduleClassTypes(customAxios) {
      return customAxios.get("/schedule/class/type");
    }
    function getScheduleClasses(customAxios) {
      return customAxios.get("/schedule/class");
    }





    Promise.all([
      getUsers(customAxios),
      getUserGroups(customAxios),
      getScheduleClassTypes(customAxios),
      getScheduleClasses(customAxios),
    ]).then((results) => {

      if (results[0].status === 200) {
        this.users = results[0].data;
      }
      if (results[1].status === 200) {
        this.userGroups = results[1].data;
      }
      if (results[2].status === 200) {
        this.scheduleClassTypes = results[2].data;
      }
      if (results[3].status === 200) {
        this.scheduleClasses = results[3].data;
      }

      this.showLoader = false;
    })

  },


  setup() {
    return {};
  },
};
function dynamicSort(property, sortOrder) {
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    let result;
    if (property === "scheduleClassType") {
      result =
          a.scheduleClassType.scheduleClassTypeName < b.scheduleClassType.scheduleClassTypeName
              ? -1
              : a.scheduleClassType.scheduleClassTypeName >
              b.scheduleClassType.scheduleClassTypeName
                  ? 1
                  : 0;
      return result * sortOrder;
    } else {
      result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    }
  };
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list, id) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

</script>

<style lang="scss">
.form-element {
  box-shadow: $shadow;
  display: grid;
  grid-template-columns: 40% 1fr;
  overflow: hidden;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: $gradient-1;
  }

  &__card.v-card {
    box-shadow: none;
    padding: 30px;
  }

  &__title.v-card-title {
    color: $main;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
  }

  .v-text-field__input {
    input::placeholder {
      font-size: 18px;
      line-height: 22px;
    }

    label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #6e6d94;
    }

    .v-field__outline {
      color: rgba(#6e6d94, 0.5);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;

    &__img {
      display: none;
    }
  }
}
</style>
