<template>
  <ListPreview
    :list="filteredDictations"
    title="Диктанты"
    itemTitleKey="dictationName"
    :link="link"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { computed, onMounted, ref, watch } from "vue";

import { useJournalData } from "@/composables/journalData";
import { usePopupStore } from "@/store/popup";
import { useRoute } from "vue-router";

import ListPreview from "./ListPreview.vue";

const route = useRoute();

const { showError } = usePopupStore();
const { removeEmptyArrays, getAllData } = useJournalData();

const { journal, isForeignJournal, journalDaysWithIds } = storeToRefs(
  useSubjectsJournalStore()
);

const link = computed(() => {
  const name = isForeignJournal.value ? "foreign-dictations" : "dictations";

  return {
    name: name,
    query: {
      journalId: route.query.journalId,
      dayId: journalDaysWithIds.value.length
        ? journalDaysWithIds.value[0].teacherLogDayId
        : null,
    },
  };
});

const dictations = ref([]);
const filteredDictations = computed(() => {
  if (!dictations.value.length) {
    return [];
  }

  return removeEmptyArrays(dictations.value);
});

onMounted(getData);

watch(() => route.query, getData);

async function getData() {
  if (journal.value) {
    try {
      dictations.value = await getAllDictations();
    } catch (e) {
      showError(e);
    }
  }
}

async function getAllDictations() {
  if (isForeignJournal.value) {
    return getAllData(`foreign_dictation/by_log_day`, journal.value);
  } else {
    return await getAllData(`dictation/by_log_day`, journal.value);
  }
}
</script>
