import { customAxios } from "@/service/customAxios";
import { ref } from "vue"

export function useParentData() {
    const parent = ref(null) 
    const children = ref([])
    const selectedChildId = ref(null);

    async function getUser() {
        try {
          const r = await customAxios.get("/user/i");
          parent.value = r.data;
          children.value = r.data.personChilds.map((v) => ({
            title: v.firstname + " " + v.lastname,
            value: v.id,
          }));
        } catch (e) {
          console.log(e);
        }
      }

    return {
        getUser,
        selectedChildId,
        children,
    }
}