<template>
  <div v-if="line" class="page-wrapper">
    <div class="results">
      <div
        v-for="l in line.checklists"
        :key="l.id"
        @click="open(l)"
        class="results__item"
      >
        <p>{{ getNumber(l.checklistName) }}</p>
        <div class="results__bg">
          <div class="results__circle" :class="getResultColor(l.line)"></div>
        </div>
      </div>

      <div class="results__item results__item--end">
        <p>{{ line.lineName }}</p>

        <div class="results__bg">
          <div class="results__circle"></div>
          <div class="results__arrow"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
defineProps({ line: { type: Object, required: false } });
const emits = defineEmits("openPopup");

function getNumber(title) {
  const regexp = /\d+/;
  const fromTitle = title.match(regexp);

  if (fromTitle) {
    return fromTitle.join("");
  }
  return title;
}

function getResultColor(item) {
  if (item.isEmpty) {
    return "grey";
  }

  if (item.lineBall === 0) {
    return "red";
  }

  if (item.lineBall === 1) {
    return "green";
  }

  if (item.lineBall > 0 && item.lineBall < 1) {
    return "yellow";
  }

  return "grey";
}

function open(item) {
  if (item.line.isEmpty || item.line.chapters.length === 0) {
    return;
  }

  emits("openPopup", item);
}
</script>

<style lang="scss">
.results {
  display: flex;
  flex-wrap: wrap;
  padding: 32px 8px;

  p {
    padding: 8px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  &__item {
    text-align: center;
    display: grid;
    grid-template-rows: 1fr 30px;
    position: relative;
    max-width: 200px;
  }

  &__bg {
    min-width: 50px;
    height: 30px;
    position: relative;
  }

  &__circle {
    height: 100%;
    width: 100%;

    &::before {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      position: absolute;
      content: "";
      height: 100%;
      aspect-ratio: 1/1;
      border: 2px solid rgba($secondary, 0.5);
      border-radius: 50%;
      background-color: #fff;
    }

    &::after {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      content: "";
      height: 20%;
      width: 100%;
      left: 0;
      background-color: rgba($secondary, 0.5);
    }

    &.green::before {
      background-color: green;
    }
    &.grey::before {
      background-color: lightgrey;
    }
    &.red::before {
      background-color: red;
    }
    &.yellow::before {
      background-color: yellow;
    }
  }

  &__arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(100%, -50%);
    border: 16px solid transparent;
    border-left: 16px solid rgba($secondary, 0.5);
  }

  &__item--end {
    p {
      font-weight: 600;
      color: $secondary;
    }

    .results__circle::before {
      display: none;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    p {
      justify-content: flex-start;
    }

    &__item {
      display: grid;
      grid-template-columns: 50px 1fr;
      column-gap: 16px;
      grid-template-rows: 1fr;
      max-width: none;
      min-height: 50px;

      p {
        grid-column: 2 / -1;
        grid-row: 1/-1;
      }
    }

    &__bg {
      height: 100%;
      width: 50px;
      min-width: auto;
      grid-column: 1 / 2;
      grid-row: 1/-1;
    }

    &__circle {
      &::after {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 10px;
      }

      &::before {
        height: 40px;
      }
    }

    &__arrow {
      top: 0;
      left: 50%;
      right: auto;
      transform: translate(-50%, -100%) rotate(-90deg);
    }

    &__item--end {
      padding-top: 20px;
    }
  }
}
</style>
