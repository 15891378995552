<template>
  <div>
    <PageTitle title="Журнал" />
    <SubjectsJournalsSearch />
    <div v-if="journal">
      <SubjectJournalPreview class="mt-3" />
      <SubjectJournalTable class="mt-3" @updateLists="emits('updateLists')" />
    </div>
    <div v-else class="page-wrapper mt-3 d-flex justify-center">
      <v-label class="text-deep-purple-darken-1 font-weight-bold"
        >Здесь появится журнал</v-label
      >
    </div>
  </div>
</template>

<script setup>
import PageTitle from "@/elements/PageTitle.vue";
import SubjectJournalTable from "./SubjectJournalTable.vue";
import SubjectJournalPreview from "./SubjectJournalPreview.vue";
import SubjectsJournalsSearch from "./components/SubjectsJournalsSearch.vue";

import { storeToRefs } from "pinia";
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { defineEmits } from "vue";

const emits = defineEmits(["updateLists"]);

const { journal } = storeToRefs(useSubjectsJournalStore());
</script>
