<template>
  <div>
    <v-label class="text-deep-purple-darken-1 font-weight-bold mb-2"
      >Сортировка</v-label
    >
    <v-select
      v-model="sort"
      :items="[
        { title: 'По убыванию даты', value: 'descending' },
        { title: 'По возрастанию даты', value: 'ascending' },
      ]"
      variant="outlined"
      density="compact"
      hide-details
      class="d-flex justify-start"
    ></v-select>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { removeFilterFromURL, pushFilterToURL } from "@/service/router";
import { useRoute } from "vue-router";

const sort = ref("descending");
const route = useRoute();

watch(sort, (v) =>
  v
    ? pushFilterToURL({ name: "sortByDate", value: v })
    : removeFilterFromURL("sortByDate")
);

onMounted(setCurrentFilters);

function setCurrentFilters() {
  if (route.query.sortByDate) {
    sort.value = route.query.sortByDate;
  } else {
    pushFilterToURL({ name: "sortByDate", value: sort.value });
  }
}
</script>
