<template>
  <LoaderRing v-if="loading" />
  <router-view v-else></router-view>
</template>

<script setup>
import { useIstudio } from "@/composables/istudio";
import { onMounted } from "vue";

import LoaderRing from "@/elements/LoaderRing.vue";
const { updateStudios, loading } = useIstudio();

onMounted(updateStudios);
</script>
