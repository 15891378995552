import { customAxios } from "@/service/customAxios";
import { useDatesChanger } from "./datesChanger";

export function useCognations() {
  const MONTH = 30;
const { setDateTimeNoSeconds } = useDatesChanger();

  async function getCategoriesHandler() {
    return customAxios.get("/cognation/category/active");
  }

  async function getMyCognationsHandler() {
    return customAxios.get("/cognation/my");
  }

  async function getCognationsHandler() {
    return customAxios.post("/cognation/all_cognations_on_period", setPeriod());
  }

  function setPeriod() {
    const end = new Date();
    let start = new Date();
    start.setDate(end.getDate() - MONTH);
    end.setDate(end.getDate() + 1);
  
    return {
      beginDate: setDateTimeNoSeconds(start),
      endDate: setDateTimeNoSeconds(end),
    };
  }

  return {
    getCognationsHandler,
    getCategoriesHandler,
    getMyCognationsHandler,
  };
}
